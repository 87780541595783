import { TextField } from "@mui/material";
import React, { useEffect } from "react";

interface Props {
  value: string;
  setValue: (value: string) => void;
  label: string;
  name: string;
  helperText: string;
  runValidation: boolean | "true";
  valueError: null | string;
  setValueError: (value: null | string) => void;
}

const RequiredTextField = ({
  value,
  setValue,
  label,
  name,
  helperText,
  runValidation,
  valueError,
  setValueError,
}: Props) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (valueError !== null) {
      setValueError(null);
    }
    const { value } = event.target;
    setValue(value);
  };

  const validateValue = (value: string) => {
    const isValid = value.length > 0;
    if (isValid === false) {
      setValueError(helperText);
    } else {
      setValueError(null);
    }
    return isValid;
  };

  useEffect(() => {
    if (runValidation) {
      validateValue(value);
    }
  }, [runValidation]);

  return (
    <>
      <TextField
        fullWidth
        label={label}
        name={name}
        value={value}
        onChange={handleValueChange}
        error={!!valueError}
        helperText={valueError}
      />
    </>
  );
};

export default RequiredTextField;
