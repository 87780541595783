import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDeleteUser } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebase";

const DeleteUser = () => {
  const [deleteUser, loading, error] = useDeleteUser(auth);

  const [toggleDeleteWindow, setToggleDeleteWindow] = useState(false);
  const [sureAboutDelete, setSureAboutDelete] = useState(false);

  return (
    <>
      <Modal
        open={toggleDeleteWindow}
        onClose={() => setToggleDeleteWindow(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Card sx={{ borderRadius: "10px", padding: "40px" }}>
          <Typography variant="h4" align="center" sx={{ marginBottom: "20px" }}>
            Är du säker på att du vill radera ditt konto?
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ marginBottom: "20px" }}
          >
            Om du raderar ditt konto kommer all din information att raderas och
            kan inte återställas.
          </Typography>
          <FormGroup sx={{ marginBottom: "20px" }}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={sureAboutDelete}
                  onChange={() => setSureAboutDelete(!sureAboutDelete)}
                />
              }
              label="Jag är säker på att jag vill radera mitt konto."
            />
          </FormGroup>
          <Button
            variant="contained"
            color="error"
            disabled={!sureAboutDelete}
            fullWidth
            onClick={async () => deleteUser()}
          >
            Radera konto
          </Button>
        </Card>
      </Modal>
      <Button
        variant="outlined"
        color="error"
        sx={{ maxHeight: "36.5px", marginTop: "20px" }}
        onClick={() => {
          setToggleDeleteWindow(true);
        }}
      >
        Radera konto
      </Button>
    </>
  );
};

export default DeleteUser;
