import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NotFoundScreen() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          gap: "15px",
          rowGap: "15px",
          maxWidth: "800px",
          margin: "auto",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <Typography variant="h2" fontSize={"2em"}>
          {"Vi har tyvärr ingen sida här :("}
        </Typography>
        <Typography>
          Även kallat 404, sidan du försöker nå finns inte.
        </Typography>
        <Typography>Kanske du istället vill:</Typography>
        <Button variant="contained" onClick={() => navigate("/logga-in")}>
          Logga in
        </Button>
        <Typography>eller</Typography>
        <Button variant="contained" onClick={() => navigate("/skapa-konto")}>
          Skapa konto
        </Button>
      </Box>
    </Box>
  );
}

export default NotFoundScreen;
