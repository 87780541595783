import React from "react";
import { Typography, Box } from "@mui/material";
import ConcentBanner from "../consent/ConcentBanner";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer style={{ width: "100%" }}>
      <Box sx={{ width: "100%", paddingY: "5px" }}>
        <ConcentBanner />
        <Typography align="center">
          <Link to="/integritetspolicy">Integritetspolicy</Link>
        </Typography>
        <Typography align="center">
          &copy; {new Date().getFullYear()} aplplats.se - Alla rättigheter är
          reserverade.
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
