import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { LoadingOverlay } from "../commons/LoadingOverlay";
import useConsent from "../../hooks/useConsent";
import { Link, useLocation } from "react-router-dom";

const ConsentBanner = () => {
  const [open, setOpen] = useState(true);
  const {
    consent,
    postCosent,
    setConsent,
    loadingConsent,
    consentFetchError,
    consentDeclinedError,
  } = useConsent();

  const location = useLocation();

  const handleAccept = async () => {
    await postCosent();
    setOpen(false);
  };

  useEffect(() => {
    if (consent === false) {
      if (open === false) {
        setOpen(true);
      }
    }
    if (consent === undefined && open === false) {
      setOpen(true);
    }
  }, [consentDeclinedError]);

  return (
    <>
      {loadingConsent === false &&
        (consent === false ||
          (consent === undefined &&
            location.pathname !== "/integritetspolicy")) && (
          <Modal
            open={open}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "24px",
                borderRadius: "10px",
                maxWidth: "600px",
                height: "auto",
                minHeight: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                <Typography variant="body1" align="center">
                  Vi använder kakor (cookies) för att förbättra din upplevelse
                  på vår webbplats. Genom att fortsätta använda vår webbplats
                  accepterar du vår användning av kakor. Läs mer i vår{" "}
                  <Link to={"/integritetspolicy"} target="__blank">
                    intigritetspolicy
                  </Link>
                  .
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" onClick={handleAccept}>
                  Acceptera nödvändiga kakor
                </Button>
              </Box>
            </Box>
          </Modal>
        )}
    </>
  );
};

export default ConsentBanner;
