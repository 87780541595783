import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getAppCheckToken } from "../utils/appCheck";
import { ConsentContext } from "../context/consentContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";
import { CreateConsentRequest } from "../api/types/consentCollection.api";

const useConsent = () => {
  const [user, loading, error] = useAuthState(auth);
  const { consent, loadingConsentInContext, setConsent } =
    useContext(ConsentContext);
  const [loadingConsent, setLoadingConsent] = useState(false);
  const [consentFetchError, setConsentFechError] = useState<Error | undefined>(
    undefined
  );
  const [consentDeclinedError, setConsentDeclinedError] = useState<
    null | string
  >(null);
  const [ipAddress, setIpAddress] = useState<string | undefined>(undefined);

  const postCosent = async () => {
    const now = new Date();

    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(now.getDate() + 30);

    const twoYearsFromNow = new Date();
    twoYearsFromNow.setFullYear(now.getFullYear() + 2);

    if (ipAddress === undefined) {
      return setConsentFechError(new Error("ipAddress is undefined"));
    }

    const gdprConsent = {
      ipAddress: ipAddress,
      userAgent: navigator.userAgent,
      consentTimestamp: now.toISOString(),
      userConsentExpirationTimestamp: user?.uid
        ? twoYearsFromNow.toISOString()
        : undefined,
      userId: user?.uid,
      acceptsNecessary: true,
      localValidUntil: thirtyDaysFromNow.toISOString(),
    };

    setConsent(gdprConsent);

    const gdprConsentRequestData: CreateConsentRequest = {
      ipAddress: gdprConsent.ipAddress,
      userAgent: gdprConsent.userAgent,
      consentTimestamp: gdprConsent.consentTimestamp,
      userId: gdprConsent.userId,
      userConsentExpirationTimestamp:
        gdprConsent.userConsentExpirationTimestamp,
      acceptsNecessary: gdprConsent.acceptsNecessary,
    };

    if (gdprConsentRequestData.userId === undefined) {
      delete gdprConsentRequestData.userId;
    }
    if (gdprConsentRequestData.userConsentExpirationTimestamp === undefined) {
      delete gdprConsentRequestData.userConsentExpirationTimestamp;
    }

    try {
      setLoadingConsent(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/consent`,
        gdprConsentRequestData,
        {
          headers: {
            "X-Firebase-AppCheck": await getAppCheckToken(
              process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
              consent !== false && consent !== undefined
            ),
            "Content-Type": "application/json",
          },
        }
      );
      setLoadingConsent(false);
    } catch (err) {
      setConsentFechError(err as Error);
      setLoadingConsent(false);
    }
  };

  useEffect(() => {
    if (loadingConsentInContext === true && loadingConsent === false) {
      setLoadingConsent(true);
    }
    if (loadingConsentInContext === false && loadingConsent === true) {
      setLoadingConsent(false);
    }
  }, [loadingConsentInContext]);

  useEffect(() => {
    const getIpAddress = async () => {
      setLoadingConsent(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/ip`);
        const ip =
          process.env.REACT_APP_NODE_ENV === "development" // Because its not possible to get the ip in development
            ? "123"
            : res.data.ip;
        setIpAddress(ip);
      } catch (error) {
        setConsentFechError(error as Error);
      } finally {
        setLoadingConsent(false);
      }
    };

    getIpAddress();
  }, []);

  useEffect(() => {
    if (consent === false) {
      setConsentDeclinedError(
        "Ni måste acceptera nödvändliga cookies för att kunna använda sidan."
      );
    }
    if (consent !== false) {
      setConsentDeclinedError(null);
    }
  }, [consent]);

  return {
    consent,
    setConsent,
    postCosent,
    loadingConsent,
    consentFetchError,
    consentDeclinedError,
  };
};

export default useConsent;
