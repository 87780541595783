import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import DashboardScreen from "./screens/DashboardScreen";
import RegisterInterestScreen from "./screens/RegisterInterestScreen";
import LoginScreen from "./screens/LoginScreen";
import { CssBaseline } from "@mui/material";
import RequestHeadersProvider from "./context/requestHeadersContext";
import FirebaseAuthActions from "./components/actions/auth/FirebaseAuthActions";
import EmailVerifiedScreen from "./screens/EmailVerifiedScreen";
import { ProtectedRoute } from "./components/actions/routing/ProtectedRoute";
import { PublicOnlyRoute } from "./components/actions/routing/PublicOnlyRoute";
import RegisterCompanyScreen from "./screens/RegisterCompanyScreen";
import UserAccountProvider from "./context/userAccountContext";
import NotFoundScreen from "./screens/NotFoundScreen";
import FiebaseSignOutAction from "./components/actions/auth/FiebaseSignOutAction";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import HeaderPublicView from "./components/base/HeaderPublicView";
import Footer from "./components/base/Footer";
import ConsentProvider from "./context/consentContext";
import EmailVerifiedCompanyScreen from "./screens/EmailVerifiedCompanyScreen";
import HeaderProtectedView from "./components/base/HeaderProtectedView";
import CompanyProfileScreen from "./screens/ProfileScreen";
import ThankRegistrationCompanyScreen from "./screens/ThankRegistrationCompanyScreen";
import LoadingState from "./components/base/LoadingState";
import AccountLoadingProvider from "./context/accountLoadingContext";
import Background from "./components/base/Background";
import EditCompanyProfileScreen from "./screens/EditCompanyProfileScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <HeaderProtectedView />
        <DashboardScreen />
        <Footer />
      </ProtectedRoute>
    ),
  },
  {
    path: "/profil/redigera",
    element: (
      <ProtectedRoute>
        <HeaderProtectedView />
        <EditCompanyProfileScreen />
        <Footer />
      </ProtectedRoute>
    ),
  },
  {
    path: "/profil",
    element: (
      <ProtectedRoute>
        <HeaderProtectedView />
        <CompanyProfileScreen />
        <Footer />
      </ProtectedRoute>
    ),
  },
  {
    path: "/foretag/tack",
    element: (
      <ProtectedRoute>
        <Background minHeight="100vh">
          <HeaderPublicView />
          <ThankRegistrationCompanyScreen />
        </Background>
        <Footer />
      </ProtectedRoute>
    ),
  },
  {
    path: "/skapa-konto",
    element: (
      <PublicOnlyRoute>
        <Background minHeight="100vh">
          <HeaderPublicView />
          <RegisterInterestScreen />
        </Background>
        <Footer />
      </PublicOnlyRoute>
    ),
  },
  {
    path: "/logga-in",
    element: (
      <PublicOnlyRoute>
        <Background minHeight="100vh">
          <HeaderPublicView />
          <LoginScreen />
        </Background>
        <Footer />
      </PublicOnlyRoute>
    ),
  },
  {
    path: "/epost-verifierad",
    element: (
      <>
        <Background minHeight="100vh">
          <HeaderPublicView />
          <EmailVerifiedScreen />
        </Background>
        <Footer />
      </>
    ),
  },
  {
    path: "/foretag/epost-verifierad",
    element: (
      <>
        <Background minHeight="100vh">
          <HeaderPublicView />
          <EmailVerifiedCompanyScreen />
        </Background>
        <Footer />
      </>
    ),
  },
  {
    path: "foretag/skapa-konto",
    element: (
      <PublicOnlyRoute>
        <Background minHeight="100vh">
          <HeaderPublicView />
          <RegisterCompanyScreen />
        </Background>
        <Footer />
      </PublicOnlyRoute>
    ),
  },
  {
    path: "/integritetspolicy",
    element: (
      <>
        <Background minHeight="100vh">
          <HeaderPublicView />
          <PrivacyPolicyScreen />
        </Background>
        <Footer />
      </>
    ),
  },
  {
    path: "/auth-action",
    element: <FirebaseAuthActions />,
  },
  {
    path: "/logga-ut",
    element: (
      <>
        <FiebaseSignOutAction />
      </>
    ),
  },
  {
    path: "*",
    element: (
      <>
        <Background minHeight="100vh">
          <HeaderPublicView />
          <NotFoundScreen />
        </Background>
        <Footer />
      </>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AccountLoadingProvider>
      <ConsentProvider>
        <UserAccountProvider>
          <RequestHeadersProvider>
            <LoadingState />
            <CssBaseline />
            <RouterProvider router={router} />
          </RequestHeadersProvider>
        </UserAccountProvider>
      </ConsentProvider>
    </AccountLoadingProvider>
  </React.StrictMode>
);
