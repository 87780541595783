import { Box } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;
  height?: string;
  minHeight?: string;
}

const Background = ({ children, height, minHeight }: Props) => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(35deg, rgb(241, 89, 90) 0%, rgb(236, 194, 123) 73%)",
        minHeight: minHeight ?? "auto",
        height: height ?? "auto",
        padding: "20px",
      }}
    >
      {children}
    </Box>
  );
};

export default Background;
