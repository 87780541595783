import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Image from "../commons/Image";

function HeaderPublicView() {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        onClick={() => navigate("/")}
        sx={{
          cursor: "pointer",
          paddingX: "20px",
          paddingY: "5px",
          marginBottom: "20px",
          width: "200px",
          height: "auto",
        }}
      >
        <Image
          height="50px"
          margin="0"
          padding="5px"
          maxHeight="50px"
          maxWidth="auto"
          objectFit="contain"
          width="auto"
          src="https://firebasestorage.googleapis.com/v0/b/apl-plats.appspot.com/o/APLplats--logo-white.png?alt=media&token=bdb93d2e-126b-463e-b868-8ebec0eb7a92"
        />
      </Box>
    </Box>
  );
}

export default HeaderPublicView;
