import React from "react";
import ContentAreaWrapper from "../components/base/ContentAreaWrapper";
import { useUserAccount } from "../hooks/useUserAccount";
import EditCompanyProfile from "../components/profile/EditCompanyProfile";

const EditCompanyProfileScreen = () => {
  const { companyAccount } = useUserAccount();
  return (
    <ContentAreaWrapper>
      {companyAccount && <EditCompanyProfile companyProfile={companyAccount} />}
    </ContentAreaWrapper>
  );
};

export default EditCompanyProfileScreen;
