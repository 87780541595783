import { Backdrop, CircularProgress } from "@mui/material";

interface Args {
  loading: boolean;
}

export const LoadingOverlay = ({ loading }: Args) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
