import React from "react";
import { Box, Typography } from "@mui/material";

const EmailVerifiedCompanyScreen = () => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          gap: "15px",
          rowGap: "15px",
          maxWidth: "800px",
          margin: "auto",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <Typography variant="h2" fontSize={"2em"}>
          Din företagsprofil är nu verifierad!
        </Typography>
        <Typography>
          Du kan nu logga in och börja använda tjänsten så fort vi har lanserat.
        </Typography>
      </Box>
    </Box>
  );
};

export default EmailVerifiedCompanyScreen;
