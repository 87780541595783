import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
  TextFieldVariants,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import { isValidPostalCode } from "../../utils/validations";

interface Props {
  postalCode: string;
  setPostalCode: (value: string) => void;
  label: string;
  runValidation: boolean | "true";
  postalCodeError: string | null;
  setPostalCodeError: (value: string | null) => void;
}

const PostalCodeTextField = ({
  postalCode,
  setPostalCode,
  label,
  runValidation,
  postalCodeError,
  setPostalCodeError,
}: Props) => {
  const handlePostalCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (postalCodeError !== null) {
      setPostalCodeError(null);
    }
    const { value } = event.target;
    setPostalCode(value);
  };

  const validatePostalCode = (postalCode: string) => {
    const isValid = isValidPostalCode(postalCode);
    if (isValid === false) {
      setPostalCodeError("Ogiligt postnummer. Försök igen.");
    } else {
      setPostalCodeError(null);
    }
    return isValid;
  };

  useEffect(() => {
    if (runValidation) {
      validatePostalCode(postalCode);
    }
  }, [runValidation]);

  return (
    <>
      <MaskedInput
        style={{ width: "100%" }}
        name="postal-code"
        onChange={handlePostalCodeChange}
        mask={[/\d/, /\d/, /\d/, " ", /\d/, /\d/]}
        render={(
          innerRef: React.Ref<any> | undefined,
          props: JSX.IntrinsicAttributes & {
            variant?: TextFieldVariants | undefined;
          } & Omit<
              | FilledTextFieldProps
              | OutlinedTextFieldProps
              | StandardTextFieldProps,
              "variant"
            >
        ) => (
          <TextField
            fullWidth
            label={label}
            error={!!postalCodeError}
            helperText={postalCodeError}
            value={postalCode}
            placeholder="123 45"
            {...props}
            inputRef={innerRef}
          />
        )}
      />
    </>
  );
};

export default PostalCodeTextField;
