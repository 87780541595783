interface Kommun {
  kommunkod: string;
  namn: string;
}

const kommuner: Kommun[] = [
  {
    kommunkod: "0114",
    namn: "Upplands Väsby",
  },
  {
    kommunkod: "0115",
    namn: "Vallentuna",
  },
  {
    kommunkod: "0117",
    namn: "Österåker",
  },
  {
    kommunkod: "0120",
    namn: "Värmdö",
  },
  {
    kommunkod: "0123",
    namn: "Järfälla",
  },
  {
    kommunkod: "0125",
    namn: "Ekerö",
  },
  {
    kommunkod: "0126",
    namn: "Huddinge",
  },
  {
    kommunkod: "0127",
    namn: "Botkyrka",
  },
  {
    kommunkod: "0128",
    namn: "Salem",
  },
  {
    kommunkod: "0136",
    namn: "Haninge",
  },
  {
    kommunkod: "0138",
    namn: "Tyresö",
  },
  {
    kommunkod: "0139",
    namn: "Upplands-Bro",
  },
  {
    kommunkod: "0140",
    namn: "Nykvarn",
  },
  {
    kommunkod: "0160",
    namn: "Täby",
  },
  {
    kommunkod: "0162",
    namn: "Danderyd",
  },
  {
    kommunkod: "0163",
    namn: "Sollentuna",
  },
  {
    kommunkod: "0180",
    namn: "Stockholm",
  },
  {
    kommunkod: "0181",
    namn: "Södertälje",
  },
  {
    kommunkod: "0182",
    namn: "Nacka",
  },
  {
    kommunkod: "0183",
    namn: "Sundbyberg",
  },
  {
    kommunkod: "0184",
    namn: "Solna",
  },
  {
    kommunkod: "0186",
    namn: "Lidingö",
  },
  {
    kommunkod: "0187",
    namn: "Vaxholm",
  },
  {
    kommunkod: "0188",
    namn: "Norrtälje",
  },
  {
    kommunkod: "0191",
    namn: "Sigtuna",
  },
  {
    kommunkod: "0192",
    namn: "Nynäshamn",
  },
  {
    kommunkod: "0305",
    namn: "Håbo",
  },
  {
    kommunkod: "0319",
    namn: "Älvkarleby",
  },
  {
    kommunkod: "0330",
    namn: "Knivsta",
  },
  {
    kommunkod: "0331",
    namn: "Heby",
  },
  {
    kommunkod: "0360",
    namn: "Tierp",
  },
  {
    kommunkod: "0380",
    namn: "Uppsala",
  },
  {
    kommunkod: "0381",
    namn: "Enköping",
  },
  {
    kommunkod: "0382",
    namn: "Östhammar",
  },
  {
    kommunkod: "0428",
    namn: "Vingåker",
  },
  {
    kommunkod: "0461",
    namn: "Gnesta",
  },
  {
    kommunkod: "0480",
    namn: "Nyköping",
  },
  {
    kommunkod: "0481",
    namn: "Oxelösund",
  },
  {
    kommunkod: "0482",
    namn: "Flen",
  },
  {
    kommunkod: "0483",
    namn: "Katrineholm",
  },
  {
    kommunkod: "0484",
    namn: "Eskilstuna",
  },
  {
    kommunkod: "0486",
    namn: "Strängnäs",
  },
  {
    kommunkod: "0488",
    namn: "Trosa",
  },
  {
    kommunkod: "0509",
    namn: "Ödeshög",
  },
  {
    kommunkod: "0512",
    namn: "Ydre",
  },
  {
    kommunkod: "0513",
    namn: "Kinda",
  },
  {
    kommunkod: "0560",
    namn: "Boxholm",
  },
  {
    kommunkod: "0561",
    namn: "Åtvidaberg",
  },
  {
    kommunkod: "0562",
    namn: "Finspång",
  },
  {
    kommunkod: "0563",
    namn: "Valdemarsvik",
  },
  {
    kommunkod: "0580",
    namn: "Linköping",
  },
  {
    kommunkod: "0581",
    namn: "Norrköping",
  },
  {
    kommunkod: "0582",
    namn: "Söderköping",
  },
  {
    kommunkod: "0583",
    namn: "Motala",
  },
  {
    kommunkod: "0584",
    namn: "Vadstena",
  },
  {
    kommunkod: "0586",
    namn: "Mjölby",
  },
  {
    kommunkod: "0604",
    namn: "Aneby",
  },
  {
    kommunkod: "0617",
    namn: "Gnosjö",
  },
  {
    kommunkod: "0642",
    namn: "Mullsjö",
  },
  {
    kommunkod: "0643",
    namn: "Habo",
  },
  {
    kommunkod: "0662",
    namn: "Gislaved",
  },
  {
    kommunkod: "0665",
    namn: "Vaggeryd",
  },
  {
    kommunkod: "0680",
    namn: "Jönköping",
  },
  {
    kommunkod: "0682",
    namn: "Nässjö",
  },
  {
    kommunkod: "0683",
    namn: "Värnamo",
  },
  {
    kommunkod: "0684",
    namn: "Sävsjö",
  },
  {
    kommunkod: "0685",
    namn: "Vetlanda",
  },
  {
    kommunkod: "0686",
    namn: "Eksjö",
  },
  {
    kommunkod: "0687",
    namn: "Tranås",
  },
  {
    kommunkod: "0760",
    namn: "Uppvidinge",
  },
  {
    kommunkod: "0761",
    namn: "Lessebo",
  },
  {
    kommunkod: "0763",
    namn: "Tingsryd",
  },
  {
    kommunkod: "0764",
    namn: "Alvesta",
  },
  {
    kommunkod: "0765",
    namn: "Älmhult",
  },
  {
    kommunkod: "0767",
    namn: "Markaryd",
  },
  {
    kommunkod: "0780",
    namn: "Växjö",
  },
  {
    kommunkod: "0781",
    namn: "Ljungby",
  },
  {
    kommunkod: "0821",
    namn: "Högsby",
  },
  {
    kommunkod: "0834",
    namn: "Torsås",
  },
  {
    kommunkod: "0840",
    namn: "Mörbylånga",
  },
  {
    kommunkod: "0860",
    namn: "Hultsfred",
  },
  {
    kommunkod: "0861",
    namn: "Mönsterås",
  },
  {
    kommunkod: "0862",
    namn: "Emmaboda",
  },
  {
    kommunkod: "0880",
    namn: "Kalmar",
  },
  {
    kommunkod: "0881",
    namn: "Nybro",
  },
  {
    kommunkod: "0882",
    namn: "Oskarshamn",
  },
  {
    kommunkod: "0883",
    namn: "Västervik",
  },
  {
    kommunkod: "0884",
    namn: "Vimmerby",
  },
  {
    kommunkod: "0885",
    namn: "Borgholm",
  },
  {
    kommunkod: "0980",
    namn: "Gotland",
  },
  {
    kommunkod: "1060",
    namn: "Olofström",
  },
  {
    kommunkod: "1080",
    namn: "Karlskrona",
  },
  {
    kommunkod: "1081",
    namn: "Ronneby",
  },
  {
    kommunkod: "1082",
    namn: "Karlshamn",
  },
  {
    kommunkod: "1083",
    namn: "Sölvesborg",
  },
  {
    kommunkod: "1214",
    namn: "Svalöv",
  },
  {
    kommunkod: "1230",
    namn: "Staffanstorp",
  },
  {
    kommunkod: "1231",
    namn: "Burlöv",
  },
  {
    kommunkod: "1233",
    namn: "Vellinge",
  },
  {
    kommunkod: "1256",
    namn: "Östra Göinge",
  },
  {
    kommunkod: "1257",
    namn: "Örkelljunga",
  },
  {
    kommunkod: "1260",
    namn: "Bjuv",
  },
  {
    kommunkod: "1261",
    namn: "Kävlinge",
  },
  {
    kommunkod: "1262",
    namn: "Lomma",
  },
  {
    kommunkod: "1263",
    namn: "Svedala",
  },
  {
    kommunkod: "1264",
    namn: "Skurup",
  },
  {
    kommunkod: "1265",
    namn: "Sjöbo",
  },
  {
    kommunkod: "1266",
    namn: "Hörby",
  },
  {
    kommunkod: "1267",
    namn: "Höör",
  },
  {
    kommunkod: "1270",
    namn: "Tomelilla",
  },
  {
    kommunkod: "1272",
    namn: "Bromölla",
  },
  {
    kommunkod: "1273",
    namn: "Osby",
  },
  {
    kommunkod: "1275",
    namn: "Perstorp",
  },
  {
    kommunkod: "1276",
    namn: "Klippan",
  },
  {
    kommunkod: "1277",
    namn: "Åstorp",
  },
  {
    kommunkod: "1278",
    namn: "Båstad",
  },
  {
    kommunkod: "1280",
    namn: "Malmö",
  },
  {
    kommunkod: "1281",
    namn: "Lund",
  },
  {
    kommunkod: "1282",
    namn: "Landskrona",
  },
  {
    kommunkod: "1283",
    namn: "Helsingborg",
  },
  {
    kommunkod: "1284",
    namn: "Höganäs",
  },
  {
    kommunkod: "1285",
    namn: "Eslöv",
  },
  {
    kommunkod: "1286",
    namn: "Ystad",
  },
  {
    kommunkod: "1287",
    namn: "Trelleborg",
  },
  {
    kommunkod: "1290",
    namn: "Kristianstad",
  },
  {
    kommunkod: "1291",
    namn: "Simrishamn",
  },
  {
    kommunkod: "1292",
    namn: "Ängelholm",
  },
  {
    kommunkod: "1293",
    namn: "Hässleholm",
  },
  {
    kommunkod: "1315",
    namn: "Hylte",
  },
  {
    kommunkod: "1380",
    namn: "Halmstad",
  },
  {
    kommunkod: "1381",
    namn: "Laholm",
  },
  {
    kommunkod: "1382",
    namn: "Falkenberg",
  },
  {
    kommunkod: "1383",
    namn: "Varberg",
  },
  {
    kommunkod: "1384",
    namn: "Kungsbacka",
  },
  {
    kommunkod: "1401",
    namn: "Härryda",
  },
  {
    kommunkod: "1402",
    namn: "Partille",
  },
  {
    kommunkod: "1407",
    namn: "Öckerö",
  },
  {
    kommunkod: "1415",
    namn: "Stenungsund",
  },
  {
    kommunkod: "1419",
    namn: "Tjörn",
  },
  {
    kommunkod: "1421",
    namn: "Orust",
  },
  {
    kommunkod: "1427",
    namn: "Sotenäs",
  },
  {
    kommunkod: "1430",
    namn: "Munkedal",
  },
  {
    kommunkod: "1435",
    namn: "Tanum",
  },
  {
    kommunkod: "1438",
    namn: "Dals-Ed",
  },
  {
    kommunkod: "1439",
    namn: "Färgelanda",
  },
  {
    kommunkod: "1440",
    namn: "Ale",
  },
  {
    kommunkod: "1441",
    namn: "Lerum",
  },
  {
    kommunkod: "1442",
    namn: "Vårgårda",
  },
  {
    kommunkod: "1443",
    namn: "Bollebygd",
  },
  {
    kommunkod: "1444",
    namn: "Grästorp",
  },
  {
    kommunkod: "1445",
    namn: "Essunga",
  },
  {
    kommunkod: "1446",
    namn: "Karlsborg",
  },
  {
    kommunkod: "1447",
    namn: "Gullspång",
  },
  {
    kommunkod: "1452",
    namn: "Tranemo",
  },
  {
    kommunkod: "1460",
    namn: "Bengtsfors",
  },
  {
    kommunkod: "1461",
    namn: "Mellerud",
  },
  {
    kommunkod: "1462",
    namn: "Lilla Edet",
  },
  {
    kommunkod: "1463",
    namn: "Mark",
  },
  {
    kommunkod: "1465",
    namn: "Svenljunga",
  },
  {
    kommunkod: "1466",
    namn: "Herrljunga",
  },
  {
    kommunkod: "1470",
    namn: "Vara",
  },
  {
    kommunkod: "1471",
    namn: "Götene",
  },
  {
    kommunkod: "1472",
    namn: "Tibro",
  },
  {
    kommunkod: "1473",
    namn: "Töreboda",
  },
  {
    kommunkod: "1480",
    namn: "Göteborg",
  },
  {
    kommunkod: "1481",
    namn: "Mölndal",
  },
  {
    kommunkod: "1482",
    namn: "Kungälv",
  },
  {
    kommunkod: "1484",
    namn: "Lysekil",
  },
  {
    kommunkod: "1485",
    namn: "Uddevalla",
  },
  {
    kommunkod: "1486",
    namn: "Strömstad",
  },
  {
    kommunkod: "1487",
    namn: "Vänersborg",
  },
  {
    kommunkod: "1488",
    namn: "Trollhättan",
  },
  {
    kommunkod: "1489",
    namn: "Alingsås",
  },
  {
    kommunkod: "1490",
    namn: "Borås",
  },
  {
    kommunkod: "1491",
    namn: "Ulricehamn",
  },
  {
    kommunkod: "1492",
    namn: "Åmål",
  },
  {
    kommunkod: "1493",
    namn: "Mariestad",
  },
  {
    kommunkod: "1494",
    namn: "Lidköping",
  },
  {
    kommunkod: "1495",
    namn: "Skara",
  },
  {
    kommunkod: "1496",
    namn: "Skövde",
  },
  {
    kommunkod: "1497",
    namn: "Hjo",
  },
  {
    kommunkod: "1498",
    namn: "Tidaholm",
  },
  {
    kommunkod: "1499",
    namn: "Falköping",
  },
  {
    kommunkod: "1715",
    namn: "Kil",
  },
  {
    kommunkod: "1730",
    namn: "Eda",
  },
  {
    kommunkod: "1737",
    namn: "Torsby",
  },
  {
    kommunkod: "1760",
    namn: "Storfors",
  },
  {
    kommunkod: "1761",
    namn: "Hammarö",
  },
  {
    kommunkod: "1762",
    namn: "Munkfors",
  },
  {
    kommunkod: "1763",
    namn: "Forshaga",
  },
  {
    kommunkod: "1764",
    namn: "Grums",
  },
  {
    kommunkod: "1765",
    namn: "Årjäng",
  },
  {
    kommunkod: "1766",
    namn: "Sunne",
  },
  {
    kommunkod: "1780",
    namn: "Karlstad",
  },
  {
    kommunkod: "1781",
    namn: "Kristinehamn",
  },
  {
    kommunkod: "1782",
    namn: "Filipstad",
  },
  {
    kommunkod: "1783",
    namn: "Hagfors",
  },
  {
    kommunkod: "1784",
    namn: "Arvika",
  },
  {
    kommunkod: "1785",
    namn: "Säffle",
  },
  {
    kommunkod: "1814",
    namn: "Lekeberg",
  },
  {
    kommunkod: "1860",
    namn: "Laxå",
  },
  {
    kommunkod: "1861",
    namn: "Hallsberg",
  },
  {
    kommunkod: "1862",
    namn: "Degerfors",
  },
  {
    kommunkod: "1863",
    namn: "Hällefors",
  },
  {
    kommunkod: "1864",
    namn: "Ljusnarsberg",
  },
  {
    kommunkod: "1880",
    namn: "Örebro",
  },
  {
    kommunkod: "1881",
    namn: "Kumla",
  },
  {
    kommunkod: "1882",
    namn: "Askersund",
  },
  {
    kommunkod: "1883",
    namn: "Karlskoga",
  },
  {
    kommunkod: "1884",
    namn: "Nora",
  },
  {
    kommunkod: "1885",
    namn: "Lindesberg",
  },
  {
    kommunkod: "1904",
    namn: "Skinnskatteberg",
  },
  {
    kommunkod: "1907",
    namn: "Surahammar",
  },
  {
    kommunkod: "1960",
    namn: "Kungsör",
  },
  {
    kommunkod: "1961",
    namn: "Hallstahammar",
  },
  {
    kommunkod: "1962",
    namn: "Norberg",
  },
  {
    kommunkod: "1980",
    namn: "Västerås",
  },
  {
    kommunkod: "1981",
    namn: "Sala",
  },
  {
    kommunkod: "1982",
    namn: "Fagersta",
  },
  {
    kommunkod: "1983",
    namn: "Köping",
  },
  {
    kommunkod: "1984",
    namn: "Arboga",
  },
  {
    kommunkod: "2021",
    namn: "Vansbro",
  },
  {
    kommunkod: "2023",
    namn: "Malung-Sälen",
  },
  {
    kommunkod: "2026",
    namn: "Gagnef",
  },
  {
    kommunkod: "2029",
    namn: "Leksand",
  },
  {
    kommunkod: "2031",
    namn: "Rättvik",
  },
  {
    kommunkod: "2034",
    namn: "Orsa",
  },
  {
    kommunkod: "2039",
    namn: "Älvdalen",
  },
  {
    kommunkod: "2061",
    namn: "Smedjebacken",
  },
  {
    kommunkod: "2062",
    namn: "Mora",
  },
  {
    kommunkod: "2080",
    namn: "Falun",
  },
  {
    kommunkod: "2081",
    namn: "Borlänge",
  },
  {
    kommunkod: "2082",
    namn: "Säter",
  },
  {
    kommunkod: "2083",
    namn: "Hedemora",
  },
  {
    kommunkod: "2084",
    namn: "Avesta",
  },
  {
    kommunkod: "2085",
    namn: "Ludvika",
  },
  {
    kommunkod: "2101",
    namn: "Ockelbo",
  },
  {
    kommunkod: "2104",
    namn: "Hofors",
  },
  {
    kommunkod: "2121",
    namn: "Ovanåker",
  },
  {
    kommunkod: "2132",
    namn: "Nordanstig",
  },
  {
    kommunkod: "2161",
    namn: "Ljusdal",
  },
  {
    kommunkod: "2180",
    namn: "Gävle",
  },
  {
    kommunkod: "2181",
    namn: "Sandviken",
  },
  {
    kommunkod: "2182",
    namn: "Söderhamn",
  },
  {
    kommunkod: "2183",
    namn: "Bollnäs",
  },
  {
    kommunkod: "2184",
    namn: "Hudiksvall",
  },
  {
    kommunkod: "2260",
    namn: "Ånge",
  },
  {
    kommunkod: "2262",
    namn: "Timrå",
  },
  {
    kommunkod: "2280",
    namn: "Härnösand",
  },
  {
    kommunkod: "2281",
    namn: "Sundsvall",
  },
  {
    kommunkod: "2282",
    namn: "Kramfors",
  },
  {
    kommunkod: "2283",
    namn: "Sollefteå",
  },
  {
    kommunkod: "2284",
    namn: "Örnsköldsvik",
  },
  {
    kommunkod: "2303",
    namn: "Ragunda",
  },
  {
    kommunkod: "2305",
    namn: "Bräcke",
  },
  {
    kommunkod: "2309",
    namn: "Krokom",
  },
  {
    kommunkod: "2313",
    namn: "Strömsund",
  },
  {
    kommunkod: "2321",
    namn: "Åre",
  },
  {
    kommunkod: "2326",
    namn: "Berg",
  },
  {
    kommunkod: "2361",
    namn: "Härjedalen",
  },
  {
    kommunkod: "2380",
    namn: "Östersund",
  },
  {
    kommunkod: "2401",
    namn: "Nordmaling",
  },
  {
    kommunkod: "2403",
    namn: "Bjurholm",
  },
  {
    kommunkod: "2404",
    namn: "Vindeln",
  },
  {
    kommunkod: "2409",
    namn: "Robertsfors",
  },
  {
    kommunkod: "2417",
    namn: "Norsjö",
  },
  {
    kommunkod: "2418",
    namn: "Malå",
  },
  {
    kommunkod: "2421",
    namn: "Storuman",
  },
  {
    kommunkod: "2422",
    namn: "Sorsele",
  },
  {
    kommunkod: "2425",
    namn: "Dorotea",
  },
  {
    kommunkod: "2460",
    namn: "Vännäs",
  },
  {
    kommunkod: "2462",
    namn: "Vilhelmina",
  },
  {
    kommunkod: "2463",
    namn: "Åsele",
  },
  {
    kommunkod: "2480",
    namn: "Umeå",
  },
  {
    kommunkod: "2481",
    namn: "Lycksele",
  },
  {
    kommunkod: "2482",
    namn: "Skellefteå",
  },
  {
    kommunkod: "2505",
    namn: "Arvidsjaur",
  },
  {
    kommunkod: "2506",
    namn: "Arjeplog",
  },
  {
    kommunkod: "2510",
    namn: "Jokkmokk",
  },
  {
    kommunkod: "2513",
    namn: "Överkalix",
  },
  {
    kommunkod: "2514",
    namn: "Kalix",
  },
  {
    kommunkod: "2518",
    namn: "Övertorneå",
  },
  {
    kommunkod: "2521",
    namn: "Pajala",
  },
  {
    kommunkod: "2523",
    namn: "Gällivare",
  },
  {
    kommunkod: "2560",
    namn: "Älvsbyn",
  },
  {
    kommunkod: "2580",
    namn: "Luleå",
  },
  {
    kommunkod: "2581",
    namn: "Piteå",
  },
  {
    kommunkod: "2582",
    namn: "Boden",
  },
  {
    kommunkod: "2583",
    namn: "Haparanda",
  },
  {
    kommunkod: "2584",
    namn: "Kiruna",
  },
];

export default kommuner;
