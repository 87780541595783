import React from "react";
import { useSearchParams } from "react-router-dom";
import HandleResetPassword from "./HandleResetPassword";
import HandleRecoverEmail from "./HandleRecoverEmail";
import HandleVerifyEmail from "./HandleVerifyEmail";
import { asString } from "../../../utils/validations";

const FirebaseAuthActions = () => {
  const [searchParams] = useSearchParams();

  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");
  const continueUrl = searchParams.get("continueUrl");
  const lang = searchParams.get("lang");

  switch (mode) {
    case "resetPassword":
      return (
        <>
          <HandleResetPassword />
        </>
      );
    case "recoverEmail":
      return (
        <>
          <HandleRecoverEmail />
        </>
      );
    case "verifyEmail":
      return (
        <>
          <HandleVerifyEmail
            actionCode={asString(actionCode)}
            continueUrl={continueUrl}
            lang={asString(lang)}
          />
        </>
      );
    default:
      return null;
  }
};

export default FirebaseAuthActions;
