import React, { useState } from "react";
import { useHeaders } from "./useHeaders";
import axios from "axios";
import { getAppCheckToken } from "../utils/appCheck";
import { useNavigate } from "react-router-dom";
import { CreateUserRequest } from "../api/types/usersCollection.api";
import useConsent from "./useConsent";

const useUser = () => {
  const { consent } = useConsent();
  const { baseHeaders } = useHeaders();
  const [loadingUser, setLoading] = useState(false);
  const [errorUser, setError] = useState<Error | null>(null);

  const postUser = async (request: CreateUserRequest, token?: string) => {
    let headers = baseHeaders;

    if (headers === null && token) {
      headers = await (async () => {
        const headers = {
          "X-Firebase-AppCheck": await getAppCheckToken(
            process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
            consent !== false && consent !== undefined
          ),
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        return headers;
      })();
    }

    if (headers !== null) {
      try {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_API_URL}/users`, request, {
          headers: headers,
        });
        setLoading(false);
      } catch (err) {
        setError(err as Error);
      }
    }
  };

  return {
    postUser,
    loadingUser,
    errorUser,
  };
};

export default useUser;
