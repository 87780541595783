import React from "react";
import { useSearchParams } from "react-router-dom";

const EmailVerifiedScreen = () => {
  const [searchParams] = useSearchParams();

  const referrer = searchParams.get("referrer");
  const referred = searchParams.get("referred");

  return (
    <div>
      referrer: {referrer}. referred: {referred}
    </div>
  );
};

export default EmailVerifiedScreen;
