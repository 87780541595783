import React, { ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOverlay } from "../../commons/LoadingOverlay";
import { auth } from "../../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AccountLoadingContext } from "../../../context/accountLoadingContext";

interface Props {
  children: ReactNode;
}

export const PublicOnlyRoute = ({ children }: Props) => {
  const { accountLoading: accountLoading } = useContext(
    AccountLoadingContext
  );
  const [user, error] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (user && accountLoading === false) {
      navigate("/");
    }
  }, [user, accountLoading]);

  return <>{children}</>;
};
