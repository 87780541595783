import React, {
  useRef,
  useState,
  DragEvent,
  ChangeEvent,
  useEffect,
} from "react";
import { Button, Box, Typography, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingOverlay } from "./LoadingOverlay";

interface Props {
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const FileUploader = ({ selectedFile, setSelectedFile }: Props) => {
  const [loading, setLoading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);

  const validateSize = (file: File): boolean => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 5) {
      setFileSizeError(
        `Din fil är ${Math.round(
          fileSize
        ).toString()}mb stor. Max tillåtna är 5mb`
      );
      return false;
    }
    return true;
  };

  const handleDrag = (e: DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDragBox = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleSetFile = (file: File) => {
    const isValid = validateSize(file);

    if (!isValid) {
      return setLoading(false);
    }
    setSelectedFile(file);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleSetFile(e.dataTransfer.files[0]);
    }
    setLoading(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLoading(true);
    if (e.target.files && e.target.files[0]) {
      handleSetFile(e.target.files[0]);
    }
    setLoading(false);
  };

  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setImageUrl(null);
  };

  useEffect(() => {
    setFileSizeError(null);
    if (selectedFile) {
      setImageUrl(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  return (
    <>
      <LoadingOverlay loading={loading} />
      {imageUrl && selectedFile && (
        <Box sx={{ position: "relative", width: "250px" }}>
          <img
            style={{ zIndex: 1, position: "relative" }}
            src={imageUrl}
            alt={selectedFile.name}
            width={"250px"}
            height={"auto"}
          />
          <IconButton
            sx={{
              right: 0,
              top: 0,
              position: "absolute",
              color: "#d32f2f",
              zIndex: 500,
            }}
            onClick={handleRemoveFile}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )}
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        <label id="label-file-upload" htmlFor="input-file-upload">
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingY: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              border: "2px dashed rgba(25, 118, 210, 0.4)",
              cursor: "pointer",
            }}
            bgcolor={dragActive ? "rgba(25, 118, 210, 0.1)" : "white"}
            onDragEnter={handleDragBox}
            onDragLeave={handleDragBox}
            onDragOver={handleDragBox}
            onDrop={handleDrop}
          >
            {fileSizeError && (
              <Typography color="error">{fileSizeError}</Typography>
            )}
            <Typography>
              {selectedFile
                ? "Släpp en ny fil här för att ändra"
                : "Släpp din fil här"}
            </Typography>
            <Typography>eller</Typography>
            <Button variant="outlined" onClick={onButtonClick}>
              Välj fil
            </Button>
          </Box>
        </label>
      </form>
    </>
  );
};

export default FileUploader;
