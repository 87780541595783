import { signOut } from "firebase/auth";
import React, { useEffect } from "react";
import { auth } from "../../../services/firebase";
import { useNavigate } from "react-router-dom";
import { LoadingOverlay } from "../../commons/LoadingOverlay";

const FiebaseSignOutAction = () => {
  const navigate = useNavigate();
  useEffect(() => {
    signOut(auth).then(() => {
      navigate("/logga-in");
    });
  }, []);
  return (
    <>
      <LoadingOverlay loading={true} />
    </>
  );
};

export default FiebaseSignOutAction;
