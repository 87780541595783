import { Box, Typography } from "@mui/material";
import ContentAreaWrapper from "../components/base/ContentAreaWrapper";

function DashboardScreen() {
  return (
    <ContentAreaWrapper>
      <Typography variant="h2" fontSize={"2em"}>
        Kommer snart..
      </Typography>
    </ContentAreaWrapper>
  );
}

export default DashboardScreen;
