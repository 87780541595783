import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isValidEmail } from "../../utils/validations";

interface Props {
  email: string;
  setEmail: (value: string) => void;
  label: string;
  runValidation: boolean | "true";
  emailError: null | string;
  setEmailError: (value: null | string) => void;
}

const EmailTextField = ({
  email,
  setEmail,
  label,
  runValidation,
  emailError,
  setEmailError,
}: Props) => {
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError !== null) {
      setEmailError(null);
    }
    const { value } = event.target;
    setEmail(value);
  };

  const validateEmail = (email: string) => {
    const isValid = isValidEmail(email);
    if (isValid === false) {
      setEmailError("Ogiltig epost. Försök igen.");
    } else {
      setEmailError(null);
    }
    return isValid;
  };

  useEffect(() => {
    if (runValidation) {
      validateEmail(email);
    }
  }, [runValidation]);

  return (
    <>
      <TextField
        fullWidth
        label={label}
        name="email"
        value={email}
        onChange={handleEmailChange}
        error={!!emailError}
        helperText={emailError}
      />
    </>
  );
};

export default EmailTextField;
