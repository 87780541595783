import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
  password: string;
  setPassword: (value: string) => void;
  label: string;
  name: string;
  runValidation: boolean | "true";
  passwordError: null | string;
  setPasswordError: (value: null | string) => void;
}

const PasswordTextField = ({
  password,
  setPassword,
  label,
  name,
  runValidation,
  passwordError,
  setPasswordError,
}: Props) => {
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordError !== null) {
      setPasswordError(null);
    }
    const { value } = event.target;
    setPassword(value);
  };

  const validatePassword = (password: string) => {
    let isValid = true;
    if (password.length < 6) {
      isValid = false;
      setPasswordError("Lösenordet måste vara minst 6 tecken långt.");
    } else {
      setPasswordError(null);
    }
    return isValid;
  };

  useEffect(() => {
    if (runValidation) {
      validatePassword(password);
    }
  }, [runValidation]);

  return (
    <>
      <TextField
        fullWidth
        label={label}
        name={name}
        type="password"
        value={password}
        onChange={handlePasswordChange}
        error={!!passwordError}
        helperText={passwordError}
      />
    </>
  );
};

export default PasswordTextField;
