import React from "react";
import { CompanyViewModel } from "../../api/types/companyCollection.api";
import { Box, Button, Card, Typography } from "@mui/material";
// import HashtagList from "../commons/HashtagList";
import {
  municipalityToView,
  organizationNumberToView,
  serializedPhoneToView,
} from "../../utils/common";
import Image from "../commons/Image";
import Background from "../base/Background";
import { useNavigate } from "react-router-dom";
import DeleteUser from "../auth/DeleteUser";

interface Props {
  companyProfile: CompanyViewModel;
}

const CompanyProfile = ({ companyProfile }: Props) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
      <Card sx={{ borderRadius: "20px", paddingBottom: "20px" }}>
        <Background height="200px">
          <></>
        </Background>
        <Box
          sx={{
            paddingX: "40px",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                width: "200px",
                height: "200px",
                overflow: "hidden",
                marginTop: "-120px",
                background: "white",
                borderRadius: "50%",
                border: "5px solid white",
              }}
            >
              {companyProfile.logoUrl && (
                <Image
                  src={companyProfile.logoUrl}
                  padding="10px"
                  maxWidth="100%"
                  maxHeight="100%"
                  width="auto"
                  height="auto"
                  objectFit="contain"
                  margin="auto"
                />
              )}
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  maxHeight: "36.5px",
                  marginTop: "20px",
                  marginRight: "10px",
                }}
                onClick={() => navigate("/profil/redigera")}
              >
                Redigera profil
              </Button>
              <DeleteUser />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "1.7rem" }}>
              {companyProfile.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "5px",
              }}
            >
              <Typography sx={{ fontSize: "0.9rem", marginTop: "-0.3rem" }}>
                {organizationNumberToView(companyProfile.organizationNumber)}
              </Typography>
              <Box display="flex" columnGap="10px">
                <Typography sx={{ fontSize: "1rem" }}>
                  {companyProfile.industry}
                </Typography>
                •
                <Typography sx={{ fontSize: "1rem" }}>
                  {companyProfile.numberOfPraoPositions} praoplatser
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "1rem" }}>
                {municipalityToView(companyProfile.municipality)},{" "}
                {companyProfile.postalTown}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
      {/* <Card sx={{ borderRadius: "20px", paddingY: "20px" }}>
      <Box
        sx={{
          paddingX: "40px",
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
         <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
          Hashtags
        </Typography>
        <HashtagList hashtags={companyProfile.hashtags} />
      </Box>
    </Card> */}
      <Card sx={{ borderRadius: "20px", paddingY: "20px" }}>
        <Box
          sx={{
            paddingX: "40px",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
            Om företaget
          </Typography>
          <Typography sx={{ fontSize: "1rem" }}>
            {companyProfile.about}
          </Typography>
        </Box>
      </Card>
      <Card sx={{ borderRadius: "20px", paddingY: "20px" }}>
        <Box
          sx={{
            paddingX: "40px",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
            Vilka arbetsuppgifter finns?
          </Typography>
          <Typography sx={{ fontSize: "1rem" }}>
            {companyProfile.praoTasksDescription}
          </Typography>
        </Box>
      </Card>
      <Card sx={{ borderRadius: "20px", paddingY: "20px" }}>
        <Box
          sx={{
            paddingX: "40px",
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            columnGap: { sm: "40px", xs: "0px" },
            rowGap: { sm: "0px", xs: "20px" },
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
          >
            <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
              Kontakt
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                columnGap: "10px",
              }}
            >
              <Typography sx={{ fontSize: "1rem" }}>
                {companyProfile.contactPersonFirstName}{" "}
                {companyProfile.contactPersonLastName}
              </Typography>
              <Typography sx={{ fontSize: "1rem" }}>
                {companyProfile.contactEmail}
              </Typography>
              <Typography sx={{ fontSize: "1rem" }}>
                {serializedPhoneToView(companyProfile.contactPhone)}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
              Adress
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                columnGap: "20px",
              }}
            >
              <Box sx={{ display: "flex", columnGap: "10px" }}>
                <Typography sx={{ fontSize: "1rem" }}>
                  {companyProfile.street}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", columnGap: "10px" }}>
                <Typography sx={{ fontSize: "1rem" }}>
                  {companyProfile.postalCode}
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  {companyProfile.postalTown},{" "}
                  {municipalityToView(companyProfile.municipality)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default CompanyProfile;
