import {
  FilledTextFieldProps,
  InputAdornment,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
  TextFieldVariants,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import { isValidPhoneNumber } from "../../utils/validations";

interface Props {
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  label: string;
  name: string;
  runValidation: boolean | "true";
  phoneNumberError: string | null;
  setPhoneNumberError: (value: string | null) => void;
}

const PhoneNumberTextField = ({
  phoneNumber,
  setPhoneNumber,
  label,
  name,
  runValidation,
  phoneNumberError,
  setPhoneNumberError,
}: Props) => {
  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (phoneNumberError !== null) {
      setPhoneNumberError(null);
    }
    const { value } = event.target;
    setPhoneNumber(value);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const isValid = isValidPhoneNumber(phoneNumber);
    if (isValid === false) {
      setPhoneNumberError("Ogiligt telefonnummer.");
    } else {
      setPhoneNumberError(null);
    }
    return isValid;
  };

  useEffect(() => {
    if (runValidation) {
      validatePhoneNumber(phoneNumber);
    }
  }, [runValidation]);

  return (
    <>
      <MaskedInput
        style={{ width: "100%" }}
        name={name}
        onChange={handlePhoneNumberChange}
        mask={[
          /[1-9]/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
        ]}
        render={(
          innerRef: React.Ref<any> | undefined,
          props: JSX.IntrinsicAttributes & {
            variant?: TextFieldVariants | undefined;
          } & Omit<
              | FilledTextFieldProps
              | OutlinedTextFieldProps
              | StandardTextFieldProps,
              "variant"
            >
        ) => (
          <TextField
            fullWidth
            label={label}
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            value={phoneNumber}
            placeholder="755-55 55 55"
            {...props}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+46</InputAdornment>
              ),
            }}
            inputRef={innerRef}
          />
        )}
      />
    </>
  );
};

export default PhoneNumberTextField;
