import React, { useState } from "react";
import { CircularProgress } from "@mui/material";

interface Props {
  src: string;
  padding: string;
  maxWidth: string;
  maxHeight: string;
  width: string;
  height: string;
  objectFit: "contain" | "cover" | "fill" | "none" | "scale-down";
  margin: string;
}

const Image = ({
  src,
  padding,
  maxWidth,
  maxHeight,
  width,
  height,
  objectFit,
  margin,
}: Props) => {
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  return (
    <>
      {imageLoading && (
        <CircularProgress sx={{ margin: "auto" }} color="inherit" />
      )}
      <img
        style={{
          padding,
          maxWidth,
          maxHeight,
          width,
          height,
          objectFit,
          margin,
          display: imageLoading ? "none" : "block",
        }}
        onLoad={() => {
          setImageLoading(false);
        }}
        src={src}
      />
    </>
  );
};

export default Image;
