import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import kommuner from "../data/kommuner";
import skolor, { Skola } from "../data/skolor";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";
import useUser from "../hooks/useUser";
import { sendEmailVerification } from "firebase/auth";
import { isValidEmail } from "../utils/validations";
import useSchool from "../hooks/useSchool";
import { LoadingOverlay } from "../components/commons/LoadingOverlay";

interface Option {
  label: string;
  id: string;
}

function RegisterInterestScreen() {
  const { getSchoolExists, loadingSchool } = useSchool();
  const { postUser } = useUser();
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [selectedKommun, setSelectedKommun] = useState<Option | null>(null);
  const [selectedSkola, setSelectedSkola] = useState<Option | null>(null);
  const [schools, setSchools] = useState<Skola[]>([]);
  const [emailError, setEmailError] = useState<null | string>(null);
  const [passwordError, setPasswordError] = useState<null | string>(null);
  const [policyBoxCheckedError, setPolicyBoxCheckedError] = useState<
    null | string
  >(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    null | string
  >(null);
  const [kommunIsSelected, setKommunIsSelected] = useState<null | string>(null);
  const [skolaIsSelected, setSkolaIsSelected] = useState<null | string>(null);
  const [schoolExists, setSchoolExists] = useState<null | boolean>(null);
  const [policyBoxChecked, setPolicyBoxChecked] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const kommunerOptions = kommuner.map((kommun) => ({
    label: kommun.namn,
    id: kommun.kommunkod,
  }));

  const schoolsOptions = schools.map((skola) => ({
    label: skola.skolenhetensNamn,
    id: skola.skolenhetsKod,
  }));

  const runEmailValidation = (email: string) => {
    const isValid = isValidEmail(email);
    if (isValid === false) {
      setEmailError("Ogiltig epost. Försök igen :P");
    } else {
      setEmailError(null);
    }
    return isValid;
  };

  const runValidateKommunSelected = () => {
    const isSelected = selectedKommun !== null;
    if (isSelected === false) {
      setKommunIsSelected("Du måste välja en kommun.");
    } else {
      setKommunIsSelected(null);
    }
    return isSelected;
  };

  const validateSkolaSelected = () => {
    const isSelected = selectedSkola !== null;
    if (isSelected === false) {
      setSkolaIsSelected("Du måste välja en skola.");
    } else {
      setSkolaIsSelected(null);
    }
    return isSelected;
  };

  const validatePassword = (password: string) => {
    let isValid = true;
    if (password.length < 6) {
      isValid = false;
      setPasswordError(
        "Lösenordet måste vara minst 6 tecken långt. Försök igen :D"
      );
    } else {
      setPasswordError(null);
    }
    return isValid;
  };

  const validateConfirmPassword = (confirmPassword: string) => {
    let isValid = true;
    if (confirmPassword !== password) {
      isValid = false;
      setConfirmPasswordError(
        "Det måste vara samma lösenord. Fixa och försök igen :)"
      );
    } else {
      setConfirmPasswordError(null);
    }
    return isValid;
  };

  const handlePolicyCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (policyBoxCheckedError !== null) {
      setPolicyBoxCheckedError(null);
    }
    setPolicyBoxChecked(event.target.checked);
  };

  const validatePolicyBox = () => {
    if (policyBoxChecked === false) {
      setPolicyBoxCheckedError(
        "Du måste godkänna integritetspolicyn för att skapa ett konto."
      );
    }
    return policyBoxChecked;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validEmail = runEmailValidation(email);
    const validPassword = validatePassword(password);
    const validPasswordConfirmation =
      validateConfirmPassword(passwordConfirmation);
    const validKommun = runValidateKommunSelected();
    const validSkola = validateSkolaSelected();
    const validPolicyBox = validatePolicyBox();

    if (
      validEmail === false ||
      validPassword === false ||
      validPasswordConfirmation === false ||
      validKommun === false ||
      validSkola === false ||
      validPolicyBox === false
    ) {
      return;
    }
    const referrer = searchParams.get("referrer");
    const user = await createUserWithEmailAndPassword(email, password);

    const data: any = { school: selectedSkola!.id };

    if (referrer !== null) {
      data.referrer = referrer;
    }

    if (user) {
      const url = `${process.env.REACT_APP_BASE_URL}/epost-verifierad${
        referrer ? `?referrer=${referrer}&referred=${user.user.uid}` : ""
      }`;

      await sendEmailVerification(user.user, {
        url,
      });
      const token = await user.user.getIdToken();
      await postUser(data, token);
      navigate("/");
    }
  };

  const handleKommunChange = (
    event: ChangeEvent<{}>,
    value: { label: string; id: string } | null
  ) => {
    if (kommunIsSelected !== null) {
      setKommunIsSelected(null);
    }
    setSelectedKommun(value);
  };

  const handleSchoolChange = (
    event: ChangeEvent<{}>,
    value: { label: string; id: string } | null
  ) => {
    if (skolaIsSelected !== null) {
      setSkolaIsSelected(null);
    }
    setSelectedSkola(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordError !== null) {
      setPasswordError(null);
    }
    const { value } = event.target;
    setPassword(value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (confirmPasswordError !== null) {
      setConfirmPasswordError(null);
    }
    const { value } = event.target;
    setPasswordConfirmation(value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError !== null) {
      setEmailError(null);
    }
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubmitSchool = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedSkola) {
      const exists = await getSchoolExists(selectedSkola.id);
      if (exists) {
        setSchoolExists(true);
      } else {
        setSchoolExists(false);
      }
    }
  };

  useEffect(() => {
    const kommun = kommuner.find(
      (kommun) => kommun.namn === selectedKommun?.label
    );
    if (kommun) {
      setSchools(skolor.filter((skola) => skola.kommun === kommun.kommunkod));
    }
  }, [selectedKommun]);

  return (
    <>
      <LoadingOverlay loading={loading || loadingSchool} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            gap: "15px",
            rowGap: "15px",
            maxWidth: "600px",
            margin: "auto",
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          {schoolExists === null && (
            <>
              <Box>
                <Typography variant="h2" fontSize={"2em"}>
                  Kontrollera att din skola är ansluten!
                </Typography>
              </Box>
              <form onSubmit={handleSubmitSchool}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "15px",
                  }}
                >
                  <Autocomplete
                    value={selectedKommun ?? null}
                    onChange={handleKommunChange}
                    options={kommunerOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label && option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="I vilken kommun finns din skola?"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        error={!!kommunIsSelected}
                        helperText={kommunIsSelected}
                      />
                    )}
                  />
                  {selectedKommun && (
                    <Autocomplete
                      value={selectedSkola ?? null}
                      onChange={handleSchoolChange}
                      options={schoolsOptions}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label && option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Vilken är din skola?"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          error={!!skolaIsSelected}
                          helperText={skolaIsSelected}
                        />
                      )}
                    />
                  )}
                  <Button sx={{ flex: 1 }} type="submit" variant="contained">
                    Kontrollera skolan
                  </Button>
                </Box>
              </form>
            </>
          )}

          {schoolExists === true && (
            <>
              <Box>
                <Typography fontSize={"1.4em"} sx={{ marginBottom: "10px" }}>
                  Fritt fram, din Skola är ansluten!
                </Typography>
                <Typography variant="h2" fontSize={"2em"}>
                  Registrera dig för tävlingen!
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "15px",
                  }}
                >
                  <TextField
                    label="Epost"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    error={!!emailError}
                    helperText={emailError}
                  />
                  <TextField
                    label="Lösenord"
                    name="password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                  <TextField
                    label="Upprepa lösenord"
                    name="confirmPassword"
                    type="password"
                    value={passwordConfirmation}
                    onChange={handleConfirmPasswordChange}
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={policyBoxChecked}
                          onChange={handlePolicyCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2" color="textSecondary">
                          Jag godkänner{" "}
                          <Link
                            to="/integritetspolicy"
                            color="inherit"
                            target="_blank"
                          >
                            integritetspolicyn
                          </Link>
                        </Typography>
                      }
                    />
                    <FormHelperText
                      error={!!policyBoxCheckedError}
                      sx={{ color: "#d32f2f" }}
                    >
                      {policyBoxCheckedError}
                    </FormHelperText>
                  </FormGroup>
                  <Button sx={{ flex: 1 }} type="submit" variant="contained">
                    Registrera dig
                  </Button>
                </Box>
              </form>
            </>
          )}

          {schoolExists === false && (
            <>
              <Typography variant="h2" fontSize={"2em"}>
                Din skola är tyvärr inte ansluten ännu.
              </Typography>
              <Typography>
                Vi kommer att kontakta dem och se ifall de vill vara med.
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default RegisterInterestScreen;
