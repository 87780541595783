const authErrorCodes = [
  "auth/admin-restricted-operation",
  "auth/argument-error",
  "auth/app-not-authorized",
  "auth/app-not-installed",
  "auth/captcha-check-failed",
  "auth/code-expired",
  "auth/cordova-not-ready",
  "auth/cors-unsupported",
  "auth/credential-already-in-use",
  "auth/custom-token-mismatch",
  "auth/requires-recent-login",
  "auth/dependent-sdk-initialized-before-auth",
  "auth/dynamic-link-not-activated",
  "auth/email-change-needs-verification",
  "auth/email-already-in-use",
  "auth/emulator-config-failed",
  "auth/expired-action-code",
  "auth/cancelled-popup-request",
  "auth/internal-error",
  "auth/invalid-api-key",
  "auth/invalid-app-credential",
  "auth/invalid-app-id",
  "auth/invalid-user-token",
  "auth/invalid-auth-event",
  "auth/invalid-cert-hash",
  "auth/invalid-verification-code",
  "auth/invalid-continue-uri",
  "auth/invalid-cordova-configuration",
  "auth/invalid-custom-token",
  "auth/invalid-dynamic-link-domain",
  "auth/invalid-email",
  "auth/invalid-emulator-scheme",
  "auth/invalid-credential",
  "auth/invalid-message-payload",
  "auth/invalid-multi-factor-session",
  "auth/invalid-oauth-client-id",
  "auth/invalid-oauth-provider",
  "auth/invalid-action-code",
  "auth/unauthorized-domain",
  "auth/wrong-password",
  "auth/invalid-persistence-type",
  "auth/invalid-phone-number",
  "auth/invalid-provider-id",
  "auth/invalid-recipient-email",
  "auth/invalid-sender",
  "auth/invalid-verification-id",
  "auth/invalid-tenant-id",
  "auth/multi-factor-info-not-found",
  "auth/multi-factor-auth-required",
  "auth/missing-android-pkg-name",
  "auth/missing-app-credential",
  "auth/auth-domain-config-required",
  "auth/missing-verification-code",
  "auth/missing-continue-uri",
  "auth/missing-iframe-start",
  "auth/missing-ios-bundle-id",
  "auth/missing-or-invalid-nonce",
  "auth/missing-multi-factor-info",
  "auth/missing-multi-factor-session",
  "auth/missing-phone-number",
  "auth/missing-verification-id",
  "auth/app-deleted",
  "auth/account-exists-with-different-credential",
  "auth/network-request-failed",
  "auth/null-user",
  "auth/no-auth-event",
  "auth/no-such-provider",
  "auth/operation-not-allowed",
  "auth/operation-not-supported-in-this-environment",
  "auth/popup-blocked",
  "auth/popup-closed-by-user",
  "auth/provider-already-linked",
  "auth/quota-exceeded",
  "auth/redirect-cancelled-by-user",
  "auth/redirect-operation-pending",
  "auth/rejected-credential",
  "auth/second-factor-already-in-use",
  "auth/maximum-second-factor-count-exceeded",
  "auth/tenant-id-mismatch",
  "auth/timeout",
  "auth/user-token-expired",
  "auth/too-many-requests",
  "auth/unauthorized-continue-uri",
  "auth/unsupported-first-factor",
  "auth/unsupported-persistence-type",
  "auth/unsupported-tenant-operation",
  "auth/unverified-email",
  "auth/user-cancelled",
  "auth/user-not-found",
  "auth/user-disabled",
  "auth/user-mismatch",
  "auth/user-signed-out",
  "auth/weak-password",
  "auth/web-storage-unsupported",
  "auth/already-initialized",
  "auth/recaptcha-not-enabled",
  "auth/missing-recaptcha-token",
  "auth/invalid-recaptcha-token",
  "auth/invalid-recaptcha-action",
  "auth/missing-client-type",
  "auth/missing-recaptcha-version",
  "auth/invalid-recaptcha-version",
  "auth/invalid-req-type",
] as const;
export type AuthErrorCode = (typeof authErrorCodes)[number];

export const mapAuthErrorCodeToText = (code: AuthErrorCode) => {
  switch (code) {
    case "auth/admin-restricted-operation":
      return "Den specifika operationen är begränsad till administratörer.";
    case "auth/argument-error":
      return "Ett felaktigt argument har angetts till en metod.";
    case "auth/app-not-authorized":
      return "Den här appen, som har en giltig App ID och är registrerad i App Check, är inte tillåten att använda Firebase Authentication. ";
    case "auth/app-not-installed":
      return "Appen är inte installerad.";
    case "auth/captcha-check-failed":
      return "ReCAPTCHA-lösningen är ogiltig.";
    case "auth/code-expired":
      return "Den angivna åtgärdskoden har gått ut.";
    case "auth/cordova-not-ready":
      return "Cordova-ramverket är inte redo.";
    case "auth/cors-unsupported":
      return "Denna operation är inte tillåten i det här miljön. Se https://firebase.google.com/docs/reference/js/firebase.auth.Auth#cors-configuration för en lista över tillåtna domäner.";
    case "auth/credential-already-in-use":
      return "Detta konto är redan kopplat till en annan användare.";
    case "auth/custom-token-mismatch":
      return "Den anpassade token som tillhandahölls matchar inte den registrerade appen.";
    case "auth/requires-recent-login":
      return "Den senaste användarautentiseringen krävs för att återaktivera den här åtgärden.";
    case "auth/dependent-sdk-initialized-before-auth":
      return "En beroende SDK initierades utanför Firebase Auth och har återställt alla åtgärder. Om du använder andra SDK:er tillsammans med Firebase Auth, se till att Firebase Auth initialiseras först.";
    case "auth/dynamic-link-not-activated":
      return "Denna funktion kräver att dynamiska länkar är aktiverade.";
    case "auth/email-change-needs-verification":
      return "E-poständing behöver verifieras.";
    case "auth/email-already-in-use":
      return "Det finns redan ett konto med den här e-postadressen.";
    case "auth/emulator-config-failed":
      return "Det gick inte att ansluta till det emulerade backend-systemet.";
    case "auth/expired-action-code":
      return "Den angivna åtgärdskoden har gått ut.";
    case "auth/cancelled-popup-request":
      return "Den här operationen har avbrutits på grund av att popup-fönsteret har stängts.";
    case "auth/internal-error":
      return "Internt fel.";
    case "auth/invalid-api-key":
      return "Den angivna API-nyckeln är ogiltig.";
    case "auth/invalid-app-credential":
      return "Den angivna appens autentiseringsuppgifter är ogiltiga.";
    case "auth/invalid-app-id":
      return "Den angivna appens ID är ogiltigt.";
    case "auth/invalid-user-token":
      return "Den angivna användartoken är ogiltig.";
    case "auth/invalid-auth-event":
      return "Det internt genererade evenemanget är ogiltigt.";
    case "auth/invalid-cert-hash":
      return "Den angivna certifikat-hashen är ogiltig.";
    case "auth/invalid-verification-code":
      return "Den angivna åtgärdskoden är ogiltig.";
    case "auth/invalid-continue-uri":
      return "Den angivna fortsättnings-URL:en är ogiltig.";
    case "auth/invalid-cordova-configuration":
      return "Cordova-konfigurationen är ogiltig eller saknas.";
    case "auth/invalid-custom-token":
      return "Den angivna anpassade tokenen är ogiltig.";
    case "auth/invalid-dynamic-link-domain":
      return "Den angivna dynamiska länkdomänen är inte tillåten eller konfigurerad i den aktuella projektinställningen. Se Firebase-konsole för att se till att den aktuella dynamiska länkdomänen är listad i domänlistan för din aktuella Firebase-projekt.";
    case "auth/invalid-email":
      return "Den angivna e-postadressen är ogiltig.";
    case "auth/invalid-emulator-scheme":
      return "Det angivna URL-schemat är inte tillåtet för emulatorn. Vänligen ändra URL-schemat, eller inaktivera emulatorn.";
    case "auth/invalid-credential":
      return "Den angivna autentiseringsuppgiften är ogiltig. Detta kan hända om den är formaterad felaktigt.";
    case "auth/invalid-message-payload":
      return "Det angivna meddelandepaketet är ogiltigt.";
    case "auth/invalid-multi-factor-session":
      return "Den angivna flerfaktor-sessionen är ogiltig.";
    case "auth/invalid-oauth-client-id":
      return "Den angivna OAuth-klient-ID: n är ogiltig.";
    case "auth/invalid-oauth-provider":
      return "Den angivna OAuth-tjänsteleverantören är ogiltig.";
    case "auth/invalid-action-code":
      return "Den angivna åtgärdskoden är ogiltig.";
    case "auth/unauthorized-domain":
      return "Den här domänen är inte godkänd för OAuth-autentisering för din Firebase-projekt. Redigera listan över godkända domäner från Firebase-konsolen.";
    case "auth/wrong-password":
      return "Det angivna lösenordet är felaktigt.";
    case "auth/invalid-persistence-type":
      return "Den angivna typen av varaktighet är ogiltig.";
    case "auth/invalid-phone-number":
      return "Den angivna telefonnumret är ogiltigt.";
    case "auth/invalid-provider-id":
      return "Den angivna leverantörs-ID: n är ogiltig.";
    case "auth/invalid-recipient-email":
      return "Den angivna mottagarens e-postadress är ogiltig.";
    case "auth/invalid-sender":
      return "Den angivna avsändaren är ogiltig.";
    case "auth/invalid-verification-id":
      return "Den angivna verifierings-ID: n är ogiltig.";
    case "auth/invalid-tenant-id":
      return "Den angivna hyresgäst-ID: n är ogiltig.";
    case "auth/multi-factor-info-not-found":
      return "Den begärda flerfaktorinformationen finns inte.";
    case "auth/multi-factor-auth-required":
      return "Användaren måste slutföra en flerfaktorautentisering.";
    case "auth/missing-android-pkg-name":
      return "Android-paketnamn måste anges om en Android-app ska installeras.";
    case "auth/missing-app-credential":
      return "En autentiseringsuppgift för appen krävs för att autentisera en app.";
    case "auth/auth-domain-config-required":
      return "Konfigurationen för domänen för autentisering krävs för att autentisera appen.";
    case "auth/missing-verification-code":
      return "En verifieringskod måste anges.";
    case "auth/missing-continue-uri":
      return "Den fortsättnings-URL som krävs för att behandla återställningsmeddelandet saknas.";
    case "auth/missing-iframe-start":
      return "En iframe start-URL måste anges för att autentisera med en popup.";
    case "auth/missing-ios-bundle-id":
      return "Ett iOS-bundle-ID måste anges om en iOS-app ska installeras.";
    case "auth/missing-or-invalid-nonce":
      return "Den begärda åtgärden kräver en nonce-argument i dess extra parameter.";
    case "auth/missing-multi-factor-info":
      return "Ingen flerfaktorinformation finns.";
    case "auth/missing-multi-factor-session":
      return "Den begärda flerfaktor-sessionen finns inte.";
    case "auth/missing-phone-number":
      return "Ett telefonnummer måste anges.";
    case "auth/missing-verification-id":
      return "Ett verifierings-ID måste anges.";
    case "auth/app-deleted":
      return "Den här appen har tagits bort från ditt Firebase-projekt.";
    case "auth/account-exists-with-different-credential":
      return "Kontot finns det redan med en annan autentiseringsuppgift.";
    case "auth/network-request-failed":
      return "Nätverksbegäran misslyckades.";
    case "auth/null-user":
      return "Användaren som försöker genomföra åtgärden är null.";
    case "auth/no-auth-event":
      return "Det internt genererade evenemanget är null.";
    case "auth/no-such-provider":
      return "Ingen sådan leverantör.";
    case "auth/operation-not-allowed":
      return "Den här typen av konton är inte tillåtna.";
    case "auth/operation-not-supported-in-this-environment":
      return "Denna operation stöds inte i den aktuella miljön. Se https://firebase.google.com/docs/auth/admin/manage-sessions för en lista över tillåtna miljöer.";
    case "auth/popup-blocked":
      return "En popup-blockerare har blockerat popup-fönstret från att öppnas.";
    case "auth/popup-closed-by-user":
      return "Användaren stängde popup-fönstret utan att slutföra inloggningen.";
    case "auth/provider-already-linked":
      return "Användaren har redan länkats till den här leverantören.";
    case "auth/quota-exceeded":
      return "Den kvot som tilldelats har överskridits.";
    case "auth/redirect-cancelled-by-user":
      return "Användaren avbröt omdirigeringen.";
    case "auth/redirect-operation-pending":
      return "En omdirigeringsoperation är redan väntande.";
    case "auth/rejected-credential":
      return "De angivna autentiseringsuppgifterna avvisades.";
    case "auth/second-factor-already-in-use":
      return "Den andra faktorn är redan kopplad till den här användaren.";
    case "auth/maximum-second-factor-count-exceeded":
      return "Den maximala tillåtna mängden andra faktorer har överskridits.";
    case "auth/tenant-id-mismatch":
      return "Den angivna hyresgäst-ID: n matchar inte det tillhörande hyresgäst-ID: t för projektet.";
    case "auth/timeout":
      return "Den aktuella begäran har gått ut.";
    case "auth/user-token-expired":
      return "Användartokenet har gått ut.";
    case "auth/too-many-requests":
      return "För många begäranden skickades under en kort tidsperiod.";
    case "auth/unauthorized-continue-uri":
      return "Den domän som är associerad med den fortsättnings-URL som tillhandahålls är inte godkänd för den aktuella Firebase-projektet. Lägg till den domänen i Firebase-konsolen.";
    case "auth/unsupported-first-factor":
      return "Den angivna första faktorn stöds inte.";
    case "auth/unsupported-persistence-type":
      return "Den angivna typen av varaktighet stöds inte.";
    case "auth/unsupported-tenant-operation":
      return "Den här operationen stöds inte för den aktuella hyresgästen.";
    case "auth/unverified-email":
      return "Den aktuella användaren har ingen verifierad e-postadress.";
    case "auth/user-cancelled":
      return "Användaren avbröt.";
    case "auth/user-not-found":
      return "Användaren hittades inte.";
    case "auth/user-disabled":
      return "Användaren har inaktiverats.";
    case "auth/user-mismatch":
      return "De angivna autentiseringsuppgifterna matchar inte den tidigare inloggade användaren.";
    case "auth/user-signed-out":
      return "Användaren har loggats ut.";
    case "auth/weak-password":
      return "Det angivna lösenordet är för svagt.";
    case "auth/web-storage-unsupported":
      return "Webblagring stöds inte i den aktuella webbläsaren.";
    case "auth/already-initialized":
      return "Firebase Auth har redan initialiserats.";
    case "auth/recaptcha-not-enabled":
      return "ReCAPTCHA är inte aktiverat.";
    case "auth/missing-recaptcha-token":
      return "Ingen ReCAPTCHA-token har tillhandahållits.";
    case "auth/invalid-recaptcha-token":
      return "Den angivna ReCAPTCHA-tokenen är ogiltig.";
    case "auth/invalid-recaptcha-action":
      return "Den angivna ReCAPTCHA-åtgärden är ogiltig.";
    case "auth/missing-client-type":
      return "Den begärda klienttypen saknas.";
    case "auth/missing-recaptcha-version":
      return "Den begärda ReCAPTCHA-versionen saknas.";
    case "auth/invalid-recaptcha-version":
      return "Den begärda ReCAPTCHA-versionen är ogiltig.";
    case "auth/invalid-req-type":
      return "Den begärda typen av begäran är ogiltig.";
    default:
      return "Oväntat fel. Kontakta support.";
  }
};
