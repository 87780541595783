import {
  createContext,
  MutableRefObject,
  useRef,
  useState,
} from "react";

type Props = {
  children: React.ReactNode;
};

export interface RequestHeaders {
  "X-Firebase-AppCheck": string;
  "Content-Type": string;
  Authorization: string;
}

interface RequestHeadersContextType {
  requestHeaders: RequestHeaders | undefined;
  setRequestHeaders: (requestHeaders: RequestHeaders) => void;
  validUntil?: MutableRefObject<Date>;
}

const defaultContext: RequestHeadersContextType = {
  requestHeaders: undefined,
  setRequestHeaders: (requestHeaders: RequestHeaders) => {},
};

export const RequestHeadersContext =
  createContext<RequestHeadersContextType>(defaultContext);

const RequestHeadersProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const [requestHeaders, setRequestHeaders] = useState<
    RequestHeaders | undefined
  >(undefined);
  const validUntil = useRef<Date>(new Date(Date.now()));

  return (
    <RequestHeadersContext.Provider
      value={{
        requestHeaders,
        setRequestHeaders,
        validUntil: validUntil as MutableRefObject<Date>,
      }}
    >
      {children}
    </RequestHeadersContext.Provider>
  );
};

export default RequestHeadersProvider;
