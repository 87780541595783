import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
} from "@mui/material";
import React, { ChangeEvent, useEffect } from "react";

interface Props {
  value: string;
  setValue: (value: string) => void;
  label: string;
  name: string;
  helperText: string;
  minLength: number;
  runValidation: boolean | "true";
  valueError: null | string;
  setValueError: (value: null | string) => void;
  displayLabel?: boolean;
}

const RequiredTextArea = ({
  value,
  setValue,
  label,
  name,
  helperText,
  minLength,
  runValidation,
  valueError,
  setValueError,
  displayLabel = true,
}: Props) => {
  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (valueError !== null) {
      setValueError(null);
    }
    const { value } = event.target;
    setValue(value);
  };

  const validateValue = (value: string) => {
    const isValid = value.length > minLength;
    if (isValid === false) {
      setValueError(helperText);
    } else {
      setValueError(null);
    }
    return isValid;
  };

  useEffect(() => {
    if (runValidation) {
      validateValue(value);
    }
  }, [runValidation]);

  return (
    <FormControl fullWidth sx={{ marginTop: "5px" }}>
      {displayLabel && (
        <Box sx={{ marginBottom: "20px" }}>
          <InputLabel sx={{ fontSize: "1.3rem" }} shrink htmlFor={name}>
            {label}
          </InputLabel>
        </Box>
      )}
      <TextareaAutosize
        style={{ width: "100%" }}
        name={name}
        value={value}
        minRows={3}
        onChange={handleValueChange}
      />
      {valueError && (
        <FormHelperText error={!!valueError} sx={{ color: "#d32f2f" }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RequiredTextArea;
