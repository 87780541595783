import React, { ChangeEvent, useEffect, useState } from "react";
import { isValidOrganiationNumber } from "../../utils/validations";
import MaskedInput from "react-text-mask";
import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
  TextFieldVariants,
} from "@mui/material";

interface Props {
  organizationNumber: string;
  setOrganizationNumber: (value: string) => void;
  label: string;
  name: string;
  runValidation: boolean | "true";
  organizationNumberError: string | null;
  setOrganizationNumberError: (value: string | null) => void;
}

const OrganizationNumberField = ({
  organizationNumber,
  setOrganizationNumber,
  label,
  name,
  runValidation,
  organizationNumberError,
  setOrganizationNumberError,
}: Props) => {
  const handleOrganizationNumberChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (organizationNumberError !== null) {
      setOrganizationNumberError(null);
    }
    const { value } = event.target;
    setOrganizationNumber(value);
  };

  const validateOrganizationNumber = (organizationNumber: string) => {
    const isValid = isValidOrganiationNumber(organizationNumber);
    if (isValid === false) {
      setOrganizationNumberError("Ogiltigt organisationsnummer.");
    } else {
      setOrganizationNumberError(null);
    }
    return isValid;
  };

  useEffect(() => {
    if (runValidation) {
      validateOrganizationNumber(organizationNumber);
    }
  }, [runValidation]);

  return (
    <>
      <MaskedInput
        style={{ width: "100%" }}
        name={name}
        onChange={handleOrganizationNumberChange}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
        render={(
          innerRef: React.Ref<any> | undefined,
          props: JSX.IntrinsicAttributes & {
            variant?: TextFieldVariants | undefined;
          } & Omit<
              | FilledTextFieldProps
              | OutlinedTextFieldProps
              | StandardTextFieldProps,
              "variant"
            >
        ) => (
          <TextField
            fullWidth
            label={label}
            error={!!organizationNumberError}
            helperText={organizationNumberError}
            value={organizationNumber}
            placeholder="555555-5555"
            {...props}
            inputRef={innerRef}
          />
        )}
      />
    </>
  );
};

export default OrganizationNumberField;
