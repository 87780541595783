import { createContext, useEffect, useState } from "react";
import { UserViewModel } from "../api/types/usersCollection.api";
import { CompanyViewModel } from "../api/types/companyCollection.api";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";
import useCompany from "../hooks/useCompany";

type Props = {
  children: React.ReactNode;
};

type UserAccountContextType = {
  userAccount: UserViewModel | undefined;
  setUserAccount: (userAccount: UserViewModel) => void;
  companyAccount: CompanyViewModel | undefined;
  setCompanyAccount: (companyAccount: CompanyViewModel) => void;
};

const defaultContext: UserAccountContextType = {
  userAccount: undefined,
  setUserAccount: (userAccount: UserViewModel) => {},
  companyAccount: undefined,
  setCompanyAccount: (companyAccount: CompanyViewModel) => {},
};

export const UserAccountContext =
  createContext<UserAccountContextType>(defaultContext);

const UserAccountProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [userAccount, setUserAccount] = useState<UserViewModel | undefined>(
    undefined
  );
  const [companyAccount, setCompanyAccount] = useState<
    CompanyViewModel | undefined
  >(undefined);

  return (
    <UserAccountContext.Provider
      value={{
        userAccount,
        setUserAccount,
        companyAccount,
        setCompanyAccount,
      }}
    >
      {children}
    </UserAccountContext.Provider>
  );
};

export default UserAccountProvider;
