import { Box } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;
}

const ContentAreaWrapper = ({ children }: Props) => {
  return (
    <Box
      sx={{
        paddingX: "20px",
        paddingY: "20px",
        minHeight: "90vh",
        background: "#f8fafb",
      }}
    >
      <Box
        sx={{
          maxWidth: "1500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ContentAreaWrapper;
