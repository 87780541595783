import { createContext, useEffect, useState } from "react";
import { ConsentViewModel } from "../api/types/consentCollection.api";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";

interface ConsentCookieData extends ConsentViewModel {
  localValidUntil: string;
}

type Props = {
  children: React.ReactNode;
};

export const ConsentCookieName = "pp_cnt_a";

type ConsentContextType = {
  consent: ConsentCookieData | false | undefined;
  loadingConsentInContext: boolean;
  setConsent: (consent: ConsentCookieData | false | undefined) => void;
};

const defaultContext: ConsentContextType = {
  consent: undefined,
  loadingConsentInContext: true,
  setConsent: (consent: ConsentCookieData | false | undefined) => {},
};

export const ConsentContext = createContext<ConsentContextType>(defaultContext);

const ConsentProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [user] = useAuthState(auth);
  const [consent, setConsent] = useState<ConsentCookieData | false | undefined>(
    undefined
  );
  const [loadingConsentInContext, setLoadingConsent] = useState(true);
  const [cookieChecked, setCookieChecked] = useState(false);

  useEffect(() => {
    if (loadingConsentInContext === false) {
      setLoadingConsent(true);
    }
    const consentString = localStorage.getItem(ConsentCookieName);

    if (consentString) {
      const parsed = JSON.parse(consentString);

      const now = new Date();
      const localValidUntil = new Date(parsed.localValidUntil);

      if (now > localValidUntil) {
        localStorage.removeItem(ConsentCookieName);
        setLoadingConsent(false);
        return setConsent(undefined);
      }
      setLoadingConsent(false);
      return setConsent(JSON.parse(consentString));
    }
    setLoadingConsent(false);
    return setConsent(undefined);
  }, []);

  useEffect(() => {
    if (loadingConsentInContext === false) {
      setLoadingConsent(true);
    }
    if (consent) {
      localStorage.setItem(ConsentCookieName, JSON.stringify(consent));
    }
    if (consent === false) {
      localStorage.removeItem(ConsentCookieName);
    }
    setCookieChecked(true);
    setLoadingConsent(false);
  }, [consent]);

  useEffect(() => {
    if (cookieChecked === false) {
      return;
    }
    if (user === null) {
      setConsent;
    }
  }, [user, cookieChecked]);

  return (
    <ConsentContext.Provider
      value={{
        consent,
        loadingConsentInContext,
        setConsent,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

export default ConsentProvider;
