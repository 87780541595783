export interface Skola {
  huvudmanatyp: string;
  län: string;
  länsNamn: string;
  kommun: string;
  kommunsNamn: string;
  skolenhetsKod: string;
  skolenhetensNamn: string;
  postnr: string;
  besökspostnr: string;
  inriktning: string;
}

const skolor: Skola[] = [
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "74174879",
    skolenhetensNamn: "Bollstanäs skola",
    postnr: "19468",
    besökspostnr: "19468",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "55512387",
    skolenhetensNamn: "Breddenskolan",
    postnr: "19464",
    besökspostnr: "19464",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "80509560",
    skolenhetensNamn: "Engelska skolan Upplands Väsby",
    postnr: "19471",
    besökspostnr: "19471",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "24402537",
    skolenhetensNamn: "Internationella Engelska Skolan Upplands Väsby",
    postnr: "19430",
    besökspostnr: "19430",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "62078083",
    skolenhetensNamn: "Lärande Grundskola Fresta",
    postnr: "19405",
    besökspostnr: "19453",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "81453817",
    skolenhetensNamn: "Lärande Grundskola Magneten",
    postnr: "19405",
    besökspostnr: "19463",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "19733028",
    skolenhetensNamn: "Lärande Grundskola Oden",
    postnr: "19405",
    besökspostnr: "19463",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "60211862",
    skolenhetensNamn: "Lärande Grundskola Vik",
    postnr: "19405",
    besökspostnr: "19455",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "77624936",
    skolenhetensNamn: "Runby skola",
    postnr: "19447",
    besökspostnr: "19447",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "63150475",
    skolenhetensNamn: "Sjumilaskolan",
    postnr: "19447",
    besökspostnr: "19447",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "68403656",
    skolenhetensNamn: "Sverigefinska sk, Uppl Väsby",
    postnr: "19471",
    besökspostnr: "19471",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "97762139",
    skolenhetensNamn: "Thoren Framtid Sandbergska",
    postnr: "19461",
    besökspostnr: "19461",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "24374886",
    skolenhetensNamn: "Vittra Väsby",
    postnr: "19449",
    besökspostnr: "19449",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0114",
    kommunsNamn: "Upplands Väsby",
    skolenhetsKod: "31018677",
    skolenhetensNamn: "Väsby skola",
    postnr: "19480",
    besökspostnr: "19476",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "52293921",
    skolenhetensNamn: "Bällstabergsskolan",
    postnr: "18655",
    besökspostnr: "18655",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "33565539",
    skolenhetensNamn: "Gustav Vasaskolan",
    postnr: "18694",
    besökspostnr: "18694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "84520303",
    skolenhetensNamn: "Hagaskolan",
    postnr: "18643",
    besökspostnr: "18643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "20230739",
    skolenhetensNamn: "IKASUS",
    postnr: "18641",
    besökspostnr: "18641",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "57370453",
    skolenhetensNamn: "Karbyskolan",
    postnr: "18670",
    besökspostnr: "18670",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "11193969",
    skolenhetensNamn: "Karlslundsskolan",
    postnr: "18641",
    besökspostnr: "18641",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "84103650",
    skolenhetensNamn: "Kårsta skola",
    postnr: "18660",
    besökspostnr: "18660",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "29278599",
    skolenhetensNamn: "Lovisedalsskolan",
    postnr: "18653",
    besökspostnr: "18653",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "63742407",
    skolenhetensNamn: "Rosendalsskolan",
    postnr: "18634",
    besökspostnr: "18634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "71375812",
    skolenhetensNamn: "Vallentuna internationella montessoriskola",
    postnr: "18641",
    besökspostnr: "18641",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0115",
    kommunsNamn: "Vallentuna",
    skolenhetsKod: "46206050",
    skolenhetensNamn: "Vittra Vallentuna",
    postnr: "18654",
    besökspostnr: "18654",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "10715898",
    skolenhetensNamn: "Akers Friskola",
    postnr: "18438",
    besökspostnr: "18438",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "97024653",
    skolenhetensNamn: "Bergsättraskolan",
    postnr: "18461",
    besökspostnr: "18461",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "43869172",
    skolenhetensNamn: "Fredsborgskolan",
    postnr: "18422",
    besökspostnr: "18440",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "59457803",
    skolenhetensNamn: "Helleborusskolan Österåker",
    postnr: "18439",
    besökspostnr: "18439",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "53319802",
    skolenhetensNamn: "Ljusterö skola",
    postnr: "18495",
    besökspostnr: "18495",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "31758556",
    skolenhetensNamn: "Margretelundsskolan",
    postnr: "18462",
    besökspostnr: "18462",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "96653388",
    skolenhetensNamn: "Robinson Valsjön",
    postnr: "18462",
    besökspostnr: "18462",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "58653522",
    skolenhetensNamn: "Roslags Kulla skola",
    postnr: "18491",
    besökspostnr: "18491",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "73083470",
    skolenhetensNamn: "Rydbo friskola",
    postnr: "18494",
    besökspostnr: "18494",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "72074288",
    skolenhetensNamn: "Rydbo skola",
    postnr: "18492",
    besökspostnr: "18492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "12407147",
    skolenhetensNamn: "Röllingbyskolan ",
    postnr: "18494",
    besökspostnr: "18494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "23756252",
    skolenhetensNamn: "Sjökarbyskolan",
    postnr: "18434",
    besökspostnr: "18434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "73272488",
    skolenhetensNamn: "Skärgårdsstadsskolan",
    postnr: "18470",
    besökspostnr: "18470",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "88712672",
    skolenhetensNamn: "Söraskolan",
    postnr: "18437",
    besökspostnr: "18437",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "19704413",
    skolenhetensNamn: "Åkerstorpsskolan",
    postnr: "18432",
    besökspostnr: "18432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0117",
    kommunsNamn: "Österåker",
    skolenhetsKod: "94113162",
    skolenhetensNamn: "Österskärsskolan",
    postnr: "18451",
    besökspostnr: "18451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "67926479",
    skolenhetensNamn: "Antistilla skola",
    postnr: "13432",
    besökspostnr: "13432",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "42434583",
    skolenhetensNamn: "Brunns skola",
    postnr: "13407",
    besökspostnr: "13460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "18053776",
    skolenhetensNamn: "Djurö skola",
    postnr: "13973",
    besökspostnr: "13973",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "94600352",
    skolenhetensNamn: "Ekedalsskolan",
    postnr: "13431",
    besökspostnr: "13431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "25090415",
    skolenhetensNamn: "Fågelvik skola",
    postnr: "13481",
    besökspostnr: "13462",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "63682476",
    skolenhetensNamn: "Grantomta Montessoriskola",
    postnr: "13935",
    besökspostnr: "13935",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "24561769",
    skolenhetensNamn: "Hemmestaskolan",
    postnr: "13481",
    besökspostnr: "13932",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "62569730",
    skolenhetensNamn: "Internationella Engelska Skolan Värmdö",
    postnr: "13439",
    besökspostnr: "13439",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "89005271",
    skolenhetensNamn: "Kullsvedsskolan",
    postnr: "13481",
    besökspostnr: "13932",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "70803136",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "13481",
    besökspostnr: "13935",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "11364996",
    skolenhetensNamn: "Lemshagastiftelsen",
    postnr: "13461",
    besökspostnr: "13461",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "47705565",
    skolenhetensNamn: "Munkmoraskolan",
    postnr: "13481",
    besökspostnr: "13435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "71156124",
    skolenhetensNamn: "Möja skola",
    postnr: "13043",
    besökspostnr: "13043",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "34946479",
    skolenhetensNamn: "Runmarö skola",
    postnr: "13038",
    besökspostnr: "13038",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "87412084",
    skolenhetensNamn: "Skolan LÄR",
    postnr: "13443",
    besökspostnr: "13443",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "70481988",
    skolenhetensNamn: "Svartsö skola",
    postnr: "13034",
    besökspostnr: "13034",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "94902263",
    skolenhetensNamn: "Viks skola",
    postnr: "13481",
    besökspostnr: "13935",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0120",
    kommunsNamn: "Värmdö",
    skolenhetsKod: "88283203",
    skolenhetensNamn: "Ösbyskolan",
    postnr: "13443",
    besökspostnr: "13443",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "88454421",
    skolenhetensNamn: "Aspnässkolan",
    postnr: "17731",
    besökspostnr: "17731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "76033436",
    skolenhetensNamn: "Barkarbyskolan",
    postnr: "17562",
    besökspostnr: "17562",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "39568857",
    skolenhetensNamn: "Berghemskolan",
    postnr: "17770",
    besökspostnr: "17770",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "89053805",
    skolenhetensNamn: "Fastebolskolan",
    postnr: "17543",
    besökspostnr: "17543",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "15080815",
    skolenhetensNamn: "Fjällenskolan",
    postnr: "17549",
    besökspostnr: "17549",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "81021363",
    skolenhetensNamn: "Herrestaskolan",
    postnr: "17744",
    besökspostnr: "17744",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "97184063",
    skolenhetensNamn: "Högbyskolan",
    postnr: "17554",
    besökspostnr: "17554",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "36628382",
    skolenhetensNamn: "Iljansbodaskolan",
    postnr: "17676",
    besökspostnr: "17676",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "46239244",
    skolenhetensNamn: "Kolarängskolan",
    postnr: "17675",
    besökspostnr: "17675",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "82757734",
    skolenhetensNamn: "Lundskolan",
    postnr: "17545",
    besökspostnr: "17545",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "36021631",
    skolenhetensNamn: "Montessoriskolan Växthuset",
    postnr: "17739",
    besökspostnr: "17739",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "57755652",
    skolenhetensNamn: "Neptuniskolan",
    postnr: "17565",
    besökspostnr: "17565",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "58173761",
    skolenhetensNamn: "Olovslundskolan",
    postnr: "17760",
    besökspostnr: "17760",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "33794207",
    skolenhetensNamn: "Sandvikskolan",
    postnr: "17547",
    besökspostnr: "17547",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "62707060",
    skolenhetensNamn: "Skälbyskolan",
    postnr: "17568",
    besökspostnr: "17568",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "97777849",
    skolenhetensNamn: "Tallbohovskolan",
    postnr: "17734",
    besökspostnr: "17734",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "12801932",
    skolenhetensNamn: "Ulvsättraskolan",
    postnr: "17674",
    besökspostnr: "17674",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "86271166",
    skolenhetensNamn: "Vattmyraskolan",
    postnr: "17739",
    besökspostnr: "17739",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "66634449",
    skolenhetensNamn: "Vittra Jakobsberg",
    postnr: "17764",
    besökspostnr: "17764",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0123",
    kommunsNamn: "Järfälla",
    skolenhetsKod: "98666483",
    skolenhetensNamn: "Ålstaskolan",
    postnr: "17738",
    besökspostnr: "17738",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "27595656",
    skolenhetensNamn: "Birkaskolan",
    postnr: "17822",
    besökspostnr: "17832",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "28231215",
    skolenhetensNamn: "Drottningholmskolan",
    postnr: "17893",
    besökspostnr: "17893",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "99373839",
    skolenhetensNamn: "Ekebyhovskolan",
    postnr: "17834",
    besökspostnr: "17834",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "43038662",
    skolenhetensNamn: "Färentuna skola",
    postnr: "17998",
    besökspostnr: "17998",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "33159363",
    skolenhetensNamn: "Munsö skola",
    postnr: "17823",
    besökspostnr: "17891",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "40904373",
    skolenhetensNamn: "Närlundaskolan",
    postnr: "17832",
    besökspostnr: "17832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "39195819",
    skolenhetensNamn: "Sanduddens skola",
    postnr: "17840",
    besökspostnr: "17840",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "26138955",
    skolenhetensNamn: "Skå skola",
    postnr: "17975",
    besökspostnr: "17975",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "11025388",
    skolenhetensNamn: "Stenhamraskolan",
    postnr: "17960",
    besökspostnr: "17960",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "41377165",
    skolenhetensNamn: "Sundby skola",
    postnr: "17823",
    besökspostnr: "17890",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0125",
    kommunsNamn: "Ekerö",
    skolenhetsKod: "66475520",
    skolenhetensNamn: "Träkvista skola",
    postnr: "17836",
    besökspostnr: "17836",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "79012683",
    skolenhetensNamn: "Annerstaskolan",
    postnr: "14153",
    besökspostnr: "14153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "13654969",
    skolenhetensNamn: "Balingsnässkolan",
    postnr: "14134",
    besökspostnr: "14134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "98477278",
    skolenhetensNamn: "Edboskolan",
    postnr: "14263",
    besökspostnr: "14263",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "15218794",
    skolenhetensNamn: "Glömstaskolan",
    postnr: "14132",
    besökspostnr: "14132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "81611939",
    skolenhetensNamn: "Grindstuskolan",
    postnr: "14143",
    besökspostnr: "14343",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "82962565",
    skolenhetensNamn: "Hörningsnässkolan",
    postnr: "14145",
    besökspostnr: "14145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "94301041",
    skolenhetensNamn: "Innovitaskolan Myrängen",
    postnr: "14137",
    besökspostnr: "14137",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "58994357",
    skolenhetensNamn: "Innovitaskolan Segeltorp",
    postnr: "14170",
    besökspostnr: "14170",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "96689751",
    skolenhetensNamn: "Kräpplaskolan",
    postnr: "14140",
    besökspostnr: "14140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "89510449",
    skolenhetensNamn: "Kungsklippeskolan",
    postnr: "14140",
    besökspostnr: "14140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "89701816",
    skolenhetensNamn: "Kästaskolan",
    postnr: "14152",
    besökspostnr: "14152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "47585300",
    skolenhetensNamn: "Lindskolan",
    postnr: "14144",
    besökspostnr: "14144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "81903519",
    skolenhetensNamn: "Långsjöskolan",
    postnr: "14172",
    besökspostnr: "14172",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "10063280",
    skolenhetensNamn: "Lärande Grundskola Skapa",
    postnr: "14144",
    besökspostnr: "14144",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "75565366",
    skolenhetensNamn: "Myrstugubergets Friskola",
    postnr: "14332",
    besökspostnr: "14332",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "32886383",
    skolenhetensNamn: "Mörtviksskolan",
    postnr: "14241",
    besökspostnr: "14241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "66383692",
    skolenhetensNamn: "Sjötorpsskolan",
    postnr: "14185",
    besökspostnr: "14252",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "89847413",
    skolenhetensNamn: "Skansbergsskolan",
    postnr: "14170",
    besökspostnr: "14170",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "36689149",
    skolenhetensNamn: "Skogskällan",
    postnr: "14131",
    besökspostnr: "14131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "64081877",
    skolenhetensNamn: "Snättringeskolan",
    postnr: "14143",
    besökspostnr: "14143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "10225038",
    skolenhetensNamn: "Solfagraskolan",
    postnr: "14131",
    besökspostnr: "14131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "37472911",
    skolenhetensNamn: "Stenmoskolan",
    postnr: "14131",
    besökspostnr: "14131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "62004466",
    skolenhetensNamn: "Stensängsskolan",
    postnr: "14137",
    besökspostnr: "14137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "28313791",
    skolenhetensNamn: "Tomtbergaskolan",
    postnr: "14148",
    besökspostnr: "14148",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "42601396",
    skolenhetensNamn: "Trångsundsskolan",
    postnr: "14261",
    besökspostnr: "14261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "90886859",
    skolenhetensNamn: "Utsäljeskolan",
    postnr: "14132",
    besökspostnr: "14132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "50783450",
    skolenhetensNamn: "Vistaskolan",
    postnr: "14150",
    besökspostnr: "14150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "14440920",
    skolenhetensNamn: "Visättraskolan",
    postnr: "14135",
    besökspostnr: "14135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "75387026",
    skolenhetensNamn: "Vårbackaskolan",
    postnr: "14130",
    besökspostnr: "14340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0126",
    kommunsNamn: "Huddinge",
    skolenhetsKod: "64949601",
    skolenhetensNamn: "Vårbyskolan",
    postnr: "14145",
    besökspostnr: "14145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "86192571",
    skolenhetensNamn: "Banslättsskolan",
    postnr: "14631",
    besökspostnr: "14631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "43238662",
    skolenhetensNamn: "Björkhaga Skola",
    postnr: "14731",
    besökspostnr: "14731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "71048800",
    skolenhetensNamn: "Borgskolan",
    postnr: "14568",
    besökspostnr: "14568",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "81020581",
    skolenhetensNamn: "Borgskolan F-klass Gul 1GR",
    postnr: "14568",
    besökspostnr: "14568",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "84891659",
    skolenhetensNamn: "Borgsskolan Borggruppen 1GR",
    postnr: "14568",
    besökspostnr: "14568",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "80378022",
    skolenhetensNamn: "Botkyrka Friskola F-9",
    postnr: "14565",
    besökspostnr: "14565",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "38661388",
    skolenhetensNamn: "Broängsskolan",
    postnr: "14732",
    besökspostnr: "14732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "53426548",
    skolenhetensNamn: "Brunnaskolan",
    postnr: "14567",
    besökspostnr: "14567",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "16762245",
    skolenhetensNamn: "Edessaskolan",
    postnr: "14568",
    besökspostnr: "14568",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "15620768",
    skolenhetensNamn: "Eklidsskolan",
    postnr: "14630",
    besökspostnr: "14630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "40631085",
    skolenhetensNamn: "Fittjaskolan Botkyrka Norra",
    postnr: "14550",
    besökspostnr: "14550",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "29524966",
    skolenhetensNamn: "Freinetskolan Kastanjen",
    postnr: "14574",
    besökspostnr: "14574",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "57228484",
    skolenhetensNamn: "Grindtorpsskolan",
    postnr: "14556",
    besökspostnr: "14556",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "25918983",
    skolenhetensNamn: "Hammerstaskolan",
    postnr: "14564",
    besökspostnr: "14564",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "80731562",
    skolenhetensNamn: "JENSEN grundskola Kvarnsjön ",
    postnr: "14760",
    besökspostnr: "14760",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "67742754",
    skolenhetensNamn: "Karsby International School",
    postnr: "14571",
    besökspostnr: "14571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "25506439",
    skolenhetensNamn: "Kassmyraskolan",
    postnr: "14733",
    besökspostnr: "14733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "56417100",
    skolenhetensNamn: "Kvarnhagsskolan",
    postnr: "14559",
    besökspostnr: "14559",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "86985859",
    skolenhetensNamn: "Malmsjö skola",
    postnr: "14770",
    besökspostnr: "14770",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "51863294",
    skolenhetensNamn: "Parkhemsskolan",
    postnr: "14653",
    besökspostnr: "14653",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "75580959",
    skolenhetensNamn: "Rikstens skola",
    postnr: "14637",
    besökspostnr: "14637",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "10657244",
    skolenhetensNamn: "Skogsbacksskolan",
    postnr: "14750",
    besökspostnr: "14750",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "18534178",
    skolenhetensNamn: "Storvretskolan",
    postnr: "14750",
    besökspostnr: "14750",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "57850353",
    skolenhetensNamn: "Sverigefinska skolan, Botkyrka",
    postnr: "14740",
    besökspostnr: "14740",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "45757947",
    skolenhetensNamn: "Tallidsskolan",
    postnr: "14551",
    besökspostnr: "14551",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "58310322",
    skolenhetensNamn: "Trädgårdsstadsskolan",
    postnr: "14650",
    besökspostnr: "14650",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "10495223",
    skolenhetensNamn: "Tullingebergsskolan",
    postnr: "14645",
    besökspostnr: "14645",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0127",
    kommunsNamn: "Botkyrka",
    skolenhetsKod: "36350546",
    skolenhetensNamn: "Tunaskolan",
    postnr: "14740",
    besökspostnr: "14740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0128",
    kommunsNamn: "Salem",
    skolenhetsKod: "76222592",
    skolenhetensNamn: "Innovitaskolan Rönninge",
    postnr: "14452",
    besökspostnr: "14452",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0128",
    kommunsNamn: "Salem",
    skolenhetsKod: "97927760",
    skolenhetensNamn: "Noblaskolan Rönninge",
    postnr: "14463",
    besökspostnr: "14463",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0128",
    kommunsNamn: "Salem",
    skolenhetsKod: "36262199",
    skolenhetensNamn: "Nytorpsskolan",
    postnr: "14464",
    besökspostnr: "14464",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0128",
    kommunsNamn: "Salem",
    skolenhetsKod: "71677992",
    skolenhetensNamn: "Rönninge skola",
    postnr: "14462",
    besökspostnr: "14462",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0128",
    kommunsNamn: "Salem",
    skolenhetsKod: "51578532",
    skolenhetensNamn: "Salemskolan",
    postnr: "14442",
    besökspostnr: "14442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0128",
    kommunsNamn: "Salem",
    skolenhetsKod: "58385274",
    skolenhetensNamn: "Skogsängsskolan",
    postnr: "14480",
    besökspostnr: "14442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0128",
    kommunsNamn: "Salem",
    skolenhetsKod: "92406445",
    skolenhetensNamn: "Säbyskolan",
    postnr: "14430",
    besökspostnr: "14430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0128",
    kommunsNamn: "Salem",
    skolenhetsKod: "56858061",
    skolenhetensNamn: "Söderby friskola",
    postnr: "14444",
    besökspostnr: "14444",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "73184968",
    skolenhetensNamn: "Brandbergsskolan",
    postnr: "13681",
    besökspostnr: "13660",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "45914893",
    skolenhetensNamn: "Båtsmansskolan",
    postnr: "13681",
    besökspostnr: "13665",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "47640376",
    skolenhetensNamn: "Dalarö skola",
    postnr: "13770",
    besökspostnr: "13770",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "81680693",
    skolenhetensNamn: "HagaLyckebyskolan",
    postnr: "13681",
    besökspostnr: "13670",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "86440798",
    skolenhetensNamn: "Haninge Montessoriskola",
    postnr: "13643",
    besökspostnr: "13643",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "44031601",
    skolenhetensNamn: "Höglundaskolan",
    postnr: "13681",
    besökspostnr: "13767",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "17957898",
    skolenhetensNamn: "Innovitaskolan Rudan",
    postnr: "13637",
    besökspostnr: "13637",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "55233055",
    skolenhetensNamn: "Innovitaskolan Söderby",
    postnr: "13667",
    besökspostnr: "13667",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "65927702",
    skolenhetensNamn: "Innovitaskolan Vendelsö",
    postnr: "13673",
    besökspostnr: "13673",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "23886554",
    skolenhetensNamn: "I Ur och Skur Mullebo",
    postnr: "13672",
    besökspostnr: "13672",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "30255409",
    skolenhetensNamn: "JENSEN grundskola Tungelsta",
    postnr: "13757",
    besökspostnr: "13757",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "76440567",
    skolenhetensNamn: "Kvarnbäcksskolan",
    postnr: "13681",
    besökspostnr: "13768",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "19089469",
    skolenhetensNamn: "Montessoriskolan Fredsduvan",
    postnr: "13767",
    besökspostnr: "13767",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "47034991",
    skolenhetensNamn: "Muskö skola",
    postnr: "13770",
    besökspostnr: "14895",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "33624606",
    skolenhetensNamn: "Måsöskolan",
    postnr: "13681",
    besökspostnr: "13649",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "43140379",
    skolenhetensNamn: "Nakterhusskolan",
    postnr: "13681",
    besökspostnr: "13647",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "47244612",
    skolenhetensNamn: "Ornö skola",
    postnr: "13770",
    besökspostnr: "13055",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "80746043",
    skolenhetensNamn: "Ribbybergsskolan",
    postnr: "13681",
    besökspostnr: "13738",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "59497255",
    skolenhetensNamn: "Ribbyskolan",
    postnr: "13681",
    besökspostnr: "13741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "19983531",
    skolenhetensNamn: "Rodrets friskola",
    postnr: "13754",
    besökspostnr: "13754",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "76400478",
    skolenhetensNamn: "Runstensskolan",
    postnr: "13681",
    besökspostnr: "13643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "94791997",
    skolenhetensNamn: "Svartbäcksskolan",
    postnr: "13681",
    besökspostnr: "13668",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "38975788",
    skolenhetensNamn: "Tungelsta skola",
    postnr: "13681",
    besökspostnr: "13755",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "47140640",
    skolenhetensNamn: "Utö skola",
    postnr: "13056",
    besökspostnr: "13056",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "72266104",
    skolenhetensNamn: "Vendelsömalmsskolan",
    postnr: "13681",
    besökspostnr: "13667",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "90537318",
    skolenhetensNamn: "Vikingaskolan",
    postnr: "13681",
    besökspostnr: "13652",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "75527289",
    skolenhetensNamn: "Västerhaninge Montessoriskola",
    postnr: "13738",
    besökspostnr: "13738",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "54752734",
    skolenhetensNamn: "Västra Ekoskolan",
    postnr: "13793",
    besökspostnr: "13793",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0136",
    kommunsNamn: "Haninge",
    skolenhetsKod: "30270791",
    skolenhetensNamn: "Åbyskolan",
    postnr: "13681",
    besökspostnr: "13734",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "58862351",
    skolenhetensNamn: "Bergfotens skola",
    postnr: "13537",
    besökspostnr: "13537",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "71078874",
    skolenhetensNamn: "Fornuddens skola",
    postnr: "13553",
    besökspostnr: "13553",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "41888515",
    skolenhetensNamn: "Fårdala skola",
    postnr: "13542",
    besökspostnr: "13542",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "48312263",
    skolenhetensNamn: "Hanvikens skola",
    postnr: "13550",
    besökspostnr: "13550",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "84704913",
    skolenhetensNamn: "Krusboda skola",
    postnr: "13535",
    besökspostnr: "13535",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "37147254",
    skolenhetensNamn: "Kumla skola",
    postnr: "13551",
    besökspostnr: "13551",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "33455004",
    skolenhetensNamn: "Njupkärrs skola",
    postnr: "13544",
    besökspostnr: "13544",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "95747197",
    skolenhetensNamn: "Noblaskolan Brevik",
    postnr: "13568",
    besökspostnr: "13568",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "39453214",
    skolenhetensNamn: "Sofiebergsskolan",
    postnr: "13553",
    besökspostnr: "13553",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "52531425",
    skolenhetensNamn: "Stimmets skola",
    postnr: "13542",
    besökspostnr: "13542",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "87665835",
    skolenhetensNamn: "Strandskolan",
    postnr: "13563",
    besökspostnr: "13563",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "22202915",
    skolenhetensNamn: "Tyresö Montessoriskola",
    postnr: "13539",
    besökspostnr: "13539",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0138",
    kommunsNamn: "Tyresö",
    skolenhetsKod: "83020784",
    skolenhetensNamn: "Tyresö skola",
    postnr: "13561",
    besökspostnr: "13561",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "56299553",
    skolenhetensNamn: "Bergaskolan",
    postnr: "19630",
    besökspostnr: "19630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "56315778",
    skolenhetensNamn: "Brunnaskolan",
    postnr: "19638",
    besökspostnr: "19638",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "61061763",
    skolenhetensNamn: "Finnstaskolan",
    postnr: "19731",
    besökspostnr: "19731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "24606968",
    skolenhetensNamn: "Härneviskolan",
    postnr: "19730",
    besökspostnr: "19730",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "89851619",
    skolenhetensNamn: "JENSEN grundskola Bro-Mälarstrand",
    postnr: "19733",
    besökspostnr: "19733",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "63111806",
    skolenhetensNamn: "Källskolan F-5",
    postnr: "19634",
    besökspostnr: "19634",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "85759071",
    skolenhetensNamn: "Lillsjöskolan",
    postnr: "19633",
    besökspostnr: "19633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "21781901",
    skolenhetensNamn: "Råbyskolan",
    postnr: "19732",
    besökspostnr: "19732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "76550010",
    skolenhetensNamn: "Tjustaskolan",
    postnr: "19793",
    besökspostnr: "19793",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0139",
    kommunsNamn: "Upplands-Bro",
    skolenhetsKod: "31608087",
    skolenhetensNamn: "Urfjälls Montessoriskola",
    postnr: "19693",
    besökspostnr: "19693",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0140",
    kommunsNamn: "Nykvarn",
    skolenhetsKod: "68422076",
    skolenhetensNamn: "Björkestaskolan",
    postnr: "15530",
    besökspostnr: "15530",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0140",
    kommunsNamn: "Nykvarn",
    skolenhetsKod: "34225123",
    skolenhetensNamn: "Furuborgskolan",
    postnr: "15580",
    besökspostnr: "15532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0140",
    kommunsNamn: "Nykvarn",
    skolenhetsKod: "93844270",
    skolenhetensNamn: "Turingeskolan",
    postnr: "15531",
    besökspostnr: "15531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "13665610",
    skolenhetensNamn: "Brinkskolan",
    postnr: "18750",
    besökspostnr: "18750",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "69043808",
    skolenhetensNamn: "Byleskolan",
    postnr: "18773",
    besökspostnr: "18773",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "65839656",
    skolenhetensNamn: "Byängsskolan",
    postnr: "18379",
    besökspostnr: "18379",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "88700175",
    skolenhetensNamn: "Capellaskolan Dibber",
    postnr: "18368",
    besökspostnr: "18368",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "89751941",
    skolenhetensNamn: "Drakskeppsskolan",
    postnr: "18363",
    besökspostnr: "18363",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "44002887",
    skolenhetensNamn: "Ellagårdsskolan",
    postnr: "18731",
    besökspostnr: "18731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "45984740",
    skolenhetensNamn: "Helleborusskolan Täby AB",
    postnr: "18353",
    besökspostnr: "18353",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "41181908",
    skolenhetensNamn: "Hägerneholmsskolan",
    postnr: "18769",
    besökspostnr: "18769",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "78918091",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "18706",
    besökspostnr: "18770",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "52866214",
    skolenhetensNamn: "Myrängsskolan",
    postnr: "18764",
    besökspostnr: "18764",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "23312767",
    skolenhetensNamn: "Noblaskolan Täby",
    postnr: "18746",
    besökspostnr: "18746",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "71638763",
    skolenhetensNamn: "Norskolan",
    postnr: "18353",
    besökspostnr: "18353",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "83623446",
    skolenhetensNamn: "Rösjöskolan",
    postnr: "18742",
    besökspostnr: "18742",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "41554150",
    skolenhetensNamn: "Slottsparksskolan",
    postnr: "18352",
    besökspostnr: "18352",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "89424201",
    skolenhetensNamn: "Spirande lärande i Täby AB",
    postnr: "18354",
    besökspostnr: "18354",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "65545391",
    skolenhetensNamn: "Täby Friskola-Hägernäs",
    postnr: "18740",
    besökspostnr: "18359",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "55267088",
    skolenhetensNamn: "Täby Friskola-Lilla Gribbylund/Gribbylund",
    postnr: "18740",
    besökspostnr: "18764",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "72769342",
    skolenhetensNamn: "Täby Friskola-Ljungmyr",
    postnr: "18740",
    besökspostnr: "18746",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "57206066",
    skolenhetensNamn: "Vallatorpsskolan",
    postnr: "18752",
    besökspostnr: "18752",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "53352683",
    skolenhetensNamn: "Viggbyskolan",
    postnr: "18369",
    besökspostnr: "18369",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0160",
    kommunsNamn: "Täby",
    skolenhetsKod: "77370320",
    skolenhetensNamn: "Ytterbyskolan",
    postnr: "18338",
    besökspostnr: "18338",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "95432644",
    skolenhetensNamn: "Baldersskolan",
    postnr: "18235",
    besökspostnr: "18235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "59848694",
    skolenhetensNamn: "Danderyds Montessoriskola",
    postnr: "18232",
    besökspostnr: "18232",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "94987345",
    skolenhetensNamn: "Ekebyskolan",
    postnr: "18266",
    besökspostnr: "18266",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "95940425",
    skolenhetensNamn: "Elevverket",
    postnr: "18268",
    besökspostnr: "18268",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "63581692",
    skolenhetensNamn: "Enebybergs skola",
    postnr: "18247",
    besökspostnr: "18247",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "95676943",
    skolenhetensNamn: "Kevingeskolan",
    postnr: "18257",
    besökspostnr: "18257",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "89317818",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "18202",
    besökspostnr: "18234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "10735290",
    skolenhetensNamn: "Långängsskolan",
    postnr: "18275",
    besökspostnr: "18275",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "72370577",
    skolenhetensNamn: "Stocksundsskolan",
    postnr: "18273",
    besökspostnr: "18267",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "43289917",
    skolenhetensNamn: "Vasaskolan",
    postnr: "18262",
    besökspostnr: "18262",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0162",
    kommunsNamn: "Danderyd",
    skolenhetsKod: "78808043",
    skolenhetensNamn: "Ösbyskolan AB",
    postnr: "18264",
    besökspostnr: "18264",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "12092203",
    skolenhetensNamn: "Brage- skola och språkförskola",
    postnr: "19268",
    besökspostnr: "19268",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "47462120",
    skolenhetensNamn: "Dibber International School Sollentuna ",
    postnr: "19270",
    besökspostnr: "19270",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "72392309",
    skolenhetensNamn: "Dibber Snäckbacken särskola",
    postnr: "19271",
    besökspostnr: "19271",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "40467992",
    skolenhetensNamn: "Futuraskolan International Hertig Karl",
    postnr: "19207",
    besökspostnr: "19269",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "47986219",
    skolenhetensNamn: "Futuraskolan International Rådan",
    postnr: "19138",
    besökspostnr: "19138",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "73629371",
    skolenhetensNamn: "Gillbo-Gröndalsskolan",
    postnr: "19269",
    besökspostnr: "19269",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "70201551",
    skolenhetensNamn: "Gärdesskolan",
    postnr: "19251",
    besökspostnr: "19251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "62862066",
    skolenhetensNamn: "Helenelundsskolan",
    postnr: "19140",
    besökspostnr: "19140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "85440729",
    skolenhetensNamn: "Häggvik- Skälbyskolan ",
    postnr: "19124",
    besökspostnr: "19124",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "40593329",
    skolenhetensNamn: "Järva VIP För- och Grundsärskola",
    postnr: "19275",
    besökspostnr: "19275",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "28799594",
    skolenhetensNamn: "Kärrdalsskolan",
    postnr: "19208",
    besökspostnr: "19256",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "85777315",
    skolenhetensNamn: "Mikaelskolan",
    postnr: "19207",
    besökspostnr: "19269",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "21997077",
    skolenhetensNamn: "Norra Strand Dibber",
    postnr: "19275",
    besökspostnr: "19275",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "15339442",
    skolenhetensNamn: "Runbackaskolan",
    postnr: "19273",
    besökspostnr: "19273",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "51041954",
    skolenhetensNamn: "Rälsen AB i Norrviken",
    postnr: "19266",
    besökspostnr: "19266",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "47290443",
    skolenhetensNamn: "Rälsen AB i Viby",
    postnr: "19274",
    besökspostnr: "19274",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "58294981",
    skolenhetensNamn: "Rösjöskolan",
    postnr: "19252",
    besökspostnr: "19252",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "56867397",
    skolenhetensNamn: "Silverbäcken Dibber",
    postnr: "19138",
    besökspostnr: "19138",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "69195602",
    skolenhetensNamn: "Silverdalsskolan",
    postnr: "19138",
    besökspostnr: "19138",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "39907811",
    skolenhetensNamn: "Sofielundsskolan",
    postnr: "19135",
    besökspostnr: "19135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "26878879",
    skolenhetensNamn: "Stallets Skola",
    postnr: "19270",
    besökspostnr: "19270",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "83735665",
    skolenhetensNamn: "Stockholm International Academy Häggvik",
    postnr: "19150",
    besökspostnr: "19150",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "20253736",
    skolenhetensNamn: "Tegelhagens skola",
    postnr: "19139",
    besökspostnr: "19139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "75401552",
    skolenhetensNamn: "Turebergsskolan ",
    postnr: "19146",
    besökspostnr: "19146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "45882318",
    skolenhetensNamn: "Töjnaskolan",
    postnr: "19134",
    besökspostnr: "19134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "32479095",
    skolenhetensNamn: "Vaxmoraskolan",
    postnr: "19248",
    besökspostnr: "19248",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "15547680",
    skolenhetensNamn: "Vibyskolan",
    postnr: "19273",
    besökspostnr: "19273",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "59266692",
    skolenhetensNamn: "Vittra Rösjötorp",
    postnr: "19256",
    besökspostnr: "19256",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0163",
    kommunsNamn: "Sollentuna",
    skolenhetsKod: "58102266",
    skolenhetensNamn: "Vittra Sollentuna",
    postnr: "19135",
    besökspostnr: "19135",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "44673074",
    skolenhetensNamn: "Abrahamsbergsskolan",
    postnr: "16830",
    besökspostnr: "16830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "41285467",
    skolenhetensNamn: "Adolfsbergsskolan",
    postnr: "16867",
    besökspostnr: "16867",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "37796564",
    skolenhetensNamn: "Akalla grundskola F-9",
    postnr: "16471",
    besökspostnr: "16471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "82838118",
    skolenhetensNamn: "Alla Nationers Fria Skola",
    postnr: "12743",
    besökspostnr: "12743",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "72627865",
    skolenhetensNamn: "Alviksskolan",
    postnr: "16745",
    besökspostnr: "16745",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "16061350",
    skolenhetensNamn: "Apolloniaskolan",
    postnr: "12559",
    besökspostnr: "12559",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "68812584",
    skolenhetensNamn: "Askebyskolan",
    postnr: "16370",
    besökspostnr: "16370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "72672270",
    skolenhetensNamn: "Aspuddens skola",
    postnr: "12651",
    besökspostnr: "12651",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "47979659",
    skolenhetensNamn: "Backluraskolan",
    postnr: "16577",
    besökspostnr: "16577",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "13679897",
    skolenhetensNamn: "Bagarmossens skola",
    postnr: "12841",
    besökspostnr: "12841",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "45364437",
    skolenhetensNamn: "Bandhagens skola",
    postnr: "12457",
    besökspostnr: "12457",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "46616391",
    skolenhetensNamn: "Beckombergaskolan",
    postnr: "16860",
    besökspostnr: "16860",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29096335",
    skolenhetensNamn: "Bergsundsskolan",
    postnr: "11733",
    besökspostnr: "11733",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "43088454",
    skolenhetensNamn: "Björkhagens skola",
    postnr: "12152",
    besökspostnr: "12152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "22174435",
    skolenhetensNamn: "Björnbodaskolan",
    postnr: "16245",
    besökspostnr: "16245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "55382103",
    skolenhetensNamn: "Björngårdsskolan",
    postnr: "11852",
    besökspostnr: "11852",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "56949296",
    skolenhetensNamn: "Blackebergsskolan",
    postnr: "16844",
    besökspostnr: "16844",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "62533129",
    skolenhetensNamn: "Bobergsskolan",
    postnr: "11542",
    besökspostnr: "11542",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "14121892",
    skolenhetensNamn: "Bredängsskolan",
    postnr: "12702",
    besökspostnr: "12732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "36232577",
    skolenhetensNamn: "Bromma Enskilda Skola ek.fören.",
    postnr: "16756",
    besökspostnr: "16756",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "40383398",
    skolenhetensNamn: "Bromma kyrkskola",
    postnr: "16857",
    besökspostnr: "16857",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "92347699",
    skolenhetensNamn: "Bromstensskolan",
    postnr: "16343",
    besökspostnr: "16343",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "17165481",
    skolenhetensNamn: "Brotorpsskolan",
    postnr: "12844",
    besökspostnr: "12844",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "20683111",
    skolenhetensNamn: "Bäckahagens skola",
    postnr: "12433",
    besökspostnr: "12433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29371242",
    skolenhetensNamn: "Campus Manilla",
    postnr: "11521",
    besökspostnr: "11521",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "35547286",
    skolenhetensNamn: "Carlssons skola",
    postnr: "11458",
    besökspostnr: "11458",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "43134105",
    skolenhetensNamn: "Cordoba International School",
    postnr: "16475",
    besökspostnr: "16475",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "71363923",
    skolenhetensNamn: "Djurgårdsskolan",
    postnr: "11521",
    besökspostnr: "11521",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "25555358",
    skolenhetensNamn: "Eiraskolan",
    postnr: "11224",
    besökspostnr: "11224",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "64841247",
    skolenhetensNamn: "Ekensbergsskolan",
    postnr: "11769",
    besökspostnr: "11769",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "83810698",
    skolenhetensNamn: "Elinsborgsskolan",
    postnr: "16361",
    besökspostnr: "16361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "16394249",
    skolenhetensNamn: "Ellen Key skolan",
    postnr: "16343",
    besökspostnr: "16343",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "43632318",
    skolenhetensNamn: "Enbacksskolan",
    postnr: "16361",
    besökspostnr: "16361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "87521048",
    skolenhetensNamn: "Engelbrektsskolan",
    postnr: "11427",
    besökspostnr: "11427",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29725531",
    skolenhetensNamn: "Engelska Skolan Norr",
    postnr: "11421",
    besökspostnr: "11421",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "66455663",
    skolenhetensNamn: "Engelska Skolan Novia",
    postnr: "11743",
    besökspostnr: "11743",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "74631147",
    skolenhetensNamn: "Enskedefältets skola",
    postnr: "12263",
    besökspostnr: "12263",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "72248694",
    skolenhetensNamn: "Enskede skola",
    postnr: "12262",
    besökspostnr: "12262",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "59249600",
    skolenhetensNamn: "Eriksdalsskolan",
    postnr: "11861",
    besökspostnr: "11861",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "56501561",
    skolenhetensNamn: "Essingeskolan",
    postnr: "11266",
    besökspostnr: "11266",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "34540901",
    skolenhetensNamn: "Estniska Skolan i Stockholm",
    postnr: "11129",
    besökspostnr: "11129",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "79969356",
    skolenhetensNamn: "Europaskolan",
    postnr: "10262",
    besökspostnr: "11665",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "49862579",
    skolenhetensNamn: "Fagersjöskolan",
    postnr: "12349",
    besökspostnr: "12349",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "38758770",
    skolenhetensNamn: "Farsta grundskola",
    postnr: "12347",
    besökspostnr: "12332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "42179975",
    skolenhetensNamn: "Farsta Strandsskolan",
    postnr: "12372",
    besökspostnr: "12372",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "60308449",
    skolenhetensNamn: "Filosofiska",
    postnr: "12830",
    besökspostnr: "12834",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "72684556",
    skolenhetensNamn: "Framstegsskolan i Stockholm",
    postnr: "12465",
    besökspostnr: "12465",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "42328518",
    skolenhetensNamn: "Franska Skolan/Ecole francaise",
    postnr: "11183",
    besökspostnr: "11140",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "64065082",
    skolenhetensNamn: "Fredrikshovs slotts skola",
    postnr: "11523",
    besökspostnr: "11523",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "62544265",
    skolenhetensNamn: "Fria Maria Barnskola",
    postnr: "11864",
    besökspostnr: "11864",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "14115670",
    skolenhetensNamn: "Friskolan Hästens",
    postnr: "12345",
    besökspostnr: "12344",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "19353396",
    skolenhetensNamn: "Fruängens skola",
    postnr: "12907",
    besökspostnr: "12953",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "17526606",
    skolenhetensNamn: "Fryshuset Akademiska Järva",
    postnr: "16433",
    besökspostnr: "16433",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "65487837",
    skolenhetensNamn: "Futuraskolan International Stockholm",
    postnr: "11557",
    besökspostnr: "11557",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "59551033",
    skolenhetensNamn: "Förskola Skola Via Emilia",
    postnr: "11621",
    besökspostnr: "11621",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "51341279",
    skolenhetensNamn: "Grimstaskolan",
    postnr: "16257",
    besökspostnr: "16257",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "90850620",
    skolenhetensNamn: "Grundskolan Metapontum",
    postnr: "12559",
    besökspostnr: "12559",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "44185751",
    skolenhetensNamn: "Gröndalsskolan",
    postnr: "11765",
    besökspostnr: "11765",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "93412255",
    skolenhetensNamn: "Gubbängsskolan",
    postnr: "12245",
    besökspostnr: "12245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "74723082",
    skolenhetensNamn: "Gullingeskolan",
    postnr: "16363",
    besökspostnr: "16363",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "71754599",
    skolenhetensNamn: "Gustav Vasa skola",
    postnr: "11327",
    besökspostnr: "11327",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "41176931",
    skolenhetensNamn: "Gärdesskolan",
    postnr: "11553",
    besökspostnr: "11553",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "82110758",
    skolenhetensNamn: "Hagsätraskolan",
    postnr: "12470",
    besökspostnr: "12470",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "66283082",
    skolenhetensNamn: "Hammarbyskolan Norra",
    postnr: "12138",
    besökspostnr: "12138",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "68602166",
    skolenhetensNamn: "Hammarbyskolan Södra",
    postnr: "12149",
    besökspostnr: "12149",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "90561266",
    skolenhetensNamn: "Hedvig Eleonora skola",
    postnr: "11454",
    besökspostnr: "11454",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "20561651",
    skolenhetensNamn: "Herrängens skola",
    postnr: "12555",
    besökspostnr: "12555",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "68939834",
    skolenhetensNamn: "Hillelskolan",
    postnr: "10242",
    besökspostnr: "11439",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "92416778",
    skolenhetensNamn: "Hjorthagens skola",
    postnr: "11542",
    besökspostnr: "11542",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "69266159",
    skolenhetensNamn: "Hjulsta grundskola F-9",
    postnr: "16365",
    besökspostnr: "16365",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "42149666",
    skolenhetensNamn: "Husbygårdsskolan F-9",
    postnr: "16432",
    besökspostnr: "16432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "98070480",
    skolenhetensNamn: "Hägerstenshamnens skola",
    postnr: "12936",
    besökspostnr: "12936",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "70329261",
    skolenhetensNamn: "Hägerstensåsens skola",
    postnr: "12932",
    besökspostnr: "12932",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "86715303",
    skolenhetensNamn: "Hällsboskolan Mälarhöjden",
    postnr: "12940",
    besökspostnr: "12940",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "87838841",
    skolenhetensNamn: "Hässelbygårdsskolan",
    postnr: "16559",
    besökspostnr: "16559",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "86597125",
    skolenhetensNamn: "Hässelby Villastads skola",
    postnr: "16572",
    besökspostnr: "16572",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "24716970",
    skolenhetensNamn: "Högalidsskolan",
    postnr: "11735",
    besökspostnr: "11735",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "36281817",
    skolenhetensNamn: "Höglandsskolan",
    postnr: "16771",
    besökspostnr: "16771",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "57184925",
    skolenhetensNamn: "Hökarängsskolan",
    postnr: "12358",
    besökspostnr: "12358",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "81396509",
    skolenhetensNamn: "Innovitaskolan Banérporten",
    postnr: "11526",
    besökspostnr: "11526",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29585072",
    skolenhetensNamn: "Innovitaskolan Enskede",
    postnr: "12232",
    besökspostnr: "12232",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "24046791",
    skolenhetensNamn: "Innovitaskolan Farstaäng",
    postnr: "12342",
    besökspostnr: "12342",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "46646143",
    skolenhetensNamn: "Innovitaskolan Joriel",
    postnr: "12560",
    besökspostnr: "12560",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "51772207",
    skolenhetensNamn: "Innovitaskolan Södermalm",
    postnr: "11643",
    besökspostnr: "11643",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "10230535",
    skolenhetensNamn: "Innovitaskolan Vällingby Park",
    postnr: "16274",
    besökspostnr: "16274",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "33733966",
    skolenhetensNamn: "International School of Kungsholmen",
    postnr: "10386",
    besökspostnr: "11250",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "38935587",
    skolenhetensNamn: "Internationella Engelska Skolan Enskede",
    postnr: "12245",
    besökspostnr: "12245",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "61980418",
    skolenhetensNamn: "I Ur och Skur Mullekojan",
    postnr: "12836",
    besökspostnr: "12836",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "22777703",
    skolenhetensNamn: "Johannes skola",
    postnr: "11354",
    besökspostnr: "11354",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "97683561",
    skolenhetensNamn: "Johan Skytteskolan",
    postnr: "12558",
    besökspostnr: "12558",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "19377571",
    skolenhetensNamn: "Karlbergs skola",
    postnr: "11341",
    besökspostnr: "11341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "23617768",
    skolenhetensNamn: "Katarina Norra skola",
    postnr: "11621",
    besökspostnr: "11621",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "12187987",
    skolenhetensNamn: "Katarina Södra skola",
    postnr: "11639",
    besökspostnr: "11639",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "22513261",
    skolenhetensNamn: "Kista grundskola",
    postnr: "16425",
    besökspostnr: "16425",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "37556682",
    skolenhetensNamn: "Kista International School",
    postnr: "16477",
    besökspostnr: "16477",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "47273038",
    skolenhetensNamn: "Klastorpsskolan",
    postnr: "11258",
    besökspostnr: "11258",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "24754913",
    skolenhetensNamn: "Knutbyskolan",
    postnr: "16305",
    besökspostnr: "16373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "57329777",
    skolenhetensNamn: "Konradsbergsskolan",
    postnr: "11259",
    besökspostnr: "11259",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "80612612",
    skolenhetensNamn: "Kristinebergsskolan",
    postnr: "11251",
    besökspostnr: "11251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "14472068",
    skolenhetensNamn: "Kristna skolan",
    postnr: "12138",
    besökspostnr: "12138",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "98760751",
    skolenhetensNamn: "Kristofferskolan, gr",
    postnr: "16836",
    besökspostnr: "16836",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "24172067",
    skolenhetensNamn: "Kulturskolan Raketen",
    postnr: "12153",
    besökspostnr: "12153",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "42202683",
    skolenhetensNamn: "Kungsholmens friskola",
    postnr: "11224",
    besökspostnr: "11224",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29410344",
    skolenhetensNamn: "Kungsholmens grundskola",
    postnr: "11238",
    besökspostnr: "11238",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "74396596",
    skolenhetensNamn: "Kvarnbackaskolan",
    postnr: "16447",
    besökspostnr: "16447",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "72345466",
    skolenhetensNamn: "Kvarnbyskolan",
    postnr: "16305",
    besökspostnr: "16375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "88195519",
    skolenhetensNamn: "Kvickenstorpsskolan",
    postnr: "12334",
    besökspostnr: "12334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "70398560",
    skolenhetensNamn: "Kämpetorpsskolan",
    postnr: "12641",
    besökspostnr: "12641",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "72009890",
    skolenhetensNamn: "Lilla Adolf Fredriks skola",
    postnr: "11321",
    besökspostnr: "11321",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "13954336",
    skolenhetensNamn: "Lilla Martinskolan",
    postnr: "12357",
    besökspostnr: "12357",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "46581319",
    skolenhetensNamn: "Lillholmsskolan",
    postnr: "12740",
    besökspostnr: "12740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "76557608",
    skolenhetensNamn: "Lindeskolan",
    postnr: "12048",
    besökspostnr: "12048",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "70089804",
    skolenhetensNamn: "Loviselundsskolan",
    postnr: "16555",
    besökspostnr: "16555",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "49741755",
    skolenhetensNamn: "Lugnets skola",
    postnr: "12068",
    besökspostnr: "12068",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "72509254",
    skolenhetensNamn: "Lunaskolan Bromma",
    postnr: "16102",
    besökspostnr: "16868",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "28184857",
    skolenhetensNamn: "Lundaskolan",
    postnr: "11728",
    besökspostnr: "11728",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "41764666",
    skolenhetensNamn: "Långbrodalsskolan",
    postnr: "12558",
    besökspostnr: "12558",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "31903219",
    skolenhetensNamn: "Magelungsskolan",
    postnr: "12373",
    besökspostnr: "12373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "10682125",
    skolenhetensNamn: "Magitaskolan",
    postnr: "11767",
    besökspostnr: "11767",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "86337537",
    skolenhetensNamn: "Maltesholmsskolan",
    postnr: "16563",
    besökspostnr: "16563",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "40971064",
    skolenhetensNamn: "Manillaskolan",
    postnr: "10226",
    besökspostnr: "11259",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "73893187",
    skolenhetensNamn: "Maria Elementarskola",
    postnr: "11847",
    besökspostnr: "11847",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "69895255",
    skolenhetensNamn: "Mariaskolan",
    postnr: "11853",
    besökspostnr: "11853",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "74920766",
    skolenhetensNamn: "Mariehällsskolan",
    postnr: "16872",
    besökspostnr: "16872",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "82854505",
    skolenhetensNamn: "Martinskolan Söders waldorfskola",
    postnr: "12357",
    besökspostnr: "12357",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "16348406",
    skolenhetensNamn: "Matteusskolan",
    postnr: "11346",
    besökspostnr: "11346",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "54730646",
    skolenhetensNamn: "Midsommarkransens grundskola",
    postnr: "12647",
    besökspostnr: "12647",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "82408375",
    skolenhetensNamn: "Montessori Mondial Kista",
    postnr: "16441",
    besökspostnr: "16441",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "45553352",
    skolenhetensNamn: "Montessori Mondial Kungsholmen",
    postnr: "11259",
    besökspostnr: "11259",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "92689250",
    skolenhetensNamn: "Montessori Mondial Mälarhöjden",
    postnr: "12940",
    besökspostnr: "12940",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "36066067",
    skolenhetensNamn: "Musikskolan Lilla Akademien",
    postnr: "11345",
    besökspostnr: "11345",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "46165232",
    skolenhetensNamn: "Mälarhöjdens skola",
    postnr: "12944",
    besökspostnr: "12944",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "52479822",
    skolenhetensNamn: "Nockebyhovsskolan",
    postnr: "16841",
    besökspostnr: "16841",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "77314942",
    skolenhetensNamn: "Norra Ängby skola",
    postnr: "16856",
    besökspostnr: "16856",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "92127007",
    skolenhetensNamn: "Norrbyskolan",
    postnr: "16351",
    besökspostnr: "16351",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "83828066",
    skolenhetensNamn: "Nya Elementar",
    postnr: "16855",
    besökspostnr: "16855",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "36520624",
    skolenhetensNamn: "Nybohovsskolan",
    postnr: "11764",
    besökspostnr: "11764",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "43166282",
    skolenhetensNamn: "Nälstaskolan",
    postnr: "16272",
    besökspostnr: "16272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29158808",
    skolenhetensNamn: "Observatorielundens skola",
    postnr: "11327",
    besökspostnr: "11327",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "15968847",
    skolenhetensNamn: "Olaus Petriskolan",
    postnr: "11553",
    besökspostnr: "11534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "66851849",
    skolenhetensNamn: "Olovslundsskolan",
    postnr: "16772",
    besökspostnr: "16772",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "33483759",
    skolenhetensNamn: "Olympen Telefonplan",
    postnr: "10061",
    besökspostnr: "12628",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "97966458",
    skolenhetensNamn: "Ormkärrsskolan",
    postnr: "12472",
    besökspostnr: "12472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "27357778",
    skolenhetensNamn: "Oxhagsskolan",
    postnr: "16478",
    besökspostnr: "16478",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "75255864",
    skolenhetensNamn: "Pilgrimsskolan",
    postnr: "12648",
    besökspostnr: "12648",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "10360193",
    skolenhetensNamn: "Profilskolan",
    postnr: "16250",
    besökspostnr: "16250",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "50848996",
    skolenhetensNamn: "Raoul Wallenbergskolan Bromma",
    postnr: "16861",
    besökspostnr: "16861",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "37416253",
    skolenhetensNamn: "Rinkebyskolan",
    postnr: "16371",
    besökspostnr: "16371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "77656740",
    skolenhetensNamn: "Rosholmen Education Academy AB",
    postnr: "12743",
    besökspostnr: "12743",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "35779467",
    skolenhetensNamn: "Roslagstullsskolan",
    postnr: "11346",
    besökspostnr: "11346",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "60382920",
    skolenhetensNamn: "Rågsveds grundskola",
    postnr: "12465",
    besökspostnr: "12465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "61686233",
    skolenhetensNamn: "Rålambshovsskolan",
    postnr: "11260",
    besökspostnr: "11260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "97686250",
    skolenhetensNamn: "Rödabergsskolan",
    postnr: "11344",
    besökspostnr: "11344",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "19942197",
    skolenhetensNamn: "Sandåkraskolan",
    postnr: "12866",
    besökspostnr: "12866",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "15331669",
    skolenhetensNamn: "Sjöstadsskolan",
    postnr: "12064",
    besökspostnr: "12064",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "70592143",
    skolenhetensNamn: "Sjöviksskolan",
    postnr: "11755",
    besökspostnr: "11755",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "13124830",
    skolenhetensNamn: "Sjöängsskolan",
    postnr: "12535",
    besökspostnr: "12535",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "76952966",
    skolenhetensNamn: "Skanskvarnsskolan",
    postnr: "12039",
    besökspostnr: "12039",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "82075850",
    skolenhetensNamn: "Skarpatorpsskolan",
    postnr: "12835",
    besökspostnr: "12835",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "53075005",
    skolenhetensNamn: "Skarpnäcks skola",
    postnr: "12155",
    besökspostnr: "12155",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "99022046",
    skolenhetensNamn: "Sköndalsskolan",
    postnr: "12867",
    besökspostnr: "12867",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "87920455",
    skolenhetensNamn: "Skönstaholmsskolan",
    postnr: "12360",
    besökspostnr: "12360",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "59468219",
    skolenhetensNamn: "Slättgårdsskolan",
    postnr: "12744",
    besökspostnr: "12744",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "50060022",
    skolenhetensNamn: "Smedshagsskolan",
    postnr: "16512",
    besökspostnr: "16573",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "33922950",
    skolenhetensNamn: "Smedslättsskolan",
    postnr: "16761",
    besökspostnr: "16761",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29705473",
    skolenhetensNamn: "Snösätraskolan",
    postnr: "12461",
    besökspostnr: "12461",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "45436515",
    skolenhetensNamn: "Sofia skola",
    postnr: "11635",
    besökspostnr: "11635",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "34671128",
    skolenhetensNamn: "Solbergaskolan",
    postnr: "12679",
    besökspostnr: "12679",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "26852132",
    skolenhetensNamn: "Solhemsskolan",
    postnr: "16352",
    besökspostnr: "16352",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "48363218",
    skolenhetensNamn: "Solhemsängens skola",
    postnr: "16350",
    besökspostnr: "16350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "53147557",
    skolenhetensNamn: "Stefanskolan",
    postnr: "16856",
    besökspostnr: "16856",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "94943347",
    skolenhetensNamn: "S:t Eriks Katolska Skola",
    postnr: "12238",
    besökspostnr: "12232",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "11442396",
    skolenhetensNamn: "Stockholm International Academy Hägersten",
    postnr: "12640",
    besökspostnr: "12640",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "23498302",
    skolenhetensNamn: "Stockholms Montessoriskola Anne Frank",
    postnr: "11635",
    besökspostnr: "11635",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "41405700",
    skolenhetensNamn: "Storkyrkoskolan",
    postnr: "11129",
    besökspostnr: "11129",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "91352227",
    skolenhetensNamn: "Studemaskolan",
    postnr: "12432",
    besökspostnr: "12432",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "91816093",
    skolenhetensNamn: "Sturebyskolan",
    postnr: "12242",
    besökspostnr: "12242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "44046131",
    skolenhetensNamn: "Sundbyskolan",
    postnr: "16344",
    besökspostnr: "16344",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "39923451",
    skolenhetensNamn: "Sverigefinska skolan i Kista",
    postnr: "16443",
    besökspostnr: "16443",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "78508835",
    skolenhetensNamn: "Sverigefinska skolan Sthlm",
    postnr: "11240",
    besökspostnr: "11240",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "21724208",
    skolenhetensNamn: "Sätraskolan",
    postnr: "12738",
    besökspostnr: "12738",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "18064456",
    skolenhetensNamn: "Söderholmsskolan",
    postnr: "12741",
    besökspostnr: "12741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "75614609",
    skolenhetensNamn: "Södermalmsskolan",
    postnr: "11855",
    besökspostnr: "11855",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "88795871",
    skolenhetensNamn: "Södra Ängby skola",
    postnr: "16851",
    besökspostnr: "16851",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "63329692",
    skolenhetensNamn: "Sörgårdsskolan",
    postnr: "16247",
    besökspostnr: "16247",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "14479858",
    skolenhetensNamn: "Tallkrogens skola",
    postnr: "12265",
    besökspostnr: "12265",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "64732323",
    skolenhetensNamn: "The Tanto International School",
    postnr: "11842",
    besökspostnr: "11842",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29796042",
    skolenhetensNamn: "Tornadoskolan AB",
    postnr: "12832",
    besökspostnr: "12832",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "89605548",
    skolenhetensNamn: "Trollbodaskolan",
    postnr: "16576",
    besökspostnr: "16576",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "94677983",
    skolenhetensNamn: "Tullgårdsskolan",
    postnr: "11668",
    besökspostnr: "11668",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "53175499",
    skolenhetensNamn: "Tyska skolan",
    postnr: "11431",
    besökspostnr: "11431",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "68893390",
    skolenhetensNamn: "Ulvsundaskolan",
    postnr: "16868",
    besökspostnr: "16868",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "29270748",
    skolenhetensNamn: "Vanadisskolan",
    postnr: "11345",
    besökspostnr: "11345",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "52965560",
    skolenhetensNamn: "Vasa International School of Stockholm",
    postnr: "10386",
    besökspostnr: "11351",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "75656508",
    skolenhetensNamn: "Vasastans Montessoriskola",
    postnr: "11331",
    besökspostnr: "11331",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "57106833",
    skolenhetensNamn: "Vendestigens skola",
    postnr: "18262",
    besökspostnr: "18262",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "42912378",
    skolenhetensNamn: "Vinsta grundskola östra",
    postnr: "16270",
    besökspostnr: "16270",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "71669600",
    skolenhetensNamn: "Vittra Luma Park",
    postnr: "12031",
    besökspostnr: "12031",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "59900714",
    skolenhetensNamn: "Vittra Södermalm",
    postnr: "11621",
    besökspostnr: "11621",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "67662754",
    skolenhetensNamn: "Vittra Telefonplan",
    postnr: "12626",
    besökspostnr: "12626",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "36031993",
    skolenhetensNamn: "Vällingbyskolan",
    postnr: "16213",
    besökspostnr: "16264",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "64736967",
    skolenhetensNamn: "Västbergaskolan",
    postnr: "12638",
    besökspostnr: "12638",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "35890890",
    skolenhetensNamn: "Västerholms friskola",
    postnr: "12747",
    besökspostnr: "12747",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "92007673",
    skolenhetensNamn: "Västertorpsskolan",
    postnr: "12946",
    besökspostnr: "12946",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "87719096",
    skolenhetensNamn: "Ålstensskolan",
    postnr: "16764",
    besökspostnr: "16764",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "27273328",
    skolenhetensNamn: "Årstadalsskolan",
    postnr: "11743",
    besökspostnr: "11743",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "49533653",
    skolenhetensNamn: "Årsta Gårds grundsärskola",
    postnr: "12060",
    besökspostnr: "12060",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "48328583",
    skolenhetensNamn: "Årstaskolan",
    postnr: "12053",
    besökspostnr: "12053",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "39171821",
    skolenhetensNamn: "Örbyskolan",
    postnr: "12540",
    besökspostnr: "12542",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "22030561",
    skolenhetensNamn: "Östbergaskolan",
    postnr: "12573",
    besökspostnr: "12573",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0180",
    kommunsNamn: "Stockholm",
    skolenhetsKod: "96984023",
    skolenhetensNamn: "Östermalmsskolan",
    postnr: "11526",
    besökspostnr: "11526",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "34148887",
    skolenhetensNamn: "Blombackaskolan",
    postnr: "15189",
    besökspostnr: "15160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "60570294",
    skolenhetensNamn: "Brunnsängskolan",
    postnr: "15189",
    besökspostnr: "15248",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "97655739",
    skolenhetensNamn: "Eneskolan",
    postnr: "15189",
    besökspostnr: "15332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "71992738",
    skolenhetensNamn: "Fornbackaskolan",
    postnr: "15189",
    besökspostnr: "15258",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "82137009",
    skolenhetensNamn: "Hovsjöskolan",
    postnr: "15189",
    besökspostnr: "15165",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "79466321",
    skolenhetensNamn: "Hölöskolan",
    postnr: "15189",
    besökspostnr: "15371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "75505688",
    skolenhetensNamn: "Igelsta grundskola",
    postnr: "15189",
    besökspostnr: "15257",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "74489639",
    skolenhetensNamn: "Innovitaskolan Södertälje",
    postnr: "15161",
    besökspostnr: "15161",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "29497099",
    skolenhetensNamn: "Internationella Engelska Skolan Södertälje",
    postnr: "15248",
    besökspostnr: "15248",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "41584790",
    skolenhetensNamn: "Järna friskola",
    postnr: "15336",
    besökspostnr: "15336",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "43802065",
    skolenhetensNamn: "Lilla Parkskolan",
    postnr: "15132",
    besökspostnr: "15132",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "62288230",
    skolenhetensNamn: "Lina grundskola",
    postnr: "15189",
    besökspostnr: "15155",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "28845963",
    skolenhetensNamn: "Majtorpskolan",
    postnr: "15189",
    besökspostnr: "15270",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "31528772",
    skolenhetensNamn: "Mariaskolan",
    postnr: "15332",
    besökspostnr: "15332",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "44977491",
    skolenhetensNamn: "Mora Park Hölö",
    postnr: "15395",
    besökspostnr: "15395",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "26804905",
    skolenhetensNamn: "Mora Parks skola",
    postnr: "15395",
    besökspostnr: "15395",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "47204443",
    skolenhetensNamn: "Mölnboskolan",
    postnr: "15189",
    besökspostnr: "15173",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "16068461",
    skolenhetensNamn: "Nyckelskolan",
    postnr: "15225",
    besökspostnr: "15243",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "99842607",
    skolenhetensNamn: "Oxbacksskolan ",
    postnr: "15189",
    besökspostnr: "15139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "38653015",
    skolenhetensNamn: "Pershagenskolan",
    postnr: "15189",
    besökspostnr: "15153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "51210001",
    skolenhetensNamn: "Ronnaskolan",
    postnr: "15189",
    besökspostnr: "15153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "40175889",
    skolenhetensNamn: "Soldalaskolan",
    postnr: "15189",
    besökspostnr: "15147",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "45994471",
    skolenhetensNamn: "Solvikskolan",
    postnr: "15391",
    besökspostnr: "15391",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "72120589",
    skolenhetensNamn: "Stålhamraskolan",
    postnr: "15147",
    besökspostnr: "15133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "38394561",
    skolenhetensNamn: "Södertälje friskola",
    postnr: "15121",
    besökspostnr: "15132",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "16914123",
    skolenhetensNamn: "Tavestaskolan",
    postnr: "15189",
    besökspostnr: "15336",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "64836806",
    skolenhetensNamn: "Vallaskolan",
    postnr: "15189",
    besökspostnr: "15161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "56568612",
    skolenhetensNamn: "Wasaskolan",
    postnr: "15189",
    besökspostnr: "15145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "49762104",
    skolenhetensNamn: "Viksbergskolan",
    postnr: "15189",
    besökspostnr: "15295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "12757193",
    skolenhetensNamn: "Vittra Östertälje",
    postnr: "15257",
    besökspostnr: "15257",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0181",
    kommunsNamn: "Södertälje",
    skolenhetsKod: "10661388",
    skolenhetensNamn: "Örjanskolan grund",
    postnr: "15391",
    besökspostnr: "15391",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "96622957",
    skolenhetensNamn: "Backeboskolan",
    postnr: "13237",
    besökspostnr: "13237",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "75834458",
    skolenhetensNamn: "Björknässkolan",
    postnr: "13181",
    besökspostnr: "13244",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "44175786",
    skolenhetensNamn: "Boo Gårds skola",
    postnr: "13181",
    besökspostnr: "13247",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "94836092",
    skolenhetensNamn: "Borgvallaskolan",
    postnr: "13146",
    besökspostnr: "13146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "24199792",
    skolenhetensNamn: "Da Vinciskolan",
    postnr: "13335",
    besökspostnr: "13335",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "79795608",
    skolenhetensNamn: "Dibber International School Nacka",
    postnr: "13830",
    besökspostnr: "13830",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "91067048",
    skolenhetensNamn: "Ebba Braheskolan",
    postnr: "13131",
    besökspostnr: "13131",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "52885189",
    skolenhetensNamn: "Ektorps skola",
    postnr: "13181",
    besökspostnr: "13142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "44373961",
    skolenhetensNamn: "Idunskolan Östra Stockholms Waldorfskola",
    postnr: "13236",
    besökspostnr: "13236",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "86831724",
    skolenhetensNamn: "Igelboda skola",
    postnr: "13181",
    besökspostnr: "13332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "22472689",
    skolenhetensNamn: "Jarlabergs skola",
    postnr: "13181",
    besökspostnr: "13148",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "52925821",
    skolenhetensNamn: "JENSEN grundskola Sickla",
    postnr: "13154",
    besökspostnr: "13154",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "21346638",
    skolenhetensNamn: "Järla skola",
    postnr: "13181",
    besökspostnr: "13140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "35311563",
    skolenhetensNamn: "Lilla Nacka Skola",
    postnr: "13147",
    besökspostnr: "13147",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "34135484",
    skolenhetensNamn: "Montessori Mondial Nacka",
    postnr: "13160",
    besökspostnr: "13160",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "84884375",
    skolenhetensNamn: "Myrsjöskolan",
    postnr: "13181",
    besökspostnr: "13231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "27344011",
    skolenhetensNamn: "Neglinge skola",
    postnr: "13181",
    besökspostnr: "13338",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "87967939",
    skolenhetensNamn: "Noblaskolan Lännersta",
    postnr: "13245",
    besökspostnr: "13245",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "41507895",
    skolenhetensNamn: "Noblaskolan Nacka Strand",
    postnr: "13152",
    besökspostnr: "13152",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "32965692",
    skolenhetensNamn: "Noblaskolan Saltsjö-Boo",
    postnr: "13242",
    besökspostnr: "13242",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "53133366",
    skolenhetensNamn: "Orminge skola",
    postnr: "13181",
    besökspostnr: "13232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "11777750",
    skolenhetensNamn: "Saltsjöbadens samskola",
    postnr: "13181",
    besökspostnr: "13334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "64285853",
    skolenhetensNamn: "Saltsjö-Duvnäs skola",
    postnr: "13181",
    besökspostnr: "13150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "57368693",
    skolenhetensNamn: "Sickla skola",
    postnr: "13181",
    besökspostnr: "13133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "44836298",
    skolenhetensNamn: "Sigfridsborgs skola",
    postnr: "13181",
    besökspostnr: "13834",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "85321366",
    skolenhetensNamn: "Skuru skola",
    postnr: "13181",
    besökspostnr: "13147",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "14040970",
    skolenhetensNamn: "Stavsborgsskolan",
    postnr: "13181",
    besökspostnr: "13830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "86937935",
    skolenhetensNamn: "Sågtorpsskolan",
    postnr: "13181",
    besökspostnr: "13236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "27181501",
    skolenhetensNamn: "Viktor Rydbergs skola Nacka",
    postnr: "13342",
    besökspostnr: "13342",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "22904289",
    skolenhetensNamn: "Vilans skola",
    postnr: "13181",
    besökspostnr: "13131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0182",
    kommunsNamn: "Nacka",
    skolenhetsKod: "82047013",
    skolenhetensNamn: "Älta skola",
    postnr: "13181",
    besökspostnr: "13837",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "63300625",
    skolenhetensNamn: "Duvboskolan",
    postnr: "17292",
    besökspostnr: "17276",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "93968806",
    skolenhetensNamn: "Grönkullaskolan",
    postnr: "17292",
    besökspostnr: "17459",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "51100048",
    skolenhetensNamn: "Kymlingeskolan",
    postnr: "17292",
    besökspostnr: "17463",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "15049834",
    skolenhetensNamn: "Lilla Alby skola",
    postnr: "17292",
    besökspostnr: "17239",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "67788311",
    skolenhetensNamn: "Noblaskolan Sundbyberg",
    postnr: "17446",
    besökspostnr: "17446",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "32993837",
    skolenhetensNamn: "Ursvikskolan",
    postnr: "17292",
    besökspostnr: "17446",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "43138370",
    skolenhetensNamn: "Vittra Brotorp",
    postnr: "17441",
    besökspostnr: "17446",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "22847273",
    skolenhetensNamn: "Ängskolan   F-6",
    postnr: "17292",
    besökspostnr: "17240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0183",
    kommunsNamn: "Sundbyberg",
    skolenhetsKod: "92791803",
    skolenhetensNamn: "Örskolan",
    postnr: "17292",
    besökspostnr: "17450",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "43133926",
    skolenhetensNamn: "Bergshamraskolan",
    postnr: "17071",
    besökspostnr: "17071",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "24745057",
    skolenhetensNamn: "Dibber Rullen grundsärskola",
    postnr: "16964",
    besökspostnr: "16964",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "61043089",
    skolenhetensNamn: "Ekensbergsskolan",
    postnr: "16931",
    besökspostnr: "16931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "17737193",
    skolenhetensNamn: "Granbackaskolan",
    postnr: "17172",
    besökspostnr: "17172",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "84585645",
    skolenhetensNamn: "Hagaskolan ",
    postnr: "16970",
    besökspostnr: "16970",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "27323097",
    skolenhetensNamn: "Noblaskolan Solna",
    postnr: "16972",
    besökspostnr: "16972",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "26808959",
    skolenhetensNamn: "Parkskolan",
    postnr: "16960",
    besökspostnr: "16960",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "13384795",
    skolenhetensNamn: "Raoul Wallenbergskolan Bagartorp",
    postnr: "17065",
    besökspostnr: "17065",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "90879048",
    skolenhetensNamn: "Raoul Wallenbergskolan Järvastaden",
    postnr: "17062",
    besökspostnr: "17062",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "40618486",
    skolenhetensNamn: "Råsunda skola",
    postnr: "16935",
    besökspostnr: "16935",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "75017455",
    skolenhetensNamn: "Skytteholmsskolan ",
    postnr: "17143",
    besökspostnr: "17143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "26742346",
    skolenhetensNamn: "Solna Enskilda Skola",
    postnr: "17148",
    besökspostnr: "17148",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "34105152",
    skolenhetensNamn: "Spanska skolan",
    postnr: "16961",
    besökspostnr: "16961",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "89893224",
    skolenhetensNamn: "Svedenskolan Bergshamra",
    postnr: "17007",
    besökspostnr: "17071",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "73581523",
    skolenhetensNamn: "Tallbackaskolan",
    postnr: "17150",
    besökspostnr: "17150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "46706680",
    skolenhetensNamn: "Ulriksdalsskolan",
    postnr: "17067",
    besökspostnr: "17067",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0184",
    kommunsNamn: "Solna",
    skolenhetsKod: "48760074",
    skolenhetensNamn: "Vittra Frösunda",
    postnr: "16973",
    besökspostnr: "16973",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "25253749",
    skolenhetensNamn: "Albatross Montessoriskola",
    postnr: "18138",
    besökspostnr: "18138",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "98780182",
    skolenhetensNamn: "Bodals skola",
    postnr: "18136",
    besökspostnr: "18136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "52423184",
    skolenhetensNamn: "Bo skola",
    postnr: "18143",
    besökspostnr: "18143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "46097781",
    skolenhetensNamn: "Christinaskolan",
    postnr: "10451",
    besökspostnr: "18156",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "36513280",
    skolenhetensNamn: "Futuraskolan International Gåshaga",
    postnr: "18163",
    besökspostnr: "18163",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "49631077",
    skolenhetensNamn: "Högsätra skola",
    postnr: "18158",
    besökspostnr: "18158",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "36185466",
    skolenhetensNamn: "I Ur och Skur Utsikten skola",
    postnr: "18146",
    besökspostnr: "18146",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "91213471",
    skolenhetensNamn: "Klockargårdens skola",
    postnr: "18143",
    besökspostnr: "18143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "20137688",
    skolenhetensNamn: "Käppala skola",
    postnr: "18165",
    besökspostnr: "18165",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "27830075",
    skolenhetensNamn: "Ljungbackens skola",
    postnr: "18163",
    besökspostnr: "18163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "35933721",
    skolenhetensNamn: "Rudboda skola",
    postnr: "18147",
    besökspostnr: "18147",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "81857869",
    skolenhetensNamn: "Skärsätra skola",
    postnr: "18160",
    besökspostnr: "18160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "60131588",
    skolenhetensNamn: "Sticklinge skola",
    postnr: "18130",
    besökspostnr: "18130",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "45104329",
    skolenhetensNamn: "Torsviks skola",
    postnr: "18133",
    besökspostnr: "18133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0186",
    kommunsNamn: "Lidingö",
    skolenhetsKod: "46585145",
    skolenhetensNamn: "Vittra Lidingö",
    postnr: "18145",
    besökspostnr: "18145",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0187",
    kommunsNamn: "Vaxholm",
    skolenhetsKod: "43261877",
    skolenhetensNamn: "Montessoriskolan i Vaxholm",
    postnr: "18531",
    besökspostnr: "18531",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0187",
    kommunsNamn: "Vaxholm",
    skolenhetsKod: "69909271",
    skolenhetensNamn: "Resarö skola",
    postnr: "18583",
    besökspostnr: "18595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0187",
    kommunsNamn: "Vaxholm",
    skolenhetsKod: "58338225",
    skolenhetensNamn: "Rindö skola",
    postnr: "18583",
    besökspostnr: "18541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0187",
    kommunsNamn: "Vaxholm",
    skolenhetsKod: "21576764",
    skolenhetensNamn: "Söderfjärdsskolan",
    postnr: "18583",
    besökspostnr: "18531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0187",
    kommunsNamn: "Vaxholm",
    skolenhetsKod: "25772114",
    skolenhetensNamn: "Vaxö skola",
    postnr: "18583",
    besökspostnr: "18534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "56662185",
    skolenhetensNamn: "Balderskolan",
    postnr: "76124",
    besökspostnr: "76150",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "89615900",
    skolenhetensNamn: "Bålbroskolan",
    postnr: "76251",
    besökspostnr: "76241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "70182130",
    skolenhetensNamn: "Drottningdal skola",
    postnr: "76175",
    besökspostnr: "76175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "90719952",
    skolenhetensNamn: "Edsbro skola",
    postnr: "76298",
    besökspostnr: "76298",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "98239828",
    skolenhetensNamn: "Ekebyholmsskolan, gr",
    postnr: "76291",
    besökspostnr: "76291",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "46518352",
    skolenhetensNamn: "Elmsta skola",
    postnr: "76128",
    besökspostnr: "76430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "89692072",
    skolenhetensNamn: "Freinetskolan Hugin",
    postnr: "76130",
    besökspostnr: "76130",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "45133950",
    skolenhetensNamn: "Freinetskolan Mimer",
    postnr: "76163",
    besökspostnr: "76163",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "84584068",
    skolenhetensNamn: "Frötuna skola",
    postnr: "76197",
    besökspostnr: "76197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "97718207",
    skolenhetensNamn: "Gottsta",
    postnr: "76343",
    besökspostnr: "76343",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "32585503",
    skolenhetensNamn: "Grisslehamns skola",
    postnr: "76456",
    besökspostnr: "76456",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "24146389",
    skolenhetensNamn: "Hallsta skola",
    postnr: "76128",
    besökspostnr: "76334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "67809355",
    skolenhetensNamn: "Innovitaskolan Norrtälje",
    postnr: "76154",
    besökspostnr: "76154",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "95869861",
    skolenhetensNamn: "Kvisthamraskolan",
    postnr: "76140",
    besökspostnr: "76140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "86917350",
    skolenhetensNamn: "Köpmanholms skola",
    postnr: "76128",
    besökspostnr: "76018",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "72471883",
    skolenhetensNamn: "Länna skola",
    postnr: "76111",
    besökspostnr: "76111",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "39130058",
    skolenhetensNamn: "Montessoriskolan - Vigelsjö",
    postnr: "76143",
    besökspostnr: "76143",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "22531873",
    skolenhetensNamn: "Norrgärdsskolan",
    postnr: "76163",
    besökspostnr: "76163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "30490775",
    skolenhetensNamn: "Riala skola",
    postnr: "76111",
    besökspostnr: "76113",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "50905672",
    skolenhetensNamn: "Rådmansö skola",
    postnr: "76128",
    besökspostnr: "76194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "98531789",
    skolenhetensNamn: "Rånäs skola",
    postnr: "76296",
    besökspostnr: "76296",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "77002828",
    skolenhetensNamn: "Skebo skola",
    postnr: "76393",
    besökspostnr: "76393",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "52064811",
    skolenhetensNamn: "Skederids skola",
    postnr: "76292",
    besökspostnr: "76292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "47727629",
    skolenhetensNamn: "Skärsta Friskola",
    postnr: "76330",
    besökspostnr: "76330",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "59975651",
    skolenhetensNamn: "Svanbergaskolan",
    postnr: "76173",
    besökspostnr: "76173",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "86442938",
    skolenhetensNamn: "Söderbykarl skola",
    postnr: "76174",
    besökspostnr: "76174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "54806839",
    skolenhetensNamn: "Viby friskola",
    postnr: "76231",
    besökspostnr: "76231",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "15312030",
    skolenhetensNamn: "Wigulfskolan",
    postnr: "76128",
    besökspostnr: "76152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0188",
    kommunsNamn: "Norrtälje",
    skolenhetsKod: "49669037",
    skolenhetensNamn: "Vätö skola",
    postnr: "76021",
    besökspostnr: "76021",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "61056172",
    skolenhetensNamn: "Centralskolan",
    postnr: "19534",
    besökspostnr: "19534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "38526011",
    skolenhetensNamn: "Eddaskolan",
    postnr: "19558",
    besökspostnr: "19554",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "15697742",
    skolenhetensNamn: "Galaxskolan",
    postnr: "19556",
    besökspostnr: "19556",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "63592981",
    skolenhetensNamn: "Internationella Engelska Skolan Sigtuna",
    postnr: "19337",
    besökspostnr: "19337",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "55508705",
    skolenhetensNamn: "Josefinaskolan, grundskola",
    postnr: "19592",
    besökspostnr: "19592",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "58286716",
    skolenhetensNamn: "Norrbackaskolan",
    postnr: "19543",
    besökspostnr: "19543",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "20054868",
    skolenhetensNamn: "Odensala skola",
    postnr: "19592",
    besökspostnr: "19592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "10998117",
    skolenhetensNamn: "Råbergsskolan",
    postnr: "19571",
    besökspostnr: "19571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "29998540",
    skolenhetensNamn: "Sagaskolan",
    postnr: "19553",
    besökspostnr: "19553",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "80274643",
    skolenhetensNamn: "Skepptuna skola",
    postnr: "19593",
    besökspostnr: "19593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "93320132",
    skolenhetensNamn: "S:ta Gertruds skola",
    postnr: "19341",
    besökspostnr: "19341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "38854084",
    skolenhetensNamn: "Steningehöjdens skola",
    postnr: "19565",
    besökspostnr: "19565",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "42215279",
    skolenhetensNamn: "S:t Pers skola",
    postnr: "19332",
    besökspostnr: "19332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "63542436",
    skolenhetensNamn: "Sätunaskolan",
    postnr: "19547",
    besökspostnr: "19547",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "78491090",
    skolenhetensNamn: "Tingvallaskolan",
    postnr: "19533",
    besökspostnr: "19533",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0191",
    kommunsNamn: "Sigtuna",
    skolenhetsKod: "87382179",
    skolenhetensNamn: "Väringaskolan",
    postnr: "19322",
    besökspostnr: "19330",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0192",
    kommunsNamn: "Nynäshamn",
    skolenhetsKod: "65717061",
    skolenhetensNamn: "Gröndalsskolan F-9",
    postnr: "14931",
    besökspostnr: "14931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0192",
    kommunsNamn: "Nynäshamn",
    skolenhetsKod: "52406620",
    skolenhetensNamn: "Kyrkskolan F-2",
    postnr: "14870",
    besökspostnr: "14870",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0192",
    kommunsNamn: "Nynäshamn",
    skolenhetsKod: "55217164",
    skolenhetensNamn: "Montessoriskolan Binomen",
    postnr: "14833",
    besökspostnr: "14833",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0192",
    kommunsNamn: "Nynäshamn",
    skolenhetsKod: "18193338",
    skolenhetensNamn: "Svandammsskolan F-6",
    postnr: "14931",
    besökspostnr: "14931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0192",
    kommunsNamn: "Nynäshamn",
    skolenhetsKod: "12468259",
    skolenhetensNamn: "Tallbackaskolan F-3",
    postnr: "14821",
    besökspostnr: "14831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0192",
    kommunsNamn: "Nynäshamn",
    skolenhetsKod: "95790053",
    skolenhetensNamn: "Vanstaskolan F-6",
    postnr: "14821",
    besökspostnr: "14830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "01",
    länsNamn: "Stockholms län",
    kommun: "0192",
    kommunsNamn: "Nynäshamn",
    skolenhetsKod: "34814578",
    skolenhetensNamn: "Viaskolan F-9",
    postnr: "14930",
    besökspostnr: "14930",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0305",
    kommunsNamn: "Håbo",
    skolenhetsKod: "22280343",
    skolenhetensNamn: "Futurum F-6",
    postnr: "74680",
    besökspostnr: "74637",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0305",
    kommunsNamn: "Håbo",
    skolenhetsKod: "90826660",
    skolenhetensNamn: "Gransäterskolan",
    postnr: "74680",
    besökspostnr: "74652",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0305",
    kommunsNamn: "Håbo",
    skolenhetsKod: "93342037",
    skolenhetensNamn: "Grundsärskolan Grönadalenskolan",
    postnr: "74680",
    besökspostnr: "74680",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0305",
    kommunsNamn: "Håbo",
    skolenhetsKod: "49674763",
    skolenhetensNamn: "Gröna dalen",
    postnr: "74680",
    besökspostnr: "74632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0305",
    kommunsNamn: "Håbo",
    skolenhetsKod: "28306495",
    skolenhetensNamn: "Läraskolan",
    postnr: "74632",
    besökspostnr: "74632",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0305",
    kommunsNamn: "Håbo",
    skolenhetsKod: "95396892",
    skolenhetensNamn: "Slottsskolan",
    postnr: "74680",
    besökspostnr: "74696",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0305",
    kommunsNamn: "Håbo",
    skolenhetsKod: "15305025",
    skolenhetensNamn: "Viby",
    postnr: "74680",
    besökspostnr: "74680",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0305",
    kommunsNamn: "Håbo",
    skolenhetsKod: "29299366",
    skolenhetensNamn: "Västerängsskolan",
    postnr: "74680",
    besökspostnr: "74634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0319",
    kommunsNamn: "Älvkarleby",
    skolenhetsKod: "13537290",
    skolenhetensNamn: "Bodaskolan",
    postnr: "81421",
    besökspostnr: "81440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0319",
    kommunsNamn: "Älvkarleby",
    skolenhetsKod: "41187189",
    skolenhetensNamn: "Rotskärsskolan F-6",
    postnr: "81421",
    besökspostnr: "81431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0319",
    kommunsNamn: "Älvkarleby",
    skolenhetsKod: "51085265",
    skolenhetensNamn: "Sörgärdets skola",
    postnr: "81470",
    besökspostnr: "81470",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0319",
    kommunsNamn: "Älvkarleby",
    skolenhetsKod: "74070812",
    skolenhetensNamn: "Älvboda friskola",
    postnr: "81441",
    besökspostnr: "81441",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "49742321",
    skolenhetensNamn: "Alsike skola",
    postnr: "74175",
    besökspostnr: "74196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "59464350",
    skolenhetensNamn: "Brännkärrsskolan",
    postnr: "74175",
    besökspostnr: "74196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "75149624",
    skolenhetensNamn: "Högåsskolan",
    postnr: "74139",
    besökspostnr: "74139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "79159191",
    skolenhetensNamn: "Knivsta Grundsärskola",
    postnr: "74146",
    besökspostnr: "74146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "30064502",
    skolenhetensNamn: "Lagga skola",
    postnr: "74175",
    besökspostnr: "74193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "72061363",
    skolenhetensNamn: "Långhundra skola",
    postnr: "74175",
    besökspostnr: "74195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "51867275",
    skolenhetensNamn: "Margarethaskolan",
    postnr: "74144",
    besökspostnr: "74144",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "27371086",
    skolenhetensNamn: "S:ta Maria Skola, Alsike",
    postnr: "74121",
    besökspostnr: "74196",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "69174303",
    skolenhetensNamn: "Valloxskolan",
    postnr: "74175",
    besökspostnr: "74140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0330",
    kommunsNamn: "Knivsta",
    skolenhetsKod: "24774687",
    skolenhetensNamn: "Ängbyskolan",
    postnr: "74175",
    besökspostnr: "74131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0331",
    kommunsNamn: "Heby",
    skolenhetsKod: "40078624",
    skolenhetensNamn: "Harbo skola F-6",
    postnr: "74047",
    besökspostnr: "74047",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0331",
    kommunsNamn: "Heby",
    skolenhetsKod: "24983617",
    skolenhetensNamn: "Heby skola F-6",
    postnr: "74488",
    besökspostnr: "74431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0331",
    kommunsNamn: "Heby",
    skolenhetsKod: "94843896",
    skolenhetensNamn: "Huddunge Byskola",
    postnr: "74492",
    besökspostnr: "74492",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0331",
    kommunsNamn: "Heby",
    skolenhetsKod: "42595629",
    skolenhetensNamn: "Morgongåva skola F-6",
    postnr: "74451",
    besökspostnr: "74451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0331",
    kommunsNamn: "Heby",
    skolenhetsKod: "98963693",
    skolenhetensNamn: "Tärnsjö skola F-6",
    postnr: "74045",
    besökspostnr: "74045",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0331",
    kommunsNamn: "Heby",
    skolenhetsKod: "55174683",
    skolenhetensNamn: "Vittinge skola F-3",
    postnr: "74495",
    besökspostnr: "74495",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0331",
    kommunsNamn: "Heby",
    skolenhetsKod: "25110768",
    skolenhetensNamn: "Östervåla skola F-6",
    postnr: "74046",
    besökspostnr: "74046",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "15416514",
    skolenhetensNamn: "Aspenskolan",
    postnr: "81580",
    besökspostnr: "81538",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "19247682",
    skolenhetensNamn: "Björkängsskolan",
    postnr: "81940",
    besökspostnr: "81940",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "65760581",
    skolenhetensNamn: "Bruksskolan",
    postnr: "81575",
    besökspostnr: "81575",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "17240817",
    skolenhetensNamn: "Centralskolan",
    postnr: "81580",
    besökspostnr: "81540",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "70628321",
    skolenhetensNamn: "Hållnäs skola",
    postnr: "81964",
    besökspostnr: "81964",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "35386625",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "81591",
    besökspostnr: "81591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "34577135",
    skolenhetensNamn: "Mehede skola",
    postnr: "81593",
    besökspostnr: "81593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "65153737",
    skolenhetensNamn: "Ol Andersskolan",
    postnr: "81930",
    besökspostnr: "81930",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "62440231",
    skolenhetensNamn: "Tallbacksskolan",
    postnr: "81569",
    besökspostnr: "81569",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "50014733",
    skolenhetensNamn: "Vendels skola",
    postnr: "74895",
    besökspostnr: "74895",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0360",
    kommunsNamn: "Tierp",
    skolenhetsKod: "15315609",
    skolenhetensNamn: "Örbyhus skola F-6",
    postnr: "74841",
    besökspostnr: "74841",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "13431400",
    skolenhetensNamn: "Almtunaskolan",
    postnr: "75327",
    besökspostnr: "75327",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "99056007",
    skolenhetensNamn: "Almunge skola",
    postnr: "74197",
    besökspostnr: "74197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "81985750",
    skolenhetensNamn: "Ansgarskolorna     Fridhemsskolan F-6",
    postnr: "74330",
    besökspostnr: "74330",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "68552718",
    skolenhetensNamn: "Ansgarskolorna     Källskolan F-6",
    postnr: "75103",
    besökspostnr: "75450",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "70000757",
    skolenhetensNamn: "Bergaskolan",
    postnr: "75239",
    besökspostnr: "75239",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "46263319",
    skolenhetensNamn: "Björklinge skola",
    postnr: "74364",
    besökspostnr: "74364",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "13951452",
    skolenhetensNamn: "Bälinge skola",
    postnr: "74381",
    besökspostnr: "74381",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "60543153",
    skolenhetensNamn: "Börje skola",
    postnr: "75592",
    besökspostnr: "75592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "72888222",
    skolenhetensNamn: "Danmarks skola",
    postnr: "75598",
    besökspostnr: "75598",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "57903436",
    skolenhetensNamn: "Domarringens skola",
    postnr: "75334",
    besökspostnr: "75334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "76304810",
    skolenhetensNamn: "Ekuddenskolan",
    postnr: "75643",
    besökspostnr: "75643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "25794770",
    skolenhetensNamn: "Eriksskolan",
    postnr: "75231",
    besökspostnr: "75231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "30742062",
    skolenhetensNamn: "Flogstaskolan",
    postnr: "75272",
    besökspostnr: "75272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "65192477",
    skolenhetensNamn: "Fredrika Bremerskolan",
    postnr: "75442",
    besökspostnr: "75442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "19791387",
    skolenhetensNamn: "Funbo skola",
    postnr: "75597",
    besökspostnr: "75597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "47022598",
    skolenhetensNamn: "Gamla Uppsala skola",
    postnr: "75440",
    besökspostnr: "75440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "57484572",
    skolenhetensNamn: "Gluntens Montessoriskola",
    postnr: "75263",
    besökspostnr: "75263",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "12275851",
    skolenhetensNamn: "Gåvsta skola",
    postnr: "75596",
    besökspostnr: "75596",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "78556653",
    skolenhetensNamn: "Hagmarkens skola",
    postnr: "74381",
    besökspostnr: "74381",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "60438370",
    skolenhetensNamn: "Hågadalsskolan",
    postnr: "75243",
    besökspostnr: "75243",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "65459851",
    skolenhetensNamn: "Imanskolan",
    postnr: "75023",
    besökspostnr: "75327",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "25730446",
    skolenhetensNamn: "JENSEN grundskola Uppsala",
    postnr: "75237",
    besökspostnr: "75237",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "76127242",
    skolenhetensNamn: "Johannesbäcksskolan",
    postnr: "75436",
    besökspostnr: "75436",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "73816603",
    skolenhetensNamn: "Jumkils skola",
    postnr: "75592",
    besökspostnr: "75592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "58543554",
    skolenhetensNamn: "Järlåsa skola",
    postnr: "74021",
    besökspostnr: "74021",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "82442662",
    skolenhetensNamn: "Kajan Friskola AB",
    postnr: "75643",
    besökspostnr: "75643",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "22200527",
    skolenhetensNamn: "Knutby skola",
    postnr: "74174",
    besökspostnr: "74174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "61003071",
    skolenhetensNamn: "Kvarngärdesskolan",
    postnr: "75421",
    besökspostnr: "75421",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "51741765",
    skolenhetensNamn: "Liljeforsskolan",
    postnr: "75429",
    besökspostnr: "75429",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "59963808",
    skolenhetensNamn: "Lindbackens skola",
    postnr: "75471",
    besökspostnr: "75471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "30158985",
    skolenhetensNamn: "Länna skola",
    postnr: "74011",
    besökspostnr: "74011",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "43235171",
    skolenhetensNamn: "Malmaskolan",
    postnr: "75646",
    besökspostnr: "75646",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "64800703",
    skolenhetensNamn: "Manar Al-Houda Sv-arab.skola",
    postnr: "75648",
    besökspostnr: "75648",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "58103420",
    skolenhetensNamn: "Musiklådan",
    postnr: "75654",
    besökspostnr: "75654",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "30054341",
    skolenhetensNamn: "Nåntunaskolan",
    postnr: "75757",
    besökspostnr: "75757",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "74661728",
    skolenhetensNamn: "Pluggparadiset",
    postnr: "74332",
    besökspostnr: "74332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "72049334",
    skolenhetensNamn: "Ramsta skola",
    postnr: "75591",
    besökspostnr: "75591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "43933186",
    skolenhetensNamn: "Rosendals skola",
    postnr: "75643",
    besökspostnr: "75643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "28248660",
    skolenhetensNamn: "Skuttunge skola",
    postnr: "74371",
    besökspostnr: "74371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "54326087",
    skolenhetensNamn: "Skyttorps skola",
    postnr: "74394",
    besökspostnr: "74394",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "72802066",
    skolenhetensNamn: "Stavby skola",
    postnr: "74794",
    besökspostnr: "74794",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "95132430",
    skolenhetensNamn: "Stordammens skola",
    postnr: "75754",
    besökspostnr: "75754",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "21818354",
    skolenhetensNamn: "Storvretaskolan",
    postnr: "74330",
    besökspostnr: "74330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "99536619",
    skolenhetensNamn: "Sunnerstaskolan",
    postnr: "75652",
    besökspostnr: "75652",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "21969379",
    skolenhetensNamn: "Sverkerskolan",
    postnr: "75227",
    besökspostnr: "75227",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "53847396",
    skolenhetensNamn: "Tiundaskolan",
    postnr: "75230",
    besökspostnr: "75230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "30655993",
    skolenhetensNamn: "Treklangens skola",
    postnr: "75650",
    besökspostnr: "75650",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "26666728",
    skolenhetensNamn: "Tuna skola",
    postnr: "74793",
    besökspostnr: "74793",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "43465597",
    skolenhetensNamn: "Uppsala Enskilda Skola",
    postnr: "75183",
    besökspostnr: "75183",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "76938456",
    skolenhetensNamn: "Uppsala International School (UIS)",
    postnr: "75643",
    besökspostnr: "75643",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "60186012",
    skolenhetensNamn: "Uppsala Montessoriskola",
    postnr: "75425",
    besökspostnr: "75425",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "69405762",
    skolenhetensNamn: "Uppsala waldorfskola",
    postnr: "75263",
    besökspostnr: "75263",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "92938213",
    skolenhetensNamn: "Uppsävjaskolan",
    postnr: "75756",
    besökspostnr: "75756",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "28006480",
    skolenhetensNamn: "Vaksalaskolan",
    postnr: "75329",
    besökspostnr: "75329",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "56446888",
    skolenhetensNamn: "Valsätraskolan",
    postnr: "75647",
    besökspostnr: "75647",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "50591943",
    skolenhetensNamn: "Vattholmaskolan",
    postnr: "74391",
    besökspostnr: "74391",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "17250578",
    skolenhetensNamn: "von Bahrs skola",
    postnr: "75427",
    besökspostnr: "75427",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "61824203",
    skolenhetensNamn: "Vänge skola",
    postnr: "74020",
    besökspostnr: "74020",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "48134074",
    skolenhetensNamn: "Västra Stenhagenskolan",
    postnr: "75260",
    besökspostnr: "75260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "87542831",
    skolenhetensNamn: "Växthusets skola",
    postnr: "75649",
    besökspostnr: "75649",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "64760168",
    skolenhetensNamn: "Ångelstaskolan",
    postnr: "75449",
    besökspostnr: "75449",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "79320489",
    skolenhetensNamn: "Årstaskolan",
    postnr: "75446",
    besökspostnr: "75446",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0380",
    kommunsNamn: "Uppsala",
    skolenhetsKod: "18020988",
    skolenhetensNamn: "Östra Stenhagenskolan",
    postnr: "75260",
    besökspostnr: "75260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "56521117",
    skolenhetensNamn: "Bergvretenskolan",
    postnr: "74944",
    besökspostnr: "74944",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "12753670",
    skolenhetensNamn: "Enöglaskolan",
    postnr: "74562",
    besökspostnr: "74562",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "94821900",
    skolenhetensNamn: "Fjärdhundraskolan",
    postnr: "74970",
    besökspostnr: "74970",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "69708298",
    skolenhetensNamn: "Grillbyskolan",
    postnr: "74952",
    besökspostnr: "74952",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "89762772",
    skolenhetensNamn: "Hummelstaskolan",
    postnr: "74571",
    besökspostnr: "74571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "96757645",
    skolenhetensNamn: "Korsängsskolan",
    postnr: "74949",
    besökspostnr: "74949",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "22410547",
    skolenhetensNamn: "Lillkyrkaskolan",
    postnr: "74597",
    besökspostnr: "74597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "70152961",
    skolenhetensNamn: "Munksundsskolan",
    postnr: "74536",
    besökspostnr: "74536",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "89708433",
    skolenhetensNamn: "Robinson Fanna",
    postnr: "74943",
    besökspostnr: "74943",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "72849973",
    skolenhetensNamn: "Robinson Husberg",
    postnr: "74945",
    besökspostnr: "74945",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "71071044",
    skolenhetensNamn: "Robinson Svedia",
    postnr: "74562",
    besökspostnr: "74562",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "15287785",
    skolenhetensNamn: "Rombergaskolan",
    postnr: "74539",
    besökspostnr: "74539",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "30265734",
    skolenhetensNamn: "Skolstaskolan",
    postnr: "74951",
    besökspostnr: "74951",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0381",
    kommunsNamn: "Enköping",
    skolenhetsKod: "98587494",
    skolenhetensNamn: "Örsundsbroskolan",
    postnr: "74960",
    besökspostnr: "74960",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0382",
    kommunsNamn: "Östhammar",
    skolenhetsKod: "37217528",
    skolenhetensNamn: "Edsskolan",
    postnr: "74235",
    besökspostnr: "74792",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0382",
    kommunsNamn: "Östhammar",
    skolenhetsKod: "71534291",
    skolenhetensNamn: "Ekeby skola",
    postnr: "74730",
    besökspostnr: "74892",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0382",
    kommunsNamn: "Östhammar",
    skolenhetsKod: "18135815",
    skolenhetensNamn: "Kristinelundskolan",
    postnr: "74231",
    besökspostnr: "74891",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0382",
    kommunsNamn: "Östhammar",
    skolenhetsKod: "90876801",
    skolenhetensNamn: "Olandsskolan",
    postnr: "74730",
    besökspostnr: "74740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0382",
    kommunsNamn: "Östhammar",
    skolenhetsKod: "27232649",
    skolenhetensNamn: "Vretaskolan",
    postnr: "74740",
    besökspostnr: "74740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0382",
    kommunsNamn: "Östhammar",
    skolenhetsKod: "93413393",
    skolenhetensNamn: "Öregrunds skola",
    postnr: "74242",
    besökspostnr: "74831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "03",
    länsNamn: "Uppsala län",
    kommun: "0382",
    kommunsNamn: "Östhammar",
    skolenhetsKod: "57548666",
    skolenhetensNamn: "Österbyskolan",
    postnr: "74831",
    besökspostnr: "74831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0428",
    kommunsNamn: "Vingåker",
    skolenhetsKod: "30505826",
    skolenhetensNamn: "Högsjö skola",
    postnr: "64380",
    besökspostnr: "64010",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0428",
    kommunsNamn: "Vingåker",
    skolenhetsKod: "24762802",
    skolenhetensNamn: "Marmorbyns skola",
    postnr: "64380",
    besökspostnr: "64195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0428",
    kommunsNamn: "Vingåker",
    skolenhetsKod: "52280658",
    skolenhetensNamn: "Slottsskolan F-6",
    postnr: "64330",
    besökspostnr: "64330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0428",
    kommunsNamn: "Vingåker",
    skolenhetsKod: "18900112",
    skolenhetensNamn: "Sävstaskolan",
    postnr: "64380",
    besökspostnr: "64331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0428",
    kommunsNamn: "Vingåker",
    skolenhetsKod: "37464454",
    skolenhetensNamn: "Österåkersbygdens Friskola",
    postnr: "64393",
    besökspostnr: "64393",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0461",
    kommunsNamn: "Gnesta",
    skolenhetsKod: "76365900",
    skolenhetensNamn: "Dansutskolan F-6",
    postnr: "64680",
    besökspostnr: "64631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0461",
    kommunsNamn: "Gnesta",
    skolenhetsKod: "89195825",
    skolenhetensNamn: "Frejaskolan F-9",
    postnr: "64631",
    besökspostnr: "64631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0461",
    kommunsNamn: "Gnesta",
    skolenhetsKod: "35159342",
    skolenhetensNamn: "Gnesta Waldorfskola",
    postnr: "64632",
    besökspostnr: "64632",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0461",
    kommunsNamn: "Gnesta",
    skolenhetsKod: "19546209",
    skolenhetensNamn: "Kvarnbackaskolan F-6",
    postnr: "64680",
    besökspostnr: "64691",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0461",
    kommunsNamn: "Gnesta",
    skolenhetsKod: "17508329",
    skolenhetensNamn: "Welandersborgs skola F-6",
    postnr: "64650",
    besökspostnr: "64650",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "20748461",
    skolenhetensNamn: "Arnö skola",
    postnr: "61145",
    besökspostnr: "61145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "74105812",
    skolenhetensNamn: "Blomenbergska skolan",
    postnr: "61199",
    besökspostnr: "61199",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "62817522",
    skolenhetensNamn: "Bryngelstorpskolan",
    postnr: "61163",
    besökspostnr: "61163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "62179318",
    skolenhetensNamn: "Dammlötskolan",
    postnr: "61193",
    besökspostnr: "61193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "18915192",
    skolenhetensNamn: "Fokusskolan",
    postnr: "61160",
    besökspostnr: "61160",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "43811145",
    skolenhetensNamn: "Gruvans skola",
    postnr: "61137",
    besökspostnr: "61137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "91578294",
    skolenhetensNamn: "Innovitaskolan Nyköping",
    postnr: "61137",
    besökspostnr: "61137",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "25433795",
    skolenhetensNamn: "Kiladalens norra skola",
    postnr: "61150",
    besökspostnr: "61150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "91419745",
    skolenhetensNamn: "Kiladalens södra skola",
    postnr: "61170",
    besökspostnr: "61170",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "75746018",
    skolenhetensNamn: "Mikaeliskolan",
    postnr: "61110",
    besökspostnr: "61139",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "42584474",
    skolenhetensNamn: "Rosenkulla skola",
    postnr: "61162",
    besökspostnr: "61162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "53953848",
    skolenhetensNamn: "Sjösa skola",
    postnr: "61144",
    besökspostnr: "61144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "23286483",
    skolenhetensNamn: "Släbroskolan",
    postnr: "61156",
    besökspostnr: "61156",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "79566399",
    skolenhetensNamn: "Stigtomta skola",
    postnr: "61175",
    besökspostnr: "61175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "88884378",
    skolenhetensNamn: "Tunabergs skola",
    postnr: "61176",
    besökspostnr: "61176",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "68094768",
    skolenhetensNamn: "Tystberga skola",
    postnr: "61174",
    besökspostnr: "61174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "50698424",
    skolenhetensNamn: "Vittra Kungshagen",
    postnr: "61162",
    besökspostnr: "61162",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "57861440",
    skolenhetensNamn: "Vrena friskola",
    postnr: "61173",
    besökspostnr: "61173",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "81899882",
    skolenhetensNamn: "Västra skolan",
    postnr: "61132",
    besökspostnr: "61132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0480",
    kommunsNamn: "Nyköping",
    skolenhetsKod: "87469558",
    skolenhetensNamn: "Östra skolan",
    postnr: "61136",
    besökspostnr: "61136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0481",
    kommunsNamn: "Oxelösund",
    skolenhetsKod: "12053461",
    skolenhetensNamn: "Oxelöskolan",
    postnr: "61330",
    besökspostnr: "61330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0481",
    kommunsNamn: "Oxelösund",
    skolenhetsKod: "46563141",
    skolenhetensNamn: "Peterslundsskolan",
    postnr: "61381",
    besökspostnr: "61337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0481",
    kommunsNamn: "Oxelösund",
    skolenhetsKod: "98215830",
    skolenhetensNamn: "Ramdalsskolan",
    postnr: "61381",
    besökspostnr: "61336",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0482",
    kommunsNamn: "Flen",
    skolenhetsKod: "74938627",
    skolenhetensNamn: "Bettna skola",
    postnr: "64033",
    besökspostnr: "64033",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0482",
    kommunsNamn: "Flen",
    skolenhetsKod: "59846075",
    skolenhetensNamn: "Bruksskolan",
    postnr: "64831",
    besökspostnr: "64031",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0482",
    kommunsNamn: "Flen",
    skolenhetsKod: "52287808",
    skolenhetensNamn: "Flens kristna skola",
    postnr: "64221",
    besökspostnr: "64234",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0482",
    kommunsNamn: "Flen",
    skolenhetsKod: "83252289",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "64231",
    besökspostnr: "64231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0482",
    kommunsNamn: "Flen",
    skolenhetsKod: "41621337",
    skolenhetensNamn: "Malmaskolan",
    postnr: "64261",
    besökspostnr: "64261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0482",
    kommunsNamn: "Flen",
    skolenhetsKod: "66357455",
    skolenhetensNamn: "Sparreholms skola",
    postnr: "64930",
    besökspostnr: "64991",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0482",
    kommunsNamn: "Flen",
    skolenhetsKod: "93189258",
    skolenhetensNamn: "Stenhammarskolan F-3",
    postnr: "64237",
    besökspostnr: "64237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0482",
    kommunsNamn: "Flen",
    skolenhetsKod: "87319414",
    skolenhetensNamn: "Stiftelsen Dunkers förskola och skola",
    postnr: "64296",
    besökspostnr: "64296",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "12384005",
    skolenhetensNamn: "Bie skola",
    postnr: "64196",
    besökspostnr: "64196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "33139103",
    skolenhetensNamn: "Björkviks skola",
    postnr: "64163",
    besökspostnr: "64163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "37569310",
    skolenhetensNamn: "Forssjö skola",
    postnr: "64193",
    besökspostnr: "64193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "33912115",
    skolenhetensNamn: "Julita skola",
    postnr: "64360",
    besökspostnr: "64360",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "37597661",
    skolenhetensNamn: "Nyhemsskolan",
    postnr: "64152",
    besökspostnr: "64152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "53737995",
    skolenhetensNamn: "Sandbäcksskolan",
    postnr: "64133",
    besökspostnr: "64133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "93823142",
    skolenhetensNamn: "Skogsborgsskolan",
    postnr: "64136",
    besökspostnr: "64136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "84967178",
    skolenhetensNamn: "Sköldinge skola",
    postnr: "64199",
    besökspostnr: "64199",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "49653465",
    skolenhetensNamn: "Stensättersskolan 1",
    postnr: "64146",
    besökspostnr: "64146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "85558176",
    skolenhetensNamn: "Strångsjö skola",
    postnr: "64192",
    besökspostnr: "64192",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0483",
    kommunsNamn: "Katrineholm",
    skolenhetsKod: "46617602",
    skolenhetensNamn: "Valla skola",
    postnr: "64161",
    besökspostnr: "64161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "36462704",
    skolenhetensNamn: "Björktorpsskolan F-3",
    postnr: "63227",
    besökspostnr: "63227",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "40579483",
    skolenhetensNamn: "British Junior Primary, Eskilstuna",
    postnr: "63349",
    besökspostnr: "63349",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "54605092",
    skolenhetensNamn: "Djurgårdsskolan F-9",
    postnr: "63349",
    besökspostnr: "63349",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "30374696",
    skolenhetensNamn: "Edvardslundsskolan F-6",
    postnr: "64434",
    besökspostnr: "64434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "11685163",
    skolenhetensNamn: "Fröslundaskolan F-6",
    postnr: "63231",
    besökspostnr: "63231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "46674480",
    skolenhetensNamn: "Gillberga skola F-6",
    postnr: "63513",
    besökspostnr: "63513",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "85601783",
    skolenhetensNamn: "Gökstensskolan F-9",
    postnr: "64430",
    besökspostnr: "64430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "25284848",
    skolenhetensNamn: "Hammargärdets skola F-6",
    postnr: "64040",
    besökspostnr: "64040",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "13561097",
    skolenhetensNamn: "Hållstaskolan F-6",
    postnr: "63370",
    besökspostnr: "63370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "98262818",
    skolenhetensNamn: "Hällberga skola F-6",
    postnr: "63519",
    besökspostnr: "63519",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "14989708",
    skolenhetensNamn: "Hällby skola F-6",
    postnr: "63239",
    besökspostnr: "63239",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "61836686",
    skolenhetensNamn: "Idunskolans grundsärskola",
    postnr: "63344",
    besökspostnr: "63344",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "36759013",
    skolenhetensNamn: "Internationella Engelska Skolan Eskilstuna",
    postnr: "63230",
    besökspostnr: "63230",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "41563453",
    skolenhetensNamn: "Kjula skola F-6",
    postnr: "63506",
    besökspostnr: "63506",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "85172821",
    skolenhetensNamn: "Lagersbergsskolan F-6",
    postnr: "63232",
    besökspostnr: "63232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "39228461",
    skolenhetensNamn: "Lundbyskolan F-6",
    postnr: "63222",
    besökspostnr: "63222",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "83229706",
    skolenhetensNamn: "Mesta skola F-3",
    postnr: "63236",
    besökspostnr: "63236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "29254257",
    skolenhetensNamn: "Näshulta friskola",
    postnr: "63517",
    besökspostnr: "63517",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "87095321",
    skolenhetensNamn: "Odlarskolan 1 grundsärskola",
    postnr: "63349",
    besökspostnr: "63349",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "65025285",
    skolenhetensNamn: "Skiftingehus F-9",
    postnr: "63353",
    besökspostnr: "63353",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "30622727",
    skolenhetensNamn: "Skogstorpsskolan F-9",
    postnr: "63369",
    besökspostnr: "63369",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "19112648",
    skolenhetensNamn: "Skogsängsskolan F-6",
    postnr: "63356",
    besökspostnr: "63356",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "27487150",
    skolenhetensNamn: "Slagstaskolan F-6",
    postnr: "63348",
    besökspostnr: "63348",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "73006718",
    skolenhetensNamn: "Slottsskolan F-6",
    postnr: "63341",
    besökspostnr: "63341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "98816098",
    skolenhetensNamn: "Sverigefinska sk. i Eskilstuna",
    postnr: "63343",
    besökspostnr: "63343",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "78673553",
    skolenhetensNamn: "Tegelviken F-9",
    postnr: "63531",
    besökspostnr: "63531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "52597635",
    skolenhetensNamn: "Vallby skola F-3",
    postnr: "63509",
    besökspostnr: "63509",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "63315515",
    skolenhetensNamn: "Årbyskolan F-9",
    postnr: "63344",
    besökspostnr: "63344",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "66434889",
    skolenhetensNamn: "Årbyskolans grundsärskola",
    postnr: "63344",
    besökspostnr: "63344",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "97475266",
    skolenhetensNamn: "Ärla skola F-6",
    postnr: "64043",
    besökspostnr: "64043",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0484",
    kommunsNamn: "Eskilstuna",
    skolenhetsKod: "41833926",
    skolenhetensNamn: "Ärstaskolan F-6",
    postnr: "63353",
    besökspostnr: "63353",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "69136482",
    skolenhetensNamn: "Finningeskolan",
    postnr: "64580",
    besökspostnr: "64543",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "65529876",
    skolenhetensNamn: "Friskolan Karlavagnen",
    postnr: "64551",
    besökspostnr: "64551",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "65507647",
    skolenhetensNamn: "Gripsholmsskolan",
    postnr: "64721",
    besökspostnr: "64731",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "14184478",
    skolenhetensNamn: "Karinslundsskolan",
    postnr: "64580",
    besökspostnr: "64551",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "64620570",
    skolenhetensNamn: "Mariefreds skola",
    postnr: "64580",
    besökspostnr: "64730",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "42957784",
    skolenhetensNamn: "Stallarholmsskolan",
    postnr: "64580",
    besökspostnr: "64561",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "30181143",
    skolenhetensNamn: "Strängnäs Montessoriskola",
    postnr: "64551",
    besökspostnr: "64551",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "91400222",
    skolenhetensNamn: "Vasaskolan",
    postnr: "64580",
    besökspostnr: "64533",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "30548381",
    skolenhetensNamn: "Vårfruberga friskola",
    postnr: "64592",
    besökspostnr: "64592",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0486",
    kommunsNamn: "Strängnäs",
    skolenhetsKod: "74265915",
    skolenhetensNamn: "Åkerskolan",
    postnr: "64580",
    besökspostnr: "64752",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0488",
    kommunsNamn: "Trosa",
    skolenhetsKod: "78789903",
    skolenhetensNamn: "Fornbyskolan",
    postnr: "61980",
    besökspostnr: "61971",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0488",
    kommunsNamn: "Trosa",
    skolenhetsKod: "58028919",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "61980",
    besökspostnr: "61975",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0488",
    kommunsNamn: "Trosa",
    skolenhetsKod: "56521006",
    skolenhetensNamn: "Skärlagskolan",
    postnr: "61980",
    besökspostnr: "61933",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "04",
    länsNamn: "Södermanlands län",
    kommun: "0488",
    kommunsNamn: "Trosa",
    skolenhetsKod: "95410474",
    skolenhetensNamn: "Vitalisskolan",
    postnr: "61980",
    besökspostnr: "61934",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0509",
    kommunsNamn: "Ödeshög",
    skolenhetsKod: "79095423",
    skolenhetensNamn: "Fridtjuv Bergskolan",
    postnr: "59980",
    besökspostnr: "59931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0509",
    kommunsNamn: "Ödeshög",
    skolenhetsKod: "68897220",
    skolenhetensNamn: "Rök skola",
    postnr: "59980",
    besökspostnr: "59994",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0512",
    kommunsNamn: "Ydre",
    skolenhetsKod: "48056871",
    skolenhetensNamn: "Hestraskolan",
    postnr: "57374",
    besökspostnr: "57395",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0512",
    kommunsNamn: "Ydre",
    skolenhetsKod: "61969375",
    skolenhetensNamn: "Ydreskolan 1",
    postnr: "57374",
    besökspostnr: "57374",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0513",
    kommunsNamn: "Kinda",
    skolenhetsKod: "87726834",
    skolenhetensNamn: "Bäckskolan",
    postnr: "59037",
    besökspostnr: "59037",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0513",
    kommunsNamn: "Kinda",
    skolenhetsKod: "81739203",
    skolenhetensNamn: "Horn skola",
    postnr: "59042",
    besökspostnr: "59042",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0513",
    kommunsNamn: "Kinda",
    skolenhetsKod: "10524420",
    skolenhetensNamn: "Rimforsa skola F-3",
    postnr: "59046",
    besökspostnr: "59046",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0560",
    kommunsNamn: "Boxholm",
    skolenhetsKod: "28058389",
    skolenhetensNamn: "Stenbockskolan L ",
    postnr: "59503",
    besökspostnr: "59571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0560",
    kommunsNamn: "Boxholm",
    skolenhetsKod: "27814689",
    skolenhetensNamn: "Åsbo skola",
    postnr: "59503",
    besökspostnr: "59575",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0561",
    kommunsNamn: "Åtvidaberg",
    skolenhetsKod: "43771345",
    skolenhetensNamn: "Björsäters skola",
    postnr: "59794",
    besökspostnr: "59794",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0561",
    kommunsNamn: "Åtvidaberg",
    skolenhetsKod: "51207895",
    skolenhetensNamn: "Centralskolan",
    postnr: "59731",
    besökspostnr: "59731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0561",
    kommunsNamn: "Åtvidaberg",
    skolenhetsKod: "90303058",
    skolenhetensNamn: "Grebo skola",
    postnr: "58595",
    besökspostnr: "58595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "53733869",
    skolenhetensNamn: "Björke skola",
    postnr: "61295",
    besökspostnr: "61295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "95938848",
    skolenhetensNamn: "Brenäs skola",
    postnr: "64191",
    besökspostnr: "64191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "43897173",
    skolenhetensNamn: "Grytgöls skola",
    postnr: "61011",
    besökspostnr: "61011",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "98557587",
    skolenhetensNamn: "Hällestads skola",
    postnr: "61012",
    besökspostnr: "61012",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "34833593",
    skolenhetensNamn: "Högalidsskolan",
    postnr: "61240",
    besökspostnr: "61240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "52689015",
    skolenhetensNamn: "Ljusfalls friskola",
    postnr: "61273",
    besökspostnr: "61273",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "55248937",
    skolenhetensNamn: "Lotorps skola",
    postnr: "61013",
    besökspostnr: "61013",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "94721000",
    skolenhetensNamn: "Nyhemsskolan F-6",
    postnr: "61232",
    besökspostnr: "61232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "60106183",
    skolenhetensNamn: "Rejmyre skola",
    postnr: "61014",
    besökspostnr: "61014",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "50531913",
    skolenhetensNamn: "Storängsskolan",
    postnr: "61243",
    besökspostnr: "61243",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "69570704",
    skolenhetensNamn: "Svälthagsskolan",
    postnr: "61235",
    besökspostnr: "61235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0562",
    kommunsNamn: "Finspång",
    skolenhetsKod: "15534344",
    skolenhetensNamn: "Viggestorpsskolan",
    postnr: "61234",
    besökspostnr: "61234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0563",
    kommunsNamn: "Valdemarsvik",
    skolenhetsKod: "27934323",
    skolenhetensNamn: "Gusum skola",
    postnr: "61580",
    besökspostnr: "61571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0563",
    kommunsNamn: "Valdemarsvik",
    skolenhetsKod: "73537142",
    skolenhetensNamn: "Ringarum skola",
    postnr: "61580",
    besökspostnr: "61574",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0563",
    kommunsNamn: "Valdemarsvik",
    skolenhetsKod: "79192071",
    skolenhetensNamn: "Sörbyskolan",
    postnr: "61580",
    besökspostnr: "61533",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0563",
    kommunsNamn: "Valdemarsvik",
    skolenhetsKod: "49944790",
    skolenhetensNamn: "Tryserums friskola",
    postnr: "61595",
    besökspostnr: "61595",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "19631342",
    skolenhetensNamn: "Askeby skola",
    postnr: "58562",
    besökspostnr: "58562",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "84408578",
    skolenhetensNamn: "Atlasskolan ",
    postnr: "58758",
    besökspostnr: "58758",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "78579312",
    skolenhetensNamn: "Bankekinds skola",
    postnr: "59593",
    besökspostnr: "58593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "78992223",
    skolenhetensNamn: "Björkö friskola",
    postnr: "58578",
    besökspostnr: "58578",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "63906328",
    skolenhetensNamn: "Björnkärrskolan",
    postnr: "58437",
    besökspostnr: "58437",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "30147517",
    skolenhetensNamn: "Blästadsskolan",
    postnr: "58929",
    besökspostnr: "58929",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "72710465",
    skolenhetensNamn: "Brokinds skola",
    postnr: "59045",
    besökspostnr: "59045",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "84834006",
    skolenhetensNamn: "Brunnbyskolan",
    postnr: "59073",
    besökspostnr: "59073",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "53873070",
    skolenhetensNamn: "Bäckskolan",
    postnr: "58737",
    besökspostnr: "58737",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "34038904",
    skolenhetensNamn: "Bäckskolan F-klass Flex",
    postnr: "58737",
    besökspostnr: "58737",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "85886913",
    skolenhetensNamn: "Ekkälleskolan",
    postnr: "58752",
    besökspostnr: "58752",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "10671588",
    skolenhetensNamn: "Ekängens skolor",
    postnr: "58275",
    besökspostnr: "58275",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "69259821",
    skolenhetensNamn: "Fredriksbergsskolan",
    postnr: "58923",
    besökspostnr: "58923",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "64407340",
    skolenhetensNamn: "Hagbyskolan",
    postnr: "58723",
    besökspostnr: "58723",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "12265429",
    skolenhetensNamn: "Harvestadsskolorna",
    postnr: "58939",
    besökspostnr: "58939",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "88643893",
    skolenhetensNamn: "Himnaskolan",
    postnr: "59062",
    besökspostnr: "59062",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "56122739",
    skolenhetensNamn: "Hjulsbroskolan",
    postnr: "58943",
    besökspostnr: "58943",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "12146922",
    skolenhetensNamn: "Kungsbergsskolan F-6",
    postnr: "58253",
    besökspostnr: "58253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "66616050",
    skolenhetensNamn: "Kvinnebyskolan",
    postnr: "58935",
    besökspostnr: "58935",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "11861311",
    skolenhetensNamn: "Kärna skola ",
    postnr: "58662",
    besökspostnr: "58662",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "29350859",
    skolenhetensNamn: "Lillgårdsskolan",
    postnr: "58737",
    besökspostnr: "58737",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "87929781",
    skolenhetensNamn: "Ljungs skola",
    postnr: "59075",
    besökspostnr: "59075",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "75288258",
    skolenhetensNamn: "Montessori Mondial Linköping",
    postnr: "58729",
    besökspostnr: "58729",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "39665955",
    skolenhetensNamn: "Norrbergaskolan",
    postnr: "58963",
    besökspostnr: "58963",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "51947069",
    skolenhetensNamn: "Nya Rydsskolan ",
    postnr: "58436",
    besökspostnr: "58436",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "62444453",
    skolenhetensNamn: "Nygårdsskolan",
    postnr: "58644",
    besökspostnr: "58644",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "24526873",
    skolenhetensNamn: "Nykils skola",
    postnr: "59052",
    besökspostnr: "59052",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "73905927",
    skolenhetensNamn: "Rappestad skola",
    postnr: "59047",
    besökspostnr: "59047",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "37626171",
    skolenhetensNamn: "Rosendalsskolan",
    postnr: "58647",
    besökspostnr: "58647",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "36426004",
    skolenhetensNamn: "Skeda skola",
    postnr: "58597",
    besökspostnr: "58597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "47854168",
    skolenhetensNamn: "Slaka skola",
    postnr: "58337",
    besökspostnr: "58337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "64444797",
    skolenhetensNamn: "Slestadskolan",
    postnr: "58333",
    besökspostnr: "58333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "33615827",
    skolenhetensNamn: "Småskolan",
    postnr: "59071",
    besökspostnr: "59071",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "21336110",
    skolenhetensNamn: "Sätra skola",
    postnr: "58965",
    besökspostnr: "58965",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "34100694",
    skolenhetensNamn: "T1-skolorna",
    postnr: "58212",
    besökspostnr: "58212",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "27713533",
    skolenhetensNamn: "Tallbodaskolorna",
    postnr: "58272",
    besökspostnr: "58272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "78438609",
    skolenhetensNamn: "Tanneforsskolan",
    postnr: "58242",
    besökspostnr: "58242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "24291925",
    skolenhetensNamn: "Tornhagsskolan",
    postnr: "58247",
    besökspostnr: "58247",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "71502521",
    skolenhetensNamn: "Ulleviskolan ",
    postnr: "58644",
    besökspostnr: "58644",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "75005957",
    skolenhetensNamn: "Ulleviskolan F-klass Flex",
    postnr: "58642",
    besökspostnr: "58642",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "16096482",
    skolenhetensNamn: "Ulrika skola",
    postnr: "59053",
    besökspostnr: "59053",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "50759152",
    skolenhetensNamn: "Vallastaden F-klass Flex",
    postnr: "58328",
    besökspostnr: "58328",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "79102473",
    skolenhetensNamn: "Vallastadens skola",
    postnr: "58328",
    besökspostnr: "58328",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "82087469",
    skolenhetensNamn: "Vidingsjöskolan",
    postnr: "58739",
    besökspostnr: "58739",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "84248539",
    skolenhetensNamn: "Vikingstads skolor",
    postnr: "59049",
    besökspostnr: "59049",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "85043074",
    skolenhetensNamn: "Vist skola",
    postnr: "59054",
    besökspostnr: "59054",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "26255239",
    skolenhetensNamn: "Vittra Lambohov",
    postnr: "58333",
    besökspostnr: "58333",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "61381787",
    skolenhetensNamn: "Vreta Kloster skola",
    postnr: "59077",
    besökspostnr: "59077",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "59548402",
    skolenhetensNamn: "Ånestadsskolan F-6",
    postnr: "58731",
    besökspostnr: "58731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0580",
    kommunsNamn: "Linköping",
    skolenhetsKod: "35599739",
    skolenhetensNamn: "Änggårdsskolan",
    postnr: "58331",
    besökspostnr: "58331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "22561631",
    skolenhetensNamn: "Bjärbyskolan",
    postnr: "60371",
    besökspostnr: "60371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "78907666",
    skolenhetensNamn: "Bråviksskolan 1",
    postnr: "60385",
    besökspostnr: "60385",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "60154076",
    skolenhetensNamn: "Dagsbergs skola",
    postnr: "60591",
    besökspostnr: "60591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "96386396",
    skolenhetensNamn: "De Geers skola",
    postnr: "60240",
    besökspostnr: "60240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "78105439",
    skolenhetensNamn: "Diamantens skola - världens skola",
    postnr: "60368",
    besökspostnr: "60230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "45594609",
    skolenhetensNamn: "Ektorpsringen 2",
    postnr: "60181",
    besökspostnr: "60373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "45256848",
    skolenhetensNamn: "Folkparksskolan",
    postnr: "60219",
    besökspostnr: "61020",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "55724712",
    skolenhetensNamn: "Greby-skolan",
    postnr: "61020",
    besökspostnr: "60225",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "61662805",
    skolenhetensNamn: "Gustaf Adolfsskolan 2",
    postnr: "60230",
    besökspostnr: "60230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "50770926",
    skolenhetensNamn: "I Ur och Skur sk Skattkammarön",
    postnr: "60382",
    besökspostnr: "60381",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "57529996",
    skolenhetensNamn: "Johannesskolan Norrkpg Kr Sk",
    postnr: "60382",
    besökspostnr: "60382",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "15737688",
    skolenhetensNamn: "Jurslaskolan 2",
    postnr: "61634",
    besökspostnr: "61634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "23461399",
    skolenhetensNamn: "Karlshovsskolan",
    postnr: "60208",
    besökspostnr: "60208",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "66430782",
    skolenhetensNamn: "Klockaretorpsskolan",
    postnr: "60378",
    besökspostnr: "60378",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "51966487",
    skolenhetensNamn: "Kvarsebo skola",
    postnr: "61893",
    besökspostnr: "61893",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "58663615",
    skolenhetensNamn: "Kyrkskolan 2",
    postnr: "60210",
    besökspostnr: "60210",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "81104648",
    skolenhetensNamn: "Kättinge skola",
    postnr: "61027",
    besökspostnr: "60385",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "39026192",
    skolenhetensNamn: "Lindöskolan ",
    postnr: "60385",
    besökspostnr: "60385",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "61866166",
    skolenhetensNamn: "Ljuraskolan 1",
    postnr: "60350",
    besökspostnr: "60350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "72526185",
    skolenhetensNamn: "Lärande Grundskola Södra",
    postnr: "60379",
    besökspostnr: "60379",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "90838156",
    skolenhetensNamn: "Lärande Grundskola Östra",
    postnr: "60229",
    besökspostnr: "60229",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "14994184",
    skolenhetensNamn: "Matteusskolan 2",
    postnr: "60218",
    besökspostnr: "60218",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "28313481",
    skolenhetensNamn: "Norrköpings Montessoriskola 1",
    postnr: "60233",
    besökspostnr: "60233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "46722664",
    skolenhetensNamn: "Oxelbergsskolan",
    postnr: "60244",
    besökspostnr: "61732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "91576770",
    skolenhetensNamn: "Parkskolan",
    postnr: "61732",
    besökspostnr: "60371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "33530590",
    skolenhetensNamn: "Rudolf Steinerskolan, gr",
    postnr: "60116",
    besökspostnr: "60223",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "43215755",
    skolenhetensNamn: "Sandbyängsskolan 1",
    postnr: "60211",
    besökspostnr: "60211",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "35742140",
    skolenhetensNamn: "Silverdansens skola",
    postnr: "60369",
    besökspostnr: "60380",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "58040213",
    skolenhetensNamn: "Skarphagsskolan 2",
    postnr: "60380",
    besökspostnr: "60380",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "20288756",
    skolenhetensNamn: "Smedbyskolan",
    postnr: "60361",
    besökspostnr: "60361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "65775074",
    skolenhetensNamn: "Svärtingehus 1",
    postnr: "60560",
    besökspostnr: "60560",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "87220418",
    skolenhetensNamn: "Svärtinge Skogsbacke skola",
    postnr: "60580",
    besökspostnr: "60580",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "59568717",
    skolenhetensNamn: "Söderporten 2",
    postnr: "60360",
    besökspostnr: "60360",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "63512597",
    skolenhetensNamn: "Tamburinens skola",
    postnr: "60378",
    besökspostnr: "61830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "62569328",
    skolenhetensNamn: "Tåby skola",
    postnr: "61492",
    besökspostnr: "61024",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "82294598",
    skolenhetensNamn: "Uttersbergsskolan",
    postnr: "61830",
    besökspostnr: "61830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "34784374",
    skolenhetensNamn: "Vikbolandsskolan 2",
    postnr: "61024",
    besökspostnr: "61024",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "72299802",
    skolenhetensNamn: "Vilbergsskolan",
    postnr: "60356",
    besökspostnr: "60181",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "59469604",
    skolenhetensNamn: "Villastadsskolan",
    postnr: "60336",
    besökspostnr: "60224",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "38936292",
    skolenhetensNamn: "Vittra Röda stan",
    postnr: "60218",
    besökspostnr: "60218",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "61330534",
    skolenhetensNamn: "Vrinneviskolan",
    postnr: "60362",
    besökspostnr: "60362",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "94530544",
    skolenhetensNamn: "Vångaskolan",
    postnr: "61790",
    besökspostnr: "61790",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0581",
    kommunsNamn: "Norrköping",
    skolenhetsKod: "65244856",
    skolenhetensNamn: "Åbymoskolan 1",
    postnr: "61630",
    besökspostnr: "61630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "79515038",
    skolenhetensNamn: "Bergaskolan",
    postnr: "61430",
    besökspostnr: "61430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "46785601",
    skolenhetensNamn: "Björkhagaskolan",
    postnr: "61480",
    besökspostnr: "61432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "27212144",
    skolenhetensNamn: "Björkängen skola",
    postnr: "61480",
    besökspostnr: "61432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "64901558",
    skolenhetensNamn: "Gårdeby friskola",
    postnr: "61495",
    besökspostnr: "61495",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "22195087",
    skolenhetensNamn: "Mogata skola",
    postnr: "61490",
    besökspostnr: "61490",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "63176416",
    skolenhetensNamn: "Sankt Anna skola",
    postnr: "61498",
    besökspostnr: "61498",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "68550751",
    skolenhetensNamn: "Skönbergaskolan",
    postnr: "61431",
    besökspostnr: "61431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "70789823",
    skolenhetensNamn: "Söderköpings Waldorfskola",
    postnr: "61492",
    besökspostnr: "61492",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "38948355",
    skolenhetensNamn: "Västra Husby skola",
    postnr: "60596",
    besökspostnr: "60596",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0582",
    kommunsNamn: "Söderköping",
    skolenhetsKod: "59575795",
    skolenhetensNamn: "Östra Ryds skola",
    postnr: "61496",
    besökspostnr: "61496",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "30697328",
    skolenhetensNamn: "Bergvallaskolan",
    postnr: "59106",
    besökspostnr: "59176",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "30701609",
    skolenhetensNamn: "Bråstorpskolan",
    postnr: "59153",
    besökspostnr: "59153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "27941686",
    skolenhetensNamn: "Dansäterskolan",
    postnr: "59174",
    besökspostnr: "59174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "68973224",
    skolenhetensNamn: "Ekenässkolan",
    postnr: "59146",
    besökspostnr: "59146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "86335791",
    skolenhetensNamn: "Fornåsa skola",
    postnr: "59033",
    besökspostnr: "59033",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "16363452",
    skolenhetensNamn: "Fågelsta skola",
    postnr: "59193",
    besökspostnr: "59193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "84577998",
    skolenhetensNamn: "Godegårds skola",
    postnr: "59198",
    besökspostnr: "59198",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "40888014",
    skolenhetensNamn: "Innovitaskolan Motala",
    postnr: "59135",
    besökspostnr: "59160",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "65569758",
    skolenhetensNamn: "Karlslunds skola",
    postnr: "59162",
    besökspostnr: "59162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "89596219",
    skolenhetensNamn: "Klockrike skola",
    postnr: "59032",
    besökspostnr: "59032",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "62595638",
    skolenhetensNamn: "Mariebergsskolan F-3",
    postnr: "59172",
    besökspostnr: "59172",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "48868251",
    skolenhetensNamn: "Norra skolan F-3",
    postnr: "59153",
    besökspostnr: "59153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "96520696",
    skolenhetensNamn: "Nykyrka skola",
    postnr: "59197",
    besökspostnr: "59197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "78731821",
    skolenhetensNamn: "Skolgårda skola F-6",
    postnr: "59160",
    besökspostnr: "59160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "36380064",
    skolenhetensNamn: "Smedsby skola",
    postnr: "59152",
    besökspostnr: "59152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "33011928",
    skolenhetensNamn: "Södra skolan F-6",
    postnr: "59136",
    besökspostnr: "59136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "51733481",
    skolenhetensNamn: "Tjällmo skola",
    postnr: "59034",
    besökspostnr: "59034",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "56197506",
    skolenhetensNamn: "Vätternskolan F-3",
    postnr: "59154",
    besökspostnr: "59154",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0583",
    kommunsNamn: "Motala",
    skolenhetsKod: "31501751",
    skolenhetensNamn: "Österstad skola",
    postnr: "59195",
    besökspostnr: "59195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0584",
    kommunsNamn: "Vadstena",
    skolenhetsKod: "14324836",
    skolenhetensNamn: "S:t Persskolan",
    postnr: "59280",
    besökspostnr: "59230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "42116655",
    skolenhetensNamn: "Bjälbotullskolan LM",
    postnr: "59623",
    besökspostnr: "59633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "92463571",
    skolenhetensNamn: "Blåklintskolan F-3",
    postnr: "59580",
    besökspostnr: "59532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "28993546",
    skolenhetensNamn: "Egebyskolan L",
    postnr: "59543",
    besökspostnr: "59017",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "99557550",
    skolenhetensNamn: "Klämmestorpsskolan LM",
    postnr: "59020",
    besökspostnr: "59552",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "85620980",
    skolenhetensNamn: "Lindbladsskolan LM",
    postnr: "59623",
    besökspostnr: "59534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "37981902",
    skolenhetensNamn: "Normlösa skola",
    postnr: "59693",
    besökspostnr: "59632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "34622909",
    skolenhetensNamn: "Vasaskolan L",
    postnr: "59551",
    besökspostnr: "59017",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "99363913",
    skolenhetensNamn: "Veta skola LM",
    postnr: "59017",
    besökspostnr: "59018",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "71079812",
    skolenhetensNamn: "Vifolkaskolan F-6 LM",
    postnr: "59018",
    besökspostnr: "59591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "45401314",
    skolenhetensNamn: "Väderstad skola LM",
    postnr: "59021",
    besökspostnr: "59020",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "05",
    länsNamn: "Östergötlands län",
    kommun: "0586",
    kommunsNamn: "Mjölby",
    skolenhetsKod: "92579261",
    skolenhetensNamn: "Västra Hargs skola LM",
    postnr: "59591",
    besökspostnr: "59591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0604",
    kommunsNamn: "Aneby",
    skolenhetsKod: "48000763",
    skolenhetensNamn: "Oasens skola",
    postnr: "57824",
    besökspostnr: "57891",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0604",
    kommunsNamn: "Aneby",
    skolenhetsKod: "29991409",
    skolenhetensNamn: "Parkskolan",
    postnr: "57822",
    besökspostnr: "57833",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0604",
    kommunsNamn: "Aneby",
    skolenhetsKod: "72916171",
    skolenhetensNamn: "Sunhult skola",
    postnr: "57822",
    besökspostnr: "57373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0604",
    kommunsNamn: "Aneby",
    skolenhetsKod: "34811255",
    skolenhetensNamn: "Tallbackaskolan",
    postnr: "57822",
    besökspostnr: "57831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0604",
    kommunsNamn: "Aneby",
    skolenhetsKod: "81763314",
    skolenhetensNamn: "Vireda skola",
    postnr: "57822",
    besökspostnr: "57892",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0617",
    kommunsNamn: "Gnosjö",
    skolenhetsKod: "26185758",
    skolenhetensNamn: "Bäckaskolan F-6",
    postnr: "33532",
    besökspostnr: "33532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0617",
    kommunsNamn: "Gnosjö",
    skolenhetsKod: "80690202",
    skolenhetensNamn: "Ekbackens skola",
    postnr: "33593",
    besökspostnr: "33593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0617",
    kommunsNamn: "Gnosjö",
    skolenhetsKod: "39116597",
    skolenhetensNamn: "Hillerstorpsskolan F-6",
    postnr: "33573",
    besökspostnr: "33573",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0617",
    kommunsNamn: "Gnosjö",
    skolenhetsKod: "10440237",
    skolenhetensNamn: "Kulltorpsskolan",
    postnr: "33031",
    besökspostnr: "33031",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0617",
    kommunsNamn: "Gnosjö",
    skolenhetsKod: "90411655",
    skolenhetensNamn: "Åvikenskolan",
    postnr: "33531",
    besökspostnr: "33531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0642",
    kommunsNamn: "Mullsjö",
    skolenhetsKod: "45105492",
    skolenhetensNamn: "Björksäter skola",
    postnr: "56521",
    besökspostnr: "56531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0642",
    kommunsNamn: "Mullsjö",
    skolenhetsKod: "40995060",
    skolenhetensNamn: "Kronängskolan",
    postnr: "56521",
    besökspostnr: "56532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0642",
    kommunsNamn: "Mullsjö",
    skolenhetsKod: "40285376",
    skolenhetensNamn: "Sandhems skola",
    postnr: "56521",
    besökspostnr: "56594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0643",
    kommunsNamn: "Habo",
    skolenhetsKod: "20706826",
    skolenhetensNamn: "Alléskolan",
    postnr: "56633",
    besökspostnr: "56633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0643",
    kommunsNamn: "Habo",
    skolenhetsKod: "87453774",
    skolenhetensNamn: "Bränningeskolan",
    postnr: "56624",
    besökspostnr: "56634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0643",
    kommunsNamn: "Habo",
    skolenhetsKod: "42615925",
    skolenhetensNamn: "Fagerhults skola",
    postnr: "56624",
    besökspostnr: "56692",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0643",
    kommunsNamn: "Habo",
    skolenhetsKod: "89879483",
    skolenhetensNamn: "Hagenskolan",
    postnr: "56624",
    besökspostnr: "56632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0643",
    kommunsNamn: "Habo",
    skolenhetsKod: "79878458",
    skolenhetensNamn: "Kråkerydskolan",
    postnr: "56624",
    besökspostnr: "56633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "91542623",
    skolenhetensNamn: "Bureskolan",
    postnr: "33377",
    besökspostnr: "33377",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "28566222",
    skolenhetensNamn: "Ekenskolan",
    postnr: "33280",
    besökspostnr: "33431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "60157765",
    skolenhetensNamn: "Gyllenforsskolan",
    postnr: "33235",
    besökspostnr: "33235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "30777212",
    skolenhetensNamn: "Isabergskolan",
    postnr: "33571",
    besökspostnr: "33571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "27057988",
    skolenhetensNamn: "Klockargårdskolan",
    postnr: "33392",
    besökspostnr: "33392",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "93436580",
    skolenhetensNamn: "Montessorifriskolan i Gislaved",
    postnr: "33224",
    besökspostnr: "33230",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "25720600",
    skolenhetensNamn: "Skeppshultskolan",
    postnr: "33393",
    besökspostnr: "33393",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "89859181",
    skolenhetensNamn: "Sörgårdsskolan",
    postnr: "33231",
    besökspostnr: "33231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "90837768",
    skolenhetensNamn: "Töråsskolan",
    postnr: "33280",
    besökspostnr: "33433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "46098033",
    skolenhetensNamn: "Åtteråsskolan",
    postnr: "33332",
    besökspostnr: "33333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "26596084",
    skolenhetensNamn: "Ängslyckans skola",
    postnr: "33373",
    besökspostnr: "33373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0662",
    kommunsNamn: "Gislaved",
    skolenhetsKod: "85013151",
    skolenhetensNamn: "Ölmestadskolan",
    postnr: "33375",
    besökspostnr: "33375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "38030036",
    skolenhetensNamn: "Bondstorps skola",
    postnr: "56821",
    besökspostnr: "56291",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "82420962",
    skolenhetensNamn: "Byarums skola",
    postnr: "56821",
    besökspostnr: "56792",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "52839848",
    skolenhetensNamn: "Fågelforsskolan F-6",
    postnr: "56821",
    besökspostnr: "56831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "90591096",
    skolenhetensNamn: "Götafors skola",
    postnr: "56821",
    besökspostnr: "56733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "95093042",
    skolenhetensNamn: "Hoks skola",
    postnr: "56821",
    besökspostnr: "56013",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "85555052",
    skolenhetensNamn: "Klevshults skola",
    postnr: "56821",
    besökspostnr: "56892",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "39619656",
    skolenhetensNamn: "Svenarums skola",
    postnr: "56821",
    besökspostnr: "56013",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "47307357",
    skolenhetensNamn: "Sörgårdsskolan",
    postnr: "56821",
    besökspostnr: "56830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "58128691",
    skolenhetensNamn: "Tofthagaskolan",
    postnr: "56891",
    besökspostnr: "56891",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0665",
    kommunsNamn: "Vaggeryd",
    skolenhetsKod: "60778543",
    skolenhetensNamn: "Östra skolan",
    postnr: "56821",
    besökspostnr: "56730",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "17538336",
    skolenhetensNamn: "Attarpsskolan F-6",
    postnr: "56433",
    besökspostnr: "56433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "20211452",
    skolenhetensNamn: "Barnarpsskolan",
    postnr: "55631",
    besökspostnr: "55631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "64396519",
    skolenhetensNamn: "Brodalsskolan",
    postnr: "56232",
    besökspostnr: "56232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "94231816",
    skolenhetensNamn: "Dalviksskolan",
    postnr: "55445",
    besökspostnr: "56135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "18631580",
    skolenhetensNamn: "Egnahemsskolan",
    postnr: "55189",
    besökspostnr: "55456",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "88907604",
    skolenhetensNamn: "Ekhagsskolan",
    postnr: "55456",
    besökspostnr: "55321",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "19227164",
    skolenhetensNamn: "Fagerslättskolan",
    postnr: "55189",
    besökspostnr: "56140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "61784685",
    skolenhetensNamn: "Gräshagsskolan",
    postnr: "55472",
    besökspostnr: "55626",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "16249429",
    skolenhetensNamn: "Grästorpsskolan",
    postnr: "55626",
    besökspostnr: "55626",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "42372493",
    skolenhetensNamn: "Hisingstorpsskolan",
    postnr: "55447",
    besökspostnr: "55447",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "81734064",
    skolenhetensNamn: "Hovslättsskolan",
    postnr: "55626",
    besökspostnr: "55626",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "86499334",
    skolenhetensNamn: "Internationella Engelska Skolan Jönköping",
    postnr: "56133",
    besökspostnr: "56133",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "13609744",
    skolenhetensNamn: "Kumlabyskolan",
    postnr: "56331",
    besökspostnr: "56394",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "72007217",
    skolenhetensNamn: "Kålgårdsskolan",
    postnr: "55323",
    besökspostnr: "55323",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "13412549",
    skolenhetensNamn: "Landsjöskolan",
    postnr: "56146",
    besökspostnr: "56146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "68620266",
    skolenhetensNamn: "Lekerydsskolan",
    postnr: "56195",
    besökspostnr: "56195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "85127185",
    skolenhetensNamn: "Ljungarumsskolan F-6",
    postnr: "55339",
    besökspostnr: "55339",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "77787682",
    skolenhetensNamn: "Månsarpsskolan",
    postnr: "56241",
    besökspostnr: "56241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "44131379",
    skolenhetensNamn: "Norrängsskolan",
    postnr: "56136",
    besökspostnr: "56136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "28605274",
    skolenhetensNamn: "Nyarpsskolan",
    postnr: "56432",
    besökspostnr: "56432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "73197151",
    skolenhetensNamn: "Oxhagsskolan",
    postnr: "56150",
    besökspostnr: "56150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "95673344",
    skolenhetensNamn: "Prolympia, Jönköping",
    postnr: "55454",
    besökspostnr: "55454",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "83919671",
    skolenhetensNamn: "Ribbaskolan",
    postnr: "56331",
    besökspostnr: "56331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "36538130",
    skolenhetensNamn: "Rosenlundsskolan F-6",
    postnr: "55453",
    besökspostnr: "55453",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "88057049",
    skolenhetensNamn: "Ryds skola",
    postnr: "56576",
    besökspostnr: "56576",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "21464829",
    skolenhetensNamn: "Råslättsskolan",
    postnr: "55611",
    besökspostnr: "55611",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "91672762",
    skolenhetensNamn: "Skärstad skola",
    postnr: "56191",
    besökspostnr: "56191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "23434523",
    skolenhetensNamn: "Slättenskolan",
    postnr: "56231",
    besökspostnr: "56231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "69408871",
    skolenhetensNamn: "Stensholmsskolan",
    postnr: "55189",
    besökspostnr: "56139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "23250615",
    skolenhetensNamn: "Strandskolan",
    postnr: "55594",
    besökspostnr: "55594",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "11247661",
    skolenhetensNamn: "Strömsbergsskolan",
    postnr: "55307",
    besökspostnr: "55307",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "85224261",
    skolenhetensNamn: "Södergårdsskolan",
    postnr: "56142",
    besökspostnr: "56142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "87181526",
    skolenhetensNamn: "Tabergsskolan",
    postnr: "56240",
    besökspostnr: "56240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "38245682",
    skolenhetensNamn: "Taheskolan F-6",
    postnr: "56242",
    besökspostnr: "56242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "61435499",
    skolenhetensNamn: "Talavidskolan F-6",
    postnr: "55313",
    besökspostnr: "55313",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "22496996",
    skolenhetensNamn: "Tenhultsskolan",
    postnr: "56029",
    besökspostnr: "56160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "10466501",
    skolenhetensNamn: "Torpaskolan",
    postnr: "55333",
    besökspostnr: "55333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "98171083",
    skolenhetensNamn: "Torps skola",
    postnr: "56434",
    besökspostnr: "56434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "49572930",
    skolenhetensNamn: "Vittra Samset",
    postnr: "55446",
    besökspostnr: "55446",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "15406277",
    skolenhetensNamn: "Vätterslundsskolan",
    postnr: "55448",
    besökspostnr: "55448",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "96013047",
    skolenhetensNamn: "Ödestugu skola",
    postnr: "56163",
    besökspostnr: "56163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "36211599",
    skolenhetensNamn: "Ölmstadskolan",
    postnr: "56393",
    besökspostnr: "56393",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "12057247",
    skolenhetensNamn: "Örserumsskolan",
    postnr: "56391",
    besökspostnr: "56391",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0680",
    kommunsNamn: "Jönköping",
    skolenhetsKod: "98273223",
    skolenhetensNamn: "Österängsskolan",
    postnr: "55465",
    besökspostnr: "55465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "58161963",
    skolenhetensNamn: "Annebergs skola",
    postnr: "57171",
    besökspostnr: "57171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "38682093",
    skolenhetensNamn: "Emåskolan",
    postnr: "57106",
    besökspostnr: "57139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "80096894",
    skolenhetensNamn: "Erkaboskolan",
    postnr: "57136",
    besökspostnr: "57136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "10917843",
    skolenhetensNamn: "Handskerydsskolan",
    postnr: "57139",
    besökspostnr: "57139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "17341861",
    skolenhetensNamn: "Malmbäck skola",
    postnr: "57168",
    besökspostnr: "57168",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "53807998",
    skolenhetensNamn: "Nyhemsskolan",
    postnr: "57138",
    besökspostnr: "57135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "66394964",
    skolenhetensNamn: "Rosenholmsskolan",
    postnr: "57103",
    besökspostnr: "57164",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "20682278",
    skolenhetensNamn: "Runnerydsskolan F",
    postnr: "57135",
    besökspostnr: "57135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "20352322",
    skolenhetensNamn: "Sandsjöfors skola",
    postnr: "57164",
    besökspostnr: "57141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "85030969",
    skolenhetensNamn: "Åkerskolan 1",
    postnr: "57141",
    besökspostnr: "57141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0682",
    kommunsNamn: "Nässjö",
    skolenhetsKod: "20527864",
    skolenhetensNamn: "Ängs skola",
    postnr: "57174",
    besökspostnr: "57134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "90000574",
    skolenhetensNamn: "Borbackaskolan",
    postnr: "33183",
    besökspostnr: "33015",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "73914410",
    skolenhetensNamn: "Bredaryds skola",
    postnr: "33371",
    besökspostnr: "33371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "16778059",
    skolenhetensNamn: "Enehagens skola",
    postnr: "33183",
    besökspostnr: "33131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "19228578",
    skolenhetensNamn: "Fryele skola",
    postnr: "33196",
    besökspostnr: "33012",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "54519463",
    skolenhetensNamn: "Gällaryds skola",
    postnr: "33015",
    besökspostnr: "33194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "63642579",
    skolenhetensNamn: "Hanahöjskolan",
    postnr: "33012",
    besökspostnr: "33142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "84123958",
    skolenhetensNamn: "Horda skola",
    postnr: "33018",
    besökspostnr: "33142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "54642333",
    skolenhetensNamn: "Hånger skola",
    postnr: "33195",
    besökspostnr: "33195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "95736970",
    skolenhetensNamn: "Kärda skola",
    postnr: "33195",
    besökspostnr: "33017",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "59015476",
    skolenhetensNamn: "Mossleskolan",
    postnr: "33183",
    besökspostnr: "33142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "13114568",
    skolenhetensNamn: "Rydaholms skola",
    postnr: "33017",
    besökspostnr: "33150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "70738456",
    skolenhetensNamn: "Rörstorpsskolan",
    postnr: "33183",
    besökspostnr: "33197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "90809860",
    skolenhetensNamn: "Trälleborg",
    postnr: "33183",
    besökspostnr: "33183",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0683",
    kommunsNamn: "Värnamo",
    skolenhetsKod: "86230955",
    skolenhetensNamn: "Tånnö skola",
    postnr: "33197",
    besökspostnr: "33197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0684",
    kommunsNamn: "Sävsjö",
    skolenhetsKod: "13654995",
    skolenhetensNamn: "Hägneskolan",
    postnr: "57680",
    besökspostnr: "57633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0684",
    kommunsNamn: "Sävsjö",
    skolenhetsKod: "74170440",
    skolenhetensNamn: "Rörviks skola",
    postnr: "57680",
    besökspostnr: "57693",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0684",
    kommunsNamn: "Sävsjö",
    skolenhetsKod: "77440739",
    skolenhetensNamn: "Stockaryds skola",
    postnr: "57680",
    besökspostnr: "57694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0684",
    kommunsNamn: "Sävsjö",
    skolenhetsKod: "28504550",
    skolenhetensNamn: "Sävsjö kristna skola",
    postnr: "57622",
    besökspostnr: "57631",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0684",
    kommunsNamn: "Sävsjö",
    skolenhetsKod: "53857703",
    skolenhetensNamn: "Vallsjöskolan",
    postnr: "57680",
    besökspostnr: "57632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0684",
    kommunsNamn: "Sävsjö",
    skolenhetsKod: "60194444",
    skolenhetensNamn: "Vrigstad skola",
    postnr: "57680",
    besökspostnr: "57697",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "32816449",
    skolenhetensNamn: "Björkö skola",
    postnr: "57450",
    besökspostnr: "57495",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "78156460",
    skolenhetensNamn: "Brunnsgårdsskolan",
    postnr: "57480",
    besökspostnr: "57433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "82758119",
    skolenhetensNamn: "Bäckseda skola",
    postnr: "57480",
    besökspostnr: "57441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "56483826",
    skolenhetensNamn: "Ekenässjöns skola",
    postnr: "57450",
    besökspostnr: "57450",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "18690556",
    skolenhetensNamn: "Holsby skola",
    postnr: "57453",
    besökspostnr: "57453",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "59846405",
    skolenhetensNamn: "Korsberga skola",
    postnr: "57475",
    besökspostnr: "57475",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "41045050",
    skolenhetensNamn: "Kvillsfors skola",
    postnr: "57455",
    besökspostnr: "57455",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "60936284",
    skolenhetensNamn: "Landsbro skola f-6",
    postnr: "57472",
    besökspostnr: "57472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "87519348",
    skolenhetensNamn: "Mogärdeskolan",
    postnr: "57480",
    besökspostnr: "57431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "95943499",
    skolenhetensNamn: "Myresjö skola",
    postnr: "57471",
    besökspostnr: "57471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "41197950",
    skolenhetensNamn: "Norrgårdsskolan",
    postnr: "57480",
    besökspostnr: "57435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "10555003",
    skolenhetensNamn: "Nye skola",
    postnr: "57498",
    besökspostnr: "57498",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "65268060",
    skolenhetensNamn: "Ramkvilla skola",
    postnr: "57474",
    besökspostnr: "57474",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "19743050",
    skolenhetensNamn: "Tomaslundsskolan",
    postnr: "57480",
    besökspostnr: "57437",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0685",
    kommunsNamn: "Vetlanda",
    skolenhetsKod: "13123046",
    skolenhetensNamn: "Vetlanda Friskola",
    postnr: "57423",
    besökspostnr: "57433",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0686",
    kommunsNamn: "Eksjö",
    skolenhetsKod: "76775667",
    skolenhetensNamn: "Furulundsskolan A",
    postnr: "59805",
    besökspostnr: "59897",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0686",
    kommunsNamn: "Eksjö",
    skolenhetsKod: "70675077",
    skolenhetensNamn: "Grevhagsskolan",
    postnr: "57580",
    besökspostnr: "57539",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0686",
    kommunsNamn: "Eksjö",
    skolenhetsKod: "95090544",
    skolenhetensNamn: "Hultskolan",
    postnr: "57580",
    besökspostnr: "57592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0686",
    kommunsNamn: "Eksjö",
    skolenhetsKod: "14664038",
    skolenhetensNamn: "Höredaskolan",
    postnr: "57580",
    besökspostnr: "57595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0686",
    kommunsNamn: "Eksjö",
    skolenhetsKod: "65465842",
    skolenhetensNamn: "Ingatorpsskolan",
    postnr: "57580",
    besökspostnr: "57597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0686",
    kommunsNamn: "Eksjö",
    skolenhetsKod: "87335241",
    skolenhetensNamn: "Linnéskolan",
    postnr: "57580",
    besökspostnr: "57531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0686",
    kommunsNamn: "Eksjö",
    skolenhetsKod: "70174655",
    skolenhetensNamn: "Norrtullskolan",
    postnr: "57534",
    besökspostnr: "57534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0687",
    kommunsNamn: "Tranås",
    skolenhetsKod: "93581868",
    skolenhetensNamn: "Adelövs friskola",
    postnr: "57398",
    besökspostnr: "57398",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0687",
    kommunsNamn: "Tranås",
    skolenhetsKod: "43607066",
    skolenhetensNamn: "Fröafallsskolan",
    postnr: "57382",
    besökspostnr: "57338",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0687",
    kommunsNamn: "Tranås",
    skolenhetsKod: "24238863",
    skolenhetensNamn: "Gripenbergs skola",
    postnr: "57392",
    besökspostnr: "57392",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0687",
    kommunsNamn: "Tranås",
    skolenhetsKod: "70432835",
    skolenhetensNamn: "Hubbarpsskolan",
    postnr: "57382",
    besökspostnr: "57340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0687",
    kommunsNamn: "Tranås",
    skolenhetsKod: "69312919",
    skolenhetensNamn: "Junkaremålsskolan F-6",
    postnr: "57382",
    besökspostnr: "57337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0687",
    kommunsNamn: "Tranås",
    skolenhetsKod: "33228563",
    skolenhetensNamn: "Linderås skola",
    postnr: "57397",
    besökspostnr: "57341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0687",
    kommunsNamn: "Tranås",
    skolenhetsKod: "94938162",
    skolenhetensNamn: "Norrskolan",
    postnr: "57382",
    besökspostnr: "57334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "06",
    länsNamn: "Jönköpings län",
    kommun: "0687",
    kommunsNamn: "Tranås",
    skolenhetsKod: "81343555",
    skolenhetensNamn: "Sommens skola",
    postnr: "57361",
    besökspostnr: "57361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0760",
    kommunsNamn: "Uppvidinge",
    skolenhetsKod: "51633869",
    skolenhetensNamn: "Alstermoskolan",
    postnr: "36443",
    besökspostnr: "36443",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0760",
    kommunsNamn: "Uppvidinge",
    skolenhetsKod: "55214368",
    skolenhetensNamn: "Lenhovdaskolan",
    postnr: "36441",
    besökspostnr: "36441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0760",
    kommunsNamn: "Uppvidinge",
    skolenhetsKod: "57675334",
    skolenhetensNamn: "Nottebäckskolan",
    postnr: "36404",
    besökspostnr: "36444",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0760",
    kommunsNamn: "Uppvidinge",
    skolenhetsKod: "69306171",
    skolenhetensNamn: "Åsedaskolan F-6",
    postnr: "36421",
    besökspostnr: "36430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0760",
    kommunsNamn: "Uppvidinge",
    skolenhetsKod: "52461444",
    skolenhetensNamn: "Älghults Friskola",
    postnr: "36495",
    besökspostnr: "36495",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0761",
    kommunsNamn: "Lessebo",
    skolenhetsKod: "82150561",
    skolenhetensNamn: "Björkskolan",
    postnr: "36594",
    besökspostnr: "36594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0761",
    kommunsNamn: "Lessebo",
    skolenhetsKod: "36519886",
    skolenhetensNamn: "Hackebackeskolan",
    postnr: "36532",
    besökspostnr: "36532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0761",
    kommunsNamn: "Lessebo",
    skolenhetsKod: "32381603",
    skolenhetensNamn: "Kvarndammskolan",
    postnr: "36541",
    besökspostnr: "36541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0761",
    kommunsNamn: "Lessebo",
    skolenhetsKod: "66379524",
    skolenhetensNamn: "Lustigkulla skola",
    postnr: "36543",
    besökspostnr: "36543",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0763",
    kommunsNamn: "Tingsryd",
    skolenhetsKod: "79538647",
    skolenhetensNamn: "Dackeskolan F-6",
    postnr: "36222",
    besökspostnr: "36230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0763",
    kommunsNamn: "Tingsryd",
    skolenhetsKod: "60865240",
    skolenhetensNamn: "Linnerydskolan",
    postnr: "36258",
    besökspostnr: "36258",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0763",
    kommunsNamn: "Tingsryd",
    skolenhetsKod: "38920772",
    skolenhetensNamn: "Rävemåla Friskola",
    postnr: "36298",
    besökspostnr: "36298",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0763",
    kommunsNamn: "Tingsryd",
    skolenhetsKod: "38721951",
    skolenhetensNamn: "Trojaskolan",
    postnr: "36256",
    besökspostnr: "36256",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0763",
    kommunsNamn: "Tingsryd",
    skolenhetsKod: "28944273",
    skolenhetensNamn: "Urshultsskolan",
    postnr: "36254",
    besökspostnr: "36254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0763",
    kommunsNamn: "Tingsryd",
    skolenhetsKod: "23559837",
    skolenhetensNamn: "Väckelsångskolan",
    postnr: "36251",
    besökspostnr: "36251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0763",
    kommunsNamn: "Tingsryd",
    skolenhetsKod: "88544642",
    skolenhetensNamn: "Örmoskolan",
    postnr: "36240",
    besökspostnr: "36240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0764",
    kommunsNamn: "Alvesta",
    skolenhetsKod: "79378495",
    skolenhetensNamn: "Friskolan Kronobergshed",
    postnr: "34263",
    besökspostnr: "34263",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0764",
    kommunsNamn: "Alvesta",
    skolenhetsKod: "66644840",
    skolenhetensNamn: "Grönkullaskolan ",
    postnr: "34280",
    besökspostnr: "34236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0764",
    kommunsNamn: "Alvesta",
    skolenhetsKod: "36322706",
    skolenhetensNamn: "Hjortsbergaskolan",
    postnr: "34293",
    besökspostnr: "34293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0764",
    kommunsNamn: "Alvesta",
    skolenhetsKod: "69699568",
    skolenhetensNamn: "Mohedaskolan",
    postnr: "34261",
    besökspostnr: "34261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0764",
    kommunsNamn: "Alvesta",
    skolenhetsKod: "59234401",
    skolenhetensNamn: "Prästängsskolan",
    postnr: "34280",
    besökspostnr: "34235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0764",
    kommunsNamn: "Alvesta",
    skolenhetsKod: "55218290",
    skolenhetensNamn: "Skatelövsskolan",
    postnr: "34254",
    besökspostnr: "34254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0764",
    kommunsNamn: "Alvesta",
    skolenhetsKod: "88176175",
    skolenhetensNamn: "Vislandaskolan",
    postnr: "34251",
    besökspostnr: "34251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "57408646",
    skolenhetensNamn: "Bråthults Friskola",
    postnr: "34373",
    besökspostnr: "34391",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "93625542",
    skolenhetensNamn: "Diö skola",
    postnr: "34323",
    besökspostnr: "34371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "19901289",
    skolenhetensNamn: "Elmeskolan",
    postnr: "34323",
    besökspostnr: "34331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "29959232",
    skolenhetensNamn: "Gemöskolan",
    postnr: "34323",
    besökspostnr: "34337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "43289789",
    skolenhetensNamn: "Klöxhultsskolan 1",
    postnr: "34335",
    besökspostnr: "34335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "73255943",
    skolenhetensNamn: "Liatorps skola",
    postnr: "34323",
    besökspostnr: "34376",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "71440408",
    skolenhetensNamn: "Paradisskolan",
    postnr: "34323",
    besökspostnr: "34338",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "27479345",
    skolenhetensNamn: "Ryfors skola",
    postnr: "34323",
    besökspostnr: "34397",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "64505955",
    skolenhetensNamn: "The Intern. School of Älmhult",
    postnr: "34323",
    besökspostnr: "34333",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "28837069",
    skolenhetensNamn: "Virestads Friskola",
    postnr: "34373",
    besökspostnr: "34373",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0765",
    kommunsNamn: "Älmhult",
    skolenhetsKod: "60286462",
    skolenhetensNamn: "Älmhults Montessoriskola",
    postnr: "34323",
    besökspostnr: "34335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0767",
    kommunsNamn: "Markaryd",
    skolenhetsKod: "88885075",
    skolenhetensNamn: "Markaryds skola F-6",
    postnr: "28531",
    besökspostnr: "28533",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0767",
    kommunsNamn: "Markaryd",
    skolenhetsKod: "51999929",
    skolenhetensNamn: "Strömsnässkolan F-6",
    postnr: "28733",
    besökspostnr: "28733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0767",
    kommunsNamn: "Markaryd",
    skolenhetsKod: "25157363",
    skolenhetensNamn: "Timsfors skola",
    postnr: "28531",
    besökspostnr: "28540",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0767",
    kommunsNamn: "Markaryd",
    skolenhetsKod: "22058882",
    skolenhetensNamn: "Traryds skola",
    postnr: "28772",
    besökspostnr: "28772",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "85888243",
    skolenhetensNamn: "Braås skola F-3",
    postnr: "36342",
    besökspostnr: "36042",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "58187316",
    skolenhetensNamn: "Bäckaslövskolan",
    postnr: "35112",
    besökspostnr: "35235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "92096622",
    skolenhetensNamn: "Centrumskolan",
    postnr: "35112",
    besökspostnr: "36042",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "98761080",
    skolenhetensNamn: "Dädesjö skola",
    postnr: "36342",
    besökspostnr: "35242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "44232122",
    skolenhetensNamn: "Furuby skola",
    postnr: "35251",
    besökspostnr: "36593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "65877570",
    skolenhetensNamn: "Furutåskolan",
    postnr: "35254",
    besökspostnr: "36032",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "13329040",
    skolenhetensNamn: "Gemla skola",
    postnr: "35112",
    besökspostnr: "35262",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "86282718",
    skolenhetensNamn: "Gustavslundskolan",
    postnr: "35112",
    besökspostnr: "35242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "24632114",
    skolenhetensNamn: "Hovshagaskolan",
    postnr: "35112",
    besökspostnr: "35242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "82426839",
    skolenhetensNamn: "Högstorps skola",
    postnr: "35112",
    besökspostnr: "36044",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "95891686",
    skolenhetensNamn: "Ingelstad skola",
    postnr: "35112",
    besökspostnr: "35596",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "11366318",
    skolenhetensNamn: "Internationella Engelska Skolan Växjö",
    postnr: "35221",
    besökspostnr: "35221",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "84568793",
    skolenhetensNamn: "Kalvsvik skola",
    postnr: "35112",
    besökspostnr: "35239",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "12224943",
    skolenhetensNamn: "Lammhults skola F-3",
    postnr: "35112",
    besökspostnr: "36345",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "86848164",
    skolenhetensNamn: "Lillestadskolan",
    postnr: "35243",
    besökspostnr: "35254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "36698817",
    skolenhetensNamn: "Ljungfälleskolan",
    postnr: "35254",
    besökspostnr: "35233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "85236752",
    skolenhetensNamn: "Nöbbele skola",
    postnr: "35112",
    besökspostnr: "35574",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "12572097",
    skolenhetensNamn: "Pilbäckskolan",
    postnr: "35112",
    besökspostnr: "35245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "23757669",
    skolenhetensNamn: "Pär Lagerkvistskolan F-6",
    postnr: "35112",
    besökspostnr: "35258",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "65161034",
    skolenhetensNamn: "Ringsbergskolan",
    postnr: "35112",
    besökspostnr: "35231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "94928821",
    skolenhetensNamn: "Sandsbro skola",
    postnr: "35112",
    besökspostnr: "35272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "52780309",
    skolenhetensNamn: "Söraby skola",
    postnr: "35112",
    besökspostnr: "35243",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "46214380",
    skolenhetensNamn: "Tolg skola",
    postnr: "36342",
    besökspostnr: "35595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "12100546",
    skolenhetensNamn: "Torparskolan ",
    postnr: "35112",
    besökspostnr: "35254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "20682865",
    skolenhetensNamn: "Tävelsås skola",
    postnr: "35112",
    besökspostnr: "35236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "81237364",
    skolenhetensNamn: "Ulriksbergskolan",
    postnr: "35112",
    besökspostnr: "36253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "42434752",
    skolenhetensNamn: "Uråsa skola",
    postnr: "35112",
    besökspostnr: "35594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "41466096",
    skolenhetensNamn: "Vederslövs skola",
    postnr: "35112",
    besökspostnr: "35231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "17275630",
    skolenhetensNamn: "Växjö Internationella Grundskola F-6",
    postnr: "35245",
    besökspostnr: "35245",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "11860102",
    skolenhetensNamn: "Växjö Islamiska skola",
    postnr: "35106",
    besökspostnr: "35265",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "14959495",
    skolenhetensNamn: "Växjö Montessoriskola",
    postnr: "35235",
    besökspostnr: "35235",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "62677883",
    skolenhetensNamn: "Åby skola",
    postnr: "35112",
    besökspostnr: "36334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "37501814",
    skolenhetensNamn: "Åryds skola",
    postnr: "35112",
    besökspostnr: "35575",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "85185429",
    skolenhetensNamn: "Öjaby skola",
    postnr: "35112",
    besökspostnr: "35272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "67006679",
    skolenhetensNamn: "Ör skola",
    postnr: "35112",
    besökspostnr: "35264",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "21706410",
    skolenhetensNamn: "Östra Lugnets skola",
    postnr: "35112",
    besökspostnr: "35240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0780",
    kommunsNamn: "Växjö",
    skolenhetsKod: "81463382",
    skolenhetensNamn: "Östregårdskolan",
    postnr: "35112",
    besökspostnr: "35240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "87989137",
    skolenhetensNamn: "Agunnarydskolan",
    postnr: "34177",
    besökspostnr: "34177",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "87414531",
    skolenhetensNamn: "Angelstadskolan",
    postnr: "34194",
    besökspostnr: "34194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "83388272",
    skolenhetensNamn: "Astradskolan 1",
    postnr: "34138",
    besökspostnr: "34138",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "24113068",
    skolenhetensNamn: "Bolmsöskolan",
    postnr: "34193",
    besökspostnr: "34193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "20756773",
    skolenhetensNamn: "Ekebackenskolan",
    postnr: "34131",
    besökspostnr: "34137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "62324914",
    skolenhetensNamn: "Hamnedaskolan",
    postnr: "34013",
    besökspostnr: "34137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "40459152",
    skolenhetensNamn: "Hjortsbergskolan 2",
    postnr: "34141",
    besökspostnr: "34141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "94648037",
    skolenhetensNamn: "Kungshögsskolan 1",
    postnr: "34135",
    besökspostnr: "34135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "32676873",
    skolenhetensNamn: "Lidhults skolan",
    postnr: "34010",
    besökspostnr: "34133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "55606427",
    skolenhetensNamn: "Lingbygdens Friskola",
    postnr: "34191",
    besökspostnr: "34191",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "27091573",
    skolenhetensNamn: "Ryssbyskolan",
    postnr: "34176",
    besökspostnr: "34137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "67644450",
    skolenhetensNamn: "Stensbergskolan",
    postnr: "34133",
    besökspostnr: "34137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "16685490",
    skolenhetensNamn: "Stensbergskolan 2",
    postnr: "34133",
    besökspostnr: "34133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "59021648",
    skolenhetensNamn: "Thoren Framtid i Ljungby",
    postnr: "34137",
    besökspostnr: "34137",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "39704815",
    skolenhetensNamn: "Vittarydskolan",
    postnr: "34155",
    besökspostnr: "34141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "07",
    länsNamn: "Kronobergs län",
    kommun: "0781",
    kommunsNamn: "Ljungby",
    skolenhetsKod: "68870324",
    skolenhetensNamn: "Åbyskolan 1",
    postnr: "34151",
    besökspostnr: "34151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0821",
    kommunsNamn: "Högsby",
    skolenhetsKod: "74040447",
    skolenhetensNamn: "Fagerhults skola 1-6",
    postnr: "57933",
    besökspostnr: "57994",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0821",
    kommunsNamn: "Högsby",
    skolenhetsKod: "47445952",
    skolenhetensNamn: "Fröviskolan 1-6",
    postnr: "57933",
    besökspostnr: "57932",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0821",
    kommunsNamn: "Högsby",
    skolenhetsKod: "20587927",
    skolenhetensNamn: "Skyttefällaskolan 1-3",
    postnr: "57933",
    besökspostnr: "57940",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0821",
    kommunsNamn: "Högsby",
    skolenhetsKod: "84104026",
    skolenhetensNamn: "Tallåsskolan 1-6",
    postnr: "57933",
    besökspostnr: "57971",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0821",
    kommunsNamn: "Högsby",
    skolenhetsKod: "69535124",
    skolenhetensNamn: "Ugglemoskolan 1-6",
    postnr: "57933",
    besökspostnr: "57972",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0834",
    kommunsNamn: "Torsås",
    skolenhetsKod: "57473410",
    skolenhetensNamn: "Bergkvara skola",
    postnr: "38525",
    besökspostnr: "38540",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0834",
    kommunsNamn: "Torsås",
    skolenhetsKod: "88204666",
    skolenhetensNamn: "Frejaskolan",
    postnr: "38525",
    besökspostnr: "38525",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0834",
    kommunsNamn: "Torsås",
    skolenhetsKod: "37774645",
    skolenhetensNamn: "Gullabo skola",
    postnr: "38525",
    besökspostnr: "38525",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0834",
    kommunsNamn: "Torsås",
    skolenhetsKod: "88771304",
    skolenhetensNamn: "Söderåkra skola",
    postnr: "38525",
    besökspostnr: "38551",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0834",
    kommunsNamn: "Torsås",
    skolenhetsKod: "36154153",
    skolenhetensNamn: "Torskolan LM",
    postnr: "38525",
    besökspostnr: "38531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0840",
    kommunsNamn: "Mörbylånga",
    skolenhetsKod: "36208582",
    skolenhetensNamn: "Alunskolan",
    postnr: "38663",
    besökspostnr: "38663",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0840",
    kommunsNamn: "Mörbylånga",
    skolenhetsKod: "79591370",
    skolenhetensNamn: "Färjestadens skola",
    postnr: "38631",
    besökspostnr: "38631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0840",
    kommunsNamn: "Mörbylånga",
    skolenhetsKod: "15553220",
    skolenhetensNamn: "Glömmingeskolan",
    postnr: "38695",
    besökspostnr: "38695",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0840",
    kommunsNamn: "Mörbylånga",
    skolenhetsKod: "12496321",
    skolenhetensNamn: "Gårdbyskolan",
    postnr: "38692",
    besökspostnr: "38692",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0840",
    kommunsNamn: "Mörbylånga",
    skolenhetsKod: "36651204",
    skolenhetensNamn: "Skansenskolan",
    postnr: "38650",
    besökspostnr: "38650",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0840",
    kommunsNamn: "Mörbylånga",
    skolenhetsKod: "19041877",
    skolenhetensNamn: "Torslundaskolan",
    postnr: "38692",
    besökspostnr: "39692",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0840",
    kommunsNamn: "Mörbylånga",
    skolenhetsKod: "50029898",
    skolenhetensNamn: "Östra skolan, Mörbylånga",
    postnr: "39234",
    besökspostnr: "38631",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0860",
    kommunsNamn: "Hultsfred",
    skolenhetsKod: "74166555",
    skolenhetensNamn: "Emådalskolan",
    postnr: "57084",
    besökspostnr: "57084",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0860",
    kommunsNamn: "Hultsfred",
    skolenhetsKod: "81121604",
    skolenhetensNamn: "Järnforsens skola",
    postnr: "57081",
    besökspostnr: "57081",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0860",
    kommunsNamn: "Hultsfred",
    skolenhetsKod: "44239903",
    skolenhetensNamn: "Lindblomskolan 3",
    postnr: "57737",
    besökspostnr: "57737",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0860",
    kommunsNamn: "Hultsfred",
    skolenhetsKod: "41017199",
    skolenhetensNamn: "Målilla skola",
    postnr: "57082",
    besökspostnr: "57082",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0860",
    kommunsNamn: "Hultsfred",
    skolenhetsKod: "12289594",
    skolenhetensNamn: "Nya Centralskolan i Virserum AB",
    postnr: "57771",
    besökspostnr: "57771",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0860",
    kommunsNamn: "Hultsfred",
    skolenhetsKod: "18484321",
    skolenhetensNamn: "Silverdalens skola",
    postnr: "57751",
    besökspostnr: "57751",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0860",
    kommunsNamn: "Hultsfred",
    skolenhetsKod: "81646559",
    skolenhetensNamn: "Vena skola",
    postnr: "57790",
    besökspostnr: "57790",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0861",
    kommunsNamn: "Mönsterås",
    skolenhetsKod: "12445033",
    skolenhetensNamn: "Fliseryds skola",
    postnr: "38322",
    besökspostnr: "38473",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0861",
    kommunsNamn: "Mönsterås",
    skolenhetsKod: "91778864",
    skolenhetensNamn: "Krungårdsskolan ",
    postnr: "38322",
    besökspostnr: "38431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0861",
    kommunsNamn: "Mönsterås",
    skolenhetsKod: "94503343",
    skolenhetensNamn: "Ljungnässkolan",
    postnr: "38322",
    besökspostnr: "38334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0861",
    kommunsNamn: "Mönsterås",
    skolenhetsKod: "82087330",
    skolenhetensNamn: "Mölstadskolan",
    postnr: "38322",
    besökspostnr: "38337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0861",
    kommunsNamn: "Mönsterås",
    skolenhetsKod: "75262735",
    skolenhetensNamn: "Skytteanska skolan",
    postnr: "38322",
    besökspostnr: "38440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0861",
    kommunsNamn: "Mönsterås",
    skolenhetsKod: "72293580",
    skolenhetensNamn: "Tillingeskolan",
    postnr: "38322",
    besökspostnr: "38471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0862",
    kommunsNamn: "Emmaboda",
    skolenhetsKod: "73798677",
    skolenhetensNamn: "Bjurbäcksskolan F-6",
    postnr: "36122",
    besökspostnr: "36132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0862",
    kommunsNamn: "Emmaboda",
    skolenhetsKod: "21786908",
    skolenhetensNamn: "Johansfors skola   F-6",
    postnr: "36122",
    besökspostnr: "36142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0862",
    kommunsNamn: "Emmaboda",
    skolenhetsKod: "76954248",
    skolenhetensNamn: "Lindåsskolan  F-6",
    postnr: "36122",
    besökspostnr: "36195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0862",
    kommunsNamn: "Emmaboda",
    skolenhetsKod: "94837012",
    skolenhetensNamn: "Långasjö skola F-6",
    postnr: "36122",
    besökspostnr: "36130",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0862",
    kommunsNamn: "Emmaboda",
    skolenhetsKod: "41013207",
    skolenhetensNamn: "Vissefjärda skola F-6",
    postnr: "36122",
    besökspostnr: "36060",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "15356393",
    skolenhetensNamn: "Djurängsskolan",
    postnr: "39353",
    besökspostnr: "39353",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "99856371",
    skolenhetensNamn: "Falkenbergsskolan",
    postnr: "39244",
    besökspostnr: "39244",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "21736926",
    skolenhetensNamn: "Funkaboskolan",
    postnr: "39350",
    besökspostnr: "39350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "27272633",
    skolenhetensNamn: "Hagbyskolan",
    postnr: "38896",
    besökspostnr: "38896",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "39111157",
    skolenhetensNamn: "Halltorpsskolan",
    postnr: "38897",
    besökspostnr: "38897",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "24092221",
    skolenhetensNamn: "Kalmarsundsskolan",
    postnr: "39358",
    besökspostnr: "39358",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "16517618",
    skolenhetensNamn: "Kalmar waldorfskola",
    postnr: "39244",
    besökspostnr: "39244",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "51848797",
    skolenhetensNamn: "Lindsdalsskolorna",
    postnr: "39365",
    besökspostnr: "39365",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "63673299",
    skolenhetensNamn: "Lindöskolan",
    postnr: "39235",
    besökspostnr: "39235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "83034316",
    skolenhetensNamn: "Ljungbyholmsskolan",
    postnr: "38832",
    besökspostnr: "38832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "16372076",
    skolenhetensNamn: "Montessoriskolan Regnbågen",
    postnr: "39354",
    besökspostnr: "39354",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "43442452",
    skolenhetensNamn: "Pårydsskolan",
    postnr: "38850",
    besökspostnr: "38850",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "55238162",
    skolenhetensNamn: "Rinkabyholmsskolan",
    postnr: "39477",
    besökspostnr: "39477",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "74676128",
    skolenhetensNamn: "Rocknebyskolan",
    postnr: "39593",
    besökspostnr: "39593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "27675952",
    skolenhetensNamn: "Smedbyskolan",
    postnr: "39471",
    besökspostnr: "39471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "13936858",
    skolenhetensNamn: "Södra skolan",
    postnr: "39233",
    besökspostnr: "39233",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "30881651",
    skolenhetensNamn: "Thoren Framtid i Kalmar",
    postnr: "39238",
    besökspostnr: "39238",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "61033733",
    skolenhetensNamn: "Trekantenskolan",
    postnr: "38840",
    besökspostnr: "38840",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "76371416",
    skolenhetensNamn: "Tvärskogsskolan",
    postnr: "38893",
    besökspostnr: "38893",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "18784702",
    skolenhetensNamn: "Vasaskolan",
    postnr: "39245",
    besökspostnr: "39245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0880",
    kommunsNamn: "Kalmar",
    skolenhetsKod: "58247022",
    skolenhetensNamn: "Åbyskolan",
    postnr: "39597",
    besökspostnr: "39597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "62698200",
    skolenhetensNamn: "Alsterbro skola",
    postnr: "38273",
    besökspostnr: "38273",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "98689424",
    skolenhetensNamn: "Fagerslättsskolan Fa01",
    postnr: "38280",
    besökspostnr: "38241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "46177012",
    skolenhetensNamn: "Flerohopp skola",
    postnr: "38293",
    besökspostnr: "38293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "59700047",
    skolenhetensNamn: "Hanemålaskolan",
    postnr: "38280",
    besökspostnr: "38239",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "37839862",
    skolenhetensNamn: "Kristvallabrunn skola",
    postnr: "38291",
    besökspostnr: "38291",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "99461911",
    skolenhetensNamn: "Madesjöskolan Ma01",
    postnr: "38280",
    besökspostnr: "38245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "12864383",
    skolenhetensNamn: "Nybro Montessoriskola",
    postnr: "38236",
    besökspostnr: "38236",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "52416743",
    skolenhetensNamn: "Orrefors skola",
    postnr: "38271",
    besökspostnr: "38271",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "89082067",
    skolenhetensNamn: "Paradisskolan Pa01",
    postnr: "38280",
    besökspostnr: "38233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "77085096",
    skolenhetensNamn: "Västerängsskolan",
    postnr: "38280",
    besökspostnr: "38236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0881",
    kommunsNamn: "Nybro",
    skolenhetsKod: "26156663",
    skolenhetensNamn: "Örsjö skola",
    postnr: "38290",
    besökspostnr: "38290",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "63303871",
    skolenhetensNamn: "Bockaraskolan",
    postnr: "57228",
    besökspostnr: "57991",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "58067335",
    skolenhetensNamn: "Figeholmskolan",
    postnr: "57228",
    besökspostnr: "57275",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "87104917",
    skolenhetensNamn: "Kristdalaskolan",
    postnr: "57228",
    besökspostnr: "57091",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "38052846",
    skolenhetensNamn: "Kristinebergskolan F-9",
    postnr: "57228",
    besökspostnr: "57261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "90374935",
    skolenhetensNamn: "Misterhults friskola",
    postnr: "57295",
    besökspostnr: "57295",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "98898491",
    skolenhetensNamn: "Norra skolan F-6",
    postnr: "57228",
    besökspostnr: "57232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "42558848",
    skolenhetensNamn: "Påskallavikskolan",
    postnr: "57228",
    besökspostnr: "57274",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "55569663",
    skolenhetensNamn: "Rödsleskolan F-9",
    postnr: "57228",
    besökspostnr: "57250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "43214252",
    skolenhetensNamn: "Saltvikskolan",
    postnr: "57228",
    besökspostnr: "57292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0882",
    kommunsNamn: "Oskarshamn",
    skolenhetsKod: "65582914",
    skolenhetensNamn: "Vallhallaskolan F-9",
    postnr: "57228",
    besökspostnr: "57255",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "17348981",
    skolenhetensNamn: "Ankarsrums skola F-6",
    postnr: "59370",
    besökspostnr: "59370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "70475635",
    skolenhetensNamn: "Blackstad skola",
    postnr: "59376",
    besökspostnr: "59376",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "40704885",
    skolenhetensNamn: "Breviksskolan",
    postnr: "59342",
    besökspostnr: "59342",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "94635762",
    skolenhetensNamn: "Fågelbärets skola",
    postnr: "59354",
    besökspostnr: "59354",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "80536102",
    skolenhetensNamn: "Gamlebyvikens friskola",
    postnr: "59430",
    besökspostnr: "59432",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "42944182",
    skolenhetensNamn: "Gunnebo skola",
    postnr: "59375",
    besökspostnr: "59375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "75340680",
    skolenhetensNamn: "Himalajaskolan",
    postnr: "59373",
    besökspostnr: "59373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "62384613",
    skolenhetensNamn: "Lidhemsskolan",
    postnr: "59380",
    besökspostnr: "59350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "68921844",
    skolenhetensNamn: "Ljungbergaskolan",
    postnr: "59343",
    besökspostnr: "59343",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "23843784",
    skolenhetensNamn: "Loftahammars skola",
    postnr: "59472",
    besökspostnr: "59472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "23559267",
    skolenhetensNamn: "Marieborgsskolan",
    postnr: "59335",
    besökspostnr: "59335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "64057523",
    skolenhetensNamn: "Näktergalens skola",
    postnr: "59338",
    besökspostnr: "59338",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "19973473",
    skolenhetensNamn: "Ringeltaubska skolan",
    postnr: "59472",
    besökspostnr: "59472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "92991272",
    skolenhetensNamn: "Skogshagaskolan",
    postnr: "59341",
    besökspostnr: "59341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "37770053",
    skolenhetensNamn: "Åbyängskolan",
    postnr: "59430",
    besökspostnr: "59430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0883",
    kommunsNamn: "Västervik",
    skolenhetsKod: "35975798",
    skolenhetensNamn: "Överumsskolan",
    postnr: "59472",
    besökspostnr: "59472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "93135189",
    skolenhetensNamn: "Astrid Lindgrens skola F-6",
    postnr: "59835",
    besökspostnr: "59835",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "77167462",
    skolenhetensNamn: "Brännebro skola",
    postnr: "59873",
    besökspostnr: "59873",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "45902980",
    skolenhetensNamn: "Djursdala skola",
    postnr: "59872",
    besökspostnr: "59872",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "80984180",
    skolenhetensNamn: "Frödinge skola",
    postnr: "59895",
    besökspostnr: "59895",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "75928706",
    skolenhetensNamn: "Rumskulla skola",
    postnr: "59892",
    besökspostnr: "59892",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "47014605",
    skolenhetensNamn: "Storebro skola",
    postnr: "59870",
    besökspostnr: "59870",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "22086495",
    skolenhetensNamn: "Södra Vi skola",
    postnr: "59871",
    besökspostnr: "59871",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "55847773",
    skolenhetensNamn: "Tuna skola",
    postnr: "59896",
    besökspostnr: "59896",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0884",
    kommunsNamn: "Vimmerby",
    skolenhetsKod: "56813816",
    skolenhetensNamn: "Vimarskolan F-6",
    postnr: "59835",
    besökspostnr: "59835",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0885",
    kommunsNamn: "Borgholm",
    skolenhetsKod: "74710343",
    skolenhetensNamn: "Gärdslösa skola",
    postnr: "38793",
    besökspostnr: "38793",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0885",
    kommunsNamn: "Borgholm",
    skolenhetsKod: "64896318",
    skolenhetensNamn: "Köpings skola",
    postnr: "38701",
    besökspostnr: "38750",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0885",
    kommunsNamn: "Borgholm",
    skolenhetsKod: "23868656",
    skolenhetensNamn: "Rälla skola",
    postnr: "38792",
    besökspostnr: "38792",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0885",
    kommunsNamn: "Borgholm",
    skolenhetsKod: "42934439",
    skolenhetensNamn: "Viktoriaskolan",
    postnr: "38701",
    besökspostnr: "38732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "08",
    länsNamn: "Kalmar län",
    kommun: "0885",
    kommunsNamn: "Borgholm",
    skolenhetsKod: "82520504",
    skolenhetensNamn: "Åkerboskolan",
    postnr: "38772",
    besökspostnr: "38772",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "89321968",
    skolenhetensNamn: "Dalhem skola",
    postnr: "62258",
    besökspostnr: "62258",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "96366802",
    skolenhetensNamn: "Endre skola",
    postnr: "62177",
    besökspostnr: "62177",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "64449160",
    skolenhetensNamn: "Eskelhem skola",
    postnr: "62270",
    besökspostnr: "62270",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "19881184",
    skolenhetensNamn: "Fole skola",
    postnr: "62175",
    besökspostnr: "62175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "68188627",
    skolenhetensNamn: "Fårösundsskolan",
    postnr: "62462",
    besökspostnr: "62462",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "81394787",
    skolenhetensNamn: "Garda skola",
    postnr: "62363",
    besökspostnr: "62363",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "72009712",
    skolenhetensNamn: "Gråboskolan",
    postnr: "62181",
    besökspostnr: "62151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "42518434",
    skolenhetensNamn: "Guteskolan, grundskola",
    postnr: "62140",
    besökspostnr: "62140",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "17588092",
    skolenhetensNamn: "Havdhem skola",
    postnr: "62316",
    besökspostnr: "62316",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "49148021",
    skolenhetensNamn: "Humlegårdsskolan",
    postnr: "62181",
    besökspostnr: "62145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "94163432",
    skolenhetensNamn: "Högbyskolan",
    postnr: "62350",
    besökspostnr: "62350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "52229866",
    skolenhetensNamn: "Innovitaskolan Visby",
    postnr: "62125",
    besökspostnr: "62150",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "24429776",
    skolenhetensNamn: "Klinteskolan",
    postnr: "62377",
    besökspostnr: "62377",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "47799551",
    skolenhetensNamn: "Kräklingbo skola",
    postnr: "62370",
    besökspostnr: "62370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "55052496",
    skolenhetensNamn: "Lyckåkerskolan",
    postnr: "62181",
    besökspostnr: "62149",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "12113282",
    skolenhetensNamn: "Lärbro skola",
    postnr: "62452",
    besökspostnr: "62452",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "26322884",
    skolenhetensNamn: "Montessori Friskola Gotland",
    postnr: "62123",
    besökspostnr: "62138",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "16160425",
    skolenhetensNamn: "Norrbackaskolan",
    postnr: "62181",
    besökspostnr: "62141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "27928506",
    skolenhetensNamn: "Orionskolan",
    postnr: "62138",
    besökspostnr: "62138",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "64909736",
    skolenhetensNamn: "Polhemskolan",
    postnr: "62139",
    besökspostnr: "62139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "60700608",
    skolenhetensNamn: "Romaskolan",
    postnr: "62254",
    besökspostnr: "62254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "48487229",
    skolenhetensNamn: "Sanda skola",
    postnr: "62379",
    besökspostnr: "62379",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "10979668",
    skolenhetensNamn: "Solklintsskolan",
    postnr: "62448",
    besökspostnr: "62448",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "66967867",
    skolenhetensNamn: "Stenkyrka skola",
    postnr: "62442",
    besökspostnr: "62442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "92605941",
    skolenhetensNamn: "S:t Hansskolan",
    postnr: "62181",
    besökspostnr: "62156",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "88965786",
    skolenhetensNamn: "Stånga skola",
    postnr: "62360",
    besökspostnr: "62360",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "27591331",
    skolenhetensNamn: "Terra Novaskolan",
    postnr: "62181",
    besökspostnr: "62153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "40341856",
    skolenhetensNamn: "Tjelvar Resursskola F-3",
    postnr: "62182",
    besökspostnr: "62182",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "52791160",
    skolenhetensNamn: "Tjelvarskolan",
    postnr: "62181",
    besökspostnr: "62143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "41120027",
    skolenhetensNamn: "Vänge skola",
    postnr: "62236",
    besökspostnr: "62236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "15459377",
    skolenhetensNamn: "Väskinde skola",
    postnr: "62275",
    besökspostnr: "62275",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "46114318",
    skolenhetensNamn: "Västerhejde skola",
    postnr: "62181",
    besökspostnr: "62261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "09",
    länsNamn: "Gotlands län",
    kommun: "0980",
    kommunsNamn: "Gotland",
    skolenhetsKod: "37203128",
    skolenhetensNamn: "Öja skola",
    postnr: "62335",
    besökspostnr: "62335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1060",
    kommunsNamn: "Olofström",
    skolenhetsKod: "37539722",
    skolenhetensNamn: "Brännaregårdsskolan",
    postnr: "29335",
    besökspostnr: "29335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1060",
    kommunsNamn: "Olofström",
    skolenhetsKod: "83914063",
    skolenhetensNamn: "Gränums skola",
    postnr: "29324",
    besökspostnr: "29393",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1060",
    kommunsNamn: "Olofström",
    skolenhetsKod: "81995218",
    skolenhetensNamn: "Jämshögs skola",
    postnr: "29324",
    besökspostnr: "29372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1060",
    kommunsNamn: "Olofström",
    skolenhetsKod: "14765915",
    skolenhetensNamn: "Kyrkhults skola",
    postnr: "29374",
    besökspostnr: "29374",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1060",
    kommunsNamn: "Olofström",
    skolenhetsKod: "12015531",
    skolenhetensNamn: "Vilboksskolan",
    postnr: "29324",
    besökspostnr: "29338",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "20767970",
    skolenhetensNamn: "Aspöskolan F-6",
    postnr: "37022",
    besökspostnr: "37022",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "76776855",
    skolenhetensNamn: "Bastasjö Friskola AB",
    postnr: "37152",
    besökspostnr: "37152",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "93964634",
    skolenhetensNamn: "Friskolan Svettpärlan",
    postnr: "37154",
    besökspostnr: "37154",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "52201227",
    skolenhetensNamn: "Fågelmaraskolan F-6",
    postnr: "37300",
    besökspostnr: "37163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "57420772",
    skolenhetensNamn: "Hasslöskolan F-6",
    postnr: "37023",
    besökspostnr: "37300",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "55030330",
    skolenhetensNamn: "Holmsjöskolan F-6",
    postnr: "37030",
    besökspostnr: "37300",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "88401011",
    skolenhetensNamn: "Hästöskolan F-6",
    postnr: "37142",
    besökspostnr: "37142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "10662754",
    skolenhetensNamn: "Innovitaskolan Karlskrona",
    postnr: "37155",
    besökspostnr: "37155",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "22763731",
    skolenhetensNamn: "International School of Karlskrona",
    postnr: "37142",
    besökspostnr: "37142",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "97495074",
    skolenhetensNamn: "Jämjö kunskapscentrum F-9",
    postnr: "37183",
    besökspostnr: "37372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "67509465",
    skolenhetensNamn: "Karlskrona Montessorifriskola",
    postnr: "37154",
    besökspostnr: "37154",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "35751509",
    skolenhetensNamn: "Kättilsmålaskolan F-6",
    postnr: "37193",
    besökspostnr: "37024",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "55709634",
    skolenhetensNamn: "Lyckeby kunskapscentrum F-9",
    postnr: "37163",
    besökspostnr: "37163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "93919365",
    skolenhetensNamn: "Musikugglan",
    postnr: "37156",
    besökspostnr: "37156",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "76323160",
    skolenhetensNamn: "Nättraby kunskapscentrum F-9",
    postnr: "37330",
    besökspostnr: "37330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "99469094",
    skolenhetensNamn: "Piggelinen",
    postnr: "37155",
    besökspostnr: "37155",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "51415031",
    skolenhetensNamn: "Ramdalaskolan F-6",
    postnr: "37352",
    besökspostnr: "37352",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "12935859",
    skolenhetensNamn: "Rosenfeldtskolan F-6",
    postnr: "37131",
    besökspostnr: "37137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "32421136",
    skolenhetensNamn: "Rödebyskolan F-9",
    postnr: "37342",
    besökspostnr: "37342",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "37053461",
    skolenhetensNamn: "Saltöskolan F-6",
    postnr: "37137",
    besökspostnr: "37144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "58982564",
    skolenhetensNamn: "Spandelstorpskolan F-6",
    postnr: "37153",
    besökspostnr: "37163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "58881130",
    skolenhetensNamn: "Strömsbergs Friskola",
    postnr: "37371",
    besökspostnr: "37344",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "55592897",
    skolenhetensNamn: "Sturköskolan F-6",
    postnr: "37300",
    besökspostnr: "37361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "21608206",
    skolenhetensNamn: "Sunnadalskolan F-9",
    postnr: "37183",
    besökspostnr: "37163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "22552676",
    skolenhetensNamn: "Torhamnskolan F-6",
    postnr: "37300",
    besökspostnr: "37371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "14388216",
    skolenhetensNamn: "Tullskolan F-6",
    postnr: "37139",
    besökspostnr: "37139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "96392396",
    skolenhetensNamn: "Tvingskola F-2",
    postnr: "37033",
    besökspostnr: "37033",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "95695515",
    skolenhetensNamn: "Verköskolan F-2",
    postnr: "37163",
    besökspostnr: "37160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1080",
    kommunsNamn: "Karlskrona",
    skolenhetsKod: "28023411",
    skolenhetensNamn: "Östra Torpskolan F-6",
    postnr: "37160",
    besökspostnr: "37160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "56938498",
    skolenhetensNamn: "Backarydsskolan",
    postnr: "37277",
    besökspostnr: "37277",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "73174754",
    skolenhetensNamn: "Eringsbodaskolan",
    postnr: "37278",
    besökspostnr: "37278",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "22033858",
    skolenhetensNamn: "Espedalsskolan",
    postnr: "37233",
    besökspostnr: "37233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "79063974",
    skolenhetensNamn: "Fredriksbergsskolan",
    postnr: "37237",
    besökspostnr: "37237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "71629249",
    skolenhetensNamn: "G-klavens fristående skola",
    postnr: "37230",
    besökspostnr: "37230",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "11842600",
    skolenhetensNamn: "Hallabroskolan",
    postnr: "37276",
    besökspostnr: "37276",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "67089636",
    skolenhetensNamn: "Hobyskolan",
    postnr: "37260",
    besökspostnr: "37260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "71423952",
    skolenhetensNamn: "Johannishusskolan",
    postnr: "37295",
    besökspostnr: "37295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "71592444",
    skolenhetensNamn: "Kallingeskolan F-6",
    postnr: "37252",
    besökspostnr: "37252",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "89240233",
    skolenhetensNamn: "Listerbyskolan",
    postnr: "37274",
    besökspostnr: "37274",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "11927362",
    skolenhetensNamn: "Saxemaraskolan",
    postnr: "37297",
    besökspostnr: "37297",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "67037141",
    skolenhetensNamn: "Slättagårdsskolan",
    postnr: "37253",
    besökspostnr: "37253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1081",
    kommunsNamn: "Ronneby",
    skolenhetsKod: "38449858",
    skolenhetensNamn: "Thoren Framtid i Ronneby",
    postnr: "37238",
    besökspostnr: "37225",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "83020943",
    skolenhetensNamn: "Bodestorpsskolan",
    postnr: "37481",
    besökspostnr: "37437",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "75372005",
    skolenhetensNamn: "Hällaryds skola",
    postnr: "37481",
    besökspostnr: "37453",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "23032196",
    skolenhetensNamn: "Karlshamns Montessoriskola",
    postnr: "37432",
    besökspostnr: "37432",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "64496478",
    skolenhetensNamn: "Klockebacksskolan",
    postnr: "37481",
    besökspostnr: "37453",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "21244566",
    skolenhetensNamn: "Korpadalsskolan",
    postnr: "37481",
    besökspostnr: "37635",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "47955009",
    skolenhetensNamn: "Möllegårdens skola",
    postnr: "37481",
    besökspostnr: "37533",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "37589444",
    skolenhetensNamn: "Mörrums skola",
    postnr: "37481",
    besökspostnr: "37440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "11703307",
    skolenhetensNamn: "Prästslättsskolan",
    postnr: "37481",
    besökspostnr: "37453",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "83380643",
    skolenhetensNamn: "Sternöskolan",
    postnr: "37481",
    besökspostnr: "37430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "22909101",
    skolenhetensNamn: "Waldorfskolan i Karlshamn",
    postnr: "37441",
    besökspostnr: "37441",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1082",
    kommunsNamn: "Karlshamn",
    skolenhetsKod: "45295878",
    skolenhetensNamn: "Åryds Friskola",
    postnr: "37496",
    besökspostnr: "37496",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1083",
    kommunsNamn: "Sölvesborg",
    skolenhetsKod: "18846309",
    skolenhetensNamn: "Falkviksskolan",
    postnr: "29435",
    besökspostnr: "29435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1083",
    kommunsNamn: "Sölvesborg",
    skolenhetsKod: "22379655",
    skolenhetensNamn: "Havelidens skola",
    postnr: "29472",
    besökspostnr: "29472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1083",
    kommunsNamn: "Sölvesborg",
    skolenhetsKod: "49771353",
    skolenhetensNamn: "Hjortakroksskolan",
    postnr: "29438",
    besökspostnr: "29438",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1083",
    kommunsNamn: "Sölvesborg",
    skolenhetsKod: "22544999",
    skolenhetensNamn: "Högtofta skola",
    postnr: "29493",
    besökspostnr: "29493",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1083",
    kommunsNamn: "Sölvesborg",
    skolenhetsKod: "39598101",
    skolenhetensNamn: "Hörviks skola",
    postnr: "29474",
    besökspostnr: "29474",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1083",
    kommunsNamn: "Sölvesborg",
    skolenhetsKod: "88104795",
    skolenhetensNamn: "Mjällby skola",
    postnr: "29471",
    besökspostnr: "29471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1083",
    kommunsNamn: "Sölvesborg",
    skolenhetsKod: "44972693",
    skolenhetensNamn: "Möllebacksskolan",
    postnr: "29434",
    besökspostnr: "29434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "10",
    länsNamn: "Blekinge län",
    kommun: "1083",
    kommunsNamn: "Sölvesborg",
    skolenhetsKod: "80918950",
    skolenhetensNamn: "Norje skola",
    postnr: "29476",
    besökspostnr: "29476",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1214",
    kommunsNamn: "Svalöv",
    skolenhetsKod: "47848303",
    skolenhetensNamn: "Billeshögsskolan",
    postnr: "26873",
    besökspostnr: "26873",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1214",
    kommunsNamn: "Svalöv",
    skolenhetsKod: "70133882",
    skolenhetensNamn: "Heleneborgsskolan ",
    postnr: "26821",
    besökspostnr: "26831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1214",
    kommunsNamn: "Svalöv",
    skolenhetsKod: "58166048",
    skolenhetensNamn: "Lunnaskolan",
    postnr: "26877",
    besökspostnr: "26877",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1214",
    kommunsNamn: "Svalöv",
    skolenhetsKod: "58170493",
    skolenhetensNamn: "Midgårds skola",
    postnr: "26868",
    besökspostnr: "26868",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1214",
    kommunsNamn: "Svalöv",
    skolenhetsKod: "77441364",
    skolenhetensNamn: "Parkskolan",
    postnr: "26872",
    besökspostnr: "26872",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1214",
    kommunsNamn: "Svalöv",
    skolenhetsKod: "58981647",
    skolenhetensNamn: "Svalövs Montessoriskola",
    postnr: "26822",
    besökspostnr: "26831",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "49039496",
    skolenhetensNamn: "Baldersskolan F-6",
    postnr: "24532",
    besökspostnr: "24532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "61151036",
    skolenhetensNamn: "Gullåkraskolan",
    postnr: "24580",
    besökspostnr: "24537",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "27411228",
    skolenhetensNamn: "Hjärups skola",
    postnr: "24562",
    besökspostnr: "24562",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "68467205",
    skolenhetensNamn: "Internationella Engelska Skolan Staffanstorp",
    postnr: "24545",
    besökspostnr: "24545",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "82149276",
    skolenhetensNamn: "Kyrkheddinge skola",
    postnr: "24580",
    besökspostnr: "24592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "81566694",
    skolenhetensNamn: "Mellanvångsskolan",
    postnr: "24580",
    besökspostnr: "24541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "49038465",
    skolenhetensNamn: "Staffanstorps Montessoriskola",
    postnr: "24541",
    besökspostnr: "24541",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "63526897",
    skolenhetensNamn: "Stanstorpsskolan",
    postnr: "24580",
    besökspostnr: "24541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "79472428",
    skolenhetensNamn: "Tottarps skola",
    postnr: "24580",
    besökspostnr: "24593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1230",
    kommunsNamn: "Staffanstorp",
    skolenhetsKod: "20722895",
    skolenhetensNamn: "Uppåkraskolan",
    postnr: "24564",
    besökspostnr: "24564",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1231",
    kommunsNamn: "Burlöv",
    skolenhetsKod: "63471148",
    skolenhetensNamn: "Dalslundskolan F-3",
    postnr: "23221",
    besökspostnr: "23253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1231",
    kommunsNamn: "Burlöv",
    skolenhetsKod: "12436715",
    skolenhetensNamn: "Humlemadskolan",
    postnr: "23221",
    besökspostnr: "23234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1231",
    kommunsNamn: "Burlöv",
    skolenhetsKod: "55734921",
    skolenhetensNamn: "Svanetorpskolan",
    postnr: "23221",
    besökspostnr: "23253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1231",
    kommunsNamn: "Burlöv",
    skolenhetsKod: "82378508",
    skolenhetensNamn: "Svenshögskolan",
    postnr: "23221",
    besökspostnr: "23235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1231",
    kommunsNamn: "Burlöv",
    skolenhetsKod: "64830407",
    skolenhetensNamn: "Södervångskolan",
    postnr: "23221",
    besökspostnr: "23234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1231",
    kommunsNamn: "Burlöv",
    skolenhetsKod: "34882305",
    skolenhetensNamn: "Tågarpskolan",
    postnr: "23221",
    besökspostnr: "23235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "15068529",
    skolenhetensNamn: "Framtidskompassen i Vellinge",
    postnr: "23533",
    besökspostnr: "23533",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "44979671",
    skolenhetensNamn: "Framtidskomp. V Ingelstad sk",
    postnr: "23541",
    besökspostnr: "23541",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "17970468",
    skolenhetensNamn: "Henriksdalskolan",
    postnr: "23633",
    besökspostnr: "23633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "16650439",
    skolenhetensNamn: "Herrestorpskolan",
    postnr: "23537",
    besökspostnr: "23537",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "56103239",
    skolenhetensNamn: "Håslövs byaskola",
    postnr: "23331",
    besökspostnr: "23591",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "10938309",
    skolenhetensNamn: "Hököpinge skola",
    postnr: "23537",
    besökspostnr: "23537",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "41197162",
    skolenhetensNamn: "Ljungenskolan",
    postnr: "23642",
    besökspostnr: "23642",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "71367825",
    skolenhetensNamn: "Sandeplanskolan",
    postnr: "23633",
    besökspostnr: "23633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "46725455",
    skolenhetensNamn: "Skanör Falsterbo Montessorisk.",
    postnr: "23941",
    besökspostnr: "23941",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "46945514",
    skolenhetensNamn: "Skanörs skola",
    postnr: "23931",
    besökspostnr: "23931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "24181728",
    skolenhetensNamn: "Stora Hammars skola",
    postnr: "23632",
    besökspostnr: "23632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "28023150",
    skolenhetensNamn: "Södervångskolan",
    postnr: "23522",
    besökspostnr: "23535",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "58994715",
    skolenhetensNamn: "Tångvallaskolan",
    postnr: "23936",
    besökspostnr: "23936",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1233",
    kommunsNamn: "Vellinge",
    skolenhetsKod: "11885832",
    skolenhetensNamn: "Ängdalaskolan",
    postnr: "23691",
    besökspostnr: "23691",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1256",
    kommunsNamn: "Östra Göinge",
    skolenhetsKod: "26599013",
    skolenhetensNamn: "Färe Montessoriskola",
    postnr: "28906",
    besökspostnr: "28971",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1256",
    kommunsNamn: "Östra Göinge",
    skolenhetsKod: "95828750",
    skolenhetensNamn: "Glimåkraskolan",
    postnr: "28903",
    besökspostnr: "28946",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1256",
    kommunsNamn: "Östra Göinge",
    skolenhetsKod: "87913800",
    skolenhetensNamn: "Kviinge skola",
    postnr: "28903",
    besökspostnr: "28950",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1256",
    kommunsNamn: "Östra Göinge",
    skolenhetsKod: "40044299",
    skolenhetensNamn: "Mölleskolan",
    postnr: "28903",
    besökspostnr: "28972",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1256",
    kommunsNamn: "Östra Göinge",
    skolenhetsKod: "22701498",
    skolenhetensNamn: "Prästavångsskolan F-3",
    postnr: "28903",
    besökspostnr: "28941",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1256",
    kommunsNamn: "Östra Göinge",
    skolenhetsKod: "48978921",
    skolenhetensNamn: "Västerskolan",
    postnr: "28903",
    besökspostnr: "28931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1257",
    kommunsNamn: "Örkelljunga",
    skolenhetsKod: "45573931",
    skolenhetensNamn: "Beringskolan",
    postnr: "28680",
    besökspostnr: "28634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1257",
    kommunsNamn: "Örkelljunga",
    skolenhetsKod: "75730222",
    skolenhetensNamn: "Bokelundaskolan",
    postnr: "28673",
    besökspostnr: "28673",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1257",
    kommunsNamn: "Örkelljunga",
    skolenhetsKod: "68313632",
    skolenhetensNamn: "Ekets skola",
    postnr: "28695",
    besökspostnr: "28634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1257",
    kommunsNamn: "Örkelljunga",
    skolenhetsKod: "13496497",
    skolenhetensNamn: "Mårdenskolan",
    postnr: "28680",
    besökspostnr: "28692",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1257",
    kommunsNamn: "Örkelljunga",
    skolenhetsKod: "10757780",
    skolenhetensNamn: "Solängskolan",
    postnr: "28692",
    besökspostnr: "28692",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1257",
    kommunsNamn: "Örkelljunga",
    skolenhetsKod: "98085936",
    skolenhetensNamn: "von Reisers skola",
    postnr: "28672",
    besökspostnr: "28634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1260",
    kommunsNamn: "Bjuv",
    skolenhetsKod: "74696775",
    skolenhetensNamn: "Brogårdaskolan",
    postnr: "26733",
    besökspostnr: "26733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1260",
    kommunsNamn: "Bjuv",
    skolenhetsKod: "56636046",
    skolenhetensNamn: "Ekeby skola",
    postnr: "26775",
    besökspostnr: "26775",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1260",
    kommunsNamn: "Bjuv",
    skolenhetsKod: "63754384",
    skolenhetensNamn: "Jens Billeskolan",
    postnr: "26771",
    besökspostnr: "26771",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1260",
    kommunsNamn: "Bjuv",
    skolenhetsKod: "10419361",
    skolenhetensNamn: "Varagårdsskolan ",
    postnr: "26740",
    besökspostnr: "26740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "82593738",
    skolenhetensNamn: "Annelundsskolan",
    postnr: "24438",
    besökspostnr: "24438",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "22957852",
    skolenhetensNamn: "Barsebäcks Montessoriskola",
    postnr: "24657",
    besökspostnr: "24657",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "26281194",
    skolenhetensNamn: "Dösjebroskolan",
    postnr: "24471",
    besökspostnr: "24471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "27951396",
    skolenhetensNamn: "Lackalängaskolan",
    postnr: "24465",
    besökspostnr: "24494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "66829136",
    skolenhetensNamn: "Ljungenskolan",
    postnr: "24465",
    besökspostnr: "24465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "19504051",
    skolenhetensNamn: "Noblaskolan Löddeköpinge",
    postnr: "24635",
    besökspostnr: "24635",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "23990749",
    skolenhetensNamn: "Nyvångsskolan",
    postnr: "24630",
    besökspostnr: "24630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "54902755",
    skolenhetensNamn: "Olympiaskolan",
    postnr: "24438",
    besökspostnr: "24438",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "56540171",
    skolenhetensNamn: "Rinnebäcksskolan",
    postnr: "24432",
    besökspostnr: "24432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "26357566",
    skolenhetensNamn: "Skönadalsskolan",
    postnr: "24650",
    besökspostnr: "24650",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1261",
    kommunsNamn: "Kävlinge",
    skolenhetsKod: "48891355",
    skolenhetensNamn: "Söderparksskolan",
    postnr: "24635",
    besökspostnr: "24635",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "20557692",
    skolenhetensNamn: "Alfredshällskolan",
    postnr: "23736",
    besökspostnr: "23736",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "41254600",
    skolenhetensNamn: "Bjärehovskolan",
    postnr: "23734",
    besökspostnr: "23734",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "96905508",
    skolenhetensNamn: "Fladängskolan",
    postnr: "23732",
    besökspostnr: "23732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "83616080",
    skolenhetensNamn: "Karstorpskolan Norra",
    postnr: "23443",
    besökspostnr: "23443",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "58076252",
    skolenhetensNamn: "Löddesnässkolan",
    postnr: "23732",
    besökspostnr: "23732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "52025747",
    skolenhetensNamn: "Montessori Bjerred",
    postnr: "23741",
    besökspostnr: "23741",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "15610637",
    skolenhetensNamn: "Pilängskolan",
    postnr: "23431",
    besökspostnr: "23431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "19570704",
    skolenhetensNamn: "Rutsborgskolan",
    postnr: "23741",
    besökspostnr: "23741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1262",
    kommunsNamn: "Lomma",
    skolenhetsKod: "26824650",
    skolenhetensNamn: "Strandskolan",
    postnr: "23433",
    besökspostnr: "23433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1263",
    kommunsNamn: "Svedala",
    skolenhetsKod: "95451533",
    skolenhetensNamn: "Baraskolan F-3",
    postnr: "23361",
    besökspostnr: "23361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1263",
    kommunsNamn: "Svedala",
    skolenhetsKod: "84580153",
    skolenhetensNamn: "I Ur och Skur Skabersjöskolan",
    postnr: "23392",
    besökspostnr: "23392",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1263",
    kommunsNamn: "Svedala",
    skolenhetsKod: "51392146",
    skolenhetensNamn: "Klågerupskolan F-9",
    postnr: "23375",
    besökspostnr: "23375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1263",
    kommunsNamn: "Svedala",
    skolenhetsKod: "36013016",
    skolenhetensNamn: "Kyrkskolan F-3",
    postnr: "23342",
    besökspostnr: "23342",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1263",
    kommunsNamn: "Svedala",
    skolenhetsKod: "43569718",
    skolenhetensNamn: "Marbäcksskolan F-3",
    postnr: "23333",
    besökspostnr: "23333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1263",
    kommunsNamn: "Svedala",
    skolenhetsKod: "46967539",
    skolenhetensNamn: "Roslättsskolan F-3",
    postnr: "23337",
    besökspostnr: "23337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1264",
    kommunsNamn: "Skurup",
    skolenhetsKod: "60217642",
    skolenhetensNamn: "Alléskolan",
    postnr: "27435",
    besökspostnr: "27435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1264",
    kommunsNamn: "Skurup",
    skolenhetsKod: "19459738",
    skolenhetensNamn: "Mölleskolan",
    postnr: "27451",
    besökspostnr: "27451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1264",
    kommunsNamn: "Skurup",
    skolenhetsKod: "51173156",
    skolenhetensNamn: "Rutgerskolan",
    postnr: "27434",
    besökspostnr: "27434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1264",
    kommunsNamn: "Skurup",
    skolenhetsKod: "49036576",
    skolenhetensNamn: "Rydsgårdsskolan",
    postnr: "27461",
    besökspostnr: "27461",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1264",
    kommunsNamn: "Skurup",
    skolenhetsKod: "93204806",
    skolenhetensNamn: "Slimmingeby skola",
    postnr: "27492",
    besökspostnr: "27492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1264",
    kommunsNamn: "Skurup",
    skolenhetsKod: "93838287",
    skolenhetensNamn: "Östergårdsskolan",
    postnr: "27436",
    besökspostnr: "27436",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1265",
    kommunsNamn: "Sjöbo",
    skolenhetsKod: "37998000",
    skolenhetensNamn: "Emanuelskolan",
    postnr: "27580",
    besökspostnr: "27532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1265",
    kommunsNamn: "Sjöbo",
    skolenhetsKod: "75930521",
    skolenhetensNamn: "Friskolan Boken",
    postnr: "27562",
    besökspostnr: "27562",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1265",
    kommunsNamn: "Sjöbo",
    skolenhetsKod: "12191224",
    skolenhetensNamn: "Lövestads skola",
    postnr: "27580",
    besökspostnr: "27571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1265",
    kommunsNamn: "Sjöbo",
    skolenhetsKod: "34081848",
    skolenhetensNamn: "Montessoriskolan Fyrkappan",
    postnr: "27571",
    besökspostnr: "27571",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1265",
    kommunsNamn: "Sjöbo",
    skolenhetsKod: "33857264",
    skolenhetensNamn: "Nils Månssons Friskola",
    postnr: "27568",
    besökspostnr: "27568",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1265",
    kommunsNamn: "Sjöbo",
    skolenhetsKod: "47897364",
    skolenhetensNamn: "Sandbäcksskolan",
    postnr: "27580",
    besökspostnr: "27533",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1265",
    kommunsNamn: "Sjöbo",
    skolenhetsKod: "55675413",
    skolenhetensNamn: "Storkskolan",
    postnr: "27580",
    besökspostnr: "27561",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1265",
    kommunsNamn: "Sjöbo",
    skolenhetsKod: "98635885",
    skolenhetensNamn: "Vollsjö skola",
    postnr: "27580",
    besökspostnr: "27566",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1266",
    kommunsNamn: "Hörby",
    skolenhetsKod: "47853283",
    skolenhetensNamn: "Frostaskolan F-6",
    postnr: "24232",
    besökspostnr: "24232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1266",
    kommunsNamn: "Hörby",
    skolenhetsKod: "71588741",
    skolenhetensNamn: "Georgshillsskolan F-6",
    postnr: "24233",
    besökspostnr: "24233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1266",
    kommunsNamn: "Hörby",
    skolenhetsKod: "77977024",
    skolenhetensNamn: "Killhult skola",
    postnr: "24295",
    besökspostnr: "24295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1266",
    kommunsNamn: "Hörby",
    skolenhetsKod: "52665905",
    skolenhetensNamn: "Ludvigsborgs friskola",
    postnr: "24271",
    besökspostnr: "24271",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1266",
    kommunsNamn: "Hörby",
    skolenhetsKod: "35287613",
    skolenhetensNamn: "Långaröd skola",
    postnr: "24234",
    besökspostnr: "24296",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1266",
    kommunsNamn: "Hörby",
    skolenhetsKod: "82471875",
    skolenhetensNamn: "Älvdalsskolan",
    postnr: "24234",
    besökspostnr: "24234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1266",
    kommunsNamn: "Hörby",
    skolenhetsKod: "55629703",
    skolenhetensNamn: "Östraby skola",
    postnr: "24297",
    besökspostnr: "24297",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1267",
    kommunsNamn: "Höör",
    skolenhetsKod: "56948895",
    skolenhetensNamn: "Edens skola",
    postnr: "24330",
    besökspostnr: "24330",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1267",
    kommunsNamn: "Höör",
    skolenhetsKod: "63846783",
    skolenhetensNamn: "Emiliaskolan Höörs waldorfsk.",
    postnr: "24393",
    besökspostnr: "24393",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1267",
    kommunsNamn: "Höör",
    skolenhetsKod: "63279573",
    skolenhetensNamn: "Enebackeskolan",
    postnr: "24321",
    besökspostnr: "24330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1267",
    kommunsNamn: "Höör",
    skolenhetsKod: "63671158",
    skolenhetensNamn: "Gudmuntorps skola",
    postnr: "24395",
    besökspostnr: "24395",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1267",
    kommunsNamn: "Höör",
    skolenhetsKod: "77120985",
    skolenhetensNamn: "Kubelidens Montessoriskola",
    postnr: "24322",
    besökspostnr: "24331",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1267",
    kommunsNamn: "Höör",
    skolenhetsKod: "95024923",
    skolenhetensNamn: "Segrande Liv Grundskola",
    postnr: "24392",
    besökspostnr: "24392",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1267",
    kommunsNamn: "Höör",
    skolenhetsKod: "35670754",
    skolenhetensNamn: "Sätoftaskolan",
    postnr: "24321",
    besökspostnr: "24335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1267",
    kommunsNamn: "Höör",
    skolenhetsKod: "64327803",
    skolenhetensNamn: "Tjörnarps skola",
    postnr: "24321",
    besökspostnr: "24372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1270",
    kommunsNamn: "Tomelilla",
    skolenhetsKod: "29868932",
    skolenhetensNamn: "Brösarps skola",
    postnr: "27380",
    besökspostnr: "27350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1270",
    kommunsNamn: "Tomelilla",
    skolenhetsKod: "48048407",
    skolenhetensNamn: "Byavångsskolan",
    postnr: "27380",
    besökspostnr: "27334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1270",
    kommunsNamn: "Tomelilla",
    skolenhetsKod: "90664546",
    skolenhetensNamn: "Lindesborgsskolan",
    postnr: "27380",
    besökspostnr: "27333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1270",
    kommunsNamn: "Tomelilla",
    skolenhetsKod: "13631556",
    skolenhetensNamn: "Odenslundsskolan",
    postnr: "27380",
    besökspostnr: "27395",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1270",
    kommunsNamn: "Tomelilla",
    skolenhetsKod: "20138309",
    skolenhetensNamn: "Smedstorps skola",
    postnr: "27380",
    besökspostnr: "27398",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1270",
    kommunsNamn: "Tomelilla",
    skolenhetsKod: "15210966",
    skolenhetensNamn: "Tryde Friskola",
    postnr: "27397",
    besökspostnr: "27397",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1272",
    kommunsNamn: "Bromölla",
    skolenhetsKod: "55163663",
    skolenhetensNamn: "Alvikenskolan",
    postnr: "29535",
    besökspostnr: "29535",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1272",
    kommunsNamn: "Bromölla",
    skolenhetsKod: "87987096",
    skolenhetensNamn: "Dalaskolan Norra",
    postnr: "29532",
    besökspostnr: "29532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1272",
    kommunsNamn: "Bromölla",
    skolenhetsKod: "37658022",
    skolenhetensNamn: "Edenryds skola",
    postnr: "29591",
    besökspostnr: "29591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1272",
    kommunsNamn: "Bromölla",
    skolenhetsKod: "67942007",
    skolenhetensNamn: "Gualövs skola",
    postnr: "29572",
    besökspostnr: "29572",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1272",
    kommunsNamn: "Bromölla",
    skolenhetsKod: "43549980",
    skolenhetensNamn: "Näsums skola",
    postnr: "29574",
    besökspostnr: "29574",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1273",
    kommunsNamn: "Osby",
    skolenhetsKod: "21621712",
    skolenhetensNamn: "Hasslarödsskolan F-6",
    postnr: "28380",
    besökspostnr: "28333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1273",
    kommunsNamn: "Osby",
    skolenhetsKod: "54518621",
    skolenhetensNamn: "Killebergskolan F-6",
    postnr: "28380",
    besökspostnr: "28376",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1273",
    kommunsNamn: "Osby",
    skolenhetsKod: "67170282",
    skolenhetensNamn: "Klockarskogsskolan F-3 ",
    postnr: "28380",
    besökspostnr: "28334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1273",
    kommunsNamn: "Osby",
    skolenhetsKod: "70332017",
    skolenhetensNamn: "Parkskolan F-6",
    postnr: "28380",
    besökspostnr: "28341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1273",
    kommunsNamn: "Osby",
    skolenhetsKod: "21002578",
    skolenhetensNamn: "Örkenedskolan F-9",
    postnr: "28302",
    besökspostnr: "28372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1275",
    kommunsNamn: "Perstorp",
    skolenhetsKod: "60288966",
    skolenhetensNamn: "Oderljunga skola",
    postnr: "28485",
    besökspostnr: "28492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1275",
    kommunsNamn: "Perstorp",
    skolenhetsKod: "54638535",
    skolenhetensNamn: "Parkskolan",
    postnr: "28485",
    besökspostnr: "28433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1276",
    kommunsNamn: "Klippan",
    skolenhetsKod: "22186991",
    skolenhetensNamn: "Antilopenskolan",
    postnr: "26480",
    besökspostnr: "26432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1276",
    kommunsNamn: "Klippan",
    skolenhetsKod: "88988946",
    skolenhetensNamn: "Krika Bygdeskola",
    postnr: "26492",
    besökspostnr: "26492",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1276",
    kommunsNamn: "Klippan",
    skolenhetsKod: "77298745",
    skolenhetensNamn: "Kungsfiskareskolan",
    postnr: "26435",
    besökspostnr: "26435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1276",
    kommunsNamn: "Klippan",
    skolenhetsKod: "75091646",
    skolenhetensNamn: "Ljungbyhedsskolan",
    postnr: "26452",
    besökspostnr: "26471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1276",
    kommunsNamn: "Klippan",
    skolenhetsKod: "45890320",
    skolenhetensNamn: "Pilagårdsskolan",
    postnr: "26471",
    besökspostnr: "26434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1276",
    kommunsNamn: "Klippan",
    skolenhetsKod: "91920964",
    skolenhetensNamn: "Vedby skola",
    postnr: "26437",
    besökspostnr: "26437",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1277",
    kommunsNamn: "Åstorp",
    skolenhetsKod: "31894674",
    skolenhetensNamn: "Haganässkolan",
    postnr: "26535",
    besökspostnr: "26535",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1277",
    kommunsNamn: "Åstorp",
    skolenhetsKod: "11662899",
    skolenhetensNamn: "Hyllinge skola",
    postnr: "26503",
    besökspostnr: "26061",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1277",
    kommunsNamn: "Åstorp",
    skolenhetsKod: "10402832",
    skolenhetensNamn: "Kvidinge skola",
    postnr: "26571",
    besökspostnr: "26571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1277",
    kommunsNamn: "Åstorp",
    skolenhetsKod: "59667741",
    skolenhetensNamn: "Nyvångs skola",
    postnr: "26550",
    besökspostnr: "26550",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1277",
    kommunsNamn: "Åstorp",
    skolenhetsKod: "73867676",
    skolenhetensNamn: "Rågenskolan",
    postnr: "26533",
    besökspostnr: "26531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1277",
    kommunsNamn: "Åstorp",
    skolenhetsKod: "19755937",
    skolenhetensNamn: "Tingdalsskolan",
    postnr: "26531",
    besökspostnr: "26538",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1278",
    kommunsNamn: "Båstad",
    skolenhetsKod: "64633692",
    skolenhetensNamn: "Bjäre skola",
    postnr: "26980",
    besökspostnr: "26974",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1278",
    kommunsNamn: "Båstad",
    skolenhetsKod: "35158169",
    skolenhetensNamn: "Båstad Montessoriskola Asken",
    postnr: "26921",
    besökspostnr: "26992",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1278",
    kommunsNamn: "Båstad",
    skolenhetsKod: "94453321",
    skolenhetensNamn: "Förslövs skola F-9",
    postnr: "26980",
    besökspostnr: "26971",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1278",
    kommunsNamn: "Båstad",
    skolenhetsKod: "16148785",
    skolenhetensNamn: "Strandängsskolan F-9",
    postnr: "26922",
    besökspostnr: "26933",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1278",
    kommunsNamn: "Båstad",
    skolenhetsKod: "88295885",
    skolenhetensNamn: "Östra Karups skola",
    postnr: "26980",
    besökspostnr: "26941",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "75399786",
    skolenhetensNamn: "Al-Salamahskolan",
    postnr: "21233",
    besökspostnr: "21233",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "59926249",
    skolenhetensNamn: "Annebergsskolan",
    postnr: "20580",
    besökspostnr: "21466",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "56672773",
    skolenhetensNamn: "Apelgårdsskolan F-6",
    postnr: "20580",
    besökspostnr: "21370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "89876778",
    skolenhetensNamn: "Augustenborgsskolan",
    postnr: "20580",
    besökspostnr: "21445",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "55040455",
    skolenhetensNamn: "Backaskolan",
    postnr: "21224",
    besökspostnr: "21224",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "40035084",
    skolenhetensNamn: "Bergaskolan",
    postnr: "20580",
    besökspostnr: "21616",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "57534630",
    skolenhetensNamn: "Bladins grundskola",
    postnr: "20074",
    besökspostnr: "21742",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "78986228",
    skolenhetensNamn: "Bladins International School of Malmö",
    postnr: "20074",
    besökspostnr: "21762",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "62126085",
    skolenhetensNamn: "Boukefs Småskola",
    postnr: "21238",
    besökspostnr: "21238",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "54391363",
    skolenhetensNamn: "Bulltoftaskolan",
    postnr: "20580",
    besökspostnr: "21243",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "12636945",
    skolenhetensNamn: "Bäckagårdsskolan",
    postnr: "20580",
    besökspostnr: "21236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "48874671",
    skolenhetensNamn: "Dammfriskolan",
    postnr: "20580",
    besökspostnr: "21745",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "14379396",
    skolenhetensNamn: "Djupadalsskolan",
    postnr: "20580",
    besökspostnr: "21622",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "77378918",
    skolenhetensNamn: "Elinelundsskolan",
    postnr: "20580",
    besökspostnr: "21633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "24339301",
    skolenhetensNamn: "Europaportens grundskola",
    postnr: "21762",
    besökspostnr: "21762",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "17159507",
    skolenhetensNamn: "Fridhemsskolan",
    postnr: "20580",
    besökspostnr: "21753",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "26676746",
    skolenhetensNamn: "Geijerskolan",
    postnr: "20580",
    besökspostnr: "21613",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "85051905",
    skolenhetensNamn: "Glasbruksskolan",
    postnr: "20580",
    besökspostnr: "21644",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "80189510",
    skolenhetensNamn: "Gullviksskolan",
    postnr: "20580",
    besökspostnr: "21374",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "56666435",
    skolenhetensNamn: "Hermodsdalsskolan",
    postnr: "20580",
    besökspostnr: "21550",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "16353434",
    skolenhetensNamn: "Hindby Dibber",
    postnr: "21459",
    besökspostnr: "21459",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "54845539",
    skolenhetensNamn: "Holmaskolan",
    postnr: "20580",
    besökspostnr: "21526",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "86384125",
    skolenhetensNamn: "Husieskolan",
    postnr: "20580",
    besökspostnr: "21232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "55549522",
    skolenhetensNamn: "Hyllie Park Grundskola",
    postnr: "21623",
    besökspostnr: "21623",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "53047724",
    skolenhetensNamn: "Hyllievångsskolan",
    postnr: "20580",
    besökspostnr: "21536",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "16037900",
    skolenhetensNamn: "Högaholmsskolan",
    postnr: "20580",
    besökspostnr: "21574",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "99835038",
    skolenhetensNamn: "Höjaskolan",
    postnr: "20580",
    besökspostnr: "21233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "82797662",
    skolenhetensNamn: "Johannesskolan",
    postnr: "20580",
    besökspostnr: "21421",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "19435652",
    skolenhetensNamn: "Karl Johansskolan",
    postnr: "20580",
    besökspostnr: "21616",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "56755694",
    skolenhetensNamn: "Karlshögsskolan",
    postnr: "20580",
    besökspostnr: "21229",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "19024032",
    skolenhetensNamn: "Kastanjeskolan",
    postnr: "21434",
    besökspostnr: "21434",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "25054799",
    skolenhetensNamn: "Kirsebergsskolan",
    postnr: "20580",
    besökspostnr: "21219",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "21120076",
    skolenhetensNamn: "Klagshamnsskolan",
    postnr: "20580",
    besökspostnr: "20580",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "98745015",
    skolenhetensNamn: "Kroksbäcksskolan",
    postnr: "20580",
    besökspostnr: "21625",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "66836744",
    skolenhetensNamn: "Kryddgårdsskolan",
    postnr: "20580",
    besökspostnr: "21372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "17441553",
    skolenhetensNamn: "Kulladalsskolan",
    postnr: "20580",
    besökspostnr: "21566",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "49165163",
    skolenhetensNamn: "Kungshögsskolan",
    postnr: "20580",
    besökspostnr: "23831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "22509001",
    skolenhetensNamn: "Lindeborgsskolan",
    postnr: "20580",
    besökspostnr: "21585",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "75875503",
    skolenhetensNamn: "Lindängeskolan",
    postnr: "20580",
    besökspostnr: "21574",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "16185651",
    skolenhetensNamn: "Lorensborgsskolan",
    postnr: "20580",
    besökspostnr: "21763",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "75599504",
    skolenhetensNamn: "Malmö Friskola",
    postnr: "21222",
    besökspostnr: "21222",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "56446897",
    skolenhetensNamn: "Malmö International School",
    postnr: "20580",
    besökspostnr: "21646",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "13299659",
    skolenhetensNamn: "Malmö Montessoriskola",
    postnr: "21764",
    besökspostnr: "21764",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "67272295",
    skolenhetensNamn: "Mariaskolan",
    postnr: "21765",
    besökspostnr: "21765",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "29300350",
    skolenhetensNamn: "Mellanhedsskolan",
    postnr: "20580",
    besökspostnr: "21773",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "12536784",
    skolenhetensNamn: "Monbijouskolan",
    postnr: "20580",
    besökspostnr: "21153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "45776214",
    skolenhetensNamn: "Montessorigrundskolan Maria",
    postnr: "10124",
    besökspostnr: "21238",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "74972690",
    skolenhetensNamn: "Munkhätteskolan",
    postnr: "20580",
    besökspostnr: "21456",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "64737940",
    skolenhetensNamn: "Mölletoftaskolan",
    postnr: "20580",
    besökspostnr: "21239",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "88590084",
    skolenhetensNamn: "Möllevångsskolan",
    postnr: "20580",
    besökspostnr: "21153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "58250857",
    skolenhetensNamn: "Nydalaskolan",
    postnr: "20580",
    besökspostnr: "21454",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "64402572",
    skolenhetensNamn: "Oxievångsskolan",
    postnr: "20580",
    besökspostnr: "23840",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "54555485",
    skolenhetensNamn: "Ribersborgsskolan",
    postnr: "20580",
    besökspostnr: "21756",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "69234477",
    skolenhetensNamn: "Risebergaskolan",
    postnr: "20580",
    besökspostnr: "21230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "93270739",
    skolenhetensNamn: "Rosengårdsskolan F-6",
    postnr: "20580",
    besökspostnr: "21368",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "56989398",
    skolenhetensNamn: "Rosenholmsskolan",
    postnr: "20580",
    besökspostnr: "21363",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "57386343",
    skolenhetensNamn: "Runstyckets skola",
    postnr: "21238",
    besökspostnr: "21238",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "97818558",
    skolenhetensNamn: "Rörsjöskolan",
    postnr: "20580",
    besökspostnr: "21214",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "62952638",
    skolenhetensNamn: "Skolan på Ön",
    postnr: "20580",
    besökspostnr: "21642",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "65836771",
    skolenhetensNamn: "Sofielundsskolan",
    postnr: "20580",
    besökspostnr: "21434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "67130748",
    skolenhetensNamn: "Sorgenfriskolan",
    postnr: "20580",
    besökspostnr: "21438",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "39087850",
    skolenhetensNamn: "Stapelbäddsskolan",
    postnr: "20580",
    besökspostnr: "21175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "85082785",
    skolenhetensNamn: "Stenkulaskolan",
    postnr: "20580",
    besökspostnr: "21441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "15068136",
    skolenhetensNamn: "Sundsbroskolan",
    postnr: "20580",
    besökspostnr: "21837",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "60366078",
    skolenhetensNamn: "Tingdammsskolan",
    postnr: "20540",
    besökspostnr: "23842",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "98713812",
    skolenhetensNamn: "Toftanässkolan",
    postnr: "20580",
    besökspostnr: "21245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "10509907",
    skolenhetensNamn: "Tygelsjöskolan",
    postnr: "20580",
    besökspostnr: "21872",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "31996111",
    skolenhetensNamn: "Videdals privatskolor",
    postnr: "20024",
    besökspostnr: "21231",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "37324136",
    skolenhetensNamn: "Videdalsskolan",
    postnr: "20580",
    besökspostnr: "21231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "13495830",
    skolenhetensNamn: "Vittra Västra hamnen",
    postnr: "21115",
    besökspostnr: "21115",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "65099615",
    skolenhetensNamn: "Värner Rydénskolan",
    postnr: "20580",
    besökspostnr: "21367",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "84512892",
    skolenhetensNamn: "Västra skolan",
    postnr: "20580",
    besökspostnr: "21133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "34314029",
    skolenhetensNamn: "Ängsdals skola AB",
    postnr: "21831",
    besökspostnr: "21831",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "33174525",
    skolenhetensNamn: "Ängslättskolan",
    postnr: "20580",
    besökspostnr: "21837",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "36880435",
    skolenhetensNamn: "Ögårdsskolan",
    postnr: "21237",
    besökspostnr: "21237",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "78498675",
    skolenhetensNamn: "Örtagårdsskolan F-6",
    postnr: "20580",
    besökspostnr: "21365",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1280",
    kommunsNamn: "Malmö",
    skolenhetsKod: "86471786",
    skolenhetensNamn: "Österportskolan",
    postnr: "20580",
    besökspostnr: "21127",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "95226024",
    skolenhetensNamn: "Apelskolan",
    postnr: "22359",
    besökspostnr: "22359",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "43054997",
    skolenhetensNamn: "Backaskolan",
    postnr: "22649",
    besökspostnr: "22649",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "45699665",
    skolenhetensNamn: "Byskolan",
    postnr: "22100",
    besökspostnr: "24734",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "86202475",
    skolenhetensNamn: "Delfinskolan",
    postnr: "22645",
    besökspostnr: "22645",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "29396502",
    skolenhetensNamn: "Flygelskolan",
    postnr: "22468",
    besökspostnr: "22735",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "60492534",
    skolenhetensNamn: "Freinetskolan i Lund",
    postnr: "22270",
    besökspostnr: "22270",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "64936034",
    skolenhetensNamn: "Fågelskolan",
    postnr: "22735",
    besökspostnr: "22735",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "85762828",
    skolenhetensNamn: "Gunnesboskolan",
    postnr: "22654",
    besökspostnr: "22654",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "63017061",
    skolenhetensNamn: "Hagalundskolan",
    postnr: "24751",
    besökspostnr: "24751",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "21703142",
    skolenhetensNamn: "Hubertusgården",
    postnr: "22652",
    besökspostnr: "22652",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "51938440",
    skolenhetensNamn: "Håstads skola",
    postnr: "22594",
    besökspostnr: "22594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "33536595",
    skolenhetensNamn: "Häckebergaskolan",
    postnr: "24770",
    besökspostnr: "24770",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "20140050",
    skolenhetensNamn: "Idalaskolan",
    postnr: "24761",
    besökspostnr: "22355",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "70615815",
    skolenhetensNamn: "ISLK/Int. School of Lund",
    postnr: "22460",
    besökspostnr: "22460",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "89516312",
    skolenhetensNamn: "Klostergårdskolan",
    postnr: "22228",
    besökspostnr: "22228",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "28403786",
    skolenhetensNamn: "Kulparkskolan",
    postnr: "22651",
    besökspostnr: "22651",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "90340546",
    skolenhetensNamn: "Lerbäckskolan",
    postnr: "22764",
    besökspostnr: "22764",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "34409875",
    skolenhetensNamn: "Lingua Montessoriskola",
    postnr: "22270",
    besökspostnr: "22270",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "35615086",
    skolenhetensNamn: "Lund International School",
    postnr: "22465",
    besökspostnr: "22465",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "70044059",
    skolenhetensNamn: "Lunds Montessorigrundskola",
    postnr: "22270",
    besökspostnr: "22270",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "96533068",
    skolenhetensNamn: "Lunds Waldorfskola",
    postnr: "24791",
    besökspostnr: "24791",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "40248457",
    skolenhetensNamn: "Montessori Mondial Lund",
    postnr: "22478",
    besökspostnr: "22478",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "30058363",
    skolenhetensNamn: "Munspelets skola",
    postnr: "22468",
    besökspostnr: "22468",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "26555956",
    skolenhetensNamn: "Mårtenskolan",
    postnr: "22467",
    besökspostnr: "22467",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "70811599",
    skolenhetensNamn: "Nyvångskolan",
    postnr: "24754",
    besökspostnr: "24754",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "85777148",
    skolenhetensNamn: "Oskarskolan",
    postnr: "22764",
    besökspostnr: "22639",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "54781241",
    skolenhetensNamn: "Palettskolan",
    postnr: "22730",
    besökspostnr: "22730",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "82239909",
    skolenhetensNamn: "Påskagängets skola",
    postnr: "24756",
    besökspostnr: "24756",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "36401369",
    skolenhetensNamn: "Revinge skola",
    postnr: "24741",
    besökspostnr: "24741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "28753548",
    skolenhetensNamn: "Sankt Thomas Skola",
    postnr: "22270",
    besökspostnr: "22270",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "95785072",
    skolenhetensNamn: "Skrylleängs Montessoriskola",
    postnr: "24751",
    besökspostnr: "24751",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "75154610",
    skolenhetensNamn: "Stiftelsen BMSL",
    postnr: "22240",
    besökspostnr: "22240",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "27508103",
    skolenhetensNamn: "Svaleboskolan",
    postnr: "24762",
    besökspostnr: "24762",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "14485761",
    skolenhetensNamn: "Torna Hällestads skola",
    postnr: "24745",
    besökspostnr: "24745",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "76684064",
    skolenhetensNamn: "Tunaskolan",
    postnr: "22465",
    besökspostnr: "22465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "34508695",
    skolenhetensNamn: "Uggleskolan",
    postnr: "24735",
    besökspostnr: "24735",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "41028102",
    skolenhetensNamn: "Waldorfbarnträdgården Solvinden",
    postnr: "22639",
    besökspostnr: "22639",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "13504003",
    skolenhetensNamn: "Vallkärra skola",
    postnr: "22650",
    besökspostnr: "22650",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "12127586",
    skolenhetensNamn: "Vegalyckan",
    postnr: "22457",
    besökspostnr: "22457",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "68201054",
    skolenhetensNamn: "Vikingaskolan",
    postnr: "22475",
    besökspostnr: "22475",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "83324871",
    skolenhetensNamn: "Vårfruskolan",
    postnr: "22361",
    besökspostnr: "22361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "31398939",
    skolenhetensNamn: "Österskolan",
    postnr: "24732",
    besökspostnr: "24732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1281",
    kommunsNamn: "Lund",
    skolenhetsKod: "73657597",
    skolenhetensNamn: "Östervångsskolan",
    postnr: "22465",
    besökspostnr: "22465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "37935182",
    skolenhetensNamn: "Albanoskolan",
    postnr: "26137",
    besökspostnr: "26137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "74833346",
    skolenhetensNamn: "Alléskolan",
    postnr: "26132",
    besökspostnr: "26132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "70803452",
    skolenhetensNamn: "Asmundtorps skola",
    postnr: "26175",
    besökspostnr: "26175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "12040511",
    skolenhetensNamn: "Emiliaskolan",
    postnr: "26173",
    besökspostnr: "26173",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "30917121",
    skolenhetensNamn: "Glumslövs skola",
    postnr: "26162",
    besökspostnr: "26162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "50966696",
    skolenhetensNamn: "Härslövs skola",
    postnr: "26165",
    besökspostnr: "26165",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "94830241",
    skolenhetensNamn: "Innovitaskolan Landskrona",
    postnr: "26132",
    besökspostnr: "26132",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "21085818",
    skolenhetensNamn: "Internationella Engelska Skolan Landskrona",
    postnr: "26161",
    besökspostnr: "26161",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "81433742",
    skolenhetensNamn: "M.A.R.I.A-skolan",
    postnr: "26152",
    besökspostnr: "26152",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "52049139",
    skolenhetensNamn: "Pilängskolan",
    postnr: "26142",
    besökspostnr: "26142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "41641029",
    skolenhetensNamn: "Seminarieskolan",
    postnr: "26152",
    besökspostnr: "26152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "42908219",
    skolenhetensNamn: "Säbyholms Montessoriskola",
    postnr: "26191",
    besökspostnr: "26191",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "26343031",
    skolenhetensNamn: "Titanskolan",
    postnr: "26142",
    besökspostnr: "26142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "16851764",
    skolenhetensNamn: "Uranienborgs skola",
    postnr: "26013",
    besökspostnr: "26013",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1282",
    kommunsNamn: "Landskrona",
    skolenhetsKod: "29541747",
    skolenhetensNamn: "Västervångskolan",
    postnr: "26139",
    besökspostnr: "26139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "88007898",
    skolenhetensNamn: "Allerums skola",
    postnr: "25476",
    besökspostnr: "25476",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "86764194",
    skolenhetensNamn: "Anneroskolan",
    postnr: "25462",
    besökspostnr: "25462",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "27323226",
    skolenhetensNamn: "Brunnsparksskolan",
    postnr: "25657",
    besökspostnr: "25657",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "69469866",
    skolenhetensNamn: "Bårslövs skola",
    postnr: "25374",
    besökspostnr: "25374",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "10982752",
    skolenhetensNamn: "Centrumskolan i Hbg",
    postnr: "25437",
    besökspostnr: "25437",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "19593407",
    skolenhetensNamn: "Dibber International School Helsingborg",
    postnr: "25732",
    besökspostnr: "25732",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "80083873",
    skolenhetensNamn: "Drottninghögsskolan",
    postnr: "25457",
    besökspostnr: "25457",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "41811292",
    skolenhetensNamn: "Elinebergsskolan",
    postnr: "25656",
    besökspostnr: "25656",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "24352906",
    skolenhetensNamn: "Fridaskolan Helsingborg",
    postnr: "25232",
    besökspostnr: "25232",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "61558490",
    skolenhetensNamn: "Fyrklövern Dibber",
    postnr: "25667",
    besökspostnr: "25667",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "28424574",
    skolenhetensNamn: "Gantofta skola",
    postnr: "25189",
    besökspostnr: "25373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "67721487",
    skolenhetensNamn: "Gläntanskolan",
    postnr: "25472",
    besökspostnr: "25472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "30731471",
    skolenhetensNamn: "Gustavslundsskolan",
    postnr: "25189",
    besökspostnr: "25189",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "28754789",
    skolenhetensNamn: "Holstagårdsskolan",
    postnr: "25286",
    besökspostnr: "25286",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "59129239",
    skolenhetensNamn: "Husensjö skola",
    postnr: "25251",
    besökspostnr: "25251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "13175575",
    skolenhetensNamn: "Högastensskolan",
    postnr: "25232",
    besökspostnr: "25232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "47717980",
    skolenhetensNamn: "International school of Hbg/Gr",
    postnr: "25437",
    besökspostnr: "25437",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "48316366",
    skolenhetensNamn: "Kattarps skola",
    postnr: "25470",
    besökspostnr: "25470",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "81418958",
    skolenhetensNamn: "Kingelstad Byskola",
    postnr: "25342",
    besökspostnr: "25342",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "82943624",
    skolenhetensNamn: "Kubikskolan Dibber",
    postnr: "25422",
    besökspostnr: "25422",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "36850222",
    skolenhetensNamn: "Kärnhuset Dibber",
    postnr: "25269",
    besökspostnr: "25269",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "87251726",
    skolenhetensNamn: "Laröds skola",
    postnr: "25284",
    besökspostnr: "25284",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "85368005",
    skolenhetensNamn: "Långvinkeln Dibber",
    postnr: "25220",
    besökspostnr: "25220",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "42164778",
    skolenhetensNamn: "Maria Parkskolan",
    postnr: "25451",
    besökspostnr: "25451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "91004081",
    skolenhetensNamn: "Montemini Hbg, Montessoriskola",
    postnr: "25451",
    besökspostnr: "25451",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "55200547",
    skolenhetensNamn: "Musikgrundskolan Synkopen",
    postnr: "25223",
    besökspostnr: "25223",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "48337100",
    skolenhetensNamn: "Mörarps skola",
    postnr: "25355",
    besökspostnr: "25355",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "48089316",
    skolenhetensNamn: "Nanny Palmkvistskolan",
    postnr: "25263",
    besökspostnr: "25263",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "57590681",
    skolenhetensNamn: "Noblaskolan Mariastaden",
    postnr: "25451",
    besökspostnr: "25451",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "50194424",
    skolenhetensNamn: "Pauliskolan",
    postnr: "25665",
    besökspostnr: "25665",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "66351593",
    skolenhetensNamn: "Påarps skola",
    postnr: "25351",
    besökspostnr: "25351",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "41675828",
    skolenhetensNamn: "Ramlösa friskola",
    postnr: "25668",
    besökspostnr: "25668",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "44040804",
    skolenhetensNamn: "Raus Planterings skola",
    postnr: "25240",
    besökspostnr: "25240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "15455892",
    skolenhetensNamn: "Ringstorpsskolan",
    postnr: "25453",
    besökspostnr: "25453",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "40385157",
    skolenhetensNamn: "Rydebäckskolan",
    postnr: "25731",
    besökspostnr: "25731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "84590558",
    skolenhetensNamn: "Råå Södra skola",
    postnr: "25270",
    besökspostnr: "25270",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "31106340",
    skolenhetensNamn: "Sally Bauer skolan F-6",
    postnr: "25252",
    besökspostnr: "25661",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "85966004",
    skolenhetensNamn: "Slottshöjdens Skola",
    postnr: "25437",
    besökspostnr: "25437",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "63957271",
    skolenhetensNamn: "Slottsvångsskolan",
    postnr: "25223",
    besökspostnr: "25223",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "55527569",
    skolenhetensNamn: "S:t Jörgens skola",
    postnr: "25221",
    besökspostnr: "25221",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "89693828",
    skolenhetensNamn: "Svensgårdsskolan",
    postnr: "25475",
    besökspostnr: "25475",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "23353946",
    skolenhetensNamn: "Söderskolan",
    postnr: "25227",
    besökspostnr: "25227",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "87948789",
    skolenhetensNamn: "Tågaborgsskolan",
    postnr: "25441",
    besökspostnr: "25441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "37992567",
    skolenhetensNamn: "Wallåkrabygdens skola",
    postnr: "25341",
    besökspostnr: "25341",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "98442801",
    skolenhetensNamn: "Vasalundsskolan",
    postnr: "25459",
    besökspostnr: "25459",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "51014228",
    skolenhetensNamn: "Wieselgrensskolan",
    postnr: "25246",
    besökspostnr: "25246",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "71069736",
    skolenhetensNamn: "Vittra Adolfsberg",
    postnr: "25662",
    besökspostnr: "25662",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "90080902",
    skolenhetensNamn: "Vittra Landborgen",
    postnr: "25223",
    besökspostnr: "25223",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "68973554",
    skolenhetensNamn: "Välkomsten",
    postnr: "25189",
    besökspostnr: "25189",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "28060171",
    skolenhetensNamn: "Västra Berga skola",
    postnr: "25455",
    besökspostnr: "25455",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "49010566",
    skolenhetensNamn: "Västra Ramlösa skola",
    postnr: "25656",
    besökspostnr: "25656",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1283",
    kommunsNamn: "Helsingborg",
    skolenhetsKod: "64728140",
    skolenhetensNamn: "Ättekullaskolan",
    postnr: "25361",
    besökspostnr: "25361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "58637812",
    skolenhetensNamn: "Bruksskolan F-3",
    postnr: "26338",
    besökspostnr: "26338",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "95113658",
    skolenhetensNamn: "Brunnbyskolan",
    postnr: "26373",
    besökspostnr: "26041",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "67026015",
    skolenhetensNamn: "Jonstorpsskolan F-3",
    postnr: "26371",
    besökspostnr: "26371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "24385083",
    skolenhetensNamn: "Lerbergsskolan F-3",
    postnr: "26354",
    besökspostnr: "26354",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "69182531",
    skolenhetensNamn: "Montessoriskolan Tellus",
    postnr: "26338",
    besökspostnr: "26338",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "95034203",
    skolenhetensNamn: "Nyhamnsskolan F-3",
    postnr: "26376",
    besökspostnr: "26376",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "35488074",
    skolenhetensNamn: "Tornlyckeskolan F-3",
    postnr: "26334",
    besökspostnr: "26334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "13274269",
    skolenhetensNamn: "Vikenskolan F-3",
    postnr: "26363",
    besökspostnr: "26363",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1284",
    kommunsNamn: "Höganäs",
    skolenhetsKod: "17770513",
    skolenhetensNamn: "Vikens Montessoriskola",
    postnr: "26362",
    besökspostnr: "26362",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "30190678",
    skolenhetensNamn: "Flyingeskolan",
    postnr: "24747",
    besökspostnr: "24135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "85535876",
    skolenhetensNamn: "Fridasroskolan",
    postnr: "24135",
    besökspostnr: "24164",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "89857863",
    skolenhetensNamn: "Harlösa skola",
    postnr: "24164",
    besökspostnr: "24139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "10583321",
    skolenhetensNamn: "Marieskolan",
    postnr: "24172",
    besökspostnr: "24134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "68546833",
    skolenhetensNamn: "Montessorifriskolan i Eslöv",
    postnr: "24131",
    besökspostnr: "24131",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "28661237",
    skolenhetensNamn: "Norrevångsskolan F-6",
    postnr: "24134",
    besökspostnr: "24134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "13488043",
    skolenhetensNamn: "Sallerupskolan",
    postnr: "24137",
    besökspostnr: "24174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "74200315",
    skolenhetensNamn: "Stehagskolan",
    postnr: "24174",
    besökspostnr: "24139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "58463429",
    skolenhetensNamn: "Västra skolan",
    postnr: "24131",
    besökspostnr: "24162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "97617940",
    skolenhetensNamn: "Ölyckeskolan f-6",
    postnr: "24162",
    besökspostnr: "24162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "86681887",
    skolenhetensNamn: "Östra skolan",
    postnr: "24138",
    besökspostnr: "24138",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1285",
    kommunsNamn: "Eslöv",
    skolenhetsKod: "85547980",
    skolenhetensNamn: "Östra Strö skola",
    postnr: "24191",
    besökspostnr: "24191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "10475419",
    skolenhetensNamn: "Backaskolan",
    postnr: "27180",
    besökspostnr: "27136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "16274059",
    skolenhetensNamn: "Edvinshemsskolan",
    postnr: "27152",
    besökspostnr: "27152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "45170446",
    skolenhetensNamn: "Hedeskoga skola",
    postnr: "27199",
    besökspostnr: "27199",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "52279246",
    skolenhetensNamn: "Källan skola",
    postnr: "27139",
    besökspostnr: "27139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "68391076",
    skolenhetensNamn: "Köpingebro skola",
    postnr: "27172",
    besökspostnr: "27172",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "44841058",
    skolenhetensNamn: "Löderups skola",
    postnr: "27177",
    besökspostnr: "27177",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "67247370",
    skolenhetensNamn: "Svarteskolan",
    postnr: "27180",
    besökspostnr: "27192",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "77838140",
    skolenhetensNamn: "Sövestads skola",
    postnr: "27180",
    besökspostnr: "27195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "53011246",
    skolenhetensNamn: "Villa Montessori Ystad",
    postnr: "27139",
    besökspostnr: "27139",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "25834038",
    skolenhetensNamn: "Villa Sjöstadens Montessori",
    postnr: "27153",
    besökspostnr: "27153",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1286",
    kommunsNamn: "Ystad",
    skolenhetsKod: "54436997",
    skolenhetensNamn: "Västerportskolan",
    postnr: "27150",
    besökspostnr: "27150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "70747736",
    skolenhetensNamn: "Alstad skola",
    postnr: "23195",
    besökspostnr: "23196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "44524062",
    skolenhetensNamn: "Internationella Engelska Skolan Trelleborg",
    postnr: "23134",
    besökspostnr: "23134",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "74071562",
    skolenhetensNamn: "Kattebäcksskolan",
    postnr: "23164",
    besökspostnr: "23164",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "18414072",
    skolenhetensNamn: "Klagstorps skola",
    postnr: "23197",
    besökspostnr: "23197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "72668294",
    skolenhetensNamn: "Liljeskolan",
    postnr: "23121",
    besökspostnr: "23121",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "67369522",
    skolenhetensNamn: "Pilens Montessoriskola",
    postnr: "23196",
    besökspostnr: "23196",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "24947938",
    skolenhetensNamn: "Pilevallskolan",
    postnr: "23134",
    besökspostnr: "23131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "98006060",
    skolenhetensNamn: "Serresjöskolan",
    postnr: "23131",
    besökspostnr: "23125",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "32492959",
    skolenhetensNamn: "Sjöviksskolan",
    postnr: "23162",
    besökspostnr: "23162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "17417951",
    skolenhetensNamn: "Skegrie skola",
    postnr: "23193",
    besökspostnr: "23179",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "50958320",
    skolenhetensNamn: "Smygeskolan",
    postnr: "23179",
    besökspostnr: "23143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "38375490",
    skolenhetensNamn: "Västervångskolan",
    postnr: "23166",
    besökspostnr: "23166",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "16081769",
    skolenhetensNamn: "Väståkraskolan",
    postnr: "23170",
    besökspostnr: "23170",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1287",
    kommunsNamn: "Trelleborg",
    skolenhetsKod: "25807975",
    skolenhetensNamn: "Östra skolan",
    postnr: "23132",
    besökspostnr: "23132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "14555807",
    skolenhetensNamn: "Antonskolan Blekedamm",
    postnr: "29133",
    besökspostnr: "29133",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "57538739",
    skolenhetensNamn: "Antonskolan Österäng",
    postnr: "29145",
    besökspostnr: "29145",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "50943576",
    skolenhetensNamn: "Centralskolan",
    postnr: "29030",
    besökspostnr: "29030",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "58968208",
    skolenhetensNamn: "Degebergaskolan L",
    postnr: "29731",
    besökspostnr: "29731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "27858536",
    skolenhetensNamn: "Everöds skola",
    postnr: "29772",
    besökspostnr: "29772",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "12725451",
    skolenhetensNamn: "Fjälkinge skola L",
    postnr: "29167",
    besökspostnr: "29167",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "51988106",
    skolenhetensNamn: "Fröknegårdskolan LM",
    postnr: "29136",
    besökspostnr: "29136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "69629367",
    skolenhetensNamn: "Färlövs skola",
    postnr: "29111",
    besökspostnr: "29111",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "73547170",
    skolenhetensNamn: "Hammars skola A",
    postnr: "29150",
    besökspostnr: "29150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "52719739",
    skolenhetensNamn: "Hammars skola L",
    postnr: "29150",
    besökspostnr: "29150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "53285375",
    skolenhetensNamn: "Helgedalskolan",
    postnr: "29162",
    besökspostnr: "29162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "47456573",
    skolenhetensNamn: "Kiaby skola",
    postnr: "29034",
    besökspostnr: "29034",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "49567614",
    skolenhetensNamn: "Kulltorpskolan LM",
    postnr: "29144",
    besökspostnr: "29144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "79166834",
    skolenhetensNamn: "Köpinge skola",
    postnr: "29177",
    besökspostnr: "29177",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "82896913",
    skolenhetensNamn: "Linderöds skola",
    postnr: "29894",
    besökspostnr: "29894",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "84589157",
    skolenhetensNamn: "Lingenässkolan A",
    postnr: "29139",
    besökspostnr: "29139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "94892893",
    skolenhetensNamn: "Lingenässkolan F",
    postnr: "29139",
    besökspostnr: "29139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "72491525",
    skolenhetensNamn: "Montessori Mondial Kristianstad",
    postnr: "29132",
    besökspostnr: "29132",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "23680908",
    skolenhetensNamn: "Nygårdskolan",
    postnr: "29135",
    besökspostnr: "29135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "15190275",
    skolenhetensNamn: "Parkskolan LM",
    postnr: "29141",
    besökspostnr: "29141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "22284806",
    skolenhetensNamn: "Rickarums skola",
    postnr: "29892",
    besökspostnr: "29892",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "14799941",
    skolenhetensNamn: "Rinkaby skola",
    postnr: "29176",
    besökspostnr: "29176",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "50316106",
    skolenhetensNamn: "Slättängsskolan LM",
    postnr: "29162",
    besökspostnr: "29162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "45819321",
    skolenhetensNamn: "Spängerskolan",
    postnr: "29155",
    besökspostnr: "29155",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "61927182",
    skolenhetensNamn: "Sånnaskolan F",
    postnr: "29635",
    besökspostnr: "29635",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "81452013",
    skolenhetensNamn: "Thoren Framtid Axona",
    postnr: "29832",
    besökspostnr: "29832",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "75994251",
    skolenhetensNamn: "Tollarps skola L",
    postnr: "29833",
    besökspostnr: "29833",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "29669758",
    skolenhetensNamn: "Trolle Ljungby skola",
    postnr: "29169",
    besökspostnr: "29169",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "68950651",
    skolenhetensNamn: "Viby skola",
    postnr: "29151",
    besökspostnr: "29151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "89875292",
    skolenhetensNamn: "Villaskolan L",
    postnr: "29634",
    besökspostnr: "29634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "41240740",
    skolenhetensNamn: "Vittskövle skola",
    postnr: "29791",
    besökspostnr: "29791",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "70339689",
    skolenhetensNamn: "Väskolan L",
    postnr: "29165",
    besökspostnr: "29165",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "88010789",
    skolenhetensNamn: "Yngsjö skola",
    postnr: "29672",
    besökspostnr: "29672",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "24241015",
    skolenhetensNamn: "Ängsskolan",
    postnr: "29133",
    besökspostnr: "29133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "88024354",
    skolenhetensNamn: "Öllsjöskolan L",
    postnr: "29166",
    besökspostnr: "29166",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "55252770",
    skolenhetensNamn: "Önnestads skola LM",
    postnr: "29173",
    besökspostnr: "29173",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1290",
    kommunsNamn: "Kristianstad",
    skolenhetsKod: "66367722",
    skolenhetensNamn: "Österslövs skola",
    postnr: "29194",
    besökspostnr: "29194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1291",
    kommunsNamn: "Simrishamn",
    skolenhetsKod: "92471460",
    skolenhetensNamn: "Borrby skola",
    postnr: "27630",
    besökspostnr: "27630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1291",
    kommunsNamn: "Simrishamn",
    skolenhetsKod: "93223643",
    skolenhetensNamn: "Gärsnäs skola",
    postnr: "27261",
    besökspostnr: "27261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1291",
    kommunsNamn: "Simrishamn",
    skolenhetsKod: "36559452",
    skolenhetensNamn: "Hammenhögs Friskola",
    postnr: "27650",
    besökspostnr: "27650",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1291",
    kommunsNamn: "Simrishamn",
    skolenhetsKod: "24445412",
    skolenhetensNamn: "Nils Holgerssonskolan",
    postnr: "27231",
    besökspostnr: "27231",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1291",
    kommunsNamn: "Simrishamn",
    skolenhetsKod: "42158538",
    skolenhetensNamn: "Piratenskolan",
    postnr: "27730",
    besökspostnr: "27730",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1291",
    kommunsNamn: "Simrishamn",
    skolenhetsKod: "88731583",
    skolenhetensNamn: "Sankt Olofs skola",
    postnr: "27730",
    besökspostnr: "27740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1291",
    kommunsNamn: "Simrishamn",
    skolenhetsKod: "53416468",
    skolenhetensNamn: "Simrislundsskolan",
    postnr: "27280",
    besökspostnr: "27237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1291",
    kommunsNamn: "Simrishamn",
    skolenhetsKod: "59958745",
    skolenhetensNamn: "Sophiaskolan",
    postnr: "27295",
    besökspostnr: "27295",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "69871183",
    skolenhetensNamn: "Errarps skola SO 1",
    postnr: "26261",
    besökspostnr: "26261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "49539818",
    skolenhetensNamn: "Magnarps skola SO 2",
    postnr: "26655",
    besökspostnr: "26655",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "49868503",
    skolenhetensNamn: "Rebbelberga skola SO 1",
    postnr: "26244",
    besökspostnr: "26244",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "34992126",
    skolenhetensNamn: "Strövelstorps skola SO 2",
    postnr: "26270",
    besökspostnr: "26270",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "34797598",
    skolenhetensNamn: "Södra Utmarkens skola SO 1",
    postnr: "26253",
    besökspostnr: "26253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "62412121",
    skolenhetensNamn: "Söndrebalgs skola SO 1",
    postnr: "26675",
    besökspostnr: "26675",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "51497405",
    skolenhetensNamn: "Toftaskolan SO 1",
    postnr: "26631",
    besökspostnr: "26631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "39381869",
    skolenhetensNamn: "Vejbystrands skola",
    postnr: "26654",
    besökspostnr: "26654",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "50198866",
    skolenhetensNamn: "Villanskolan SO 2",
    postnr: "26252",
    besökspostnr: "26252",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "78910791",
    skolenhetensNamn: "Ängelholms Montessoriskola",
    postnr: "26263",
    besökspostnr: "26263",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1292",
    kommunsNamn: "Ängelholm",
    skolenhetsKod: "36962579",
    skolenhetensNamn: "Össjö skola",
    postnr: "26691",
    besökspostnr: "26691",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "97094194",
    skolenhetensNamn: "Ballingslövs skola F-6",
    postnr: "28180",
    besökspostnr: "28197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "65212509",
    skolenhetensNamn: "Bjärnums skola F-9",
    postnr: "28180",
    besökspostnr: "28261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "16744637",
    skolenhetensNamn: "Farstorps skola F-6",
    postnr: "28180",
    besökspostnr: "28276",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "67308549",
    skolenhetensNamn: "Finja skola F-6",
    postnr: "28180",
    besökspostnr: "28153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "85679977",
    skolenhetensNamn: "Grönängsskolan F-6",
    postnr: "28180",
    besökspostnr: "28147",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "28804882",
    skolenhetensNamn: "Hässleholms Montessoriskola F-9",
    postnr: "28180",
    besökspostnr: "28152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "56398338",
    skolenhetensNamn: "Hästveda skola F-9",
    postnr: "28180",
    besökspostnr: "28140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "57945497",
    skolenhetensNamn: "Lejonskolan F-5",
    postnr: "28180",
    besökspostnr: "28272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "84432632",
    skolenhetensNamn: "Linnéskolan F-9",
    postnr: "28180",
    besökspostnr: "28136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "37198266",
    skolenhetensNamn: "Ljungdalaskolan F-6",
    postnr: "28180",
    besökspostnr: "28141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "29689372",
    skolenhetensNamn: "Läredaskolan F-9",
    postnr: "28180",
    besökspostnr: "28139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "49702354",
    skolenhetensNamn: "Mala skola F-6",
    postnr: "28180",
    besökspostnr: "28265",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "69162574",
    skolenhetensNamn: "Röinge skola F-6",
    postnr: "28180",
    besökspostnr: "28137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "24099280",
    skolenhetensNamn: "Röke skola F-6",
    postnr: "28180",
    besökspostnr: "28293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "65408331",
    skolenhetensNamn: "Silviaskolan F-9",
    postnr: "28180",
    besökspostnr: "28149",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "68970313",
    skolenhetensNamn: "Stoby skola F-6",
    postnr: "28180",
    besökspostnr: "28144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "55815171",
    skolenhetensNamn: "Söderparkskolan F-3",
    postnr: "28180",
    besökspostnr: "28831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "79864142",
    skolenhetensNamn: "Thoren Framtid i Hässleholm",
    postnr: "28135",
    besökspostnr: "28135",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "87274697",
    skolenhetensNamn: "Tormestorps skola F-5",
    postnr: "28180",
    besökspostnr: "28146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "98058756",
    skolenhetensNamn: "Tyringe skola F-9",
    postnr: "28180",
    besökspostnr: "28222",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "16939144",
    skolenhetensNamn: "Vittsjö skola F-6",
    postnr: "28180",
    besökspostnr: "28267",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "75318783",
    skolenhetensNamn: "Västerskolan F-9",
    postnr: "28180",
    besökspostnr: "28149",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "12",
    länsNamn: "Skåne län",
    kommun: "1293",
    kommunsNamn: "Hässleholm",
    skolenhetsKod: "83016261",
    skolenhetensNamn: "Västra Torups skola F-3",
    postnr: "28180",
    besökspostnr: "28292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1315",
    kommunsNamn: "Hylte",
    skolenhetsKod: "55039327",
    skolenhetensNamn: "Elias Fries skola",
    postnr: "31480",
    besökspostnr: "31434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1315",
    kommunsNamn: "Hylte",
    skolenhetsKod: "32214820",
    skolenhetensNamn: "Kinnareds skola",
    postnr: "31480",
    besökspostnr: "31405",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1315",
    kommunsNamn: "Hylte",
    skolenhetsKod: "74764978",
    skolenhetensNamn: "Kråkbergsskolan",
    postnr: "31480",
    besökspostnr: "31432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1315",
    kommunsNamn: "Hylte",
    skolenhetsKod: "46143897",
    skolenhetensNamn: "Landeryds skola",
    postnr: "31480",
    besökspostnr: "31494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1315",
    kommunsNamn: "Hylte",
    skolenhetsKod: "50709257",
    skolenhetensNamn: "Rydöbruks skola",
    postnr: "31480",
    besökspostnr: "31442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1315",
    kommunsNamn: "Hylte",
    skolenhetsKod: "26544203",
    skolenhetensNamn: "Torups skola",
    postnr: "31480",
    besökspostnr: "31441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1315",
    kommunsNamn: "Hylte",
    skolenhetsKod: "28921911",
    skolenhetensNamn: "Unnaryds skola",
    postnr: "31480",
    besökspostnr: "31083",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "70167853",
    skolenhetensNamn: "Andersbergsskolan F-3",
    postnr: "30105",
    besökspostnr: "30255",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "64647994",
    skolenhetensNamn: "Brearedsskolan F-6",
    postnr: "30105",
    besökspostnr: "31038",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "50564422",
    skolenhetensNamn: "Bäckagårdsskolan F-5",
    postnr: "30105",
    besökspostnr: "30241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "28459364",
    skolenhetensNamn: "Eketånga Montessoriskola",
    postnr: "30240",
    besökspostnr: "30240",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "61728212",
    skolenhetensNamn: "Eldsbergaskolan F-6",
    postnr: "30105",
    besökspostnr: "30244",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "81285832",
    skolenhetensNamn: "Esperedsskolan F-6",
    postnr: "30105",
    besökspostnr: "30247",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "23491900",
    skolenhetensNamn: "Frennarps byskola F-3",
    postnr: "30105",
    besökspostnr: "30247",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "51685096",
    skolenhetensNamn: "Frösakullsskolan F-4",
    postnr: "30105",
    besökspostnr: "30247",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "44028674",
    skolenhetensNamn: "Furulundsskolan F-3",
    postnr: "30105",
    besökspostnr: "30247",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "58934713",
    skolenhetensNamn: "Fyllingeskolan F-3",
    postnr: "30105",
    besökspostnr: "31044",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "99829869",
    skolenhetensNamn: "Getingeskolan F-3",
    postnr: "30105",
    besökspostnr: "31041",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "62072720",
    skolenhetensNamn: "Gullbrandstorpsskolan F-3",
    postnr: "30105",
    besökspostnr: "30279",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "25323576",
    skolenhetensNamn: "Haverdals byskola F-5",
    postnr: "30105",
    besökspostnr: "30241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "18685784",
    skolenhetensNamn: "Jutarumsskolan F-4",
    postnr: "30105",
    besökspostnr: "30238",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "21578641",
    skolenhetensNamn: "Kvibilleskolan F-6",
    postnr: "30102",
    besökspostnr: "30251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "54036921",
    skolenhetensNamn: "Linehedsskolan F-3",
    postnr: "30105",
    besökspostnr: "30244",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "81339748",
    skolenhetensNamn: "Lummerskolan grs",
    postnr: "30105",
    besökspostnr: "30240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "35195991",
    skolenhetensNamn: "Lyngåkraskolan F-5",
    postnr: "30105",
    besökspostnr: "30244",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "99784968",
    skolenhetensNamn: "Nyhemsskolan F-3",
    postnr: "30105",
    besökspostnr: "30244",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "15130387",
    skolenhetensNamn: "Slottsjordsskolan F-3 A",
    postnr: "30105",
    besökspostnr: "30236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "92874341",
    skolenhetensNamn: "Slottsjordsskolan F-3 B",
    postnr: "30105",
    besökspostnr: "30236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "62917382",
    skolenhetensNamn: "Slottsjordsskolan F-3 C",
    postnr: "30105",
    besökspostnr: "30236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "43539930",
    skolenhetensNamn: "Snöstorpsskolan F-5",
    postnr: "30105",
    besökspostnr: "30256",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "75899176",
    skolenhetensNamn: "Sofiebergsskolan F",
    postnr: "30105",
    besökspostnr: "30250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "35619413",
    skolenhetensNamn: "Steningeskolan F-3",
    postnr: "30105",
    besökspostnr: "30250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "74641631",
    skolenhetensNamn: "Söndrumsskolan F-5",
    postnr: "30241",
    besökspostnr: "30241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "83383844",
    skolenhetensNamn: "Trönningeskolan F-3",
    postnr: "30105",
    besökspostnr: "30258",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "16275809",
    skolenhetensNamn: "Valhallaskolan F-6",
    postnr: "30105",
    besökspostnr: "30238",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "42422737",
    skolenhetensNamn: "Wallbergsskolan",
    postnr: "30231",
    besökspostnr: "30231",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "19274764",
    skolenhetensNamn: "Vallåsskolan F-3 A",
    postnr: "30105",
    besökspostnr: "30266",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "61582926",
    skolenhetensNamn: "Vittra Halmstad",
    postnr: "30233",
    besökspostnr: "30233",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1380",
    kommunsNamn: "Halmstad",
    skolenhetsKod: "90287874",
    skolenhetensNamn: "Åledsskolan F-6",
    postnr: "30105",
    besökspostnr: "30270",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "37733735",
    skolenhetensNamn: "Ahlaskolan",
    postnr: "31280",
    besökspostnr: "31293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "89494668",
    skolenhetensNamn: "Centrumskolan - Glänninge resursskola",
    postnr: "31280",
    besökspostnr: "31236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "98343454",
    skolenhetensNamn: "Centrumskolan - Lagahöjd resursskola",
    postnr: "31280",
    besökspostnr: "31231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "91969425",
    skolenhetensNamn: "Genevadsskolan",
    postnr: "31280",
    besökspostnr: "31021",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "21359430",
    skolenhetensNamn: "Glänningeskolan",
    postnr: "31280",
    besökspostnr: "31236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "35596848",
    skolenhetensNamn: "Hasslövs Skola Ideell Förening",
    postnr: "31297",
    besökspostnr: "31297",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "59683214",
    skolenhetensNamn: "Hishultsskolan",
    postnr: "31280",
    besökspostnr: "31231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "87530959",
    skolenhetensNamn: "Knäredsskolan",
    postnr: "31280",
    besökspostnr: "31231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "76368627",
    skolenhetensNamn: "Mellbystrandsskolan",
    postnr: "31280",
    besökspostnr: "31261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "85345776",
    skolenhetensNamn: "Parkskolan",
    postnr: "31280",
    besökspostnr: "31250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "46467884",
    skolenhetensNamn: "Skottorpsskolan",
    postnr: "31280",
    besökspostnr: "31245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "61290860",
    skolenhetensNamn: "Vallbergaskolan",
    postnr: "31280",
    besökspostnr: "31245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "73088689",
    skolenhetensNamn: "Veingeskolan F-6",
    postnr: "31280",
    besökspostnr: "31294",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "40523308",
    skolenhetensNamn: "Ysbyskolan",
    postnr: "31294",
    besökspostnr: "31294",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1381",
    kommunsNamn: "Laholm",
    skolenhetsKod: "29824923",
    skolenhetensNamn: "Öringe Skola",
    postnr: "31291",
    besökspostnr: "31291",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "41605059",
    skolenhetensNamn: "Apelskolan",
    postnr: "31160",
    besökspostnr: "31160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "14729346",
    skolenhetensNamn: "Fageredssskolan",
    postnr: "31160",
    besökspostnr: "31162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "10237615",
    skolenhetensNamn: "Fajansskolan",
    postnr: "31137",
    besökspostnr: "31137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "78647439",
    skolenhetensNamn: "Falkenbergs Montessoriskola",
    postnr: "31136",
    besökspostnr: "31136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "42973495",
    skolenhetensNamn: "Hertingsskolan",
    postnr: "31145",
    besökspostnr: "31145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "37122951",
    skolenhetensNamn: "Hjortsbergsskolan",
    postnr: "31141",
    besökspostnr: "31141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "61787701",
    skolenhetensNamn: "Ljungbyskolan",
    postnr: "31195",
    besökspostnr: "31195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "69301003",
    skolenhetensNamn: "Långavekaskolan",
    postnr: "31133",
    besökspostnr: "31198",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "24273275",
    skolenhetensNamn: "Långåsskolan",
    postnr: "31133",
    besökspostnr: "31193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "12266600",
    skolenhetensNamn: "Morups friskola",
    postnr: "31190",
    besökspostnr: "31190",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "48567965",
    skolenhetensNamn: "Okome skola",
    postnr: "31160",
    besökspostnr: "31160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "74631634",
    skolenhetensNamn: "Schubergstorpsskolan",
    postnr: "31132",
    besökspostnr: "31174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "34104887",
    skolenhetensNamn: "Skogstorpsskolan 1",
    postnr: "31133",
    besökspostnr: "31133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "29837397",
    skolenhetensNamn: "Slöingeskolan",
    postnr: "31196",
    besökspostnr: "31168",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "59144575",
    skolenhetensNamn: "Söderskolan",
    postnr: "31196",
    besökspostnr: "31196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "97555379",
    skolenhetensNamn: "Thoren Framtid i Falkenberg",
    postnr: "31170",
    besökspostnr: "31170",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "59080390",
    skolenhetensNamn: "Tångaskolan 1",
    postnr: "31136",
    besökspostnr: "31136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "61569087",
    skolenhetensNamn: "Vessigebroskolan",
    postnr: "31160",
    besökspostnr: "31164",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "35136963",
    skolenhetensNamn: "Vesterhavsskolan",
    postnr: "31148",
    besökspostnr: "31148",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "24744194",
    skolenhetensNamn: "Vinbergsskolan",
    postnr: "31194",
    besökspostnr: "31194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "62814243",
    skolenhetensNamn: "Årstadsskolan",
    postnr: "31196",
    besökspostnr: "31197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "78790933",
    skolenhetensNamn: "Älvseredsskolan",
    postnr: "31160",
    besökspostnr: "31063",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1382",
    kommunsNamn: "Falkenberg",
    skolenhetsKod: "15702531",
    skolenhetensNamn: "Ätranskolan",
    postnr: "31160",
    besökspostnr: "31151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "38621807",
    skolenhetensNamn: "Ankarskolan F-3 och fritidshem",
    postnr: "43274",
    besökspostnr: "43274",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "54709952",
    skolenhetensNamn: "Bläshammar skola F-6 och fritidshem",
    postnr: "43248",
    besökspostnr: "43248",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "31680839",
    skolenhetensNamn: "Bockstensskolan F-2 och fritidshem",
    postnr: "43239",
    besökspostnr: "43239",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "39064377",
    skolenhetensNamn: "Bosgårdsskolan F-3 och fritidshem",
    postnr: "43279",
    besökspostnr: "43279",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "11061967",
    skolenhetensNamn: "Buaskolan F-6 och fritidshem",
    postnr: "43263",
    besökspostnr: "43263",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "44822255",
    skolenhetensNamn: "Deromeskolan F-3 och fritidshem",
    postnr: "43268",
    besökspostnr: "43268",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "86828079",
    skolenhetensNamn: "Furubergsskolan F-klass och fritidshem",
    postnr: "43236",
    besökspostnr: "43236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "67801661",
    skolenhetensNamn: "Göthriks skola F-6 och fritidshem",
    postnr: "43291",
    besökspostnr: "43291",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "36022339",
    skolenhetensNamn: "Hagaskolan F-3 och fritidshem",
    postnr: "43242",
    besökspostnr: "43242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "30677357",
    skolenhetensNamn: "Kung Karl skola F-6 och fritidshem",
    postnr: "51992",
    besökspostnr: "51992",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "52088732",
    skolenhetensNamn: "Lindbergs skola F-3 och fritidshem",
    postnr: "43292",
    besökspostnr: "43292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "62599057",
    skolenhetensNamn: "Mariedalsskolan F-3 och fritidshem",
    postnr: "43250",
    besökspostnr: "43250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "74843338",
    skolenhetensNamn: "Nyhemsskolan Varbergs Krist sk",
    postnr: "43235",
    besökspostnr: "43235",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "42127916",
    skolenhetensNamn: "Påskbergsskolan F-3 och fritidshem",
    postnr: "43243",
    besökspostnr: "43243",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "17803296",
    skolenhetensNamn: "Rolfstorps skola F-3 och fritidshem",
    postnr: "43297",
    besökspostnr: "43297",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "24218413",
    skolenhetensNamn: "Sibbarps skola F-6 och fritidshem",
    postnr: "43277",
    besökspostnr: "43277",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "26737694",
    skolenhetensNamn: "Skällinge skola F-3 och fritidshem",
    postnr: "43299",
    besökspostnr: "43299",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "45240035",
    skolenhetensNamn: "Spannarps skola F-3 och fritidshem",
    postnr: "43274",
    besökspostnr: "43277",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "14839197",
    skolenhetensNamn: "Trönninge skola F-3 och fritidshem",
    postnr: "43292",
    besökspostnr: "43292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "68980561",
    skolenhetensNamn: "Varbergs Montessoriskola",
    postnr: "43245",
    besökspostnr: "43245",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "24688208",
    skolenhetensNamn: "Vidhögeskolan F-3 och fritidshem",
    postnr: "43267",
    besökspostnr: "43267",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1383",
    kommunsNamn: "Varberg",
    skolenhetsKod: "94908515",
    skolenhetensNamn: "Väröbackaskolan F-3 och fritidshem",
    postnr: "43265",
    besökspostnr: "43265",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "65390344",
    skolenhetensNamn: "Björkris skola F-6",
    postnr: "43481",
    besökspostnr: "43460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "44247625",
    skolenhetensNamn: "Fjordskolan Kungen",
    postnr: "43481",
    besökspostnr: "43935",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "22251626",
    skolenhetensNamn: "Fjärås Bräckaskolan Gaia",
    postnr: "43481",
    besökspostnr: "43962",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "19922386",
    skolenhetensNamn: "Fjärås Bräckaskolan Tellus",
    postnr: "43481",
    besökspostnr: "43972",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "99043601",
    skolenhetensNamn: "Frillesåsskolan F-3",
    postnr: "43481",
    besökspostnr: "43962",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "96836168",
    skolenhetensNamn: "Fullriggaren Malevik F-3",
    postnr: "43481",
    besökspostnr: "42934",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "59624096",
    skolenhetensNamn: "Furulidsskolan",
    postnr: "43481",
    besökspostnr: "43492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "90784555",
    skolenhetensNamn: "Gottskär Grundskola",
    postnr: "43993",
    besökspostnr: "43993",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "70505032",
    skolenhetensNamn: "Gullregnsskolan",
    postnr: "43481",
    besökspostnr: "43444",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "15914381",
    skolenhetensNamn: "Gällingeskolan Blå",
    postnr: "43481",
    besökspostnr: "43975",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "29443968",
    skolenhetensNamn: "Hedeskolan F-3",
    postnr: "43481",
    besökspostnr: "43443",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "25278170",
    skolenhetensNamn: "Internationella Engelska Skolan Kungsbacka",
    postnr: "43450",
    besökspostnr: "43450",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "53535119",
    skolenhetensNamn: "Iseråsskolan Körsbärsdalen",
    postnr: "43481",
    besökspostnr: "43930",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "10902849",
    skolenhetensNamn: "KMS Kullaviks Montessoriskola",
    postnr: "42932",
    besökspostnr: "42932",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "10220991",
    skolenhetensNamn: "Kollaskolan F-3 Gul",
    postnr: "43481",
    besökspostnr: "43451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "16481707",
    skolenhetensNamn: "Kullaviksskolan F-3",
    postnr: "43481",
    besökspostnr: "42931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "47832100",
    skolenhetensNamn: "Nova Montessoriskola",
    postnr: "43423",
    besökspostnr: "43432",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "70319074",
    skolenhetensNamn: "Pontos Grundskola",
    postnr: "42911",
    besökspostnr: "43992",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "27211026",
    skolenhetensNamn: "Presseskolan F-3",
    postnr: "43481",
    besökspostnr: "43932",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "57869288",
    skolenhetensNamn: "Särö Montessoriskola",
    postnr: "42941",
    besökspostnr: "42941",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "32837182",
    skolenhetensNamn: "Särö skola F-4",
    postnr: "42911",
    besökspostnr: "42942",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "69028103",
    skolenhetensNamn: "Tingbergsskolan A-C",
    postnr: "43481",
    besökspostnr: "43433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "50013979",
    skolenhetensNamn: "Tingbergsskolan B",
    postnr: "43481",
    besökspostnr: "43433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "17237309",
    skolenhetensNamn: "Toråsskolan F-3",
    postnr: "43481",
    besökspostnr: "43490",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "42568760",
    skolenhetensNamn: "Vittra Forsgläntan",
    postnr: "43446",
    besökspostnr: "43446",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "38085441",
    skolenhetensNamn: "Åsa Gårdsskola F-3",
    postnr: "43481",
    besökspostnr: "43954",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "44974182",
    skolenhetensNamn: "Åsaskolan F-3",
    postnr: "43481",
    besökspostnr: "43496",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "13",
    länsNamn: "Hallands län",
    kommun: "1384",
    kommunsNamn: "Kungsbacka",
    skolenhetsKod: "55524589",
    skolenhetensNamn: "Älvsåkersskolan F-3",
    postnr: "43481",
    besökspostnr: "43496",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "57200977",
    skolenhetensNamn: "Backaskolan F-3",
    postnr: "43836",
    besökspostnr: "43836",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "62830537",
    skolenhetensNamn: "Eskilsbyskolan F-2",
    postnr: "43893",
    besökspostnr: "43893",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "27274628",
    skolenhetensNamn: "Fridaskolan, Härryda",
    postnr: "43542",
    besökspostnr: "43542",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "39189591",
    skolenhetensNamn: "Furuhällsskolan F-3",
    postnr: "43544",
    besökspostnr: "43544",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "13949022",
    skolenhetensNamn: "Hindåsskolan F-3",
    postnr: "43580",
    besökspostnr: "43853",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "57795411",
    skolenhetensNamn: "Hällingsjöskolan F-5",
    postnr: "43896",
    besökspostnr: "43896",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "81048920",
    skolenhetensNamn: "Härrydaskolan",
    postnr: "43892",
    besökspostnr: "43892",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "92600497",
    skolenhetensNamn: "Högadalsskolan F-6",
    postnr: "43537",
    besökspostnr: "43537",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "20152644",
    skolenhetensNamn: "Lunnaskolan F-3 A+B",
    postnr: "43580",
    besökspostnr: "43833",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "82464071",
    skolenhetensNamn: "Lunnaskolan F-3 C+D",
    postnr: "43580",
    besökspostnr: "43833",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "33400568",
    skolenhetensNamn: "Rävlandaskolan F-6",
    postnr: "43897",
    besökspostnr: "43897",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "95834239",
    skolenhetensNamn: "Skinnefjällsskolan F-3",
    postnr: "43540",
    besökspostnr: "43540",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "30507569",
    skolenhetensNamn: "Säteriskolan F-3",
    postnr: "43531",
    besökspostnr: "43531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1401",
    kommunsNamn: "Härryda",
    skolenhetsKod: "44612766",
    skolenhetensNamn: "Vällsjöskolan",
    postnr: "43542",
    besökspostnr: "43542",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "54361227",
    skolenhetensNamn: "Björndammens skola F-6",
    postnr: "43344",
    besökspostnr: "43344",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "68358072",
    skolenhetensNamn: "Casa Montessori",
    postnr: "43330",
    besökspostnr: "43330",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "69175568",
    skolenhetensNamn: "Furulunds skola F-6",
    postnr: "43347",
    besökspostnr: "43347",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "60280777",
    skolenhetensNamn: "Jonsereds skola F-5",
    postnr: "43375",
    besökspostnr: "43375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "10695761",
    skolenhetensNamn: "Lillegårdsskolan F-5",
    postnr: "43332",
    besökspostnr: "43332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "88535398",
    skolenhetensNamn: "Oxledsskolan ",
    postnr: "43370",
    besökspostnr: "43370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "12767465",
    skolenhetensNamn: "Skulltorps skola F-5",
    postnr: "43337",
    besökspostnr: "43337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "84718487",
    skolenhetensNamn: "Ugglums skola F-4",
    postnr: "43361",
    besökspostnr: "43361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "65226712",
    skolenhetensNamn: "Vallhamra skola F-3",
    postnr: "43365",
    besökspostnr: "43365",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "67739660",
    skolenhetensNamn: "Öjersjö Brunn skola A F-4",
    postnr: "43351",
    besökspostnr: "43351",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1402",
    kommunsNamn: "Partille",
    skolenhetsKod: "85130794",
    skolenhetensNamn: "Öjersjö Brunn skola B F-4",
    postnr: "43351",
    besökspostnr: "43351",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1407",
    kommunsNamn: "Öckerö",
    skolenhetsKod: "46441044",
    skolenhetensNamn: "Bergagårdsskolan",
    postnr: "47580",
    besökspostnr: "47541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1407",
    kommunsNamn: "Öckerö",
    skolenhetsKod: "65677988",
    skolenhetensNamn: "Betelskolan",
    postnr: "47542",
    besökspostnr: "47542",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1407",
    kommunsNamn: "Öckerö",
    skolenhetsKod: "70498449",
    skolenhetensNamn: "Björkö skola",
    postnr: "47580",
    besökspostnr: "47537",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1407",
    kommunsNamn: "Öckerö",
    skolenhetsKod: "53126890",
    skolenhetensNamn: "Fotö skola",
    postnr: "47580",
    besökspostnr: "47540",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1407",
    kommunsNamn: "Öckerö",
    skolenhetsKod: "20144959",
    skolenhetensNamn: "Hälsö skola",
    postnr: "47580",
    besökspostnr: "47551",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1407",
    kommunsNamn: "Öckerö",
    skolenhetsKod: "36423290",
    skolenhetensNamn: "Kompassenskolan",
    postnr: "47580",
    besökspostnr: "47531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1407",
    kommunsNamn: "Öckerö",
    skolenhetsKod: "22044740",
    skolenhetensNamn: "Rörö skola",
    postnr: "47580",
    besökspostnr: "47531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "81521477",
    skolenhetensNamn: "Ekenässkolan",
    postnr: "44495",
    besökspostnr: "44495",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "67573705",
    skolenhetensNamn: "Hallernaskolan",
    postnr: "44452",
    besökspostnr: "44452",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "68179041",
    skolenhetensNamn: "Jörlandaskolan",
    postnr: "44465",
    besökspostnr: "44465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "38508908",
    skolenhetensNamn: "Kopperskolan",
    postnr: "44442",
    besökspostnr: "44442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "92548657",
    skolenhetensNamn: "Kristinedalskolan F-6",
    postnr: "44447",
    besökspostnr: "44447",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "32571908",
    skolenhetensNamn: "Kristinedal Trubaduren, grundsärskola",
    postnr: "44447",
    besökspostnr: "44447",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "57205367",
    skolenhetensNamn: "Kyrkenorumskolan",
    postnr: "44446",
    besökspostnr: "44446",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "96922755",
    skolenhetensNamn: "Spekerödskolan",
    postnr: "44493",
    besökspostnr: "44493",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "38786895",
    skolenhetensNamn: "Stenungsunds Montessoriskola",
    postnr: "44431",
    besökspostnr: "44431",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "59097955",
    skolenhetensNamn: "Stora Högaskolan F-6",
    postnr: "44460",
    besökspostnr: "44460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1415",
    kommunsNamn: "Stenungsund",
    skolenhetsKod: "44666664",
    skolenhetensNamn: "Ucklumskolan",
    postnr: "44494",
    besökspostnr: "44494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1419",
    kommunsNamn: "Tjörn",
    skolenhetsKod: "91601937",
    skolenhetensNamn: "Fridas Hage",
    postnr: "47170",
    besökspostnr: "47170",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1419",
    kommunsNamn: "Tjörn",
    skolenhetsKod: "97207389",
    skolenhetensNamn: "Kållekärr skola",
    postnr: "47194",
    besökspostnr: "47194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1419",
    kommunsNamn: "Tjörn",
    skolenhetsKod: "32497986",
    skolenhetensNamn: "Myggenäs skola",
    postnr: "47160",
    besökspostnr: "47160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1419",
    kommunsNamn: "Tjörn",
    skolenhetsKod: "65647489",
    skolenhetensNamn: "Rönnäng skola",
    postnr: "47142",
    besökspostnr: "47142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1419",
    kommunsNamn: "Tjörn",
    skolenhetsKod: "82162039",
    skolenhetensNamn: "Skärhamns skola",
    postnr: "47131",
    besökspostnr: "47131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1419",
    kommunsNamn: "Tjörn",
    skolenhetsKod: "89065021",
    skolenhetensNamn: "Tjörns montessori skola och förskola",
    postnr: "47191",
    besökspostnr: "47191",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1421",
    kommunsNamn: "Orust",
    skolenhetsKod: "44875711",
    skolenhetensNamn: "Ellös skola",
    postnr: "47431",
    besökspostnr: "47431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1421",
    kommunsNamn: "Orust",
    skolenhetsKod: "24399504",
    skolenhetensNamn: "Henåns skola F-3",
    postnr: "47332",
    besökspostnr: "47332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1421",
    kommunsNamn: "Orust",
    skolenhetsKod: "31200039",
    skolenhetensNamn: "Orust Montessoriskola",
    postnr: "47332",
    besökspostnr: "47332",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1421",
    kommunsNamn: "Orust",
    skolenhetsKod: "46028327",
    skolenhetensNamn: "Solskolan",
    postnr: "47392",
    besökspostnr: "47392",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1421",
    kommunsNamn: "Orust",
    skolenhetsKod: "46609170",
    skolenhetensNamn: "Stensbo skola",
    postnr: "47494",
    besökspostnr: "47494",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1421",
    kommunsNamn: "Orust",
    skolenhetsKod: "89541556",
    skolenhetensNamn: "Varekils skola",
    postnr: "47295",
    besökspostnr: "47295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1421",
    kommunsNamn: "Orust",
    skolenhetsKod: "90671452",
    skolenhetensNamn: "Ängås skola F-3",
    postnr: "47232",
    besökspostnr: "47232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1427",
    kommunsNamn: "Sotenäs",
    skolenhetsKod: "79203245",
    skolenhetensNamn: "Bovallstrands skola",
    postnr: "45680",
    besökspostnr: "45647",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1427",
    kommunsNamn: "Sotenäs",
    skolenhetsKod: "93176070",
    skolenhetensNamn: "Hunnebostrands skola",
    postnr: "45680",
    besökspostnr: "45661",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1427",
    kommunsNamn: "Sotenäs",
    skolenhetsKod: "72249764",
    skolenhetensNamn: "Smögens skola",
    postnr: "45680",
    besökspostnr: "45661",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1427",
    kommunsNamn: "Sotenäs",
    skolenhetsKod: "33799734",
    skolenhetensNamn: "Särskolan ,Integrerade särskoleelever,",
    postnr: "45680",
    besökspostnr: "45632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1427",
    kommunsNamn: "Sotenäs",
    skolenhetsKod: "15307329",
    skolenhetensNamn: "Åsenskolan",
    postnr: "45680",
    besökspostnr: "45632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1430",
    kommunsNamn: "Munkedal",
    skolenhetsKod: "80093339",
    skolenhetensNamn: "Bruksskolan",
    postnr: "45534",
    besökspostnr: "45534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1430",
    kommunsNamn: "Munkedal",
    skolenhetsKod: "24798079",
    skolenhetensNamn: "Hedekas skola",
    postnr: "45541",
    besökspostnr: "45541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1430",
    kommunsNamn: "Munkedal",
    skolenhetsKod: "64957366",
    skolenhetensNamn: "Hällevadsholms skola",
    postnr: "45760",
    besökspostnr: "45760",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1430",
    kommunsNamn: "Munkedal",
    skolenhetsKod: "11715340",
    skolenhetensNamn: "Munkedalsskolan",
    postnr: "45580",
    besökspostnr: "45530",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1435",
    kommunsNamn: "Tanum",
    skolenhetsKod: "49130128",
    skolenhetensNamn: "Backa skola",
    postnr: "45782",
    besökspostnr: "45750",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1435",
    kommunsNamn: "Tanum",
    skolenhetsKod: "22465670",
    skolenhetensNamn: "Fjällbacka skola",
    postnr: "45782",
    besökspostnr: "45071",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1435",
    kommunsNamn: "Tanum",
    skolenhetsKod: "20334544",
    skolenhetensNamn: "Grebbestadskolan",
    postnr: "45782",
    besökspostnr: "45745",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1435",
    kommunsNamn: "Tanum",
    skolenhetsKod: "81072495",
    skolenhetensNamn: "Hamburgsundskolan F-9",
    postnr: "45782",
    besökspostnr: "45745",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1435",
    kommunsNamn: "Tanum",
    skolenhetsKod: "21203914",
    skolenhetensNamn: "Lurs skola",
    postnr: "45782",
    besökspostnr: "45755",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1435",
    kommunsNamn: "Tanum",
    skolenhetsKod: "35591063",
    skolenhetensNamn: "Rabbalshede skola",
    postnr: "45782",
    besökspostnr: "45755",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1435",
    kommunsNamn: "Tanum",
    skolenhetsKod: "51364488",
    skolenhetensNamn: "Sjöstjärneskolan",
    postnr: "45797",
    besökspostnr: "45797",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1435",
    kommunsNamn: "Tanum",
    skolenhetsKod: "10663043",
    skolenhetensNamn: "Tanumskolan F-6",
    postnr: "45782",
    besökspostnr: "45731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1438",
    kommunsNamn: "Dals-Ed",
    skolenhetsKod: "62394711",
    skolenhetensNamn: "Snörrumskolan",
    postnr: "66821",
    besökspostnr: "66832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1439",
    kommunsNamn: "Färgelanda",
    skolenhetsKod: "83198353",
    skolenhetensNamn: "Högsäter skola",
    postnr: "45870",
    besökspostnr: "45870",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1439",
    kommunsNamn: "Färgelanda",
    skolenhetsKod: "79397845",
    skolenhetensNamn: "Höjdenskolan",
    postnr: "45831",
    besökspostnr: "45831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1439",
    kommunsNamn: "Färgelanda",
    skolenhetsKod: "39533335",
    skolenhetensNamn: "Järbo friskola",
    postnr: "45897",
    besökspostnr: "45897",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1439",
    kommunsNamn: "Färgelanda",
    skolenhetsKod: "87759640",
    skolenhetensNamn: "Stigens Friskola AB",
    postnr: "45894",
    besökspostnr: "45894",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1439",
    kommunsNamn: "Färgelanda",
    skolenhetsKod: "35061408",
    skolenhetensNamn: "Ödeborgs skola",
    postnr: "45840",
    besökspostnr: "45840",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1440",
    kommunsNamn: "Ale",
    skolenhetsKod: "71387206",
    skolenhetensNamn: "Ahlafors Fria skola",
    postnr: "44914",
    besökspostnr: "44951",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1440",
    kommunsNamn: "Ale",
    skolenhetsKod: "11043800",
    skolenhetensNamn: "Bohusskolan",
    postnr: "44980",
    besökspostnr: "44537",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1440",
    kommunsNamn: "Ale",
    skolenhetsKod: "97093265",
    skolenhetensNamn: "Garnvindeskolan",
    postnr: "44980",
    besökspostnr: "44641",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1440",
    kommunsNamn: "Ale",
    skolenhetsKod: "54807007",
    skolenhetensNamn: "Himlaskolan",
    postnr: "44980",
    besökspostnr: "44951",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1440",
    kommunsNamn: "Ale",
    skolenhetsKod: "59239104",
    skolenhetensNamn: "Kronaskolan F-3",
    postnr: "44980",
    besökspostnr: "44634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1440",
    kommunsNamn: "Ale",
    skolenhetsKod: "12789480",
    skolenhetensNamn: "Nolskolan",
    postnr: "44980",
    besökspostnr: "44942",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1440",
    kommunsNamn: "Ale",
    skolenhetsKod: "22564015",
    skolenhetensNamn: "Nödingeskolan",
    postnr: "44980",
    besökspostnr: "44931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1440",
    kommunsNamn: "Ale",
    skolenhetsKod: "81439850",
    skolenhetensNamn: "Surteskolan",
    postnr: "44980",
    besökspostnr: "44556",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "27282475",
    skolenhetensNamn: "Almekärrsskolan skolenhet 1",
    postnr: "44380",
    besökspostnr: "44339",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "92895758",
    skolenhetensNamn: "Aspen Montessori",
    postnr: "44351",
    besökspostnr: "44351",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "11632851",
    skolenhetensNamn: "Aspenässkolan 1",
    postnr: "44380",
    besökspostnr: "44331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "27210933",
    skolenhetensNamn: "Berghultskolan 1",
    postnr: "44832",
    besökspostnr: "44832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "88544643",
    skolenhetensNamn: "Centralskolan",
    postnr: "44380",
    besökspostnr: "44830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "96933862",
    skolenhetensNamn: "Hulanskolan 1",
    postnr: "44351",
    besökspostnr: "44351",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "80799891",
    skolenhetensNamn: "Hästhagenskolan 1",
    postnr: "44380",
    besökspostnr: "44836",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "11677656",
    skolenhetensNamn: "Knappekullaskolan skolenhet 1",
    postnr: "44380",
    besökspostnr: "44335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "13638561",
    skolenhetensNamn: "Lekstorpsskolan A",
    postnr: "44340",
    besökspostnr: "44340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "44095064",
    skolenhetensNamn: "Ljungviksskolan skolenhet A",
    postnr: "44370",
    besökspostnr: "44370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "82651095",
    skolenhetensNamn: "Montessoriskolan Floda säteri",
    postnr: "44830",
    besökspostnr: "44830",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "60035054",
    skolenhetensNamn: "Röselidsskolan skolenhet A",
    postnr: "44342",
    besökspostnr: "44342",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "67114690",
    skolenhetensNamn: "Stenkulan F-3",
    postnr: "44331",
    besökspostnr: "44331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "72684276",
    skolenhetensNamn: "Tolleredskolan",
    postnr: "44380",
    besökspostnr: "44850",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "69057737",
    skolenhetensNamn: "Uddaredsskolan",
    postnr: "44380",
    besökspostnr: "44837",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1441",
    kommunsNamn: "Lerum",
    skolenhetsKod: "97540573",
    skolenhetensNamn: "Östad skola",
    postnr: "44374",
    besökspostnr: "44374",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1442",
    kommunsNamn: "Vårgårda",
    skolenhetsKod: "75974498",
    skolenhetensNamn: "Asklanda skola",
    postnr: "44780",
    besökspostnr: "44793",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1442",
    kommunsNamn: "Vårgårda",
    skolenhetsKod: "29725783",
    skolenhetensNamn: "Fridhemskolan",
    postnr: "44780",
    besökspostnr: "44734",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1442",
    kommunsNamn: "Vårgårda",
    skolenhetsKod: "50494263",
    skolenhetensNamn: "Hols skola",
    postnr: "44780",
    besökspostnr: "44193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1442",
    kommunsNamn: "Vårgårda",
    skolenhetsKod: "13436901",
    skolenhetensNamn: "Lena skola",
    postnr: "44780",
    besökspostnr: "44192",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1442",
    kommunsNamn: "Vårgårda",
    skolenhetsKod: "83517039",
    skolenhetensNamn: "Lindbladskolan F-3",
    postnr: "44731",
    besökspostnr: "44731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1442",
    kommunsNamn: "Vårgårda",
    skolenhetsKod: "92287124",
    skolenhetensNamn: "Nårunga skola",
    postnr: "44780",
    besökspostnr: "44794",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1443",
    kommunsNamn: "Bollebygd",
    skolenhetsKod: "39280013",
    skolenhetensNamn: "Bollebygdskolan F-3",
    postnr: "51784",
    besökspostnr: "51734",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1443",
    kommunsNamn: "Bollebygd",
    skolenhetsKod: "43939920",
    skolenhetensNamn: "Föräldrakooperativet Gadden",
    postnr: "51737",
    besökspostnr: "51737",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1443",
    kommunsNamn: "Bollebygd",
    skolenhetsKod: "45052222",
    skolenhetensNamn: "Töllsjöskolan",
    postnr: "51770",
    besökspostnr: "51770",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1443",
    kommunsNamn: "Bollebygd",
    skolenhetsKod: "16196769",
    skolenhetensNamn: "Örelundskolan",
    postnr: "51734",
    besökspostnr: "51771",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1444",
    kommunsNamn: "Grästorp",
    skolenhetsKod: "54556634",
    skolenhetensNamn: "Lunnevienheten",
    postnr: "46735",
    besökspostnr: "46735",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1445",
    kommunsNamn: "Essunga",
    skolenhetsKod: "81777373",
    skolenhetensNamn: "Bredöls skola",
    postnr: "46582",
    besökspostnr: "46591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1445",
    kommunsNamn: "Essunga",
    skolenhetsKod: "58737998",
    skolenhetensNamn: "Jonslunds skola",
    postnr: "46582",
    besökspostnr: "46595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1445",
    kommunsNamn: "Essunga",
    skolenhetsKod: "57353499",
    skolenhetensNamn: "Nossebro skola år 0-5",
    postnr: "46582",
    besökspostnr: "46530",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1446",
    kommunsNamn: "Karlsborg",
    skolenhetsKod: "54962744",
    skolenhetensNamn: "Forsviks friskola",
    postnr: "54673",
    besökspostnr: "54673",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1446",
    kommunsNamn: "Karlsborg",
    skolenhetsKod: "72757148",
    skolenhetensNamn: "Mölltorpskolan",
    postnr: "54672",
    besökspostnr: "54672",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1446",
    kommunsNamn: "Karlsborg",
    skolenhetsKod: "22891571",
    skolenhetensNamn: "Strandskolan",
    postnr: "54631",
    besökspostnr: "54631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1447",
    kommunsNamn: "Gullspång",
    skolenhetsKod: "41746496",
    skolenhetensNamn: "Gullstensskolan åk F-6",
    postnr: "54221",
    besökspostnr: "54731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1447",
    kommunsNamn: "Gullspång",
    skolenhetsKod: "44509207",
    skolenhetensNamn: "Regnbågsskolan",
    postnr: "54221",
    besökspostnr: "54892",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1452",
    kommunsNamn: "Tranemo",
    skolenhetsKod: "87422942",
    skolenhetensNamn: "Dalstorpskolan",
    postnr: "51460",
    besökspostnr: "51460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1452",
    kommunsNamn: "Tranemo",
    skolenhetsKod: "82023614",
    skolenhetensNamn: "Grimsåsskolan",
    postnr: "51460",
    besökspostnr: "51470",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1452",
    kommunsNamn: "Tranemo",
    skolenhetsKod: "88207548",
    skolenhetensNamn: "Limmaredskolan",
    postnr: "51440",
    besökspostnr: "51455",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1452",
    kommunsNamn: "Tranemo",
    skolenhetsKod: "77589919",
    skolenhetensNamn: "Länghemskolan",
    postnr: "51443",
    besökspostnr: "51494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1452",
    kommunsNamn: "Tranemo",
    skolenhetsKod: "54601339",
    skolenhetensNamn: "Tranängskolan F-6",
    postnr: "51480",
    besökspostnr: "51433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1460",
    kommunsNamn: "Bengtsfors",
    skolenhetsKod: "46016643",
    skolenhetensNamn: "Bäckeskolan",
    postnr: "66840",
    besökspostnr: "66840",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1460",
    kommunsNamn: "Bengtsfors",
    skolenhetsKod: "95530848",
    skolenhetensNamn: "Ekhagsskolan",
    postnr: "66694",
    besökspostnr: "66694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1460",
    kommunsNamn: "Bengtsfors",
    skolenhetsKod: "89639468",
    skolenhetensNamn: "Franserudsskolan",
    postnr: "66621",
    besökspostnr: "66631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1461",
    kommunsNamn: "Mellerud",
    skolenhetsKod: "68467694",
    skolenhetensNamn: "Fagerlidsskolan",
    postnr: "46480",
    besökspostnr: "46432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1461",
    kommunsNamn: "Mellerud",
    skolenhetsKod: "81025260",
    skolenhetensNamn: "Karolinerskolan",
    postnr: "46450",
    besökspostnr: "46450",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1461",
    kommunsNamn: "Mellerud",
    skolenhetsKod: "28301176",
    skolenhetensNamn: "Nordalsskolan",
    postnr: "46480",
    besökspostnr: "46430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1461",
    kommunsNamn: "Mellerud",
    skolenhetsKod: "49291677",
    skolenhetensNamn: "Åsebro skola",
    postnr: "46493",
    besökspostnr: "46493",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1461",
    kommunsNamn: "Mellerud",
    skolenhetsKod: "37842901",
    skolenhetensNamn: "Åsen skola",
    postnr: "46440",
    besökspostnr: "46440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1462",
    kommunsNamn: "Lilla Edet",
    skolenhetsKod: "77417759",
    skolenhetensNamn: "Fuxernaskolan F-6",
    postnr: "46380",
    besökspostnr: "46380",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1462",
    kommunsNamn: "Lilla Edet",
    skolenhetsKod: "74771085",
    skolenhetensNamn: "Nya Skolan, Lilla Edet",
    postnr: "46138",
    besökspostnr: "46330",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1462",
    kommunsNamn: "Lilla Edet",
    skolenhetsKod: "19260226",
    skolenhetensNamn: "Ryrsjöskolan",
    postnr: "46335",
    besökspostnr: "46335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1462",
    kommunsNamn: "Lilla Edet",
    skolenhetsKod: "60978397",
    skolenhetensNamn: "Strömsskolan",
    postnr: "46332",
    besökspostnr: "46332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1462",
    kommunsNamn: "Lilla Edet",
    skolenhetsKod: "89394884",
    skolenhetensNamn: "Tingbergsskolan ",
    postnr: "46371",
    besökspostnr: "46371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "27924449",
    skolenhetensNamn: "Björketorpskolan",
    postnr: "51994",
    besökspostnr: "51994",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "75172236",
    skolenhetensNamn: "Fotskälskolan",
    postnr: "51996",
    besökspostnr: "51996",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "33369644",
    skolenhetensNamn: "Fritslaskolan",
    postnr: "51171",
    besökspostnr: "51171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "84184524",
    skolenhetensNamn: "Hyssnaskolan",
    postnr: "51168",
    besökspostnr: "51168",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "79844557",
    skolenhetensNamn: "Leteboskolan",
    postnr: "51990",
    besökspostnr: "51990",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "80072686",
    skolenhetensNamn: "Montessoriskolan Pärlan",
    postnr: "51162",
    besökspostnr: "51162",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "19909922",
    skolenhetensNamn: "Parkskolan",
    postnr: "51161",
    besökspostnr: "51161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "35972293",
    skolenhetensNamn: "Sjöbyskolan",
    postnr: "51930",
    besökspostnr: "51930",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "44027818",
    skolenhetensNamn: "Stommenskolan F-3",
    postnr: "51157",
    besökspostnr: "51157",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "60796098",
    skolenhetensNamn: "Strömskolan F-3",
    postnr: "51154",
    besökspostnr: "51154",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "34605264",
    skolenhetensNamn: "Sätilaskolan F-6",
    postnr: "51169",
    besökspostnr: "51169",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "53345308",
    skolenhetensNamn: "Torestorpskolan",
    postnr: "51193",
    besökspostnr: "51193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "12813506",
    skolenhetensNamn: "Ubbhultskolan",
    postnr: "43895",
    besökspostnr: "43895",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "46133825",
    skolenhetensNamn: "Örbyskolan F-3",
    postnr: "51131",
    besökspostnr: "51131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1463",
    kommunsNamn: "Mark",
    skolenhetsKod: "30040859",
    skolenhetensNamn: "Öxabäckskolan",
    postnr: "51195",
    besökspostnr: "51195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1465",
    kommunsNamn: "Svenljunga",
    skolenhetsKod: "28714876",
    skolenhetensNamn: "Hillareds skola",
    postnr: "51276",
    besökspostnr: "51276",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1465",
    kommunsNamn: "Svenljunga",
    skolenhetsKod: "36851594",
    skolenhetensNamn: "Landboskolan",
    postnr: "51251",
    besökspostnr: "51251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1465",
    kommunsNamn: "Svenljunga",
    skolenhetsKod: "24017095",
    skolenhetensNamn: "Mjöbäcks skola",
    postnr: "51265",
    besökspostnr: "51265",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1465",
    kommunsNamn: "Svenljunga",
    skolenhetsKod: "71010514",
    skolenhetensNamn: "Mogaskolan F-6",
    postnr: "51254",
    besökspostnr: "51254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1465",
    kommunsNamn: "Svenljunga",
    skolenhetsKod: "45241148",
    skolenhetensNamn: "Mårdaklevs skola",
    postnr: "51262",
    besökspostnr: "51262",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1465",
    kommunsNamn: "Svenljunga",
    skolenhetsKod: "45925310",
    skolenhetensNamn: "Sexdrega skola",
    postnr: "51277",
    besökspostnr: "51277",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1465",
    kommunsNamn: "Svenljunga",
    skolenhetsKod: "95945189",
    skolenhetensNamn: "Östra Frölunda skola",
    postnr: "51263",
    besökspostnr: "51263",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1466",
    kommunsNamn: "Herrljunga",
    skolenhetsKod: "55603851",
    skolenhetensNamn: "Eggvena skola",
    postnr: "52423",
    besökspostnr: "52491",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1466",
    kommunsNamn: "Herrljunga",
    skolenhetsKod: "44710845",
    skolenhetensNamn: "Eriksbergs skola",
    postnr: "52423",
    besökspostnr: "52493",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1466",
    kommunsNamn: "Herrljunga",
    skolenhetsKod: "48603324",
    skolenhetensNamn: "Horsbyskolan F-3",
    postnr: "52423",
    besökspostnr: "52432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1466",
    kommunsNamn: "Herrljunga",
    skolenhetsKod: "14576123",
    skolenhetensNamn: "Hudene skola",
    postnr: "52423",
    besökspostnr: "52492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1466",
    kommunsNamn: "Herrljunga",
    skolenhetsKod: "38841825",
    skolenhetensNamn: "Molla skola",
    postnr: "52423",
    besökspostnr: "52495",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1466",
    kommunsNamn: "Herrljunga",
    skolenhetsKod: "78630966",
    skolenhetensNamn: "Mörlandaskolan",
    postnr: "52423",
    besökspostnr: "52442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1466",
    kommunsNamn: "Herrljunga",
    skolenhetsKod: "31126240",
    skolenhetensNamn: "Ods skola",
    postnr: "52423",
    besökspostnr: "52496",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1470",
    kommunsNamn: "Vara",
    skolenhetsKod: "76523419",
    skolenhetensNamn: "Arentorps skola",
    postnr: "53494",
    besökspostnr: "53494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1470",
    kommunsNamn: "Vara",
    skolenhetsKod: "28319392",
    skolenhetensNamn: "Broholmskolan Jung",
    postnr: "53592",
    besökspostnr: "53592",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1470",
    kommunsNamn: "Vara",
    skolenhetsKod: "69387160",
    skolenhetensNamn: "Larv Tråvad skola",
    postnr: "53492",
    besökspostnr: "53492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1470",
    kommunsNamn: "Vara",
    skolenhetsKod: "87282627",
    skolenhetensNamn: "Levene skola",
    postnr: "53493",
    besökspostnr: "53473",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1470",
    kommunsNamn: "Vara",
    skolenhetsKod: "30527685",
    skolenhetensNamn: "Nästegårdsskolan fsk-åk6",
    postnr: "53530",
    besökspostnr: "53530",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1470",
    kommunsNamn: "Vara",
    skolenhetsKod: "62111546",
    skolenhetensNamn: "Stiftelsen Önums friskola",
    postnr: "53491",
    besökspostnr: "53491",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1470",
    kommunsNamn: "Vara",
    skolenhetsKod: "90185249",
    skolenhetensNamn: "Torsgårdsskolan",
    postnr: "53432",
    besökspostnr: "53432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1470",
    kommunsNamn: "Vara",
    skolenhetsKod: "12902856",
    skolenhetensNamn: "Vedums skola",
    postnr: "53460",
    besökspostnr: "53460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1471",
    kommunsNamn: "Götene",
    skolenhetsKod: "62884539",
    skolenhetensNamn: "Hällekisskolan",
    postnr: "53374",
    besökspostnr: "53374",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1471",
    kommunsNamn: "Götene",
    skolenhetsKod: "77691159",
    skolenhetensNamn: "Källby gård",
    postnr: "53173",
    besökspostnr: "53173",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1471",
    kommunsNamn: "Götene",
    skolenhetsKod: "59512837",
    skolenhetensNamn: "Ljungsbackenskolan",
    postnr: "53334",
    besökspostnr: "53334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1471",
    kommunsNamn: "Götene",
    skolenhetsKod: "24771373",
    skolenhetensNamn: "Lundabyn",
    postnr: "53372",
    besökspostnr: "53372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1471",
    kommunsNamn: "Götene",
    skolenhetsKod: "60697764",
    skolenhetensNamn: "Prästgårdsskolan",
    postnr: "53331",
    besökspostnr: "53331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1472",
    kommunsNamn: "Tibro",
    skolenhetsKod: "48800150",
    skolenhetensNamn: "Baggeboskolan",
    postnr: "54334",
    besökspostnr: "54334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1472",
    kommunsNamn: "Tibro",
    skolenhetsKod: "92595260",
    skolenhetensNamn: "Häggetorpsskolan",
    postnr: "54331",
    besökspostnr: "54331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1472",
    kommunsNamn: "Tibro",
    skolenhetsKod: "16249041",
    skolenhetensNamn: "Ransbergs skola",
    postnr: "54394",
    besökspostnr: "54333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1473",
    kommunsNamn: "Töreboda",
    skolenhetsKod: "91055758",
    skolenhetensNamn: "Fredsbergs Friskola",
    postnr: "54590",
    besökspostnr: "54590",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1473",
    kommunsNamn: "Töreboda",
    skolenhetsKod: "13492888",
    skolenhetensNamn: "Kilenskolan",
    postnr: "54522",
    besökspostnr: "54534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1473",
    kommunsNamn: "Töreboda",
    skolenhetsKod: "65599852",
    skolenhetensNamn: "Moholms skola",
    postnr: "54522",
    besökspostnr: "54541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1473",
    kommunsNamn: "Töreboda",
    skolenhetsKod: "55688408",
    skolenhetensNamn: "Älgarås skola",
    postnr: "54522",
    besökspostnr: "54572",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "40526633",
    skolenhetensNamn: "Algebraskolan",
    postnr: "42472",
    besökspostnr: "42355",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "66906957",
    skolenhetensNamn: "Annedalsskolan F-6",
    postnr: "40521",
    besökspostnr: "41128",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "42558944",
    skolenhetensNamn: "Askimsskolan F-6",
    postnr: "40521",
    besökspostnr: "43639",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "19582003",
    skolenhetensNamn: "Asperöskolan F-3",
    postnr: "43080",
    besökspostnr: "43080",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "25852980",
    skolenhetensNamn: "Assaredsskolan",
    postnr: "41536",
    besökspostnr: "41536",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "13475966",
    skolenhetensNamn: "Azbuka Lyceum Grundskola",
    postnr: "41311",
    besökspostnr: "41311",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "44439157",
    skolenhetensNamn: "Backaskolan F-6",
    postnr: "40521",
    besökspostnr: "42258",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "26299421",
    skolenhetensNamn: "Backegårdsskolan F-6",
    postnr: "40521",
    besökspostnr: "41521",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "25410468",
    skolenhetensNamn: "Bagaregårdsskolan F-6",
    postnr: "40521",
    besökspostnr: "41670",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "96685185",
    skolenhetensNamn: "Bergsgårdsskolan F-6",
    postnr: "40521",
    besökspostnr: "42431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "27526205",
    skolenhetensNamn: "Bergsjöskolan F-9",
    postnr: "40521",
    besökspostnr: "40521",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "43545297",
    skolenhetensNamn: "Bergumsskolan F-9",
    postnr: "40521",
    besökspostnr: "40521",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "37407044",
    skolenhetensNamn: "Bjurslättsskolan F-6",
    postnr: "40521",
    besökspostnr: "40521",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "43136650",
    skolenhetensNamn: "Björkåsskolan F-6",
    postnr: "40521",
    besökspostnr: "42133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "14567750",
    skolenhetensNamn: "Björlandaskolan F-6",
    postnr: "40521",
    besökspostnr: "40521",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "76096182",
    skolenhetensNamn: "Björsaredsskolan F-3",
    postnr: "40521",
    besökspostnr: "42471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "11295861",
    skolenhetensNamn: "Brandströmska skolan Nylöse",
    postnr: "40252",
    besökspostnr: "41503",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "67882295",
    skolenhetensNamn: "Brottkärrskolan F-6",
    postnr: "40521",
    besökspostnr: "43655",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "97649106",
    skolenhetensNamn: "Brunnsboskolan F-9",
    postnr: "40521",
    besökspostnr: "42241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "29466561",
    skolenhetensNamn: "Bräckeskolan F-6",
    postnr: "40521",
    besökspostnr: "40521",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "76375477",
    skolenhetensNamn: "Brännöskolan F-3",
    postnr: "40521",
    besökspostnr: "43085",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "55977201",
    skolenhetensNamn: "Bärbyskolan F-6",
    postnr: "40521",
    besökspostnr: "42373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "83071620",
    skolenhetensNamn: "Böskolans friskola",
    postnr: "41274",
    besökspostnr: "41274",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "92384910",
    skolenhetensNamn:
      "Communityskolan Gårdsten RM Bildning AB CO Räddningsmissionen",
    postnr: "41327",
    besökspostnr: "42439",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "52278406",
    skolenhetensNamn: "Dalaskolan F-6",
    postnr: "40521",
    besökspostnr: "43082",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "67803573",
    skolenhetensNamn: "Donsöskolan F-6",
    postnr: "43084",
    besökspostnr: "44372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "60526603",
    skolenhetensNamn: "Drakbergsskolan",
    postnr: "42162",
    besökspostnr: "42162",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "88247874",
    skolenhetensNamn: "Ebba Petterssons Privatskola",
    postnr: "42147",
    besökspostnr: "42147",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "99852133",
    skolenhetensNamn: "Ellen Keyskolan F-6",
    postnr: "40521",
    besökspostnr: "42251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "64026031",
    skolenhetensNamn: "Emmaskolan F-3",
    postnr: "40521",
    besökspostnr: "42436",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "80744215",
    skolenhetensNamn: "Eriksboskolan F-6",
    postnr: "40521",
    besökspostnr: "42434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "80408917",
    skolenhetensNamn: "Fiskebäcksskolan F-9",
    postnr: "40521",
    besökspostnr: "42658",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "60764994",
    skolenhetensNamn: "Fjällboskolan F-5",
    postnr: "40521",
    besökspostnr: "41317",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "53332364",
    skolenhetensNamn: "Fjällskolan F-6",
    postnr: "40521",
    besökspostnr: "41317",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "43664749",
    skolenhetensNamn: "Flatåsskolan F-6",
    postnr: "40521",
    besökspostnr: "42137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "50742176",
    skolenhetensNamn: "Franska skolan Göteborg F-5",
    postnr: "41133",
    besökspostnr: "41130",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "37984980",
    skolenhetensNamn: "Freinétförskolan Bild & Form Masthugget",
    postnr: "40232",
    besökspostnr: "41311",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "44605298",
    skolenhetensNamn: "Frejaskolan F-9",
    postnr: "40521",
    besökspostnr: "42655",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "56328727",
    skolenhetensNamn: "Fridaskolan Kvillebäcken",
    postnr: "41749",
    besökspostnr: "41749",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "51009918",
    skolenhetensNamn: "Frölundaskolan Bräcke",
    postnr: "40521",
    besökspostnr: "42145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "16464648",
    skolenhetensNamn: "Frölundaskolan F-9",
    postnr: "40521",
    besökspostnr: "42145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "29744401",
    skolenhetensNamn: "Gamlestadsskolan F-9",
    postnr: "40521",
    besökspostnr: "41501",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "11814097",
    skolenhetensNamn: "Guldhedsskolan F-9",
    postnr: "40521",
    besökspostnr: "41322",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "30107536",
    skolenhetensNamn: "Gunnaredsskolan F-3",
    postnr: "40521",
    besökspostnr: "42466",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "17484314",
    skolenhetensNamn: "Gunnestorpsskolan F-6",
    postnr: "40521",
    besökspostnr: "41118",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "74306815",
    skolenhetensNamn: "Gunnilseskolan F-6",
    postnr: "40521",
    besökspostnr: "42441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "88405264",
    skolenhetensNamn: "Gustaviskolan F-5",
    postnr: "40521",
    besökspostnr: "41118",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "75004443",
    skolenhetensNamn: "Gärdsmosseskolan F-6",
    postnr: "40521",
    besökspostnr: "41302",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "17157735",
    skolenhetensNamn: "Hagaskolan F-5",
    postnr: "40521",
    besökspostnr: "41302",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "31665312",
    skolenhetensNamn: "Hagenskolan F-6",
    postnr: "40521",
    besökspostnr: "41263",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "20175215",
    skolenhetensNamn: "Hammarkullsskolan F-3",
    postnr: "40521",
    besökspostnr: "42437",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "62479729",
    skolenhetensNamn: "Herrgårdsskolan F-6",
    postnr: "40521",
    besökspostnr: "41758",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "86247174",
    skolenhetensNamn: "Hjuviksskolan F-3",
    postnr: "40521",
    besökspostnr: "43651",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "99319890",
    skolenhetensNamn: "Hovåsskolan F-9",
    postnr: "40521",
    besökspostnr: "43644",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "89356085",
    skolenhetensNamn: "Hultskolan F-6",
    postnr: "40521",
    besökspostnr: "41133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "45963596",
    skolenhetensNamn: "Hällsviksskolan F-3",
    postnr: "40521",
    besökspostnr: "42138",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "60279184",
    skolenhetensNamn: "Härlandatjärnskolan F-9",
    postnr: "40521",
    besökspostnr: "41654",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "15500024",
    skolenhetensNamn: "Högenskolan F-3",
    postnr: "40521",
    besökspostnr: "41480",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "27265374",
    skolenhetensNamn: "Innovitaskolan S:t Jörgen",
    postnr: "42249",
    besökspostnr: "42249",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "41294775",
    skolenhetensNamn: "ISGR International K-5",
    postnr: "41320",
    besökspostnr: "41320",
    inriktning: "Internationell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "93758282",
    skolenhetensNamn: "ISGR/Swedish-Eng F-5",
    postnr: "41320",
    besökspostnr: "41320",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "20535849",
    skolenhetensNamn: "Johannebergsskolan Estetiska F-6",
    postnr: "40521",
    besökspostnr: "41260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "60715716",
    skolenhetensNamn: "Johannebergsskolan Montessori F-5",
    postnr: "40521",
    besökspostnr: "41260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "55124975",
    skolenhetensNamn: "Jättestensskolan F-9",
    postnr: "40521",
    besökspostnr: "41872",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "56339656",
    skolenhetensNamn: "Kannebäcksskolan Döv o Hörsel",
    postnr: "40521",
    besökspostnr: "42163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "55529681",
    skolenhetensNamn: "Kannebäcksskolan Tal o Språk",
    postnr: "40521",
    besökspostnr: "42163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "25795325",
    skolenhetensNamn: "Kannebäcksskolan Tremastaren F-6",
    postnr: "40521",
    besökspostnr: "42163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "61374247",
    skolenhetensNamn: "Karl Johansskolan F-6",
    postnr: "40521",
    besökspostnr: "41140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "45045293",
    skolenhetensNamn: "Katolska skolan av Notre Dame",
    postnr: "41274",
    besökspostnr: "41274",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "71204165",
    skolenhetensNamn: "Klarebergsskolan F-9",
    postnr: "40521",
    besökspostnr: "42534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "46512685",
    skolenhetensNamn: "Kristinaskolan",
    postnr: "42449",
    besökspostnr: "42449",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "91798133",
    skolenhetensNamn: "Kungsladugårdsskolan F-6",
    postnr: "40521",
    besökspostnr: "41469",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "79522184",
    skolenhetensNamn: "Kvibergsskolan F-9",
    postnr: "40521",
    besökspostnr: "41528",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "14037390",
    skolenhetensNamn: "Kärralundsskolan F-9",
    postnr: "40521",
    besökspostnr: "42530",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "46391611",
    skolenhetensNamn: "Kärraskolan F-9",
    postnr: "40521",
    besökspostnr: "42530",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "31282737",
    skolenhetensNamn: "Kärrdalsskolan F-5",
    postnr: "40521",
    besökspostnr: "41729",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "30055205",
    skolenhetensNamn: "Landalaskolan F-3",
    postnr: "40521",
    besökspostnr: "41728",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "38862939",
    skolenhetensNamn: "Landamäreskolan F-3",
    postnr: "40521",
    besökspostnr: "41843",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "48653651",
    skolenhetensNamn: "Lerlyckeskolan F-6",
    postnr: "40521",
    besökspostnr: "41728",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "33143689",
    skolenhetensNamn: "Lilla Gårdstensskolan F-3",
    postnr: "40521",
    besökspostnr: "42442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "67568030",
    skolenhetensNamn: "Lilla Samskolan F-6",
    postnr: "41127",
    besökspostnr: "41127",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "81015114",
    skolenhetensNamn: "Lilla Trulsegårdsskolan F-3",
    postnr: "40521",
    besökspostnr: "42534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "29670879",
    skolenhetensNamn: "Lillebyskolan F-6",
    postnr: "40521",
    besökspostnr: "42534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "87375656",
    skolenhetensNamn: "Lindåsskolan F-9",
    postnr: "40521",
    besökspostnr: "42676",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "62419611",
    skolenhetensNamn: "Lundenskolan F-9",
    postnr: "40521",
    besökspostnr: "42440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "28236603",
    skolenhetensNamn: "Långedragsskolan F-6",
    postnr: "40521",
    besökspostnr: "42676",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "61240460",
    skolenhetensNamn: "Långmosseskolan F-3",
    postnr: "40521",
    besökspostnr: "42440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "39701961",
    skolenhetensNamn: "Lärjeskolan F-9",
    postnr: "40521",
    besökspostnr: "42421",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "47927698",
    skolenhetensNamn: "Martinaskolan",
    postnr: "41533",
    besökspostnr: "41533",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "52061762",
    skolenhetensNamn: "Montessoriskolan Alexandra",
    postnr: "41323",
    besökspostnr: "41323",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "76938537",
    skolenhetensNamn: "Montessoriskolan Casa",
    postnr: "41274",
    besökspostnr: "41274",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "73995321",
    skolenhetensNamn: "Montessoriskolan Centrum",
    postnr: "41124",
    besökspostnr: "41124",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "25894254",
    skolenhetensNamn: "Montessoriskolan Skäret",
    postnr: "42677",
    besökspostnr: "42677",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "16883454",
    skolenhetensNamn: "Mossebergsskolan F-2",
    postnr: "40521",
    besökspostnr: "41323",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "75616744",
    skolenhetensNamn: "Noblaskolan Nya Hovås F-5",
    postnr: "43657",
    besökspostnr: "43657",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "52601922",
    skolenhetensNamn: "Noleredsskolan F-6",
    postnr: "40521",
    besökspostnr: "42332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "19804936",
    skolenhetensNamn: "Nordhemsskolan F-9",
    postnr: "40521",
    besökspostnr: "42332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "36115436",
    skolenhetensNamn: "Nya skolan Länsmansgården F-3",
    postnr: "40521",
    besökspostnr: "41841",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "15623804",
    skolenhetensNamn: "Nya Varvetskolan F-6",
    postnr: "40521",
    besökspostnr: "42736",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "65651613",
    skolenhetensNamn: "Nygårdsskolan F-9",
    postnr: "40521",
    besökspostnr: "42435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "22344869",
    skolenhetensNamn: "Näsetskolan F-6",
    postnr: "40521",
    besökspostnr: "41317",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "30230888",
    skolenhetensNamn: "Oscar Fredriksskolan F-6",
    postnr: "40521",
    besökspostnr: "41317",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "17065517",
    skolenhetensNamn: "Parkskolan F-6",
    postnr: "40521",
    besökspostnr: "41654",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "77556882",
    skolenhetensNamn: "Påvelundsskolan F-9",
    postnr: "40521",
    besökspostnr: "42677",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "28236918",
    skolenhetensNamn: "Rambergsskolan F-6",
    postnr: "40521",
    besökspostnr: "41713",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "49507388",
    skolenhetensNamn: "Ramsdalsskolan F-3",
    postnr: "40521",
    besökspostnr: "42437",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "37630524",
    skolenhetensNamn: "Rannebergsskolan F-3",
    postnr: "40521",
    besökspostnr: "42441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "31966730",
    skolenhetensNamn: "Rosendalsskolan F-6",
    postnr: "40521",
    besökspostnr: "42447",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "42817980",
    skolenhetensNamn: "Rudolf Steinerskolan Göteborg",
    postnr: "41674",
    besökspostnr: "41674",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "71424690",
    skolenhetensNamn: "Ryaskolan F-9",
    postnr: "40521",
    besökspostnr: "41831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "12868813",
    skolenhetensNamn: "Rävebergsskolan F-3",
    postnr: "40521",
    besökspostnr: "42442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "48773104",
    skolenhetensNamn: "Sandarnaskolan F-6",
    postnr: "40521",
    besökspostnr: "41450",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "91683255",
    skolenhetensNamn: "Sandeklevsskolan F-9",
    postnr: "40521",
    besökspostnr: "41124",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "25991607",
    skolenhetensNamn: "Sisjöskolan F-3",
    postnr: "40521",
    besökspostnr: "42734",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "39406080",
    skolenhetensNamn: "Sjumilaskolan F-9",
    postnr: "40521",
    besökspostnr: "41838",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "48883830",
    skolenhetensNamn: "Skogomeskolan F-6",
    postnr: "40521",
    besökspostnr: "42250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "78863858",
    skolenhetensNamn: "Skutehagsskolan F-6",
    postnr: "40521",
    besökspostnr: "41838",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "65688769",
    skolenhetensNamn: "Skytteskolan F-9",
    postnr: "40521",
    besökspostnr: "42253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "94336500",
    skolenhetensNamn: "Skårsskolan F-6",
    postnr: "40521",
    besökspostnr: "42336",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "64116429",
    skolenhetensNamn: "Slottsbergsskolan F-6",
    postnr: "40251",
    besökspostnr: "42151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "85514854",
    skolenhetensNamn: "Snäckebergsskolan F-3",
    postnr: "40521",
    besökspostnr: "42334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "28887899",
    skolenhetensNamn: "Solbackeskolan F-6",
    postnr: "40521",
    besökspostnr: "41746",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "64430977",
    skolenhetensNamn: "Stiftelsen Backatorpsskolan",
    postnr: "42257",
    besökspostnr: "42257",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "66890810",
    skolenhetensNamn: "Stiftelsen The English School Gothenburg",
    postnr: "42677",
    besökspostnr: "42677",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "81179515",
    skolenhetensNamn: "Styrsöskolan F-9",
    postnr: "43084",
    besökspostnr: "41667",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "72450138",
    skolenhetensNamn: "Talldungeskolan F-3",
    postnr: "40521",
    besökspostnr: "41729",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "11099088",
    skolenhetensNamn: "Taubeskolan F-6",
    postnr: "40521",
    besökspostnr: "41767",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "43055932",
    skolenhetensNamn: "Toleredsskolan F-9",
    postnr: "40521",
    besökspostnr: "41729",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "11465652",
    skolenhetensNamn: "Torpaskolan F-9",
    postnr: "40521",
    besökspostnr: "42332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "82589201",
    skolenhetensNamn: "Torslandaskolan F-6",
    postnr: "40521",
    besökspostnr: "42332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "72624322",
    skolenhetensNamn: "Tretjärnsskolan F-3",
    postnr: "40521",
    besökspostnr: "42441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "97724398",
    skolenhetensNamn: "Trollängsskolan F-6",
    postnr: "40521",
    besökspostnr: "41743",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "24070185",
    skolenhetensNamn: "Tångenskolan F-6",
    postnr: "40521",
    besökspostnr: "41515",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "72572754",
    skolenhetensNamn: "Utbynässkolan F-3",
    postnr: "40521",
    besökspostnr: "41571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "56168083",
    skolenhetensNamn: "Vasaskolan",
    postnr: "41132",
    besökspostnr: "41132",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "80678068",
    skolenhetensNamn: "Victoriaskolan",
    postnr: "41127",
    besökspostnr: "41127",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "57333669",
    skolenhetensNamn: "Viljaskolan",
    postnr: "42465",
    besökspostnr: "42465",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "67543412",
    skolenhetensNamn: "Vittra Kronhusparken",
    postnr: "41105",
    besökspostnr: "41105",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "54863812",
    skolenhetensNamn: "Västerhedsskolan F-3",
    postnr: "40521",
    besökspostnr: "42149",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "64067087",
    skolenhetensNamn: "Vättnedalsskolan F-6",
    postnr: "40521",
    besökspostnr: "42351",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "45526612",
    skolenhetensNamn: "Åkeredsskolan F-6",
    postnr: "40521",
    besökspostnr: "42148",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "84500401",
    skolenhetensNamn: "Ånässkolan F-6",
    postnr: "40521",
    besökspostnr: "42159",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "30072224",
    skolenhetensNamn: "Älvegårdsskolan F-3",
    postnr: "40521",
    besökspostnr: "42159",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "67435601",
    skolenhetensNamn: "Ängåsskolan F-6",
    postnr: "40521",
    besökspostnr: "42147",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1480",
    kommunsNamn: "Göteborg",
    skolenhetsKod: "91687391",
    skolenhetensNamn: "Önneredsskolan F-9",
    postnr: "40521",
    besökspostnr: "42159",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "28119547",
    skolenhetensNamn: "Balltorpsskolan",
    postnr: "43151",
    besökspostnr: "43151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "72857130",
    skolenhetensNamn: "Bosgårdsskolan",
    postnr: "43141",
    besökspostnr: "43141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "38620615",
    skolenhetensNamn: "Eklandaskolan",
    postnr: "43159",
    besökspostnr: "43159",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "90966089",
    skolenhetensNamn: "Glasbergsskolan",
    postnr: "43134",
    besökspostnr: "43134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "32309720",
    skolenhetensNamn: "Hallenskolan",
    postnr: "42835",
    besökspostnr: "42835",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "99527239",
    skolenhetensNamn: "Hällesåkersskolan",
    postnr: "43792",
    besökspostnr: "43792",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "42908622",
    skolenhetensNamn: "Innovitaskolan Mölndal",
    postnr: "43134",
    besökspostnr: "43134",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "13050305",
    skolenhetensNamn: "Katrinebergsskolan",
    postnr: "43161",
    besökspostnr: "43161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "11203004",
    skolenhetensNamn: "Krokslättsskolan",
    postnr: "43138",
    besökspostnr: "43138",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "13770039",
    skolenhetensNamn: "Lackarebäcksskolan",
    postnr: "43166",
    besökspostnr: "43166",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "64490264",
    skolenhetensNamn: "Montessoriskolan Kvarnhjulet",
    postnr: "43142",
    besökspostnr: "43142",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "92647733",
    skolenhetensNamn: "Rävekärrsskolan",
    postnr: "43133",
    besökspostnr: "43133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "36569773",
    skolenhetensNamn: "Sinntorpsskolan F-3",
    postnr: "43736",
    besökspostnr: "43736",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "55382532",
    skolenhetensNamn: "Skånhällaskolan F-6",
    postnr: "43740",
    besökspostnr: "43740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "71288945",
    skolenhetensNamn: "Toltorpsskolan",
    postnr: "43169",
    besökspostnr: "43169",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "31638941",
    skolenhetensNamn: "Valåsskolan",
    postnr: "43732",
    besökspostnr: "43732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "97689213",
    skolenhetensNamn: "Västerbergsskolan",
    postnr: "43142",
    besökspostnr: "43142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1481",
    kommunsNamn: "Mölndal",
    skolenhetsKod: "21475197",
    skolenhetensNamn: "Östergårdsskolan",
    postnr: "42831",
    besökspostnr: "42831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "62418563",
    skolenhetensNamn: "Diseröds skola",
    postnr: "44277",
    besökspostnr: "44277",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "28511697",
    skolenhetensNamn: "Fontinskolan",
    postnr: "44231",
    besökspostnr: "44231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "38353942",
    skolenhetensNamn: "Fredkullaskolan ,waldorfskola,",
    postnr: "44233",
    besökspostnr: "44233",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "25790317",
    skolenhetensNamn: "Friskolan i Kärna",
    postnr: "44202",
    besökspostnr: "44270",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "80848296",
    skolenhetensNamn: "Hålta skola",
    postnr: "44295",
    besökspostnr: "44295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "54878605",
    skolenhetensNamn: "Kareby skola",
    postnr: "44293",
    besökspostnr: "44293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "33588309",
    skolenhetensNamn: "Kastellegårdsskolan 1",
    postnr: "44250",
    besökspostnr: "44250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "61511069",
    skolenhetensNamn: "Klöverbackens skola",
    postnr: "44242",
    besökspostnr: "44242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "43370798",
    skolenhetensNamn: "Kode skola",
    postnr: "44260",
    besökspostnr: "44260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "52844016",
    skolenhetensNamn: "Kullens skola",
    postnr: "44241",
    besökspostnr: "44241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "77589196",
    skolenhetensNamn: "Kärna skola",
    postnr: "44270",
    besökspostnr: "44270",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "71394657",
    skolenhetensNamn: "Marstrands skola",
    postnr: "44267",
    besökspostnr: "44267",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "49323438",
    skolenhetensNamn: "Montessoriskolan Älvkullen",
    postnr: "44231",
    besökspostnr: "44231",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "68916732",
    skolenhetensNamn: "Sandbackaskolan 1",
    postnr: "44237",
    besökspostnr: "44237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "28596641",
    skolenhetensNamn: "Sparråsskolan",
    postnr: "44254",
    besökspostnr: "44254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1482",
    kommunsNamn: "Kungälv",
    skolenhetsKod: "21917957",
    skolenhetensNamn: "Strandskolan Montessoriföreningen i Kungälv",
    postnr: "44236",
    besökspostnr: "44236",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1484",
    kommunsNamn: "Lysekil",
    skolenhetsKod: "33574346",
    skolenhetensNamn: "Bergs skola",
    postnr: "45391",
    besökspostnr: "45391",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1484",
    kommunsNamn: "Lysekil",
    skolenhetsKod: "21004427",
    skolenhetensNamn: "Bro skola",
    postnr: "45494",
    besökspostnr: "45494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1484",
    kommunsNamn: "Lysekil",
    skolenhetsKod: "38925677",
    skolenhetensNamn: "Gullmarsskolan F-6",
    postnr: "45334",
    besökspostnr: "45334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1484",
    kommunsNamn: "Lysekil",
    skolenhetsKod: "15905789",
    skolenhetensNamn: "Mariedalsskolan",
    postnr: "45337",
    besökspostnr: "45337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1484",
    kommunsNamn: "Lysekil",
    skolenhetsKod: "71589053",
    skolenhetensNamn: "Skaftö skola",
    postnr: "45179",
    besökspostnr: "45149",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1484",
    kommunsNamn: "Lysekil",
    skolenhetsKod: "71907938",
    skolenhetensNamn: "Stångenässkolan",
    postnr: "45430",
    besökspostnr: "45430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "20245835",
    skolenhetensNamn: "Bokenäs skola",
    postnr: "45181",
    besökspostnr: "45196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "55030337",
    skolenhetensNamn: "Dalabergsskolan",
    postnr: "45181",
    besökspostnr: "45172",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "19612340",
    skolenhetensNamn: "Forshällaskolan",
    postnr: "45181",
    besökspostnr: "45191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "27489448",
    skolenhetensNamn: "Fridaskolan, Uddevalla",
    postnr: "45134",
    besökspostnr: "45134",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "31201216",
    skolenhetensNamn: "Hogstorp skola",
    postnr: "45181",
    besökspostnr: "45194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "25121914",
    skolenhetensNamn: "Hovhultsskolan",
    postnr: "45181",
    besökspostnr: "45171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "52464734",
    skolenhetensNamn: "Hällebergsskolan",
    postnr: "45932",
    besökspostnr: "45932",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "37667577",
    skolenhetensNamn: "Kisslebergsskolan",
    postnr: "45181",
    besökspostnr: "45175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "25674555",
    skolenhetensNamn: "Källdalsskolan F - 3",
    postnr: "45181",
    besökspostnr: "45175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "43476389",
    skolenhetensNamn: "Lane Ryrs skola",
    postnr: "45181",
    besökspostnr: "45193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "88846671",
    skolenhetensNamn: "Ljungskileskolan F - 3",
    postnr: "45181",
    besökspostnr: "45932",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "94910948",
    skolenhetensNamn: "Norrskolan",
    postnr: "45181",
    besökspostnr: "45154",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "55668386",
    skolenhetensNamn: "Ramneröd F-3",
    postnr: "45151",
    besökspostnr: "45154",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "35359339",
    skolenhetensNamn: "Sandersdalskolan",
    postnr: "45181",
    besökspostnr: "45160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "68954250",
    skolenhetensNamn: "Sommarhemsskolan F-6",
    postnr: "45181",
    besökspostnr: "45144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "77005811",
    skolenhetensNamn: "Stenbackeskolan",
    postnr: "45121",
    besökspostnr: "45131",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "16748592",
    skolenhetensNamn: "Stråketskolan",
    postnr: "45181",
    besökspostnr: "45173",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1485",
    kommunsNamn: "Uddevalla",
    skolenhetsKod: "95427242",
    skolenhetensNamn: "Äsperödsskolan F-6",
    postnr: "45181",
    besökspostnr: "45134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1486",
    kommunsNamn: "Strömstad",
    skolenhetsKod: "93814108",
    skolenhetensNamn: "Bojarskolan F-6",
    postnr: "45280",
    besökspostnr: "45240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1486",
    kommunsNamn: "Strömstad",
    skolenhetsKod: "80203669",
    skolenhetensNamn: "Koster skola F-3",
    postnr: "45280",
    besökspostnr: "45205",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1486",
    kommunsNamn: "Strömstad",
    skolenhetsKod: "78828269",
    skolenhetensNamn: "Mellegården skola F-6",
    postnr: "45280",
    besökspostnr: "45293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1486",
    kommunsNamn: "Strömstad",
    skolenhetsKod: "23813942",
    skolenhetensNamn: "Odelsbergsskolan F-3",
    postnr: "45280",
    besökspostnr: "45232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1486",
    kommunsNamn: "Strömstad",
    skolenhetsKod: "11065005",
    skolenhetensNamn: "Rossö skola F-6",
    postnr: "45280",
    besökspostnr: "45295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1486",
    kommunsNamn: "Strömstad",
    skolenhetsKod: "57063673",
    skolenhetensNamn: "Skee skola F-6",
    postnr: "45280",
    besökspostnr: "45260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1486",
    kommunsNamn: "Strömstad",
    skolenhetsKod: "14814740",
    skolenhetensNamn: "Tjärnö skola F-3",
    postnr: "45280",
    besökspostnr: "45296",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1486",
    kommunsNamn: "Strömstad",
    skolenhetsKod: "32311635",
    skolenhetensNamn: "Valemyrskolan F-6",
    postnr: "45280",
    besökspostnr: "45235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "11648934",
    skolenhetensNamn: "Blåsut skola",
    postnr: "46260",
    besökspostnr: "46260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "34594542",
    skolenhetensNamn: "Brålanda skola F-3",
    postnr: "46285",
    besökspostnr: "46461",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "10982633",
    skolenhetensNamn: "Fridaskolan, Vänersborg",
    postnr: "46232",
    besökspostnr: "46232",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "26412678",
    skolenhetensNamn: "Frändeskolan",
    postnr: "46269",
    besökspostnr: "46269",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "25729428",
    skolenhetensNamn: "Granås skola F-3",
    postnr: "46834",
    besökspostnr: "46834",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "46319044",
    skolenhetensNamn: "Mariedalskolan F-3",
    postnr: "46254",
    besökspostnr: "46254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "49559000",
    skolenhetensNamn: "Mariedalskolan Grundsärskola",
    postnr: "46254",
    besökspostnr: "46254",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "47721692",
    skolenhetensNamn: "Mulltorp skola 4-6",
    postnr: "46193",
    besökspostnr: "46193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "26068352",
    skolenhetensNamn: "Mulltorp skola F-3",
    postnr: "46193",
    besökspostnr: "46193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "94492012",
    skolenhetensNamn: "Norra skolan F-3",
    postnr: "46230",
    besökspostnr: "46230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "77512067",
    skolenhetensNamn: "Onsjöskolan F-3",
    postnr: "46241",
    besökspostnr: "46241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "21541732",
    skolenhetensNamn: "Rånnums skola F-3",
    postnr: "46830",
    besökspostnr: "46830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "45249416",
    skolenhetensNamn: "Rösebo skola",
    postnr: "46285",
    besökspostnr: "46269",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "13511202",
    skolenhetensNamn: "Skerruds skola F-3",
    postnr: "46285",
    besökspostnr: "46465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "47331235",
    skolenhetensNamn: "Tärnan F-3",
    postnr: "46285",
    besökspostnr: "46285",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "59697432",
    skolenhetensNamn: "Vänerskolan",
    postnr: "46223",
    besökspostnr: "46236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "60726203",
    skolenhetensNamn: "Vänersnäs Montessoriskola",
    postnr: "46890",
    besökspostnr: "46890",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1487",
    kommunsNamn: "Vänersborg",
    skolenhetsKod: "92050682",
    skolenhetensNamn: "Öxnered skola F-3",
    postnr: "46261",
    besökspostnr: "46261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "10161117",
    skolenhetensNamn: "Björndalsskolan F-3",
    postnr: "46159",
    besökspostnr: "46159",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "73710116",
    skolenhetensNamn: "Dalkjusans skola F-3",
    postnr: "46171",
    besökspostnr: "46171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "56884611",
    skolenhetensNamn: "Fridaskolan, Trollhättan",
    postnr: "46140",
    besökspostnr: "46140",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "48630799",
    skolenhetensNamn: "Hjortmosseskolan F-5",
    postnr: "46150",
    besökspostnr: "46150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "57342282",
    skolenhetensNamn: "Hälltorpskolan F-3",
    postnr: "46158",
    besökspostnr: "46158",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "14423966",
    skolenhetensNamn: "Kronan F-3",
    postnr: "46160",
    besökspostnr: "46160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "86568988",
    skolenhetensNamn: "Lextorpsskolan F-6",
    postnr: "46163",
    besökspostnr: "46163",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "30202580",
    skolenhetensNamn: "Lyrfågelskolan F",
    postnr: "46143",
    besökspostnr: "46143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "49756946",
    skolenhetensNamn: "Montessoriskolan Trilobiten",
    postnr: "46154",
    besökspostnr: "46154",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "64839129",
    skolenhetensNamn: "Nya Skolan Egna Hem",
    postnr: "46138",
    besökspostnr: "41639",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "11588924",
    skolenhetensNamn: "Nya Skolan, Trollhättan, Pettersberg",
    postnr: "46138",
    besökspostnr: "46153",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "95817060",
    skolenhetensNamn: "Sjuntorpskolan F-3",
    postnr: "46178",
    besökspostnr: "46178",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "77189144",
    skolenhetensNamn: "Skoftebyskolan F-4",
    postnr: "46155",
    besökspostnr: "46155",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "62124821",
    skolenhetensNamn: "Stavreskolan F-5",
    postnr: "46140",
    besökspostnr: "46140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "21251931",
    skolenhetensNamn: "Strömslundsskolan  F-3",
    postnr: "46157",
    besökspostnr: "46157",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "36250779",
    skolenhetensNamn: "Sylteskolan F-6",
    postnr: "46167",
    besökspostnr: "46167",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "46989514",
    skolenhetensNamn: "Upphärads skola F-5",
    postnr: "46179",
    besökspostnr: "46179",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "30900579",
    skolenhetensNamn: "Velanda skola F-6",
    postnr: "46198",
    besökspostnr: "46198",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1488",
    kommunsNamn: "Trollhättan",
    skolenhetsKod: "24640784",
    skolenhetensNamn: "Åsaka skola F-6",
    postnr: "46194",
    besökspostnr: "46194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "63452058",
    skolenhetensNamn: "Hemsjö Kyrkskola",
    postnr: "44196",
    besökspostnr: "44196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "49115714",
    skolenhetensNamn: "Ingaredsskolan",
    postnr: "44165",
    besökspostnr: "44165",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "78832157",
    skolenhetensNamn: "Kullingsbergsskolan",
    postnr: "44143",
    besökspostnr: "44143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "34338735",
    skolenhetensNamn: "Lendahlsskolan F-6",
    postnr: "44130",
    besökspostnr: "44130",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "32478204",
    skolenhetensNamn: "Linnéskolan",
    postnr: "44128",
    besökspostnr: "44158",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "28639614",
    skolenhetensNamn: "Långareds skola",
    postnr: "44191",
    besökspostnr: "44191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "78273632",
    skolenhetensNamn: "Magra skola",
    postnr: "44174",
    besökspostnr: "44174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "87109819",
    skolenhetensNamn: "Montessoriskolan Globen",
    postnr: "44155",
    besökspostnr: "44155",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "46546067",
    skolenhetensNamn: "Nolbyskolan",
    postnr: "44135",
    besökspostnr: "44135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "86435318",
    skolenhetensNamn: "Noltorpsskolan F-3",
    postnr: "44156",
    besökspostnr: "44156",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "76908037",
    skolenhetensNamn: "Sollebrunns skola F-9",
    postnr: "44111",
    besökspostnr: "46630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "38957274",
    skolenhetensNamn: "Stadsskogenskolan",
    postnr: "44143",
    besökspostnr: "44143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "90924483",
    skolenhetensNamn: "Stora Mellby skola",
    postnr: "44173",
    besökspostnr: "44173",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "98272016",
    skolenhetensNamn: "Vittra Gerdsken",
    postnr: "44140",
    besökspostnr: "44140",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "71895385",
    skolenhetensNamn: "Västra Bodarna skola",
    postnr: "44160",
    besökspostnr: "44160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "57136802",
    skolenhetensNamn: "Ängaboskolan F-6",
    postnr: "44150",
    besökspostnr: "44150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1489",
    kommunsNamn: "Alingsås",
    skolenhetsKod: "70153094",
    skolenhetensNamn: "Ödenäs skola",
    postnr: "44195",
    besökspostnr: "44195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "79575640",
    skolenhetensNamn: "Aplaredskolan",
    postnr: "51650",
    besökspostnr: "51650",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "97485987",
    skolenhetensNamn: "Asklandaskolan",
    postnr: "51332",
    besökspostnr: "51332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "85493502",
    skolenhetensNamn: "Bergdalskolan F-6",
    postnr: "50457",
    besökspostnr: "50457",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "80373308",
    skolenhetensNamn: "Björkhöjdskolan F-6",
    postnr: "50180",
    besökspostnr: "50180",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "94172239",
    skolenhetensNamn: "Bodaskolan F-6",
    postnr: "50742",
    besökspostnr: "50742",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "77362129",
    skolenhetensNamn: "Borgstenaskolan",
    postnr: "51397",
    besökspostnr: "51397",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "64720166",
    skolenhetensNamn: "Borås Kristna skola",
    postnr: "50630",
    besökspostnr: "50630",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "88594941",
    skolenhetensNamn: "Bredaredskolan",
    postnr: "50496",
    besökspostnr: "50496",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "60337075",
    skolenhetensNamn: "Byttorpskolan",
    postnr: "50465",
    besökspostnr: "50465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "13746989",
    skolenhetensNamn: "Daltorpskolan F-6 Kronäng 2",
    postnr: "50441",
    besökspostnr: "50441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "24961270",
    skolenhetensNamn: "Dannikeskolan",
    postnr: "51694",
    besökspostnr: "51694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "95440729",
    skolenhetensNamn: "Ekarängskolan",
    postnr: "50753",
    besökspostnr: "50753",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "70233136",
    skolenhetensNamn: "Engelbrektskolan F-6",
    postnr: "50636",
    besökspostnr: "50636",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "71926648",
    skolenhetensNamn: "Erikslundskolan F-6",
    postnr: "50640",
    besökspostnr: "50640",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "74866813",
    skolenhetensNamn: "Fjärdingskolan F-6",
    postnr: "50741",
    besökspostnr: "50741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "76173170",
    skolenhetensNamn: "Fjärdingskolan Sär",
    postnr: "50741",
    besökspostnr: "50741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "90802324",
    skolenhetensNamn: "Gula skolan",
    postnr: "51334",
    besökspostnr: "51334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "20256677",
    skolenhetensNamn: "Gånghesterskolan",
    postnr: "50770",
    besökspostnr: "50770",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "77733096",
    skolenhetensNamn: "Hestra Midgårdskolan",
    postnr: "50470",
    besökspostnr: "50470",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "53233617",
    skolenhetensNamn: "Kerstinsgårdskolan",
    postnr: "51634",
    besökspostnr: "51634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "98161840",
    skolenhetensNamn: "Kinnarummaskolan F-6",
    postnr: "51592",
    besökspostnr: "51592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "26533038",
    skolenhetensNamn: "Kristinebergskolan",
    postnr: "50444",
    besökspostnr: "50444",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "10257640",
    skolenhetensNamn: "Kunskapsskolan Borås",
    postnr: "50431",
    besökspostnr: "50431",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "10132939",
    skolenhetensNamn: "Malmen Montessori",
    postnr: "50439",
    besökspostnr: "50439",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "41007432",
    skolenhetensNamn: "Myråsskolan",
    postnr: "50733",
    besökspostnr: "50733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "90649345",
    skolenhetensNamn: "Målsrydskolan",
    postnr: "50180",
    besökspostnr: "51677",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "48846255",
    skolenhetensNamn: "Rångedalaskolan",
    postnr: "51693",
    besökspostnr: "51693",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "72403229",
    skolenhetensNamn: "Sandaredskolan",
    postnr: "51821",
    besökspostnr: "51830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "64760945",
    skolenhetensNamn: "Sandhultskolan",
    postnr: "50478",
    besökspostnr: "50478",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "64604672",
    skolenhetensNamn: "Sjöboskolan",
    postnr: "50643",
    besökspostnr: "50643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "42811967",
    skolenhetensNamn: "Sjömarkenskolan",
    postnr: "51841",
    besökspostnr: "51841",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "63472172",
    skolenhetensNamn: "Sparsörskolorna",
    postnr: "51350",
    besökspostnr: "51350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "22258443",
    skolenhetensNamn: "Svaneholmskolan F-6",
    postnr: "51561",
    besökspostnr: "51561",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "46112711",
    skolenhetensNamn: "Svedjeskolan",
    postnr: "50445",
    besökspostnr: "50445",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "64915682",
    skolenhetensNamn: "Särlaskolan F-6",
    postnr: "50465",
    besökspostnr: "50465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "88323204",
    skolenhetensNamn: "Trandaredskolan F-6",
    postnr: "50761",
    besökspostnr: "50761",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "98158692",
    skolenhetensNamn: "Tummarpskolan",
    postnr: "51631",
    besökspostnr: "51631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "50756758",
    skolenhetensNamn: "Viskaforsskolan 7-9",
    postnr: "51522",
    besökspostnr: "51534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "43567691",
    skolenhetensNamn: "Viskaforsskolan F-6",
    postnr: "51534",
    besökspostnr: "51534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1490",
    kommunsNamn: "Borås",
    skolenhetsKod: "24622763",
    skolenhetensNamn: "Äsperedskolan",
    postnr: "51692",
    besökspostnr: "51692",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "72147290",
    skolenhetensNamn: "Blidsbergs skola",
    postnr: "52024",
    besökspostnr: "52024",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "47154034",
    skolenhetensNamn: "Bogesundsskolan",
    postnr: "52386",
    besökspostnr: "52330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "45023137",
    skolenhetensNamn: "Dalums skola",
    postnr: "52375",
    besökspostnr: "52375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "47657033",
    skolenhetensNamn: "Gällstads skola",
    postnr: "52360",
    besökspostnr: "52360",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "87650899",
    skolenhetensNamn: "Hökerums skola",
    postnr: "52374",
    besökspostnr: "52374",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "88344039",
    skolenhetensNamn: "Hössna skola",
    postnr: "52397",
    besökspostnr: "52397",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "77159690",
    skolenhetensNamn: "I Ur och Skur Myrstacken",
    postnr: "52333",
    besökspostnr: "52333",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "73076557",
    skolenhetensNamn: "Marbäcks skola",
    postnr: "52393",
    besökspostnr: "52393",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "70072450",
    skolenhetensNamn: "Montessoriskolan Lindängen",
    postnr: "52336",
    besökspostnr: "52336",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "97453154",
    skolenhetensNamn: "Timmele skola",
    postnr: "52372",
    besökspostnr: "52372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "22689061",
    skolenhetensNamn: "Tvärreds skola",
    postnr: "52394",
    besökspostnr: "52394",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "32255976",
    skolenhetensNamn: "Ulrikaskolan",
    postnr: "52386",
    besökspostnr: "52336",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "28791604",
    skolenhetensNamn: "Vegby skola",
    postnr: "52362",
    besökspostnr: "52362",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1491",
    kommunsNamn: "Ulricehamn",
    skolenhetsKod: "92595749",
    skolenhetensNamn: "Vistaholms skola",
    postnr: "52337",
    besökspostnr: "52337",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1492",
    kommunsNamn: "Åmål",
    skolenhetsKod: "25567918",
    skolenhetensNamn: "Edsleskogs Friskola",
    postnr: "66291",
    besökspostnr: "66291",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1492",
    kommunsNamn: "Åmål",
    skolenhetsKod: "80947842",
    skolenhetensNamn: "Natur och Miljöskolan i Åmål",
    postnr: "66233",
    besökspostnr: "66233",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1492",
    kommunsNamn: "Åmål",
    skolenhetsKod: "86458114",
    skolenhetensNamn: "Rösparksskolan",
    postnr: "66237",
    besökspostnr: "66237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1492",
    kommunsNamn: "Åmål",
    skolenhetsKod: "79937107",
    skolenhetensNamn: "Tösse skola",
    postnr: "66298",
    besökspostnr: "66298",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "99243321",
    skolenhetensNamn: "Grangärdets skola",
    postnr: "54234",
    besökspostnr: "54234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "55024806",
    skolenhetensNamn: "Hasslerörs skola",
    postnr: "54272",
    besökspostnr: "54272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "17581579",
    skolenhetensNamn: "Högelidsskolan F-6",
    postnr: "54231",
    besökspostnr: "54231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "71484023",
    skolenhetensNamn: "Kvarnstenens skola",
    postnr: "54274",
    besökspostnr: "54274",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "28646634",
    skolenhetensNamn: "Lillängsskolan",
    postnr: "54232",
    besökspostnr: "54232",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "57260659",
    skolenhetensNamn: "Lyrestads skola",
    postnr: "54873",
    besökspostnr: "54873",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "26296411",
    skolenhetensNamn: "Prismaskolan",
    postnr: "54245",
    besökspostnr: "54245",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "12357670",
    skolenhetensNamn: "Tidavads skola",
    postnr: "54293",
    besökspostnr: "54293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "48056070",
    skolenhetensNamn: "Torsö Skärgårdsskola",
    postnr: "54291",
    besökspostnr: "54291",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "69164983",
    skolenhetensNamn: "Ullervads skola",
    postnr: "54273",
    besökspostnr: "54273",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1493",
    kommunsNamn: "Mariestad",
    skolenhetsKod: "30467029",
    skolenhetensNamn: "Unicaskolan",
    postnr: "54240",
    besökspostnr: "54240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "98083121",
    skolenhetensNamn: "Broholmskolan",
    postnr: "53151",
    besökspostnr: "53151",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "31366755",
    skolenhetensNamn: "Järpås skola",
    postnr: "53175",
    besökspostnr: "53175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "66892094",
    skolenhetensNamn: "Lidåkerskolan",
    postnr: "53139",
    besökspostnr: "53139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "10100155",
    skolenhetensNamn: "Lilleskogs skola",
    postnr: "53157",
    besökspostnr: "53157",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "61093956",
    skolenhetensNamn: "Majåkerskolan",
    postnr: "53141",
    besökspostnr: "53141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "47317570",
    skolenhetensNamn: "Månesköldskolan",
    postnr: "53152",
    besökspostnr: "53152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "39219577",
    skolenhetensNamn: "Otterstad skola",
    postnr: "53199",
    besökspostnr: "53199",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "75230575",
    skolenhetensNamn: "Råda skola",
    postnr: "53196",
    besökspostnr: "53196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "59324900",
    skolenhetensNamn: "Salebyskolan",
    postnr: "53193",
    besökspostnr: "53193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "19737840",
    skolenhetensNamn: "Sjölunda skola",
    postnr: "53158",
    besökspostnr: "53158",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "65318157",
    skolenhetensNamn: "Stenhammarskolan",
    postnr: "53156",
    besökspostnr: "53156",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "38545211",
    skolenhetensNamn: "Stenportskolan",
    postnr: "53154",
    besökspostnr: "53154",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "53155784",
    skolenhetensNamn: "Tuns skola",
    postnr: "53030",
    besökspostnr: "53030",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "12640633",
    skolenhetensNamn: "Wennerbergskolan",
    postnr: "53134",
    besökspostnr: "53134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "95001615",
    skolenhetensNamn: "Vinningaskolan",
    postnr: "53171",
    besökspostnr: "53171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "94298280",
    skolenhetensNamn: "Örslösa skola",
    postnr: "53197",
    besökspostnr: "53197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1494",
    kommunsNamn: "Lidköping",
    skolenhetsKod: "12159058",
    skolenhetensNamn: "Östbyskolan",
    postnr: "53138",
    besökspostnr: "53138",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1495",
    kommunsNamn: "Skara",
    skolenhetsKod: "38048590",
    skolenhetensNamn: "Ardalaskolan",
    postnr: "53288",
    besökspostnr: "53274",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1495",
    kommunsNamn: "Skara",
    skolenhetsKod: "46013569",
    skolenhetensNamn: "Friskolan Metis",
    postnr: "53232",
    besökspostnr: "53232",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1495",
    kommunsNamn: "Skara",
    skolenhetsKod: "60102425",
    skolenhetensNamn: "Gällkvistskolan",
    postnr: "53288",
    besökspostnr: "53236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1495",
    kommunsNamn: "Skara",
    skolenhetsKod: "45880570",
    skolenhetensNamn: "Källeskolan",
    postnr: "53288",
    besökspostnr: "53231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1495",
    kommunsNamn: "Skara",
    skolenhetsKod: "27983086",
    skolenhetensNamn: "Mariebergsskolan",
    postnr: "53288",
    besökspostnr: "53232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1495",
    kommunsNamn: "Skara",
    skolenhetsKod: "51508215",
    skolenhetensNamn: "Valleskolan",
    postnr: "53288",
    besökspostnr: "53272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1495",
    kommunsNamn: "Skara",
    skolenhetsKod: "90909242",
    skolenhetensNamn: "Varnhemsskolan",
    postnr: "53288",
    besökspostnr: "53273",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "27195961",
    skolenhetensNamn: "Billingsdalsskolan",
    postnr: "54157",
    besökspostnr: "54157",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "68603832",
    skolenhetensNamn: "Billingskolan 848",
    postnr: "54132",
    besökspostnr: "54132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "36233215",
    skolenhetensNamn: "Eriksdalskolan 862",
    postnr: "54136",
    besökspostnr: "54136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "48659655",
    skolenhetensNamn: "Frösve skola",
    postnr: "54170",
    besökspostnr: "54170",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "69034506",
    skolenhetensNamn: "Heneskolan",
    postnr: "54154",
    besökspostnr: "54154",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "33331230",
    skolenhetensNamn: "Käpplundaskolan 851",
    postnr: "54143",
    besökspostnr: "54143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "13030046",
    skolenhetensNamn: "Lerdala skola",
    postnr: "54197",
    besökspostnr: "54197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "44078397",
    skolenhetensNamn: "Lundenskolan 850",
    postnr: "54139",
    besökspostnr: "54139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "52602666",
    skolenhetensNamn: "Montessoriskolan i Skövde",
    postnr: "54132",
    besökspostnr: "54132",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "74495166",
    skolenhetensNamn: "Norrmalmskolan",
    postnr: "54144",
    besökspostnr: "54144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "90728703",
    skolenhetensNamn: "Raoul Wallenbergskolan Skövde",
    postnr: "54145",
    besökspostnr: "54145",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "88083330",
    skolenhetensNamn: "Rydskolan 847",
    postnr: "54106",
    besökspostnr: "54106",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "50902077",
    skolenhetensNamn: "Sventorps skola",
    postnr: "54177",
    besökspostnr: "54177",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "16046753",
    skolenhetensNamn: "Svärtagårdsskolan",
    postnr: "54157",
    besökspostnr: "54157",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "81505508",
    skolenhetensNamn: "Tidanskolan",
    postnr: "54931",
    besökspostnr: "54931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "90449443",
    skolenhetensNamn: "Timmersdala skola",
    postnr: "54171",
    besökspostnr: "54171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "94852779",
    skolenhetensNamn: "Trädgårdsstadens skola",
    postnr: "54166",
    besökspostnr: "54166",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "32903180",
    skolenhetensNamn: "Värings skola",
    postnr: "54176",
    besökspostnr: "54176",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1496",
    kommunsNamn: "Skövde",
    skolenhetsKod: "88335935",
    skolenhetensNamn: "Värsås skola",
    postnr: "54178",
    besökspostnr: "54178",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1497",
    kommunsNamn: "Hjo",
    skolenhetsKod: "16996664",
    skolenhetensNamn: "Estrid Ericsonskolan",
    postnr: "54431",
    besökspostnr: "54431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1497",
    kommunsNamn: "Hjo",
    skolenhetsKod: "53080108",
    skolenhetensNamn: "Fågelås skola",
    postnr: "54481",
    besökspostnr: "54494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1497",
    kommunsNamn: "Hjo",
    skolenhetsKod: "44468615",
    skolenhetensNamn: "Guldkroksskolan F-6",
    postnr: "54430",
    besökspostnr: "54434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1497",
    kommunsNamn: "Hjo",
    skolenhetsKod: "85504910",
    skolenhetensNamn: "Hammarnskolan",
    postnr: "54481",
    besökspostnr: "54432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1497",
    kommunsNamn: "Hjo",
    skolenhetsKod: "55078375",
    skolenhetensNamn: "Korsberga skola",
    postnr: "54481",
    besökspostnr: "54492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1498",
    kommunsNamn: "Tidaholm",
    skolenhetsKod: "79472588",
    skolenhetensNamn: "Ekedalens skola F-6",
    postnr: "52293",
    besökspostnr: "52293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1498",
    kommunsNamn: "Tidaholm",
    skolenhetsKod: "30754289",
    skolenhetensNamn: "Forsens grundskola F-3",
    postnr: "52283",
    besökspostnr: "52232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1498",
    kommunsNamn: "Tidaholm",
    skolenhetsKod: "62137279",
    skolenhetensNamn: "Fröjereds grundskola",
    postnr: "52283",
    besökspostnr: "52294",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1498",
    kommunsNamn: "Tidaholm",
    skolenhetsKod: "45994949",
    skolenhetensNamn: "Rosenbergs grundskola F-3",
    postnr: "52237",
    besökspostnr: "52237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1498",
    kommunsNamn: "Tidaholm",
    skolenhetsKod: "53815008",
    skolenhetensNamn: "Valstads grundskola",
    postnr: "52292",
    besökspostnr: "52292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "69493590",
    skolenhetensNamn: "Broddetorpsskolan",
    postnr: "52198",
    besökspostnr: "52198",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "46358943",
    skolenhetensNamn: "Centralskolan F-6",
    postnr: "52181",
    besökspostnr: "52135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "91138242",
    skolenhetensNamn: "Dotorpsskolan",
    postnr: "52134",
    besökspostnr: "52134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "54957877",
    skolenhetensNamn: "Flobyskolan F-6",
    postnr: "52151",
    besökspostnr: "52151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "48695990",
    skolenhetensNamn: "Gudhemsskolan",
    postnr: "52174",
    besökspostnr: "52174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "43269172",
    skolenhetensNamn: "Gustaf Dalénskolan",
    postnr: "52161",
    besökspostnr: "52161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "15357005",
    skolenhetensNamn: "Kinnarpsskolan F-6",
    postnr: "52173",
    besökspostnr: "52173",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "74695983",
    skolenhetensNamn: "Mössebergsskolan",
    postnr: "52131",
    besökspostnr: "52131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "12985644",
    skolenhetensNamn: "Odensbergsskolan",
    postnr: "52151",
    besökspostnr: "52192",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "48252217",
    skolenhetensNamn: "Thoren Framtid i Falköping",
    postnr: "52146",
    besökspostnr: "52146",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "93035626",
    skolenhetensNamn: "Vartoftaskolan F-6",
    postnr: "52175",
    besökspostnr: "52175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "43435547",
    skolenhetensNamn: "Vindängen",
    postnr: "52140",
    besökspostnr: "52131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "72615710",
    skolenhetensNamn: "Åsarpsskolan",
    postnr: "52173",
    besökspostnr: "52191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "26665746",
    skolenhetensNamn: "Åsle Friskola",
    postnr: "52191",
    besökspostnr: "52191",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "14",
    länsNamn: "Västra Götalands län",
    kommun: "1499",
    kommunsNamn: "Falköping",
    skolenhetsKod: "76578072",
    skolenhetensNamn: "Åttagårdsskolan",
    postnr: "52145",
    besökspostnr: "52144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1715",
    kommunsNamn: "Kil",
    skolenhetsKod: "34568098",
    skolenhetensNamn: "Bodaskolan",
    postnr: "66593",
    besökspostnr: "66593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1715",
    kommunsNamn: "Kil",
    skolenhetsKod: "71005223",
    skolenhetensNamn: "Dallidenskolan",
    postnr: "66594",
    besökspostnr: "66594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1715",
    kommunsNamn: "Kil",
    skolenhetsKod: "22252335",
    skolenhetensNamn: "Fageråsskolan",
    postnr: "66594",
    besökspostnr: "66594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1715",
    kommunsNamn: "Kil",
    skolenhetsKod: "60285528",
    skolenhetensNamn: "Sannerudsskolan 0-6",
    postnr: "66532",
    besökspostnr: "66532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1715",
    kommunsNamn: "Kil",
    skolenhetsKod: "99742257",
    skolenhetensNamn: "Tolitaskolan",
    postnr: "66592",
    besökspostnr: "66592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1715",
    kommunsNamn: "Kil",
    skolenhetsKod: "25656550",
    skolenhetensNamn: "Vikstaskolan",
    postnr: "66533",
    besökspostnr: "66533",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1730",
    kommunsNamn: "Eda",
    skolenhetsKod: "48315891",
    skolenhetensNamn: "Adolfsfors skola",
    postnr: "67396",
    besökspostnr: "67396",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1730",
    kommunsNamn: "Eda",
    skolenhetsKod: "69352527",
    skolenhetensNamn: "Gunnarsbyskolan F-9",
    postnr: "67322",
    besökspostnr: "67332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1730",
    kommunsNamn: "Eda",
    skolenhetsKod: "28042382",
    skolenhetensNamn: "Gärdesskolan",
    postnr: "67334",
    besökspostnr: "67334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1730",
    kommunsNamn: "Eda",
    skolenhetsKod: "55674136",
    skolenhetensNamn: "Hierneskolan F-9",
    postnr: "67337",
    besökspostnr: "67337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1737",
    kommunsNamn: "Torsby",
    skolenhetsKod: "61795051",
    skolenhetensNamn: "Holmesskolan",
    postnr: "68580",
    besökspostnr: "68592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1737",
    kommunsNamn: "Torsby",
    skolenhetsKod: "83025413",
    skolenhetensNamn: "Kvistbergsskolan",
    postnr: "68060",
    besökspostnr: "68060",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1737",
    kommunsNamn: "Torsby",
    skolenhetsKod: "76747040",
    skolenhetensNamn: "Oleby skola",
    postnr: "68580",
    besökspostnr: "68534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1737",
    kommunsNamn: "Torsby",
    skolenhetsKod: "64856695",
    skolenhetensNamn: "Stölletskolan",
    postnr: "68580",
    besökspostnr: "68051",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1737",
    kommunsNamn: "Torsby",
    skolenhetsKod: "79961788",
    skolenhetensNamn: "Östmarks skola",
    postnr: "68580",
    besökspostnr: "68597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1760",
    kommunsNamn: "Storfors",
    skolenhetsKod: "58468387",
    skolenhetensNamn: "Bjurtjärns skola",
    postnr: "68892",
    besökspostnr: "68892",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1760",
    kommunsNamn: "Storfors",
    skolenhetsKod: "90407262",
    skolenhetensNamn: "Kroppaskolan",
    postnr: "68829",
    besökspostnr: "68831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1761",
    kommunsNamn: "Hammarö",
    skolenhetsKod: "71694931",
    skolenhetensNamn: "Bärstadsskolan F-2",
    postnr: "66343",
    besökspostnr: "66343",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1761",
    kommunsNamn: "Hammarö",
    skolenhetsKod: "13201443",
    skolenhetensNamn: "Götetorps skola F-6",
    postnr: "66334",
    besökspostnr: "66334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1761",
    kommunsNamn: "Hammarö",
    skolenhetsKod: "39073094",
    skolenhetensNamn: "Hammarlundens skola F-6 Väst",
    postnr: "66341",
    besökspostnr: "66341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1761",
    kommunsNamn: "Hammarö",
    skolenhetsKod: "37827108",
    skolenhetensNamn: "Hammarlundens skola F-6 Öst",
    postnr: "66341",
    besökspostnr: "66341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1761",
    kommunsNamn: "Hammarö",
    skolenhetsKod: "58895077",
    skolenhetensNamn: "Lillängens skola F-6",
    postnr: "66331",
    besökspostnr: "66331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1761",
    kommunsNamn: "Hammarö",
    skolenhetsKod: "97814555",
    skolenhetensNamn: "Mörmoskolan F-6",
    postnr: "66334",
    besökspostnr: "66334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1762",
    kommunsNamn: "Munkfors",
    skolenhetsKod: "67623106",
    skolenhetensNamn: "Munkerudsskolan",
    postnr: "68421",
    besökspostnr: "68432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1763",
    kommunsNamn: "Forshaga",
    skolenhetsKod: "63021199",
    skolenhetensNamn: "Dejeskolan F-3",
    postnr: "66722",
    besökspostnr: "66932",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1763",
    kommunsNamn: "Forshaga",
    skolenhetsKod: "83331030",
    skolenhetensNamn: "Forshaga Lärcenter LM-stadiet",
    postnr: "66722",
    besökspostnr: "66732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1763",
    kommunsNamn: "Forshaga",
    skolenhetsKod: "81149834",
    skolenhetensNamn: "Grossbolsskolan",
    postnr: "66722",
    besökspostnr: "66731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1763",
    kommunsNamn: "Forshaga",
    skolenhetsKod: "93730554",
    skolenhetensNamn: "Olsätersskolan",
    postnr: "66722",
    besökspostnr: "66992",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1763",
    kommunsNamn: "Forshaga",
    skolenhetsKod: "27783058",
    skolenhetensNamn: "Skivedsskolan",
    postnr: "66722",
    besökspostnr: "66733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1764",
    kommunsNamn: "Grums",
    skolenhetsKod: "94270954",
    skolenhetensNamn: "Borgviks skola",
    postnr: "66495",
    besökspostnr: "66495",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1764",
    kommunsNamn: "Grums",
    skolenhetsKod: "16885406",
    skolenhetensNamn: "Slottsbrons skola",
    postnr: "66480",
    besökspostnr: "66432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1764",
    kommunsNamn: "Grums",
    skolenhetsKod: "29262102",
    skolenhetensNamn: "Södra Skolan",
    postnr: "66480",
    besökspostnr: "66480",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1764",
    kommunsNamn: "Grums",
    skolenhetsKod: "99302196",
    skolenhetensNamn: "Värmskogs Friskola",
    postnr: "66492",
    besökspostnr: "66492",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1765",
    kommunsNamn: "Årjäng",
    skolenhetsKod: "71523536",
    skolenhetensNamn: "Holmedals skola",
    postnr: "67294",
    besökspostnr: "67294",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1765",
    kommunsNamn: "Årjäng",
    skolenhetsKod: "56579896",
    skolenhetensNamn: "Nordmarkens skola L",
    postnr: "67229",
    besökspostnr: "67232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1765",
    kommunsNamn: "Årjäng",
    skolenhetsKod: "14840621",
    skolenhetensNamn: "Signebyns Friskola",
    postnr: "67295",
    besökspostnr: "67295",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1765",
    kommunsNamn: "Årjäng",
    skolenhetsKod: "79211046",
    skolenhetensNamn: "Svensbyn skola",
    postnr: "67295",
    besökspostnr: "67295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1765",
    kommunsNamn: "Årjäng",
    skolenhetsKod: "77714062",
    skolenhetensNamn: "Töcksfors skola LM",
    postnr: "67241",
    besökspostnr: "67241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1766",
    kommunsNamn: "Sunne",
    skolenhetsKod: "12137105",
    skolenhetensNamn: "Ekeby skola",
    postnr: "68635",
    besökspostnr: "68635",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1766",
    kommunsNamn: "Sunne",
    skolenhetsKod: "19248047",
    skolenhetensNamn: "Gräsmarks skola",
    postnr: "68680",
    besökspostnr: "68698",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1766",
    kommunsNamn: "Sunne",
    skolenhetsKod: "14125620",
    skolenhetensNamn: "Klättenskolan",
    postnr: "68680",
    besökspostnr: "68693",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1766",
    kommunsNamn: "Sunne",
    skolenhetsKod: "50456203",
    skolenhetensNamn: "Lysviks skola",
    postnr: "68680",
    besökspostnr: "68605",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1766",
    kommunsNamn: "Sunne",
    skolenhetsKod: "25927543",
    skolenhetensNamn: "Prästbols skola",
    postnr: "68680",
    besökspostnr: "68680",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1766",
    kommunsNamn: "Sunne",
    skolenhetsKod: "59141134",
    skolenhetensNamn: "Skäggebergsskolan",
    postnr: "68680",
    besökspostnr: "68630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1766",
    kommunsNamn: "Sunne",
    skolenhetsKod: "18644192",
    skolenhetensNamn: "Svensby skola",
    postnr: "68680",
    besökspostnr: "68695",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1766",
    kommunsNamn: "Sunne",
    skolenhetsKod: "84959876",
    skolenhetensNamn: "Östra skolan",
    postnr: "68680",
    besökspostnr: "68633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "71069295",
    skolenhetensNamn: "Backa Friskola",
    postnr: "65560",
    besökspostnr: "65560",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "15838696",
    skolenhetensNamn: "Fredricelundsskolan",
    postnr: "65345",
    besökspostnr: "65632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "44474627",
    skolenhetensNamn: "Färjestadsskolan F-3",
    postnr: "65465",
    besökspostnr: "65465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "55511360",
    skolenhetensNamn: "Hagaborgsskolan",
    postnr: "65462",
    besökspostnr: "65223",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "25587225",
    skolenhetensNamn: "Herrhagsskolan F-3",
    postnr: "65346",
    besökspostnr: "65346",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "80699670",
    skolenhetensNamn: "Hultsbergsskolan F-6",
    postnr: "65346",
    besökspostnr: "65350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "57384233",
    skolenhetensNamn: "Kronoparksskolan",
    postnr: "65633",
    besökspostnr: "65633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "16972478",
    skolenhetensNamn: "Kroppkärrsskolan F-3",
    postnr: "65468",
    besökspostnr: "65468",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "94659471",
    skolenhetensNamn: "Kvarnbergsskolan",
    postnr: "65230",
    besökspostnr: "65230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "37922640",
    skolenhetensNamn: "Montessorifriskolan Stellatus",
    postnr: "65231",
    besökspostnr: "65231",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "14290135",
    skolenhetensNamn: "Nordic International School Karlstad",
    postnr: "65215",
    besökspostnr: "65215",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "41081474",
    skolenhetensNamn: "Nyeds skola",
    postnr: "65560",
    besökspostnr: "65560",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "67510587",
    skolenhetensNamn: "Orrholmsskolan",
    postnr: "65227",
    besökspostnr: "65466",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "48111694",
    skolenhetensNamn: "Råtorpsskolan",
    postnr: "65349",
    besökspostnr: "65469",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "95586087",
    skolenhetensNamn: "Skattkärrsskolan F-3",
    postnr: "65672",
    besökspostnr: "65672",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "42264769",
    skolenhetensNamn: "Skåreskolan",
    postnr: "65350",
    besökspostnr: "65348",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "43889515",
    skolenhetensNamn: "Stockfallets skola",
    postnr: "65457",
    besökspostnr: "65457",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "16944326",
    skolenhetensNamn: "Stodeneskolan",
    postnr: "65351",
    besökspostnr: "65224",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "62565038",
    skolenhetensNamn: "Södra Råtorps skola",
    postnr: "65348",
    besökspostnr: "65224",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "85092070",
    skolenhetensNamn: "Thoren Framtid Karlstad",
    postnr: "65339",
    besökspostnr: "65339",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "70178983",
    skolenhetensNamn: "Tuggeliteskolan",
    postnr: "65350",
    besökspostnr: "65350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "87919224",
    skolenhetensNamn: "Vallargärdets skola",
    postnr: "65593",
    besökspostnr: "65593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "41284234",
    skolenhetensNamn: "Vålbergsskolan F-6",
    postnr: "66451",
    besökspostnr: "66451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "60085423",
    skolenhetensNamn: "Väse skola",
    postnr: "65595",
    besökspostnr: "65595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1780",
    kommunsNamn: "Karlstad",
    skolenhetsKod: "20640247",
    skolenhetensNamn: "Västerstrandsskolan F-3",
    postnr: "65342",
    besökspostnr: "65342",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1781",
    kommunsNamn: "Kristinehamn",
    skolenhetsKod: "77815401",
    skolenhetensNamn: "Björneborgs skola F-6",
    postnr: "68184",
    besökspostnr: "68071",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1781",
    kommunsNamn: "Kristinehamn",
    skolenhetsKod: "55509192",
    skolenhetensNamn: "Djurgårdsskolan F-9",
    postnr: "68184",
    besökspostnr: "68153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1781",
    kommunsNamn: "Kristinehamn",
    skolenhetsKod: "87549929",
    skolenhetensNamn: "Grunnebacka skola F-6",
    postnr: "68184",
    besökspostnr: "68194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1781",
    kommunsNamn: "Kristinehamn",
    skolenhetsKod: "14885908",
    skolenhetensNamn: "Stenstalidskolan F-9",
    postnr: "68184",
    besökspostnr: "68135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1781",
    kommunsNamn: "Kristinehamn",
    skolenhetsKod: "43348908",
    skolenhetensNamn: "Strandskolan ",
    postnr: "68184",
    besökspostnr: "68136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1781",
    kommunsNamn: "Kristinehamn",
    skolenhetsKod: "27777261",
    skolenhetensNamn: "Södermalmsskolan F-9",
    postnr: "68184",
    besökspostnr: "68140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1781",
    kommunsNamn: "Kristinehamn",
    skolenhetsKod: "30592273",
    skolenhetensNamn: "Ölmeskolan ",
    postnr: "68184",
    besökspostnr: "68195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1782",
    kommunsNamn: "Filipstad",
    skolenhetsKod: "86042309",
    skolenhetensNamn: "Brattfors skola",
    postnr: "68291",
    besökspostnr: "68291",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1782",
    kommunsNamn: "Filipstad",
    skolenhetsKod: "87243457",
    skolenhetensNamn: "Nordmarks skola",
    postnr: "68293",
    besökspostnr: "68093",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1782",
    kommunsNamn: "Filipstad",
    skolenhetsKod: "81713999",
    skolenhetensNamn: "Nykroppa skola",
    postnr: "68090",
    besökspostnr: "68290",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1782",
    kommunsNamn: "Filipstad",
    skolenhetsKod: "99468217",
    skolenhetensNamn: "Persbergs skola",
    postnr: "68240",
    besökspostnr: "68240",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1782",
    kommunsNamn: "Filipstad",
    skolenhetsKod: "10258227",
    skolenhetensNamn: "Stjärnskolan",
    postnr: "68227",
    besökspostnr: "68231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1782",
    kommunsNamn: "Filipstad",
    skolenhetsKod: "37648787",
    skolenhetensNamn: "Stålvallaskolan",
    postnr: "68096",
    besökspostnr: "68096",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1782",
    kommunsNamn: "Filipstad",
    skolenhetsKod: "98680382",
    skolenhetensNamn: "Åsenskolan",
    postnr: "68234",
    besökspostnr: "68234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1783",
    kommunsNamn: "Hagfors",
    skolenhetsKod: "76332227",
    skolenhetensNamn: "Kyrkhedens skola F-3",
    postnr: "68360",
    besökspostnr: "68360",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1783",
    kommunsNamn: "Hagfors",
    skolenhetsKod: "64392595",
    skolenhetensNamn: "Råda skola",
    postnr: "68393",
    besökspostnr: "68393",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1783",
    kommunsNamn: "Hagfors",
    skolenhetsKod: "32863672",
    skolenhetensNamn: "Sunnemo skola",
    postnr: "68395",
    besökspostnr: "68395",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1783",
    kommunsNamn: "Hagfors",
    skolenhetsKod: "92934002",
    skolenhetensNamn: "Älvstranden bildn.centrum F-3",
    postnr: "68380",
    besökspostnr: "68330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "71123975",
    skolenhetensNamn: "Dotteviksskolan",
    postnr: "67181",
    besökspostnr: "67133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "26650042",
    skolenhetensNamn: "Edane skola",
    postnr: "67181",
    besökspostnr: "67170",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "99788707",
    skolenhetensNamn: "Gateskolan",
    postnr: "67181",
    besökspostnr: "67142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "96746847",
    skolenhetensNamn: "Glava skola",
    postnr: "67181",
    besökspostnr: "67020",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "21442650",
    skolenhetensNamn: "Graningegården",
    postnr: "67181",
    besökspostnr: "67141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "63265335",
    skolenhetensNamn: "Järvenskolan",
    postnr: "67035",
    besökspostnr: "67035",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "57618045",
    skolenhetensNamn: "Jössefors skola",
    postnr: "67181",
    besökspostnr: "67160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "34377463",
    skolenhetensNamn: "Klässbols skola",
    postnr: "67181",
    besökspostnr: "67195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "82022664",
    skolenhetensNamn: "Kyrkebyskolan F-3",
    postnr: "67152",
    besökspostnr: "67152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "71530490",
    skolenhetensNamn: "Rinnens skola",
    postnr: "67181",
    besökspostnr: "67196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1784",
    kommunsNamn: "Arvika",
    skolenhetsKod: "11995760",
    skolenhetensNamn: "Styckåsskolan",
    postnr: "67132",
    besökspostnr: "67132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1785",
    kommunsNamn: "Säffle",
    skolenhetsKod: "88301687",
    skolenhetensNamn: "Botilsäter skola",
    postnr: "66191",
    besökspostnr: "66191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1785",
    kommunsNamn: "Säffle",
    skolenhetsKod: "56423824",
    skolenhetensNamn: "Höglundaskolan ",
    postnr: "66180",
    besökspostnr: "66131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1785",
    kommunsNamn: "Säffle",
    skolenhetsKod: "51545844",
    skolenhetensNamn: "Långseruds Friskola",
    postnr: "66196",
    besökspostnr: "66196",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1785",
    kommunsNamn: "Säffle",
    skolenhetsKod: "47179243",
    skolenhetensNamn: "Nysäters skola",
    postnr: "66195",
    besökspostnr: "66195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1785",
    kommunsNamn: "Säffle",
    skolenhetsKod: "96420586",
    skolenhetensNamn: "Svanskogs skola",
    postnr: "66250",
    besökspostnr: "66193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1785",
    kommunsNamn: "Säffle",
    skolenhetsKod: "93957990",
    skolenhetensNamn: "Tingvallaskolan ",
    postnr: "66180",
    besökspostnr: "66194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1785",
    kommunsNamn: "Säffle",
    skolenhetsKod: "21561939",
    skolenhetensNamn: "Tveta friskola",
    postnr: "66194",
    besökspostnr: "66194",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "17",
    länsNamn: "Värmlands län",
    kommun: "1785",
    kommunsNamn: "Säffle",
    skolenhetsKod: "61057459",
    skolenhetensNamn: "Värmlandsbro skola",
    postnr: "66180",
    besökspostnr: "66193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1814",
    kommunsNamn: "Lekeberg",
    skolenhetsKod: "11320436",
    skolenhetensNamn: "Hidinge skola",
    postnr: "71631",
    besökspostnr: "71993",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1814",
    kommunsNamn: "Lekeberg",
    skolenhetsKod: "45620054",
    skolenhetensNamn: "Lekebergsskolan f-6",
    postnr: "71631",
    besökspostnr: "71630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1814",
    kommunsNamn: "Lekeberg",
    skolenhetsKod: "47490833",
    skolenhetensNamn: "Mullhyttans skola",
    postnr: "71631",
    besökspostnr: "71694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1860",
    kommunsNamn: "Laxå",
    skolenhetsKod: "45111461",
    skolenhetensNamn: "Finnerödja skola",
    postnr: "69580",
    besökspostnr: "69550",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1860",
    kommunsNamn: "Laxå",
    skolenhetsKod: "28511234",
    skolenhetensNamn: "Saltängsskolan",
    postnr: "69580",
    besökspostnr: "69531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1861",
    kommunsNamn: "Hallsberg",
    skolenhetsKod: "99707554",
    skolenhetensNamn: "Folkasboskolan",
    postnr: "69480",
    besökspostnr: "69731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1861",
    kommunsNamn: "Hallsberg",
    skolenhetsKod: "66493604",
    skolenhetensNamn: "Fredriksbergskolan",
    postnr: "69480",
    besökspostnr: "69450",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1861",
    kommunsNamn: "Hallsberg",
    skolenhetsKod: "17140131",
    skolenhetensNamn: "Långängskolan",
    postnr: "69480",
    besökspostnr: "69436",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1861",
    kommunsNamn: "Hallsberg",
    skolenhetsKod: "61946431",
    skolenhetensNamn: "Sköllersta skola",
    postnr: "69480",
    besökspostnr: "69774",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1861",
    kommunsNamn: "Hallsberg",
    skolenhetsKod: "30150909",
    skolenhetensNamn: "Stocksätterskolan",
    postnr: "69480",
    besökspostnr: "69434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1861",
    kommunsNamn: "Hallsberg",
    skolenhetsKod: "62839697",
    skolenhetensNamn: "Östansjö skola",
    postnr: "69480",
    besökspostnr: "69472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1862",
    kommunsNamn: "Degerfors",
    skolenhetsKod: "10302576",
    skolenhetensNamn: "Bruksskolan",
    postnr: "69380",
    besökspostnr: "69332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1862",
    kommunsNamn: "Degerfors",
    skolenhetsKod: "47814121",
    skolenhetensNamn: "Parkskolan",
    postnr: "69380",
    besökspostnr: "69333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1862",
    kommunsNamn: "Degerfors",
    skolenhetsKod: "45554806",
    skolenhetensNamn: "Strömtorpsskolan",
    postnr: "69380",
    besökspostnr: "69334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1862",
    kommunsNamn: "Degerfors",
    skolenhetsKod: "93324945",
    skolenhetensNamn: "Svartå skola",
    postnr: "69373",
    besökspostnr: "69373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1862",
    kommunsNamn: "Degerfors",
    skolenhetsKod: "67016830",
    skolenhetensNamn: "Åtorps skola",
    postnr: "69380",
    besökspostnr: "69370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1863",
    kommunsNamn: "Hällefors",
    skolenhetsKod: "17425971",
    skolenhetensNamn: "Grythyttans skola",
    postnr: "71260",
    besökspostnr: "71260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1863",
    kommunsNamn: "Hällefors",
    skolenhetsKod: "71650107",
    skolenhetensNamn: "Klockarhagsskolan F-åk3",
    postnr: "71234",
    besökspostnr: "71234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1864",
    kommunsNamn: "Ljusnarsberg",
    skolenhetsKod: "82447364",
    skolenhetensNamn: "Kyrkbacksskolan F-6",
    postnr: "71480",
    besökspostnr: "71435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "28337496",
    skolenhetensNamn: "Adolfsbergsskolan F-6",
    postnr: "70135",
    besökspostnr: "70229",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "20662016",
    skolenhetensNamn: "Almbro skola",
    postnr: "70594",
    besökspostnr: "70594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "22049573",
    skolenhetensNamn: "Almby skola F-9",
    postnr: "70135",
    besökspostnr: "70284",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "41515120",
    skolenhetensNamn: "Arena Skolor",
    postnr: "10425",
    besökspostnr: "10425",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "30738069",
    skolenhetensNamn: "Askers skola ",
    postnr: "71594",
    besökspostnr: "71594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "26642139",
    skolenhetensNamn: "Birgittaskolan",
    postnr: "70006",
    besökspostnr: "70215",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "61730546",
    skolenhetensNamn: "Björkhagaskolan",
    postnr: "70135",
    besökspostnr: "70378",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "52031697",
    skolenhetensNamn: "Brickebackens skola",
    postnr: "70135",
    besökspostnr: "70234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "30929461",
    skolenhetensNamn: "Brukets skola",
    postnr: "70135",
    besökspostnr: "70347",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "50047434",
    skolenhetensNamn: "Brunnsskolan",
    postnr: "70135",
    besökspostnr: "70228",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "40703394",
    skolenhetensNamn: "Ervalla skola",
    postnr: "71895",
    besökspostnr: "71895",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "98267471",
    skolenhetensNamn: "Fridhems friskola",
    postnr: "70595",
    besökspostnr: "70595",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "35718563",
    skolenhetensNamn: "Fyren Ekalmens skola",
    postnr: "70510",
    besökspostnr: "70510",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "88115091",
    skolenhetensNamn: "Föräldrakoop. Tysslinge skola",
    postnr: "71995",
    besökspostnr: "71995",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "89477947",
    skolenhetensNamn: "Garphyttans skola",
    postnr: "71940",
    besökspostnr: "71940",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "17183375",
    skolenhetensNamn: "Glanshammars skola ",
    postnr: "70385",
    besökspostnr: "70385",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "36409468",
    skolenhetensNamn: "Grenadjärskolan",
    postnr: "70381",
    besökspostnr: "70381",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "17643323",
    skolenhetensNamn: "Hagaskolan",
    postnr: "70135",
    besökspostnr: "70349",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "57519823",
    skolenhetensNamn: "Hampetorps skola",
    postnr: "71593",
    besökspostnr: "71593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "13390547",
    skolenhetensNamn: "Hannaskolan",
    postnr: "70140",
    besökspostnr: "70347",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "91629136",
    skolenhetensNamn: "Hovstaskolan F-3",
    postnr: "70376",
    besökspostnr: "70376",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "11909268",
    skolenhetensNamn: "Johannaskolan Örebro Waldorfskola",
    postnr: "70230",
    besökspostnr: "70230",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "91287311",
    skolenhetensNamn: "Karlslundsskolan",
    postnr: "70346",
    besökspostnr: "70346",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "63524247",
    skolenhetensNamn: "Kryddgårdsskolan",
    postnr: "70218",
    besökspostnr: "70218",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "66004397",
    skolenhetensNamn: "Latorps skola",
    postnr: "71994",
    besökspostnr: "71994",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "63158277",
    skolenhetensNamn: "Lillåns skola F-6",
    postnr: "70375",
    besökspostnr: "70375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "31288135",
    skolenhetensNamn: "Lillåns Södra skola",
    postnr: "70375",
    besökspostnr: "70375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "69293291",
    skolenhetensNamn: "Lundbyskolan",
    postnr: "70135",
    besökspostnr: "70374",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "49973795",
    skolenhetensNamn: "Mariebergsskolan",
    postnr: "70231",
    besökspostnr: "70231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "95079835",
    skolenhetensNamn: "Mellringeskolan F-6",
    postnr: "70135",
    besökspostnr: "70353",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "29032881",
    skolenhetensNamn: "Mosjö skola",
    postnr: "70233",
    besökspostnr: "70233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "14513548",
    skolenhetensNamn: "Norrbyskolan",
    postnr: "70135",
    besökspostnr: "70369",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "73709415",
    skolenhetensNamn: "Närkes Kils skola",
    postnr: "70592",
    besökspostnr: "70592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "66382532",
    skolenhetensNamn: "Odenskolan",
    postnr: "71531",
    besökspostnr: "71531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "93918784",
    skolenhetensNamn: "Olaus Petriskolan F-6",
    postnr: "70135",
    besökspostnr: "70367",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "42072415",
    skolenhetensNamn: "Rostaskolan F-3",
    postnr: "70135",
    besökspostnr: "70344",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "61320743",
    skolenhetensNamn: "Stora Mellösa skola",
    postnr: "71572",
    besökspostnr: "71572",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "18025305",
    skolenhetensNamn: "Stureskolan",
    postnr: "70135",
    besökspostnr: "70213",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "68276614",
    skolenhetensNamn: "Svealundsskolan",
    postnr: "70216",
    besökspostnr: "70216",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "67734232",
    skolenhetensNamn: "Sörbyskolan",
    postnr: "70219",
    besökspostnr: "70219",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "70976533",
    skolenhetensNamn: "Sörbyängsskolan",
    postnr: "70135",
    besökspostnr: "70218",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "19204501",
    skolenhetensNamn: "Tegnérskolan",
    postnr: "70135",
    besökspostnr: "70357",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "51948478",
    skolenhetensNamn: "Tybblelundsskolan",
    postnr: "70286",
    besökspostnr: "70286",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "61540405",
    skolenhetensNamn: "Wallerska skolan",
    postnr: "70135",
    besökspostnr: "70360",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "19742982",
    skolenhetensNamn: "Viktoriaskolan",
    postnr: "70235",
    besökspostnr: "70235",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "38054320",
    skolenhetensNamn: "Vintrosa skola",
    postnr: "71930",
    besökspostnr: "71930",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "64644807",
    skolenhetensNamn: "Vivallaskolan",
    postnr: "70135",
    besökspostnr: "70370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "57966953",
    skolenhetensNamn: "Västra Engelbrektsskolan",
    postnr: "70135",
    besökspostnr: "70359",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "60272834",
    skolenhetensNamn: "Änglandaskolan",
    postnr: "70239",
    besökspostnr: "70239",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1880",
    kommunsNamn: "Örebro",
    skolenhetsKod: "13824623",
    skolenhetensNamn: "Ölmbrotorps skola",
    postnr: "71840",
    besökspostnr: "71840",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "48016240",
    skolenhetensNamn: "Ekeby skola",
    postnr: "69280",
    besökspostnr: "69234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "73848710",
    skolenhetensNamn: "Fylsta skola",
    postnr: "69280",
    besökspostnr: "69232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "89533473",
    skolenhetensNamn: "Hagaskolan",
    postnr: "69280",
    besökspostnr: "69293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "59594715",
    skolenhetensNamn: "Hardemo skola",
    postnr: "69280",
    besökspostnr: "69234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "49957284",
    skolenhetensNamn: "Kumlaby skola F-6",
    postnr: "69280",
    besökspostnr: "69292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "17387844",
    skolenhetensNamn: "Lillhedens skola",
    postnr: "69280",
    besökspostnr: "69237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "12251866",
    skolenhetensNamn: "Malmens skola",
    postnr: "69280",
    besökspostnr: "69238",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "26053163",
    skolenhetensNamn: "Skogstorpsskolan F-6",
    postnr: "69280",
    besökspostnr: "69273",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "61336347",
    skolenhetensNamn: "Stene skola",
    postnr: "69280",
    besökspostnr: "69272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1881",
    kommunsNamn: "Kumla",
    skolenhetsKod: "22082291",
    skolenhetensNamn: "Tallängens skola",
    postnr: "69280",
    besökspostnr: "69235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1882",
    kommunsNamn: "Askersund",
    skolenhetsKod: "95952398",
    skolenhetensNamn: "Hammars skola",
    postnr: "69682",
    besökspostnr: "69674",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1882",
    kommunsNamn: "Askersund",
    skolenhetsKod: "34933810",
    skolenhetensNamn: "Närlundaskolan",
    postnr: "69682",
    besökspostnr: "69633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1882",
    kommunsNamn: "Askersund",
    skolenhetsKod: "42366130",
    skolenhetensNamn: "Rönneshytta skola",
    postnr: "69682",
    besökspostnr: "69497",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1882",
    kommunsNamn: "Askersund",
    skolenhetsKod: "94560681",
    skolenhetensNamn: "Åmmebergs skola",
    postnr: "69682",
    besökspostnr: "69675",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1882",
    kommunsNamn: "Askersund",
    skolenhetsKod: "26625115",
    skolenhetensNamn: "Åsbro skola",
    postnr: "69682",
    besökspostnr: "69460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1883",
    kommunsNamn: "Karlskoga",
    skolenhetsKod: "37648907",
    skolenhetensNamn: "Aggerudsskolan",
    postnr: "69183",
    besökspostnr: "69145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1883",
    kommunsNamn: "Karlskoga",
    skolenhetsKod: "23655127",
    skolenhetensNamn: "Bråtenskolan",
    postnr: "69183",
    besökspostnr: "69154",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1883",
    kommunsNamn: "Karlskoga",
    skolenhetsKod: "85486578",
    skolenhetensNamn: "Granbergsdals skola",
    postnr: "69192",
    besökspostnr: "69192",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1883",
    kommunsNamn: "Karlskoga",
    skolenhetsKod: "76977393",
    skolenhetensNamn: "Häsängsskolan",
    postnr: "69183",
    besökspostnr: "69142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1883",
    kommunsNamn: "Karlskoga",
    skolenhetsKod: "44008528",
    skolenhetensNamn: "Karlbergsskolan ",
    postnr: "69183",
    besökspostnr: "69136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1883",
    kommunsNamn: "Karlskoga",
    skolenhetsKod: "16112812",
    skolenhetensNamn: "Rävåsskolan",
    postnr: "69183",
    besökspostnr: "69132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1883",
    kommunsNamn: "Karlskoga",
    skolenhetsKod: "84776762",
    skolenhetensNamn: "Sandviksskolan",
    postnr: "69183",
    besökspostnr: "69152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1884",
    kommunsNamn: "Nora",
    skolenhetsKod: "18828813",
    skolenhetensNamn: "Borns Friskola",
    postnr: "71393",
    besökspostnr: "71393",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1884",
    kommunsNamn: "Nora",
    skolenhetsKod: "67661912",
    skolenhetensNamn: "Gyttorps skola",
    postnr: "71380",
    besökspostnr: "71372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1884",
    kommunsNamn: "Nora",
    skolenhetsKod: "43466167",
    skolenhetensNamn: "Järnboås skola",
    postnr: "71380",
    besökspostnr: "71394",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1884",
    kommunsNamn: "Nora",
    skolenhetsKod: "11691284",
    skolenhetensNamn: "Järntorgsskolan",
    postnr: "71380",
    besökspostnr: "71331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1884",
    kommunsNamn: "Nora",
    skolenhetsKod: "33979629",
    skolenhetensNamn: "Lärkesskolan",
    postnr: "71380",
    besökspostnr: "71330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1884",
    kommunsNamn: "Nora",
    skolenhetsKod: "29963998",
    skolenhetensNamn: "Ås skola",
    postnr: "71380",
    besökspostnr: "71334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1885",
    kommunsNamn: "Lindesberg",
    skolenhetsKod: "47885092",
    skolenhetensNamn: "Björkhagaskolan",
    postnr: "71133",
    besökspostnr: "71133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1885",
    kommunsNamn: "Lindesberg",
    skolenhetsKod: "68098327",
    skolenhetensNamn: "Brotorpsskolan",
    postnr: "71134",
    besökspostnr: "71134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1885",
    kommunsNamn: "Lindesberg",
    skolenhetsKod: "24053777",
    skolenhetensNamn: "Ekbackens skola",
    postnr: "73271",
    besökspostnr: "73271",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1885",
    kommunsNamn: "Lindesberg",
    skolenhetsKod: "15641642",
    skolenhetensNamn: "Fröviskolan F-6",
    postnr: "71831",
    besökspostnr: "71831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1885",
    kommunsNamn: "Lindesberg",
    skolenhetsKod: "33243201",
    skolenhetensNamn: "Hagabackens skola",
    postnr: "71178",
    besökspostnr: "71178",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1885",
    kommunsNamn: "Lindesberg",
    skolenhetsKod: "15245953",
    skolenhetensNamn: "Löa skola",
    postnr: "71494",
    besökspostnr: "71494",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1885",
    kommunsNamn: "Lindesberg",
    skolenhetsKod: "56594923",
    skolenhetensNamn: "Pilkrogs friskola",
    postnr: "71194",
    besökspostnr: "71194",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "18",
    länsNamn: "Örebro län",
    kommun: "1885",
    kommunsNamn: "Lindesberg",
    skolenhetsKod: "98044413",
    skolenhetensNamn: "Vedevågs skola",
    postnr: "71172",
    besökspostnr: "71172",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1904",
    kommunsNamn: "Skinnskatteberg",
    skolenhetsKod: "62958817",
    skolenhetensNamn: "Klockarbergsskolan",
    postnr: "73922",
    besökspostnr: "73931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1907",
    kommunsNamn: "Surahammar",
    skolenhetsKod: "42583411",
    skolenhetensNamn: "Bäckhammarskolan",
    postnr: "73061",
    besökspostnr: "73060",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1907",
    kommunsNamn: "Surahammar",
    skolenhetsKod: "10017830",
    skolenhetensNamn: "Nytorpsskolan",
    postnr: "73523",
    besökspostnr: "73535",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1907",
    kommunsNamn: "Surahammar",
    skolenhetsKod: "39566281",
    skolenhetensNamn: "Starbäcksskolan",
    postnr: "73523",
    besökspostnr: "73537",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1907",
    kommunsNamn: "Surahammar",
    skolenhetsKod: "36302522",
    skolenhetensNamn: "Virsboskolan",
    postnr: "73061",
    besökspostnr: "73061",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1960",
    kommunsNamn: "Kungsör",
    skolenhetsKod: "73280362",
    skolenhetensNamn: "Björskogsskolan",
    postnr: "73685",
    besökspostnr: "73160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1960",
    kommunsNamn: "Kungsör",
    skolenhetsKod: "83880062",
    skolenhetensNamn: "Hagaskolan",
    postnr: "73685",
    besökspostnr: "73632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1960",
    kommunsNamn: "Kungsör",
    skolenhetsKod: "91901412",
    skolenhetensNamn: "Västerskolan",
    postnr: "73685",
    besökspostnr: "73634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1961",
    kommunsNamn: "Hallstahammar",
    skolenhetsKod: "88403889",
    skolenhetensNamn: "Lindboskolan",
    postnr: "73480",
    besökspostnr: "73436",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1961",
    kommunsNamn: "Hallstahammar",
    skolenhetsKod: "98570516",
    skolenhetensNamn: "Nibbleskolan",
    postnr: "73480",
    besökspostnr: "73432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1961",
    kommunsNamn: "Hallstahammar",
    skolenhetsKod: "41183880",
    skolenhetensNamn: "Näslundskolan",
    postnr: "73480",
    besökspostnr: "73436",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1961",
    kommunsNamn: "Hallstahammar",
    skolenhetsKod: "17031958",
    skolenhetensNamn: "Strömsholms skola",
    postnr: "73480",
    besökspostnr: "73494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1961",
    kommunsNamn: "Hallstahammar",
    skolenhetsKod: "63910317",
    skolenhetensNamn: "Vallbyskolan",
    postnr: "73480",
    besökspostnr: "73450",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1962",
    kommunsNamn: "Norberg",
    skolenhetsKod: "22451343",
    skolenhetensNamn: "Källskolan",
    postnr: "73821",
    besökspostnr: "73831",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "89379865",
    skolenhetensNamn: "Apalbyskolan",
    postnr: "72187",
    besökspostnr: "72243",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "27031962",
    skolenhetensNamn: "Barkaröskolan",
    postnr: "72187",
    besökspostnr: "72591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "44233076",
    skolenhetensNamn: "Bjurhovdaskolan",
    postnr: "72187",
    besökspostnr: "72352",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "88691291",
    skolenhetensNamn: "Blåsboskolan",
    postnr: "72187",
    besökspostnr: "72218",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "71386020",
    skolenhetensNamn: "Brandthovdaskolan",
    postnr: "72187",
    besökspostnr: "72351",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "51133192",
    skolenhetensNamn: "Dingtuna skola",
    postnr: "72187",
    besökspostnr: "72592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "60051507",
    skolenhetensNamn: "Ekbergaskolan",
    postnr: "72187",
    besökspostnr: "72347",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "73663359",
    skolenhetensNamn: "Emausskolan",
    postnr: "72187",
    besökspostnr: "72226",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "40421253",
    skolenhetensNamn: "Fredriksbergsskolan",
    postnr: "72187",
    besökspostnr: "72471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "89826478",
    skolenhetensNamn: "Fridhemsskolan grund",
    postnr: "72187",
    besökspostnr: "72473",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "17617299",
    skolenhetensNamn: "Fridnässkolan",
    postnr: "72187",
    besökspostnr: "72460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "57243353",
    skolenhetensNamn: "Grundskolan Äventyret",
    postnr: "72338",
    besökspostnr: "72338",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "81095541",
    skolenhetensNamn: "Gäddeholmsskolan",
    postnr: "72187",
    besökspostnr: "72597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "66792205",
    skolenhetensNamn: "Hammarbyskolan",
    postnr: "72187",
    besökspostnr: "72465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "54537199",
    skolenhetensNamn: "Hamreskolan",
    postnr: "72187",
    besökspostnr: "72348",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "46380811",
    skolenhetensNamn: "Herrgärdsskolan",
    postnr: "72187",
    besökspostnr: "72216",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "42930046",
    skolenhetensNamn: "Håkantorpsskolan",
    postnr: "72187",
    besökspostnr: "72476",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "25320686",
    skolenhetensNamn: "Hällbyskolan",
    postnr: "72187",
    besökspostnr: "72473",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "54574887",
    skolenhetensNamn: "Hökåsenskolan",
    postnr: "72187",
    besökspostnr: "72231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "41344384",
    skolenhetensNamn: "IQRA Skolan",
    postnr: "72020",
    besökspostnr: "72471",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "61002601",
    skolenhetensNamn: "Irstaskolan",
    postnr: "72187",
    besökspostnr: "72355",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "86598518",
    skolenhetensNamn: "Lövängsskolan",
    postnr: "72187",
    besökspostnr: "72591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "54921874",
    skolenhetensNamn: "Malmabergsskolan",
    postnr: "72187",
    besökspostnr: "72337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "78832667",
    skolenhetensNamn: "Mistelskolan",
    postnr: "72597",
    besökspostnr: "72597",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "27628050",
    skolenhetensNamn: "Mälarparksskolan",
    postnr: "72187",
    besökspostnr: "72356",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "30313010",
    skolenhetensNamn: "Noblaskolan Hagaberg",
    postnr: "72471",
    besökspostnr: "72471",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "80009811",
    skolenhetensNamn: "Noblaskolan Hemdal",
    postnr: "72335",
    besökspostnr: "72335",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "20157484",
    skolenhetensNamn: "Norra Vallbyskolan",
    postnr: "72187",
    besökspostnr: "72481",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "28359862",
    skolenhetensNamn: "Ormkärrskolan",
    postnr: "72187",
    besökspostnr: "72246",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "65164121",
    skolenhetensNamn: "Orrestaskolan",
    postnr: "72187",
    besökspostnr: "72596",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "66142210",
    skolenhetensNamn: "Pettersbergsskolan",
    postnr: "72187",
    besökspostnr: "72463",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "48048527",
    skolenhetensNamn: "Piltorpsskolan",
    postnr: "72187",
    besökspostnr: "72244",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "81265119",
    skolenhetensNamn: "Pilträdsskolan",
    postnr: "72480",
    besökspostnr: "72480",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "84602749",
    skolenhetensNamn: "Romfartunaskolan",
    postnr: "72620",
    besökspostnr: "72594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "99408329",
    skolenhetensNamn: "Rönnbyskolan",
    postnr: "72187",
    besökspostnr: "72241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "93147654",
    skolenhetensNamn: "Rösegårdsskolan",
    postnr: "72187",
    besökspostnr: "72477",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "78004150",
    skolenhetensNamn: "Skallbergsskolan",
    postnr: "72187",
    besökspostnr: "72222",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "50095535",
    skolenhetensNamn: "Skälbyskolan",
    postnr: "72476",
    besökspostnr: "72476",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "40474560",
    skolenhetensNamn: "Sofiaskolan",
    postnr: "72187",
    besökspostnr: "72473",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "87496094",
    skolenhetensNamn: "S:ta Gertruds skola",
    postnr: "72187",
    besökspostnr: "72467",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "26293113",
    skolenhetensNamn: "Tibbleskolan",
    postnr: "72620",
    besökspostnr: "72630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "33439128",
    skolenhetensNamn: "Tillbergaskolan",
    postnr: "72187",
    besökspostnr: "72233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "85408166",
    skolenhetensNamn: "Tortunaskolan",
    postnr: "72187",
    besökspostnr: "72596",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "79456355",
    skolenhetensNamn: "Vallbyskolan",
    postnr: "72187",
    besökspostnr: "72480",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "11729143",
    skolenhetensNamn: "Vetterstorpsskolan",
    postnr: "72187",
    besökspostnr: "72462",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "77340618",
    skolenhetensNamn: "Västerås Internationella Skola",
    postnr: "72473",
    besökspostnr: "72473",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "20160192",
    skolenhetensNamn: "Västerås Montessoriskola",
    postnr: "72461",
    besökspostnr: "72461",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1980",
    kommunsNamn: "Västerås",
    skolenhetsKod: "14896115",
    skolenhetensNamn: "Västerås Waldorfskola, grund",
    postnr: "72348",
    besökspostnr: "72348",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "61230291",
    skolenhetensNamn: "Grundsärskolan",
    postnr: "73340",
    besökspostnr: "73340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "84214609",
    skolenhetensNamn: "Hedens skola",
    postnr: "73325",
    besökspostnr: "73071",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "46962800",
    skolenhetensNamn: "Kila skola",
    postnr: "73325",
    besökspostnr: "73394",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "26414444",
    skolenhetensNamn: "Kilbo skola",
    postnr: "73325",
    besökspostnr: "73362",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "61846061",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "73325",
    besökspostnr: "73375",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "74567533",
    skolenhetensNamn: "Lärkbacksskolan",
    postnr: "73325",
    besökspostnr: "73336",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "64471680",
    skolenhetensNamn: "Ransta skola",
    postnr: "73325",
    besökspostnr: "73373",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "40908531",
    skolenhetensNamn: "Vallaskolan F-6",
    postnr: "73325",
    besökspostnr: "73333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "26966214",
    skolenhetensNamn: "Varmsätra skola",
    postnr: "73325",
    besökspostnr: "73392",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "52288457",
    skolenhetensNamn: "Västerfärnebo skola",
    postnr: "73325",
    besökspostnr: "73070",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "45723714",
    skolenhetensNamn: "Åby skola",
    postnr: "73325",
    besökspostnr: "73391",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "91839062",
    skolenhetensNamn: "Åkraskolan",
    postnr: "73325",
    besökspostnr: "73335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1981",
    kommunsNamn: "Sala",
    skolenhetsKod: "96344566",
    skolenhetensNamn: "Ängshagenskolan",
    postnr: "73325",
    besökspostnr: "73340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1982",
    kommunsNamn: "Fagersta",
    skolenhetsKod: "22362814",
    skolenhetensNamn: "Alfaskolan",
    postnr: "73745",
    besökspostnr: "73745",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1982",
    kommunsNamn: "Fagersta",
    skolenhetsKod: "95162796",
    skolenhetensNamn: "Lindgårdsskolan F-6",
    postnr: "73702",
    besökspostnr: "73732",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1982",
    kommunsNamn: "Fagersta",
    skolenhetsKod: "81300090",
    skolenhetensNamn: "Mariaskolan",
    postnr: "73733",
    besökspostnr: "73733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1982",
    kommunsNamn: "Fagersta",
    skolenhetsKod: "98041227",
    skolenhetensNamn: "Per Olsskolan",
    postnr: "73743",
    besökspostnr: "73743",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1983",
    kommunsNamn: "Köping",
    skolenhetsKod: "29887798",
    skolenhetensNamn: "Elundskolan",
    postnr: "73142",
    besökspostnr: "73142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1983",
    kommunsNamn: "Köping",
    skolenhetsKod: "15212834",
    skolenhetensNamn: "Himmeta skola",
    postnr: "73196",
    besökspostnr: "73196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1983",
    kommunsNamn: "Köping",
    skolenhetsKod: "49794709",
    skolenhetensNamn: "Malmaskolan F-3",
    postnr: "73110",
    besökspostnr: "73110",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1983",
    kommunsNamn: "Köping",
    skolenhetsKod: "36924587",
    skolenhetensNamn: "Munktorpsskolan",
    postnr: "73170",
    besökspostnr: "73170",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1983",
    kommunsNamn: "Köping",
    skolenhetsKod: "36175549",
    skolenhetensNamn: "Nyckelbergsskolan",
    postnr: "73134",
    besökspostnr: "73134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1983",
    kommunsNamn: "Köping",
    skolenhetsKod: "99475738",
    skolenhetensNamn: "Odensvi skola",
    postnr: "73110",
    besökspostnr: "73193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1983",
    kommunsNamn: "Köping",
    skolenhetsKod: "79622038",
    skolenhetensNamn: "Skogsbrynskolan",
    postnr: "73151",
    besökspostnr: "73151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1983",
    kommunsNamn: "Köping",
    skolenhetsKod: "82625785",
    skolenhetensNamn: "S:t Olovsskolan",
    postnr: "73133",
    besökspostnr: "73133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1984",
    kommunsNamn: "Arboga",
    skolenhetsKod: "77132043",
    skolenhetensNamn: "Brattbergsskolan",
    postnr: "73221",
    besökspostnr: "73248",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1984",
    kommunsNamn: "Arboga",
    skolenhetsKod: "40576446",
    skolenhetensNamn: "Gäddgårdsskolan F-6",
    postnr: "73221",
    besökspostnr: "73246",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1984",
    kommunsNamn: "Arboga",
    skolenhetsKod: "56897691",
    skolenhetensNamn: "Götlunda skola",
    postnr: "73221",
    besökspostnr: "73297",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1984",
    kommunsNamn: "Arboga",
    skolenhetsKod: "76878404",
    skolenhetensNamn: "Ladubacksskolan",
    postnr: "73221",
    besökspostnr: "73233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1984",
    kommunsNamn: "Arboga",
    skolenhetsKod: "53910317",
    skolenhetensNamn: "Medåkers Friskola",
    postnr: "73296",
    besökspostnr: "73296",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "19",
    länsNamn: "Västmanlands län",
    kommun: "1984",
    kommunsNamn: "Arboga",
    skolenhetsKod: "80553326",
    skolenhetensNamn: "Nybyholmsskolan",
    postnr: "73221",
    besökspostnr: "73221",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2021",
    kommunsNamn: "Vansbro",
    skolenhetsKod: "92323583",
    skolenhetensNamn: "Berghedens skola",
    postnr: "78631",
    besökspostnr: "78694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2021",
    kommunsNamn: "Vansbro",
    skolenhetsKod: "57886808",
    skolenhetensNamn: "Nederborgs skola",
    postnr: "78631",
    besökspostnr: "78693",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2021",
    kommunsNamn: "Vansbro",
    skolenhetsKod: "21332837",
    skolenhetensNamn: "Olympicaskolan i Skålö",
    postnr: "78051",
    besökspostnr: "78051",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2021",
    kommunsNamn: "Vansbro",
    skolenhetsKod: "95672225",
    skolenhetensNamn: "Parkskolan",
    postnr: "78631",
    besökspostnr: "78631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2023",
    kommunsNamn: "Malung-Sälen",
    skolenhetsKod: "13118298",
    skolenhetensNamn: "Blomsterbäcksskolan",
    postnr: "78221",
    besökspostnr: "78231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2023",
    kommunsNamn: "Malung-Sälen",
    skolenhetsKod: "23193760",
    skolenhetensNamn: "Malungsfors skola",
    postnr: "78221",
    besökspostnr: "78275",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2023",
    kommunsNamn: "Malung-Sälen",
    skolenhetsKod: "60207912",
    skolenhetensNamn: "Rörbäcksnäs skola",
    postnr: "78066",
    besökspostnr: "78066",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2023",
    kommunsNamn: "Malung-Sälen",
    skolenhetsKod: "13866289",
    skolenhetensNamn: "Sälens skola",
    postnr: "78067",
    besökspostnr: "78067",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2023",
    kommunsNamn: "Malung-Sälen",
    skolenhetsKod: "17208837",
    skolenhetensNamn: "Ungärde skola",
    postnr: "78064",
    besökspostnr: "78064",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2026",
    kommunsNamn: "Gagnef",
    skolenhetsKod: "39921667",
    skolenhetensNamn: "Björboskolan",
    postnr: "78580",
    besökspostnr: "78545",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2026",
    kommunsNamn: "Gagnef",
    skolenhetsKod: "64837308",
    skolenhetensNamn: "Bäsna skola",
    postnr: "78580",
    besökspostnr: "78195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2026",
    kommunsNamn: "Gagnef",
    skolenhetsKod: "79683330",
    skolenhetensNamn: "Djurmoskolan",
    postnr: "78580",
    besökspostnr: "78561",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2026",
    kommunsNamn: "Gagnef",
    skolenhetsKod: "36819574",
    skolenhetensNamn: "Djuråsskolan F-9",
    postnr: "78580",
    besökspostnr: "78040",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2026",
    kommunsNamn: "Gagnef",
    skolenhetsKod: "39127609",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "78580",
    besökspostnr: "78543",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2026",
    kommunsNamn: "Gagnef",
    skolenhetsKod: "95610127",
    skolenhetensNamn: "Mockfjärdsskolan",
    postnr: "78580",
    besökspostnr: "78544",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "89749001",
    skolenhetensNamn: "Banérskolan",
    postnr: "79350",
    besökspostnr: "79350",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "37594297",
    skolenhetensNamn: "Gärde skola",
    postnr: "79350",
    besökspostnr: "79350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "62927602",
    skolenhetensNamn: "Insjöns skola",
    postnr: "79380",
    besökspostnr: "79340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "38368827",
    skolenhetensNamn: "Profilskolan Excel",
    postnr: "79331",
    besökspostnr: "79331",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "61834375",
    skolenhetensNamn: "Sammilsdalskolan F-9",
    postnr: "79331",
    besökspostnr: "79331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "46265060",
    skolenhetensNamn: "Siljansnäs skola",
    postnr: "79360",
    besökspostnr: "79360",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "95726294",
    skolenhetensNamn: "Tällberg skola",
    postnr: "79370",
    besökspostnr: "79370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "86131355",
    skolenhetensNamn: "Ullvi skola",
    postnr: "79391",
    besökspostnr: "79391",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2029",
    kommunsNamn: "Leksand",
    skolenhetsKod: "30471270",
    skolenhetensNamn: "Åkerö skola",
    postnr: "79333",
    besökspostnr: "79333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2031",
    kommunsNamn: "Rättvik",
    skolenhetsKod: "36630405",
    skolenhetensNamn: "Boda skola",
    postnr: "79580",
    besökspostnr: "79596",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2031",
    kommunsNamn: "Rättvik",
    skolenhetsKod: "30917018",
    skolenhetensNamn: "Furudals centralskola",
    postnr: "79580",
    besökspostnr: "79070",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2031",
    kommunsNamn: "Rättvik",
    skolenhetsKod: "33822526",
    skolenhetensNamn: "Ingels skola",
    postnr: "79580",
    besökspostnr: "79592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2031",
    kommunsNamn: "Rättvik",
    skolenhetsKod: "60694017",
    skolenhetensNamn: "Nyhedsskolan",
    postnr: "79580",
    besökspostnr: "79532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2031",
    kommunsNamn: "Rättvik",
    skolenhetsKod: "76353846",
    skolenhetensNamn: "Rättviks grundsärskola",
    postnr: "79580",
    besökspostnr: "79532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2031",
    kommunsNamn: "Rättvik",
    skolenhetsKod: "23181842",
    skolenhetensNamn: "Sätra skola",
    postnr: "79580",
    besökspostnr: "79591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2031",
    kommunsNamn: "Rättvik",
    skolenhetsKod: "52314089",
    skolenhetensNamn: "Söderås skola",
    postnr: "79580",
    besökspostnr: "79590",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2031",
    kommunsNamn: "Rättvik",
    skolenhetsKod: "89732023",
    skolenhetensNamn: "Vikarbyns skola",
    postnr: "79580",
    besökspostnr: "79570",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2034",
    kommunsNamn: "Orsa",
    skolenhetsKod: "44551665",
    skolenhetensNamn: "Bergetskolan",
    postnr: "79421",
    besökspostnr: "79433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2034",
    kommunsNamn: "Orsa",
    skolenhetsKod: "89047020",
    skolenhetensNamn: "Digerbergets skola",
    postnr: "79421",
    besökspostnr: "79492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2034",
    kommunsNamn: "Orsa",
    skolenhetsKod: "26900910",
    skolenhetensNamn: "Kyrkbyns skola",
    postnr: "79421",
    besökspostnr: "79430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2034",
    kommunsNamn: "Orsa",
    skolenhetsKod: "94925126",
    skolenhetensNamn: "Skattunge Friskola",
    postnr: "79495",
    besökspostnr: "79495",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2039",
    kommunsNamn: "Älvdalen",
    skolenhetsKod: "45898861",
    skolenhetensNamn: "Buskoviusskolan",
    postnr: "79090",
    besökspostnr: "79090",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2039",
    kommunsNamn: "Älvdalen",
    skolenhetsKod: "20822919",
    skolenhetensNamn: "Rot skola",
    postnr: "79622",
    besökspostnr: "79690",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2039",
    kommunsNamn: "Älvdalen",
    skolenhetsKod: "81918902",
    skolenhetensNamn: "Strandskolan",
    postnr: "79091",
    besökspostnr: "79091",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2039",
    kommunsNamn: "Älvdalen",
    skolenhetsKod: "88747700",
    skolenhetensNamn: "Älvdalsskolan",
    postnr: "79631",
    besökspostnr: "79631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2061",
    kommunsNamn: "Smedjebacken",
    skolenhetsKod: "48623893",
    skolenhetensNamn: "Hagge skola",
    postnr: "77781",
    besökspostnr: "77190",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2061",
    kommunsNamn: "Smedjebacken",
    skolenhetsKod: "44337763",
    skolenhetensNamn: "Röda Berga",
    postnr: "77781",
    besökspostnr: "77731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2061",
    kommunsNamn: "Smedjebacken",
    skolenhetsKod: "87981276",
    skolenhetensNamn: "Vad skola",
    postnr: "77781",
    besökspostnr: "77794",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2061",
    kommunsNamn: "Smedjebacken",
    skolenhetsKod: "25233410",
    skolenhetensNamn: "Vinsboskolan",
    postnr: "77781",
    besökspostnr: "77750",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "29561775",
    skolenhetensNamn: "Bjäkenbackens skola F-6",
    postnr: "79280",
    besökspostnr: "79295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "52366900",
    skolenhetensNamn: "Färnäs skola F-6",
    postnr: "79280",
    besökspostnr: "79275",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "20797156",
    skolenhetensNamn: "Morkarlby skola F-6",
    postnr: "79280",
    besökspostnr: "79237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "22251463",
    skolenhetensNamn: "Noretsskolan F-6",
    postnr: "79280",
    besökspostnr: "79250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "69560728",
    skolenhetensNamn: "Nusnäs skola F-6",
    postnr: "79280",
    besökspostnr: "79232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "48225182",
    skolenhetensNamn: "Selja-Långlets friskola",
    postnr: "79294",
    besökspostnr: "79294",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "76848295",
    skolenhetensNamn: "Sollerö skola F-6",
    postnr: "79280",
    besökspostnr: "79290",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "87211552",
    skolenhetensNamn: "Utmelands skola F-6",
    postnr: "79280",
    besökspostnr: "79234",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "34032649",
    skolenhetensNamn: "Venjans skola F-6",
    postnr: "79280",
    besökspostnr: "79293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "40674798",
    skolenhetensNamn: "Vika skola F-6",
    postnr: "79280",
    besökspostnr: "79291",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "68493881",
    skolenhetensNamn: "Våmhus skola F-6",
    postnr: "79280",
    besökspostnr: "79296",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2062",
    kommunsNamn: "Mora",
    skolenhetsKod: "88046548",
    skolenhetensNamn: "Ängsskolan F-9",
    postnr: "79237",
    besökspostnr: "79237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "48985419",
    skolenhetensNamn: "Aspebodaskolan",
    postnr: "79183",
    besökspostnr: "79193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "98702223",
    skolenhetensNamn: "Bjursåsskolan F-9",
    postnr: "79021",
    besökspostnr: "79021",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "17033155",
    skolenhetensNamn: "Britsarvsskolan",
    postnr: "79183",
    besökspostnr: "79136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "21243852",
    skolenhetensNamn: "Danholnsskolan",
    postnr: "79183",
    besökspostnr: "79171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "65494903",
    skolenhetensNamn: "Friskolan Mosaik",
    postnr: "79131",
    besökspostnr: "79137",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "53796764",
    skolenhetensNamn: "Främbyskolan",
    postnr: "79183",
    besökspostnr: "79020",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "16034765",
    skolenhetensNamn: "Gruvrisskolan",
    postnr: "79183",
    besökspostnr: "79143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "22409073",
    skolenhetensNamn: "Grycksboskolan",
    postnr: "79183",
    besökspostnr: "79143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "23964495",
    skolenhetensNamn: "Hosjöskolan",
    postnr: "79183",
    besökspostnr: "79145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "42110808",
    skolenhetensNamn: "Hälsingbergsskolan",
    postnr: "79183",
    besökspostnr: "79145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "28599917",
    skolenhetensNamn: "Hälsinggårdsskolan",
    postnr: "79183",
    besökspostnr: "79143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "72625090",
    skolenhetensNamn: "Innovitaskolan Falun",
    postnr: "79136",
    besökspostnr: "79136",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "76418419",
    skolenhetensNamn: "Liljansskolan",
    postnr: "79183",
    besökspostnr: "79131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "48065404",
    skolenhetensNamn: "Linghedsskolan",
    postnr: "79183",
    besökspostnr: "79183",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "57444890",
    skolenhetensNamn: "Montessoriskolan i Falun",
    postnr: "79183",
    besökspostnr: "79174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "78676362",
    skolenhetensNamn: "Rönndalsskolan",
    postnr: "79183",
    besökspostnr: "79015",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "52979014",
    skolenhetensNamn: "Slättaskolan",
    postnr: "79183",
    besökspostnr: "79174",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "27005091",
    skolenhetensNamn: "Sundbornsskolan",
    postnr: "79183",
    besökspostnr: "79022",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "27506076",
    skolenhetensNamn: "Svärdsjöskolan ",
    postnr: "79183",
    besökspostnr: "79151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "13917456",
    skolenhetensNamn: "Sågmyraskolan",
    postnr: "79183",
    besökspostnr: "79136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "87645833",
    skolenhetensNamn: "Södra skolan",
    postnr: "79151",
    besökspostnr: "79151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "71102563",
    skolenhetensNamn: "Toftaskolan",
    postnr: "79183",
    besökspostnr: "79160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "48804420",
    skolenhetensNamn: "Vikaskolan",
    postnr: "79183",
    besökspostnr: "79172",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2080",
    kommunsNamn: "Falun",
    skolenhetsKod: "35993773",
    skolenhetensNamn: "Östra skolan",
    postnr: "79172",
    besökspostnr: "79172",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "53466112",
    skolenhetensNamn: "Amsbergs skola",
    postnr: "78195",
    besökspostnr: "78195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "91219337",
    skolenhetensNamn: "Domnarvets skola",
    postnr: "78474",
    besökspostnr: "78474",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "16213077",
    skolenhetensNamn: "Forssaängskolan",
    postnr: "78445",
    besökspostnr: "78445",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "80265253",
    skolenhetensNamn: "Gylle skola F-6",
    postnr: "78181",
    besökspostnr: "78462",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "98971545",
    skolenhetensNamn: "Halvarsgårdars skola",
    postnr: "78198",
    besökspostnr: "78198",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "24147931",
    skolenhetensNamn: "Immanuelskolan",
    postnr: "78435",
    besökspostnr: "78435",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "53010712",
    skolenhetensNamn: "Kvarnsvedens skola",
    postnr: "78468",
    besökspostnr: "78468",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "39328823",
    skolenhetensNamn: "Kyrkskolan",
    postnr: "78463",
    besökspostnr: "78463",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "47473951",
    skolenhetensNamn: "Mjälgaskolan",
    postnr: "78435",
    besökspostnr: "78435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "23251345",
    skolenhetensNamn: "Nygårdsskolan",
    postnr: "78454",
    besökspostnr: "78454",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "58395955",
    skolenhetensNamn: "Ornäs skola",
    postnr: "78477",
    besökspostnr: "78477",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "73689078",
    skolenhetensNamn: "Paradisskolan",
    postnr: "78450",
    besökspostnr: "78450",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "73660679",
    skolenhetensNamn: "Skräddarbacksskolan",
    postnr: "78455",
    besökspostnr: "78455",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "34516710",
    skolenhetensNamn: "Tjärnaskolan",
    postnr: "78440",
    besökspostnr: "78440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "94897392",
    skolenhetensNamn: "Torsångs skola",
    postnr: "78194",
    besökspostnr: "78194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2081",
    kommunsNamn: "Borlänge",
    skolenhetsKod: "83119674",
    skolenhetensNamn: "Tunets skola",
    postnr: "78463",
    besökspostnr: "78463",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2082",
    kommunsNamn: "Säter",
    skolenhetsKod: "35024555",
    skolenhetensNamn: "Enbacka skola",
    postnr: "78350",
    besökspostnr: "78350",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2082",
    kommunsNamn: "Säter",
    skolenhetsKod: "21640456",
    skolenhetensNamn: "Kungsgårdsskolan",
    postnr: "78330",
    besökspostnr: "78330",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2082",
    kommunsNamn: "Säter",
    skolenhetsKod: "20612155",
    skolenhetensNamn: "Stora Skedvi skola",
    postnr: "78392",
    besökspostnr: "78332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2083",
    kommunsNamn: "Hedemora",
    skolenhetsKod: "59456453",
    skolenhetensNamn: "Fyrklöverskolan",
    postnr: "77628",
    besökspostnr: "77633",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2083",
    kommunsNamn: "Hedemora",
    skolenhetsKod: "41896574",
    skolenhetensNamn: "Garpenbergs skola",
    postnr: "77628",
    besökspostnr: "77698",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2083",
    kommunsNamn: "Hedemora",
    skolenhetsKod: "88099326",
    skolenhetensNamn: "Jonsboskolan F-6",
    postnr: "77628",
    besökspostnr: "77671",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2083",
    kommunsNamn: "Hedemora",
    skolenhetsKod: "87686617",
    skolenhetensNamn: "Olympica",
    postnr: "77636",
    besökspostnr: "77636",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2083",
    kommunsNamn: "Hedemora",
    skolenhetsKod: "48205003",
    skolenhetensNamn: "Smedby skola F-6",
    postnr: "77697",
    besökspostnr: "77697",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2083",
    kommunsNamn: "Hedemora",
    skolenhetsKod: "35613813",
    skolenhetensNamn: "Stureskolan",
    postnr: "77628",
    besökspostnr: "77635",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2083",
    kommunsNamn: "Hedemora",
    skolenhetsKod: "83512536",
    skolenhetensNamn: "Vikmanshyttans skola",
    postnr: "77628",
    besökspostnr: "77670",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2083",
    kommunsNamn: "Hedemora",
    skolenhetsKod: "40506262",
    skolenhetensNamn: "Västerby skola",
    postnr: "77628",
    besökspostnr: "77693",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "99431045",
    skolenhetensNamn: "By skola",
    postnr: "77481",
    besökspostnr: "77499",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "18273641",
    skolenhetensNamn: "Fors skola",
    postnr: "77481",
    besökspostnr: "77497",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "96138660",
    skolenhetensNamn: "Grytnäs friskola",
    postnr: "77461",
    besökspostnr: "77461",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "48240990",
    skolenhetensNamn: "Johan Olovsskolan",
    postnr: "77481",
    besökspostnr: "77467",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "97945801",
    skolenhetensNamn: "Karlbo skola",
    postnr: "77481",
    besökspostnr: "77570",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "62043194",
    skolenhetensNamn: "Krylbo skola",
    postnr: "77481",
    besökspostnr: "77554",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "15648377",
    skolenhetensNamn: "Markusskolan",
    postnr: "77481",
    besökspostnr: "77430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "49218585",
    skolenhetensNamn: "Nordanö skola",
    postnr: "77481",
    besökspostnr: "77595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2084",
    kommunsNamn: "Avesta",
    skolenhetsKod: "92591861",
    skolenhetensNamn: "Skogsbo skola",
    postnr: "77481",
    besökspostnr: "77460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "13775470",
    skolenhetensNamn: "Blötbergets skola",
    postnr: "77165",
    besökspostnr: "77165",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "88233361",
    skolenhetensNamn: "Fredriksbergsskolan",
    postnr: "77010",
    besökspostnr: "77010",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "73236556",
    skolenhetensNamn: "Håksberg",
    postnr: "77136",
    besökspostnr: "77136",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "17188467",
    skolenhetensNamn: "Junibackens skola",
    postnr: "77143",
    besökspostnr: "77143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "90820665",
    skolenhetensNamn: "Knutsbo skola",
    postnr: "77143",
    besökspostnr: "77143",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "63728031",
    skolenhetensNamn: "Lorensberga F-6",
    postnr: "77142",
    besökspostnr: "77142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "39306277",
    skolenhetensNamn: "Nyhammars skola",
    postnr: "77014",
    besökspostnr: "77014",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "61260328",
    skolenhetensNamn: "Parkskolan",
    postnr: "77231",
    besökspostnr: "77231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "67844568",
    skolenhetensNamn: "Solviksskolan",
    postnr: "77152",
    besökspostnr: "77152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "73277774",
    skolenhetensNamn: "Sunnansjö skola",
    postnr: "77012",
    besökspostnr: "77012",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "83237712",
    skolenhetensNamn: "Sörviks skola",
    postnr: "77192",
    besökspostnr: "77192",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "20",
    länsNamn: "Dalarnas län",
    kommun: "2085",
    kommunsNamn: "Ludvika",
    skolenhetsKod: "22818109",
    skolenhetensNamn: "Vasaskolan",
    postnr: "77132",
    besökspostnr: "77132",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2101",
    kommunsNamn: "Ockelbo",
    skolenhetsKod: "54096978",
    skolenhetensNamn: "Gäverängeskolan",
    postnr: "81680",
    besökspostnr: "81631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2101",
    kommunsNamn: "Ockelbo",
    skolenhetsKod: "65270641",
    skolenhetensNamn: "Åbyggeby skola",
    postnr: "81680",
    besökspostnr: "81694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2104",
    kommunsNamn: "Hofors",
    skolenhetsKod: "80238090",
    skolenhetensNamn: "Lillåskolan",
    postnr: "81381",
    besökspostnr: "81333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2104",
    kommunsNamn: "Hofors",
    skolenhetsKod: "74986765",
    skolenhetensNamn: "Solbergaskolan",
    postnr: "81381",
    besökspostnr: "81340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2104",
    kommunsNamn: "Hofors",
    skolenhetsKod: "67071053",
    skolenhetensNamn: "Värnaskolan",
    postnr: "81381",
    besökspostnr: "81335",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2121",
    kommunsNamn: "Ovanåker",
    skolenhetsKod: "54774286",
    skolenhetensNamn: "Alftaskolan F-6",
    postnr: "82880",
    besökspostnr: "82230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2121",
    kommunsNamn: "Ovanåker",
    skolenhetsKod: "63488719",
    skolenhetensNamn: "Celsiusskolan F-6",
    postnr: "82880",
    besökspostnr: "82833",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2121",
    kommunsNamn: "Ovanåker",
    skolenhetsKod: "21674603",
    skolenhetensNamn: "Knåda skola",
    postnr: "82880",
    besökspostnr: "82830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2121",
    kommunsNamn: "Ovanåker",
    skolenhetsKod: "85584111",
    skolenhetensNamn: "Lillboskolan",
    postnr: "82880",
    besökspostnr: "82834",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2121",
    kommunsNamn: "Ovanåker",
    skolenhetsKod: "39130316",
    skolenhetensNamn: "Rotebergs skola",
    postnr: "82880",
    besökspostnr: "82292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2121",
    kommunsNamn: "Ovanåker",
    skolenhetsKod: "24030299",
    skolenhetensNamn: "Runemo skola",
    postnr: "82880",
    besökspostnr: "82292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2121",
    kommunsNamn: "Ovanåker",
    skolenhetsKod: "44977557",
    skolenhetensNamn: "Viksjöfors skola",
    postnr: "82880",
    besökspostnr: "82895",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2132",
    kommunsNamn: "Nordanstig",
    skolenhetsKod: "73758193",
    skolenhetensNamn: "Arthur Engbergskolan",
    postnr: "82998",
    besökspostnr: "82998",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2132",
    kommunsNamn: "Nordanstig",
    skolenhetsKod: "59944575",
    skolenhetensNamn: "Bringstaskolan",
    postnr: "82995",
    besökspostnr: "82995",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2132",
    kommunsNamn: "Nordanstig",
    skolenhetsKod: "32915290",
    skolenhetensNamn: "Gnarps skola",
    postnr: "82960",
    besökspostnr: "82960",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2132",
    kommunsNamn: "Nordanstig",
    skolenhetsKod: "21342606",
    skolenhetensNamn: "Hosiannaskolan",
    postnr: "82960",
    besökspostnr: "82960",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2132",
    kommunsNamn: "Nordanstig",
    skolenhetsKod: "95077890",
    skolenhetensNamn: "Ilsbo skola",
    postnr: "82991",
    besökspostnr: "82992",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2132",
    kommunsNamn: "Nordanstig",
    skolenhetsKod: "99896722",
    skolenhetensNamn: "Lönnbergsskolan",
    postnr: "82950",
    besökspostnr: "82950",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2132",
    kommunsNamn: "Nordanstig",
    skolenhetsKod: "50339286",
    skolenhetensNamn: "Stocka Friskola",
    postnr: "82496",
    besökspostnr: "82496",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2161",
    kommunsNamn: "Ljusdal",
    skolenhetsKod: "51658900",
    skolenhetensNamn: "Båtbacken",
    postnr: "82754",
    besökspostnr: "82754",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2161",
    kommunsNamn: "Ljusdal",
    skolenhetsKod: "84673347",
    skolenhetensNamn: "Freinetskolan Tallbacken",
    postnr: "82733",
    besökspostnr: "82733",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2161",
    kommunsNamn: "Ljusdal",
    skolenhetsKod: "36425969",
    skolenhetensNamn: "Färila skola",
    postnr: "82760",
    besökspostnr: "82760",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2161",
    kommunsNamn: "Ljusdal",
    skolenhetsKod: "51846686",
    skolenhetensNamn: "Gärdeåsskolan",
    postnr: "82780",
    besökspostnr: "82733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2161",
    kommunsNamn: "Ljusdal",
    skolenhetsKod: "56759222",
    skolenhetensNamn: "Järvsö skola F-9",
    postnr: "82751",
    besökspostnr: "82751",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2161",
    kommunsNamn: "Ljusdal",
    skolenhetsKod: "87366152",
    skolenhetensNamn: "Los skola",
    postnr: "82041",
    besökspostnr: "82770",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2161",
    kommunsNamn: "Ljusdal",
    skolenhetsKod: "98347247",
    skolenhetensNamn: "Stenhamreskolan F-6",
    postnr: "82780",
    besökspostnr: "82731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2161",
    kommunsNamn: "Ljusdal",
    skolenhetsKod: "45136856",
    skolenhetensNamn: "Tallåsens skola",
    postnr: "82740",
    besökspostnr: "82740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "87313898",
    skolenhetensNamn: "Alborga skola",
    postnr: "81833",
    besökspostnr: "81833",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "97375168",
    skolenhetensNamn: "Andersbergsskolan",
    postnr: "80277",
    besökspostnr: "80277",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "55373417",
    skolenhetensNamn: "Bergby Centralskola 1-9",
    postnr: "81740",
    besökspostnr: "81740",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "41333982",
    skolenhetensNamn: "Björke skola",
    postnr: "80597",
    besökspostnr: "80597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "47347348",
    skolenhetensNamn: "Forsbacka skola",
    postnr: "81841",
    besökspostnr: "81841",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "80874928",
    skolenhetensNamn: "Fridhemsskolan",
    postnr: "80269",
    besökspostnr: "80628",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "31296560",
    skolenhetensNamn: "Hagaströms skola",
    postnr: "80628",
    besökspostnr: "80628",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "20872848",
    skolenhetensNamn: "Hedesunda skola",
    postnr: "81845",
    besökspostnr: "81845",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "35294569",
    skolenhetensNamn: "Hemlingborgs skola",
    postnr: "80258",
    besökspostnr: "80258",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "34746919",
    skolenhetensNamn: "Hemlingbyskolan",
    postnr: "80257",
    besökspostnr: "80631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "21439131",
    skolenhetensNamn: "Lilla Sätraskolan",
    postnr: "80631",
    besökspostnr: "80426",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "51387807",
    skolenhetensNamn: "Lillhagsskolan",
    postnr: "80426",
    besökspostnr: "81834",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "55989559",
    skolenhetensNamn: "Ludvigsbergsskolan",
    postnr: "81834",
    besökspostnr: "80648",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "93378411",
    skolenhetensNamn: "Milboskolan",
    postnr: "80648",
    besökspostnr: "81730",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "57578095",
    skolenhetensNamn: "Prolympia, Gävle",
    postnr: "80633",
    besökspostnr: "80633",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "14591437",
    skolenhetensNamn: "Sjöängsskolan",
    postnr: "80429",
    besökspostnr: "81830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "80398748",
    skolenhetensNamn: "Sofiaskolan Gävle AB",
    postnr: "80254",
    besökspostnr: "80254",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "80177526",
    skolenhetensNamn: "Sofiedalskolan",
    postnr: "81830",
    besökspostnr: "81830",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "58984764",
    skolenhetensNamn: "Solängsskolan",
    postnr: "80267",
    besökspostnr: "80284",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "46764433",
    skolenhetensNamn: "Staffansskolan",
    postnr: "80284",
    besökspostnr: "80284",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "91652716",
    skolenhetensNamn: "Stigslunds skola",
    postnr: "80641",
    besökspostnr: "80631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "53246486",
    skolenhetensNamn: "Strömsbro skola",
    postnr: "80646",
    besökspostnr: "80262",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "23223141",
    skolenhetensNamn: "Sörbyskolan",
    postnr: "80262",
    besökspostnr: "80596",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "62570207",
    skolenhetensNamn: "Tallbackens skola",
    postnr: "80645",
    besökspostnr: "80645",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "87088456",
    skolenhetensNamn: "Tranmursskolan GKS",
    postnr: "80432",
    besökspostnr: "80432",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "70120191",
    skolenhetensNamn: "Trödje skola",
    postnr: "80596",
    besökspostnr: "80648",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "82269879",
    skolenhetensNamn: "Vikingaskolan",
    postnr: "80432",
    besökspostnr: "80252",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "11476454",
    skolenhetensNamn: "Vittra Gävle",
    postnr: "80277",
    besökspostnr: "80277",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "28017179",
    skolenhetensNamn: "Ytterharnäs skola",
    postnr: "81491",
    besökspostnr: "81491",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2180",
    kommunsNamn: "Gävle",
    skolenhetsKod: "78748400",
    skolenhetensNamn: "Åbyggeby skola",
    postnr: "80598",
    besökspostnr: "80598",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "73972330",
    skolenhetensNamn: "Alsjöskolan",
    postnr: "81251",
    besökspostnr: "81251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "53803818",
    skolenhetensNamn: "Björksätraskolan F-9",
    postnr: "81150",
    besökspostnr: "81180",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "82989546",
    skolenhetensNamn: "Gullhedskolan",
    postnr: "81171",
    besökspostnr: "81171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "28178876",
    skolenhetensNamn: "Hedängskolan F-6",
    postnr: "81290",
    besökspostnr: "81290",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "38969377",
    skolenhetensNamn: "Jernvallskolan",
    postnr: "81161",
    besökspostnr: "81161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "89342887",
    skolenhetensNamn: "Jäderfors skola",
    postnr: "81191",
    besökspostnr: "81191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "88248777",
    skolenhetensNamn: "Kungsgårdens skola",
    postnr: "81240",
    besökspostnr: "81240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "78907748",
    skolenhetensNamn: "Murgårdsskolan",
    postnr: "81133",
    besökspostnr: "81133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "17233525",
    skolenhetensNamn: "Sandvikens Internationella Montessoriskola",
    postnr: "81133",
    besökspostnr: "81133",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "18586060",
    skolenhetensNamn: "Sätra skola",
    postnr: "81152",
    besökspostnr: "81152",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "92306906",
    skolenhetensNamn: "Vallhovskolan",
    postnr: "81137",
    besökspostnr: "81137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "86139352",
    skolenhetensNamn: "Västanbynskolan",
    postnr: "81135",
    besökspostnr: "81135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "20666715",
    skolenhetensNamn: "Årsunda Kyrkskola",
    postnr: "81022",
    besökspostnr: "81022",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2181",
    kommunsNamn: "Sandviken",
    skolenhetsKod: "73076578",
    skolenhetensNamn: "Österfärnebo skola",
    postnr: "81020",
    besökspostnr: "81020",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "57610942",
    skolenhetensNamn: "Bergviksskolan åk F-3",
    postnr: "82667",
    besökspostnr: "82667",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "63862480",
    skolenhetensNamn: "Mo skola",
    postnr: "82693",
    besökspostnr: "82693",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "63302939",
    skolenhetensNamn: "Norrtullskolan åk F-6",
    postnr: "82680",
    besökspostnr: "82631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "84393014",
    skolenhetensNamn: "Rosenvallskolan",
    postnr: "82661",
    besökspostnr: "82661",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "93261760",
    skolenhetensNamn: "Sandarne skola",
    postnr: "82672",
    besökspostnr: "82672",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "26147333",
    skolenhetensNamn: "Stenbergaskolan åk F-3",
    postnr: "82675",
    besökspostnr: "82675",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "57131254",
    skolenhetensNamn: "Stentägtskolan",
    postnr: "82680",
    besökspostnr: "82639",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "33021207",
    skolenhetensNamn: "Stråtjära skola",
    postnr: "82669",
    besökspostnr: "82669",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "93763694",
    skolenhetensNamn: "Stugsunds skola",
    postnr: "82680",
    besökspostnr: "82660",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "67899423",
    skolenhetensNamn: "Söderhamns Friskola och Fritids",
    postnr: "82662",
    besökspostnr: "82662",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "66358649",
    skolenhetensNamn: "Trönö skola",
    postnr: "82695",
    besökspostnr: "82695",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2182",
    kommunsNamn: "Söderhamn",
    skolenhetsKod: "71527628",
    skolenhetensNamn: "Vågbroskolan åk F-3",
    postnr: "82680",
    besökspostnr: "82680",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "75662206",
    skolenhetensNamn: "Arbråskolan F-9",
    postnr: "82161",
    besökspostnr: "82161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "44164435",
    skolenhetensNamn: "Björktjära skola F-6",
    postnr: "82135",
    besökspostnr: "82135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "69498258",
    skolenhetensNamn: "Edenskolan",
    postnr: "82134",
    besökspostnr: "82134",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "38769534",
    skolenhetensNamn: "FRI att VÄXA Växsjö Byskola",
    postnr: "82194",
    besökspostnr: "82194",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "72687432",
    skolenhetensNamn: "Granbergsskolan F-6",
    postnr: "82140",
    besökspostnr: "82133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "41793797",
    skolenhetensNamn: "Kilafors skola F-9",
    postnr: "82330",
    besökspostnr: "82151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "92407606",
    skolenhetensNamn: "Kilbergsskolan F-6",
    postnr: "82151",
    besökspostnr: "82151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "29124453",
    skolenhetensNamn: "Nyhamre skola F-6",
    postnr: "82131",
    besökspostnr: "82158",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "43389766",
    skolenhetensNamn: "Rengsjöskolan F-6",
    postnr: "82158",
    besökspostnr: "82393",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "74734342",
    skolenhetensNamn: "Segersta skola",
    postnr: "82124",
    besökspostnr: "82393",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "69693147",
    skolenhetensNamn: "Sörängs skola",
    postnr: "82124",
    besökspostnr: "82193",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "81577420",
    skolenhetensNamn: "Vallsta skola F-6",
    postnr: "82167",
    besökspostnr: "82167",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2183",
    kommunsNamn: "Bollnäs",
    skolenhetsKod: "95802522",
    skolenhetensNamn: "Västansjö skola",
    postnr: "82124",
    besökspostnr: "82391",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "60496759",
    skolenhetensNamn: "Björkbergs skola",
    postnr: "82451",
    besökspostnr: "82451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "45889125",
    skolenhetensNamn: "Bobygda skola",
    postnr: "82472",
    besökspostnr: "82472",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "22518275",
    skolenhetensNamn: "Delsbo waldorfskola",
    postnr: "82474",
    besökspostnr: "82474",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "83627088",
    skolenhetensNamn: "Ede skola",
    postnr: "82471",
    besökspostnr: "82471",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "95282218",
    skolenhetensNamn: "Edsta skola",
    postnr: "82492",
    besökspostnr: "82492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "69614208",
    skolenhetensNamn: "Enångers skola",
    postnr: "82595",
    besökspostnr: "82595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "47215049",
    skolenhetensNamn: "Friggesundsskolan",
    postnr: "82480",
    besökspostnr: "82478",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "77894796",
    skolenhetensNamn: "Hudikskolan F-6",
    postnr: "82414",
    besökspostnr: "82494",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "43313472",
    skolenhetensNamn: "Håsta skola",
    postnr: "82440",
    besökspostnr: "82440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "73349792",
    skolenhetensNamn: "Idenors skola",
    postnr: "82440",
    besökspostnr: "82440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "80631297",
    skolenhetensNamn: "Iggesunds skola",
    postnr: "82531",
    besökspostnr: "82531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "13941593",
    skolenhetensNamn: "Lunds skola",
    postnr: "82480",
    besökspostnr: "82460",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "88802212",
    skolenhetensNamn: "Malsta skola",
    postnr: "82494",
    besökspostnr: "82494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "80775683",
    skolenhetensNamn: "Njutångers skola",
    postnr: "82593",
    besökspostnr: "82593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "31635779",
    skolenhetensNamn: "Näsvikens skola",
    postnr: "82465",
    besökspostnr: "82465",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "64709527",
    skolenhetensNamn: "Sandvalla skola",
    postnr: "82433",
    besökspostnr: "82433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "77138988",
    skolenhetensNamn: "Skolbyn",
    postnr: "82450",
    besökspostnr: "82450",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "28743952",
    skolenhetensNamn: "Svågadalens skola",
    postnr: "82479",
    besökspostnr: "82479",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "21",
    länsNamn: "Gävleborgs län",
    kommun: "2184",
    kommunsNamn: "Hudiksvall",
    skolenhetsKod: "67629427",
    skolenhetensNamn: "Västra skolan",
    postnr: "82443",
    besökspostnr: "82443",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2260",
    kommunsNamn: "Ånge",
    skolenhetsKod: "71108679",
    skolenhetensNamn: "Björkbackaskolan",
    postnr: "84133",
    besökspostnr: "84133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2260",
    kommunsNamn: "Ånge",
    skolenhetsKod: "17514337",
    skolenhetensNamn: "Fränstaskolan",
    postnr: "84171",
    besökspostnr: "84171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2260",
    kommunsNamn: "Ånge",
    skolenhetsKod: "65951625",
    skolenhetensNamn: "Kordelia Friskola AB",
    postnr: "84196",
    besökspostnr: "84196",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2260",
    kommunsNamn: "Ånge",
    skolenhetsKod: "68495182",
    skolenhetensNamn: "Ljungaskolan",
    postnr: "84198",
    besökspostnr: "84198",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2260",
    kommunsNamn: "Ånge",
    skolenhetsKod: "64255803",
    skolenhetensNamn: "Torpshammars skola",
    postnr: "84175",
    besökspostnr: "84175",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2262",
    kommunsNamn: "Timrå",
    skolenhetsKod: "99588685",
    skolenhetensNamn: "Bergeforsen grundskola",
    postnr: "86139",
    besökspostnr: "86139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2262",
    kommunsNamn: "Timrå",
    skolenhetsKod: "25682466",
    skolenhetensNamn: "Böle skola",
    postnr: "86142",
    besökspostnr: "86142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2262",
    kommunsNamn: "Timrå",
    skolenhetsKod: "16932433",
    skolenhetensNamn: "Fagerviks skola",
    postnr: "86145",
    besökspostnr: "86145",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2262",
    kommunsNamn: "Timrå",
    skolenhetsKod: "50430513",
    skolenhetensNamn: "Laggarbergs skola",
    postnr: "86191",
    besökspostnr: "86191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2262",
    kommunsNamn: "Timrå",
    skolenhetsKod: "83487510",
    skolenhetensNamn: "Ljustorps skola",
    postnr: "86193",
    besökspostnr: "86193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2262",
    kommunsNamn: "Timrå",
    skolenhetsKod: "28838540",
    skolenhetensNamn: "Mariedalsskolan",
    postnr: "86131",
    besökspostnr: "86131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2262",
    kommunsNamn: "Timrå",
    skolenhetsKod: "58834998",
    skolenhetensNamn: "Söråkers skola",
    postnr: "86182",
    besökspostnr: "86151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "36869705",
    skolenhetensNamn: "Bondsjöhöjdens skola åk F-2",
    postnr: "87153",
    besökspostnr: "87153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "91634644",
    skolenhetensNamn: "Brunne skola",
    postnr: "87192",
    besökspostnr: "87192",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "67320845",
    skolenhetensNamn: "Brännaskolan",
    postnr: "87133",
    besökspostnr: "87133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "87856368",
    skolenhetensNamn: "Gerestaskolan åk F-6",
    postnr: "87161",
    besökspostnr: "87161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "51537516",
    skolenhetensNamn: "Häggdånger byskola",
    postnr: "87191",
    besökspostnr: "87191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "78789883",
    skolenhetensNamn: "Hälledals skola",
    postnr: "87016",
    besökspostnr: "87016",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "69454219",
    skolenhetensNamn: "Kastellskolan",
    postnr: "87103",
    besökspostnr: "87160",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Statlig",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "53471701",
    skolenhetensNamn: "Kristinaskolan",
    postnr: "87129",
    besökspostnr: "87160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "45142548",
    skolenhetensNamn: "Solenskolan åk F-6",
    postnr: "87140",
    besökspostnr: "87140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2280",
    kommunsNamn: "Härnösand",
    skolenhetsKod: "27395094",
    skolenhetensNamn: "Älandsbro skola åk F-6",
    postnr: "87010",
    besökspostnr: "87010",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "90847895",
    skolenhetensNamn: "Bergsåkers skola",
    postnr: "85185",
    besökspostnr: "85751",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "80813364",
    skolenhetensNamn: "Bosvedjeskolan",
    postnr: "85653",
    besökspostnr: "85653",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "41837838",
    skolenhetensNamn: "Bredsands skola",
    postnr: "85466",
    besökspostnr: "85466",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "51134145",
    skolenhetensNamn: "Essviks skola",
    postnr: "86234",
    besökspostnr: "85731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "27027530",
    skolenhetensNamn: "Granbergsskolan",
    postnr: "85731",
    besökspostnr: "85731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "91880609",
    skolenhetensNamn: "Gångvikens skola",
    postnr: "85185",
    besökspostnr: "85643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "55343483",
    skolenhetensNamn: "Hagaskolan",
    postnr: "85643",
    besökspostnr: "85643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "14161923",
    skolenhetensNamn: "Heliås Svartvik",
    postnr: "85237",
    besökspostnr: "86233",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "94622728",
    skolenhetensNamn: "Hellbergsskolan",
    postnr: "85630",
    besökspostnr: "85630",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "51877656",
    skolenhetensNamn: "Höglundaskolan",
    postnr: "85185",
    besökspostnr: "85741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "17237299",
    skolenhetensNamn: "Högoms skola",
    postnr: "85741",
    besökspostnr: "85643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "38897013",
    skolenhetensNamn: "Kristna skolan Oasen",
    postnr: "85006",
    besökspostnr: "85352",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "76937737",
    skolenhetensNamn: "Kyrkmons skola",
    postnr: "85185",
    besökspostnr: "86240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "64029211",
    skolenhetensNamn: "Lidens skola",
    postnr: "85599",
    besökspostnr: "85599",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "20027707",
    skolenhetensNamn: "Ljustadalens skola",
    postnr: "85185",
    besökspostnr: "86334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "92290909",
    skolenhetensNamn: "Lucksta skola",
    postnr: "86492",
    besökspostnr: "86431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "37299127",
    skolenhetensNamn: "Matfors skola",
    postnr: "86421",
    besökspostnr: "86431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "65125762",
    skolenhetensNamn: "Njurunda Friskola",
    postnr: "86202",
    besökspostnr: "86241",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "18952472",
    skolenhetensNamn: "Prolympia, Sundsvall",
    postnr: "85231",
    besökspostnr: "85231",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "21250086",
    skolenhetensNamn: "Sidsjö Fristående Grundskola",
    postnr: "85240",
    besökspostnr: "85240",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "82372659",
    skolenhetensNamn: "Skönsmons skola",
    postnr: "85185",
    besökspostnr: "85732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "51638146",
    skolenhetensNamn: "Solede skola",
    postnr: "85185",
    besökspostnr: "86232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "74902981",
    skolenhetensNamn: "Stige skola",
    postnr: "85597",
    besökspostnr: "85597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "69147256",
    skolenhetensNamn: "St Olofsskolan",
    postnr: "85185",
    besökspostnr: "85353",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "11181258",
    skolenhetensNamn: "Stöde skola",
    postnr: "86441",
    besökspostnr: "86441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "78495763",
    skolenhetensNamn: "Sundsvalls Montessoriskola",
    postnr: "85185",
    besökspostnr: "85240",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "57722644",
    skolenhetensNamn: "Thoren Framtid Kunskapsakademin",
    postnr: "86551",
    besökspostnr: "86551",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "30627944",
    skolenhetensNamn: "Uslands skola",
    postnr: "85185",
    besökspostnr: "86531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "44253135",
    skolenhetensNamn: "Vallens skola",
    postnr: "86025",
    besökspostnr: "86025",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2281",
    kommunsNamn: "Sundsvall",
    skolenhetsKod: "10170655",
    skolenhetensNamn: "Vibackeskolan",
    postnr: "85185",
    besökspostnr: "86531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2282",
    kommunsNamn: "Kramfors",
    skolenhetsKod: "68749277",
    skolenhetensNamn: "Bollstaskolan",
    postnr: "87280",
    besökspostnr: "87230",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2282",
    kommunsNamn: "Kramfors",
    skolenhetsKod: "63175758",
    skolenhetensNamn: "Docksta Friskola",
    postnr: "87396",
    besökspostnr: "87033",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2282",
    kommunsNamn: "Kramfors",
    skolenhetsKod: "76249351",
    skolenhetensNamn: "Grämestaskolan",
    postnr: "87280",
    besökspostnr: "87293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2282",
    kommunsNamn: "Kramfors",
    skolenhetsKod: "75515918",
    skolenhetensNamn: "Högakustenskolan",
    postnr: "87280",
    besökspostnr: "87297",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2282",
    kommunsNamn: "Kramfors",
    skolenhetsKod: "63916639",
    skolenhetensNamn: "Nordingråskolan",
    postnr: "87398",
    besökspostnr: "87398",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2282",
    kommunsNamn: "Kramfors",
    skolenhetsKod: "43334647",
    skolenhetensNamn: "Nylandsskolan",
    postnr: "87280",
    besökspostnr: "87371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2282",
    kommunsNamn: "Kramfors",
    skolenhetsKod: "80372390",
    skolenhetensNamn: "Skarpåkersskolan",
    postnr: "87280",
    besökspostnr: "87231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2282",
    kommunsNamn: "Kramfors",
    skolenhetsKod: "13336657",
    skolenhetensNamn: "Ullångersskolan",
    postnr: "87280",
    besökspostnr: "87372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "44570787",
    skolenhetensNamn: "Edsele skola",
    postnr: "88041",
    besökspostnr: "88041",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "10179930",
    skolenhetensNamn: "Helgums skola",
    postnr: "88293",
    besökspostnr: "88293",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "49748045",
    skolenhetensNamn: "Junsele skola",
    postnr: "88371",
    besökspostnr: "88371",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "73692894",
    skolenhetensNamn: "Kalknäs skola",
    postnr: "88195",
    besökspostnr: "88195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "12641882",
    skolenhetensNamn: "Lillängets skola",
    postnr: "88131",
    besökspostnr: "88131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "63021972",
    skolenhetensNamn: "Långsele skola",
    postnr: "88250",
    besökspostnr: "88250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "46660811",
    skolenhetensNamn: "Nipanskolan F-9",
    postnr: "88150",
    besökspostnr: "88150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "96402885",
    skolenhetensNamn: "Näsåkers skola",
    postnr: "88170",
    besökspostnr: "88170",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "60755872",
    skolenhetensNamn: "Prästbordets skola",
    postnr: "88135",
    besökspostnr: "88135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "20197159",
    skolenhetensNamn: "Ramsele skola",
    postnr: "88370",
    besökspostnr: "88370",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "25054871",
    skolenhetensNamn: "Resele skola",
    postnr: "88196",
    besökspostnr: "88196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "83493883",
    skolenhetensNamn: "Rödsta skola",
    postnr: "88133",
    besökspostnr: "88133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2283",
    kommunsNamn: "Sollefteå",
    skolenhetsKod: "79793912",
    skolenhetensNamn: "Öns skola",
    postnr: "88191",
    besökspostnr: "88191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "69067954",
    skolenhetensNamn: "Alneskolan F-4",
    postnr: "89178",
    besökspostnr: "89178",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "51597705",
    skolenhetensNamn: "Anundsjöskolan",
    postnr: "89530",
    besökspostnr: "89188",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "34074601",
    skolenhetensNamn: "Banafjälskolan",
    postnr: "89196",
    besökspostnr: "89196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "54267727",
    skolenhetensNamn: "Bjästaskolan F-6",
    postnr: "89330",
    besökspostnr: "89050",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "24396451",
    skolenhetensNamn: "Björnaskolan",
    postnr: "89050",
    besökspostnr: "89530",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "32866578",
    skolenhetensNamn: "Broskolan Ö-viks Kristna skola",
    postnr: "89234",
    besökspostnr: "89234",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "61303295",
    skolenhetensNamn: "Domsjöskolan",
    postnr: "89240",
    besökspostnr: "89231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "93703083",
    skolenhetensNamn: "Gideåskolan",
    postnr: "89693",
    besökspostnr: "89493",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "99343485",
    skolenhetensNamn: "Gottne Byskola",
    postnr: "89595",
    besökspostnr: "89595",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "78390970",
    skolenhetensNamn: "Haffstaskolan",
    postnr: "89493",
    besökspostnr: "89493",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "98918972",
    skolenhetensNamn: "Hemlingskolan",
    postnr: "89051",
    besökspostnr: "89051",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "33732345",
    skolenhetensNamn: "Husumskolan",
    postnr: "89632",
    besökspostnr: "89632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "45432132",
    skolenhetensNamn: "Hållängetskolan",
    postnr: "89251",
    besökspostnr: "89151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "65235434",
    skolenhetensNamn: "Höglandskolan ",
    postnr: "89151",
    besökspostnr: "89151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "17783822",
    skolenhetensNamn: "Idbynskolan",
    postnr: "89196",
    besökspostnr: "89196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "80221984",
    skolenhetensNamn: "Järvedskolan",
    postnr: "89177",
    besökspostnr: "89340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "69869637",
    skolenhetensNamn: "Köpmanholmenskolan",
    postnr: "89340",
    besökspostnr: "89340",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "12881185",
    skolenhetensNamn: "Mellanselskolan",
    postnr: "89540",
    besökspostnr: "89495",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "74014148",
    skolenhetensNamn: "Moskolan",
    postnr: "89495",
    besökspostnr: "89495",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "90461500",
    skolenhetensNamn: "Mycklingskolan",
    postnr: "89430",
    besökspostnr: "89430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "28914847",
    skolenhetensNamn: "Norrflärkeskolan",
    postnr: "89593",
    besökspostnr: "89593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "21175613",
    skolenhetensNamn: "Sidensjöskolan",
    postnr: "89395",
    besökspostnr: "89430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "67248275",
    skolenhetensNamn: "Själevadskolan F-6",
    postnr: "89430",
    besökspostnr: "89430",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "43952255",
    skolenhetensNamn: "Skorpedskolan",
    postnr: "89597",
    besökspostnr: "89597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "49669234",
    skolenhetensNamn: "Skärpe Ängetskolan ",
    postnr: "89142",
    besökspostnr: "89142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "57766858",
    skolenhetensNamn: "Sundskolan",
    postnr: "89233",
    besökspostnr: "89233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "42897421",
    skolenhetensNamn: "Sörlidenskolan F-6",
    postnr: "89161",
    besökspostnr: "89161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "29529489",
    skolenhetensNamn: "Trehörningsjöskolan",
    postnr: "89054",
    besökspostnr: "89054",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "10638673",
    skolenhetensNamn: "Västanåskolan",
    postnr: "89391",
    besökspostnr: "89391",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "22",
    länsNamn: "Västernorrlands län",
    kommun: "2284",
    kommunsNamn: "Örnsköldsvik",
    skolenhetsKod: "34325679",
    skolenhetensNamn: "Örnsköldskolan",
    postnr: "89134",
    besökspostnr: "89134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2303",
    kommunsNamn: "Ragunda",
    skolenhetsKod: "22316816",
    skolenhetensNamn: "Hansåkerskolan",
    postnr: "84451",
    besökspostnr: "84451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2303",
    kommunsNamn: "Ragunda",
    skolenhetsKod: "26530842",
    skolenhetensNamn: "Järåskolan",
    postnr: "84441",
    besökspostnr: "84441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2303",
    kommunsNamn: "Ragunda",
    skolenhetsKod: "40542956",
    skolenhetensNamn: "Kullstaskolan",
    postnr: "84431",
    besökspostnr: "84431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2305",
    kommunsNamn: "Bräcke",
    skolenhetsKod: "63287550",
    skolenhetensNamn: "Bräcke skola",
    postnr: "84332",
    besökspostnr: "84332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2305",
    kommunsNamn: "Bräcke",
    skolenhetsKod: "34671510",
    skolenhetensNamn: "Gällö skola",
    postnr: "84341",
    besökspostnr: "84341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2305",
    kommunsNamn: "Bräcke",
    skolenhetsKod: "82223639",
    skolenhetensNamn: "Kälarne skola",
    postnr: "84461",
    besökspostnr: "84461",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "40830548",
    skolenhetensNamn: "Aspås skola",
    postnr: "83580",
    besökspostnr: "83594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "64778601",
    skolenhetensNamn: "Cederbergsskolan F-9",
    postnr: "83060",
    besökspostnr: "83060",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "11389751",
    skolenhetensNamn: "Dvärsätts skola",
    postnr: "83580",
    besökspostnr: "83541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "35462975",
    skolenhetensNamn: "Hovs skola",
    postnr: "83051",
    besökspostnr: "83593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "24892488",
    skolenhetensNamn: "Kaxås skola",
    postnr: "83051",
    besökspostnr: "83051",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "23796121",
    skolenhetensNamn: "Kvarnbackskolan Hissmoskolan",
    postnr: "83580",
    besökspostnr: "83532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "62442955",
    skolenhetensNamn: "Laxsjö skola",
    postnr: "83060",
    besökspostnr: "83060",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "46085051",
    skolenhetensNamn: "Näldenskolan",
    postnr: "83051",
    besökspostnr: "83540",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "45736013",
    skolenhetensNamn: "Rödöns skola",
    postnr: "83580",
    besökspostnr: "83591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "28860521",
    skolenhetensNamn: "Sånghusvallens skola",
    postnr: "83694",
    besökspostnr: "83541",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "86332806",
    skolenhetensNamn: "Trångsvikens skola",
    postnr: "83580",
    besökspostnr: "83047",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "29417777",
    skolenhetensNamn: "Valsjöbyns skola",
    postnr: "83060",
    besökspostnr: "83694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "25036442",
    skolenhetensNamn: "Ås skola   F-6",
    postnr: "83694",
    besökspostnr: "83694",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2309",
    kommunsNamn: "Krokom",
    skolenhetsKod: "76684659",
    skolenhetensNamn: "Änge F-6",
    postnr: "83051",
    besökspostnr: "83051",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2313",
    kommunsNamn: "Strömsund",
    skolenhetsKod: "75646732",
    skolenhetensNamn: "Bredgårds skola",
    postnr: "83332",
    besökspostnr: "83332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2313",
    kommunsNamn: "Strömsund",
    skolenhetsKod: "20930818",
    skolenhetensNamn: "Centralskolan Hoting",
    postnr: "83351",
    besökspostnr: "83351",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2313",
    kommunsNamn: "Strömsund",
    skolenhetsKod: "82584417",
    skolenhetensNamn: "Fjällsjöskolan",
    postnr: "88372",
    besökspostnr: "88372",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2313",
    kommunsNamn: "Strömsund",
    skolenhetsKod: "76970830",
    skolenhetensNamn: "Frostviksskolan",
    postnr: "83361",
    besökspostnr: "83361",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2313",
    kommunsNamn: "Strömsund",
    skolenhetsKod: "54719506",
    skolenhetensNamn: "Grevåkerskolan Hammerdal",
    postnr: "83341",
    besökspostnr: "83341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2313",
    kommunsNamn: "Strömsund",
    skolenhetsKod: "41005196",
    skolenhetensNamn: "Hedenvindskolan",
    postnr: "83324",
    besökspostnr: "83333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2313",
    kommunsNamn: "Strömsund",
    skolenhetsKod: "80493898",
    skolenhetensNamn: "Jormvattnets skola",
    postnr: "83398",
    besökspostnr: "83398",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2313",
    kommunsNamn: "Strömsund",
    skolenhetsKod: "92795907",
    skolenhetensNamn: "Kyrktåsjö skola",
    postnr: "83359",
    besökspostnr: "83359",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2321",
    kommunsNamn: "Åre",
    skolenhetsKod: "53536862",
    skolenhetensNamn: "Duveds skola F-9",
    postnr: "83771",
    besökspostnr: "83771",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2321",
    kommunsNamn: "Åre",
    skolenhetsKod: "47003034",
    skolenhetensNamn: "Hallens Kyrkskola F-9",
    postnr: "84591",
    besökspostnr: "84591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2321",
    kommunsNamn: "Åre",
    skolenhetsKod: "42149659",
    skolenhetensNamn: "Kyrkslättens skola F-6",
    postnr: "83793",
    besökspostnr: "83793",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2321",
    kommunsNamn: "Åre",
    skolenhetsKod: "50126333",
    skolenhetensNamn: "Mörsil F-9",
    postnr: "83741",
    besökspostnr: "83741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2321",
    kommunsNamn: "Åre",
    skolenhetsKod: "19616441",
    skolenhetensNamn: "Racklöfska skolan F-6",
    postnr: "83722",
    besökspostnr: "83731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2321",
    kommunsNamn: "Åre",
    skolenhetsKod: "58764605",
    skolenhetensNamn: "Stamgärde skola F-6",
    postnr: "83795",
    besökspostnr: "83795",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2321",
    kommunsNamn: "Åre",
    skolenhetsKod: "38418050",
    skolenhetensNamn: "Åre skola F-3",
    postnr: "83752",
    besökspostnr: "83752",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2326",
    kommunsNamn: "Berg",
    skolenhetsKod: "70084130",
    skolenhetensNamn: "Hackås skola",
    postnr: "84594",
    besökspostnr: "84594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2326",
    kommunsNamn: "Berg",
    skolenhetsKod: "90462401",
    skolenhetensNamn: "Klövsjö skola",
    postnr: "84597",
    besökspostnr: "84597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2326",
    kommunsNamn: "Berg",
    skolenhetsKod: "82304602",
    skolenhetensNamn: "Kövra Byskola",
    postnr: "83024",
    besökspostnr: "83024",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2326",
    kommunsNamn: "Berg",
    skolenhetsKod: "47079538",
    skolenhetensNamn: "Ljungdalens skola",
    postnr: "84599",
    besökspostnr: "84599",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2326",
    kommunsNamn: "Berg",
    skolenhetsKod: "56709330",
    skolenhetensNamn: "Myrvikens skola",
    postnr: "84571",
    besökspostnr: "84571",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2326",
    kommunsNamn: "Berg",
    skolenhetsKod: "28750488",
    skolenhetensNamn: "Rätans skola",
    postnr: "84595",
    besökspostnr: "84595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2326",
    kommunsNamn: "Berg",
    skolenhetsKod: "64147674",
    skolenhetensNamn: "Svenstaviks skola F-6",
    postnr: "84521",
    besökspostnr: "84531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2326",
    kommunsNamn: "Berg",
    skolenhetsKod: "67992279",
    skolenhetensNamn: "Åsarna skola",
    postnr: "84551",
    besökspostnr: "84551",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2361",
    kommunsNamn: "Härjedalen",
    skolenhetsKod: "99936953",
    skolenhetensNamn: "Bruksvallarnas skola",
    postnr: "84672",
    besökspostnr: "84697",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2361",
    kommunsNamn: "Härjedalen",
    skolenhetsKod: "72295998",
    skolenhetensNamn: "Funäsdalens skola",
    postnr: "84672",
    besökspostnr: "84672",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2361",
    kommunsNamn: "Härjedalen",
    skolenhetsKod: "81803928",
    skolenhetensNamn: "Furulundsskolan",
    postnr: "84295",
    besökspostnr: "84295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2361",
    kommunsNamn: "Härjedalen",
    skolenhetsKod: "27126453",
    skolenhetensNamn: "Lofsdalens skola",
    postnr: "84296",
    besökspostnr: "84296",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2361",
    kommunsNamn: "Härjedalen",
    skolenhetsKod: "68726322",
    skolenhetensNamn: "Norra Skolan",
    postnr: "84280",
    besökspostnr: "84232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2361",
    kommunsNamn: "Härjedalen",
    skolenhetsKod: "85325551",
    skolenhetensNamn: "Sonfjällsskolan",
    postnr: "84631",
    besökspostnr: "84631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2361",
    kommunsNamn: "Härjedalen",
    skolenhetsKod: "25042188",
    skolenhetensNamn: "Vemdalens skola",
    postnr: "84631",
    besökspostnr: "84671",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2361",
    kommunsNamn: "Härjedalen",
    skolenhetsKod: "92387607",
    skolenhetensNamn: "Ytterhogdals Centralskola",
    postnr: "84271",
    besökspostnr: "84271",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "29327326",
    skolenhetensNamn: "Arnljotskolan",
    postnr: "83251",
    besökspostnr: "83251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "25996230",
    skolenhetensNamn: "Bringåsens skola",
    postnr: "83191",
    besökspostnr: "83191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "59115663",
    skolenhetensNamn: "Böle Byskola AB",
    postnr: "83293",
    besökspostnr: "83293",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "48088172",
    skolenhetensNamn: "Fagervallsskolan",
    postnr: "83160",
    besökspostnr: "83160",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "73770265",
    skolenhetensNamn: "Fjällängsskolan",
    postnr: "83171",
    besökspostnr: "83171",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "53530516",
    skolenhetensNamn: "Fåker skola",
    postnr: "84593",
    besökspostnr: "84593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "91138542",
    skolenhetensNamn: "Häggenås skola",
    postnr: "83693",
    besökspostnr: "83693",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "58922041",
    skolenhetensNamn: "Innovitaskolan Östersund",
    postnr: "83103",
    besökspostnr: "83143",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "61128264",
    skolenhetensNamn: "Internationella Engelska Skolan Östersund",
    postnr: "83148",
    besökspostnr: "83148",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "46449974",
    skolenhetensNamn: "Lillsjöskolan",
    postnr: "83151",
    besökspostnr: "83151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "42641767",
    skolenhetensNamn: "Lugnviksskolan",
    postnr: "83158",
    besökspostnr: "83158",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "88411191",
    skolenhetensNamn: "Marieby skola",
    postnr: "83498",
    besökspostnr: "83498",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "53882684",
    skolenhetensNamn: "Mimergården",
    postnr: "83178",
    besökspostnr: "83178",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "42715994",
    skolenhetensNamn: "Norra Skolan",
    postnr: "83141",
    besökspostnr: "83141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "83077602",
    skolenhetensNamn: "Orrvikens skola",
    postnr: "83294",
    besökspostnr: "83433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "16858929",
    skolenhetensNamn: "Prolympia, Östersund",
    postnr: "83132",
    besökspostnr: "83132",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "13867530",
    skolenhetensNamn: "Storvikenskolan",
    postnr: "83433",
    besökspostnr: "83433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "94294521",
    skolenhetensNamn: "Sörgårdsskolan",
    postnr: "83432",
    besökspostnr: "83432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "62727103",
    skolenhetensNamn: "Tavelbäcksskolan",
    postnr: "83161",
    besökspostnr: "83161",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "77460055",
    skolenhetensNamn: "Treälvsskolan",
    postnr: "83182",
    besökspostnr: "83631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "90874950",
    skolenhetensNamn: "Vallaskolan",
    postnr: "83253",
    besökspostnr: "83253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "19688519",
    skolenhetensNamn: "Ångsta skola",
    postnr: "83499",
    besökspostnr: "83499",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "57462607",
    skolenhetensNamn: "Ängsmogården",
    postnr: "83176",
    besökspostnr: "83176",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "23",
    länsNamn: "Jämtlands län",
    kommun: "2380",
    kommunsNamn: "Östersund",
    skolenhetsKod: "58288551",
    skolenhetensNamn: "Östersunds Waldorfskola",
    postnr: "83251",
    besökspostnr: "83251",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2401",
    kommunsNamn: "Nordmaling",
    skolenhetsKod: "40145225",
    skolenhetensNamn: "Gräsmyr skola",
    postnr: "91495",
    besökspostnr: "91496",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2401",
    kommunsNamn: "Nordmaling",
    skolenhetsKod: "58954417",
    skolenhetensNamn: "Levarskolan",
    postnr: "91481",
    besökspostnr: "91492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2401",
    kommunsNamn: "Nordmaling",
    skolenhetsKod: "14365211",
    skolenhetensNamn: "Lögdeå skola",
    postnr: "91481",
    besökspostnr: "91492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2403",
    kommunsNamn: "Bjurholm",
    skolenhetsKod: "95790040",
    skolenhetensNamn: "Castorskolan F-3",
    postnr: "91681",
    besökspostnr: "91631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2404",
    kommunsNamn: "Vindeln",
    skolenhetsKod: "19012030",
    skolenhetensNamn: "Granö småbarnsskola",
    postnr: "92295",
    besökspostnr: "92295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2404",
    kommunsNamn: "Vindeln",
    skolenhetsKod: "15359609",
    skolenhetensNamn: "Hällnäs skola",
    postnr: "92273",
    besökspostnr: "92273",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2404",
    kommunsNamn: "Vindeln",
    skolenhetsKod: "65981831",
    skolenhetensNamn: "Tvärålunds skola",
    postnr: "92294",
    besökspostnr: "92294",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2404",
    kommunsNamn: "Vindeln",
    skolenhetsKod: "42955547",
    skolenhetensNamn: "Åmsele småbarnsskola",
    postnr: "92275",
    besökspostnr: "92275",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2404",
    kommunsNamn: "Vindeln",
    skolenhetsKod: "63863363",
    skolenhetensNamn: "Älvbrinkens skola",
    postnr: "92281",
    besökspostnr: "92232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2409",
    kommunsNamn: "Robertsfors",
    skolenhetsKod: "96684788",
    skolenhetensNamn: "Bygdeå skola",
    postnr: "91581",
    besökspostnr: "91598",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2409",
    kommunsNamn: "Robertsfors",
    skolenhetsKod: "75624738",
    skolenhetensNamn: "Djäkneboda skola",
    postnr: "91581",
    besökspostnr: "91597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2409",
    kommunsNamn: "Robertsfors",
    skolenhetsKod: "37246669",
    skolenhetensNamn: "Jenningsskolan åk F-3",
    postnr: "91581",
    besökspostnr: "91531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2409",
    kommunsNamn: "Robertsfors",
    skolenhetsKod: "21641008",
    skolenhetensNamn: "Nybyskolan",
    postnr: "91581",
    besökspostnr: "91534",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2409",
    kommunsNamn: "Robertsfors",
    skolenhetsKod: "47410806",
    skolenhetensNamn: "Åkullsjöns skola",
    postnr: "91581",
    besökspostnr: "91592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2417",
    kommunsNamn: "Norsjö",
    skolenhetsKod: "81294589",
    skolenhetensNamn: "Bastuträsk skola",
    postnr: "93581",
    besökspostnr: "93061",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2417",
    kommunsNamn: "Norsjö",
    skolenhetsKod: "10421130",
    skolenhetensNamn: "Norsjöskolan F-9",
    postnr: "93581",
    besökspostnr: "93532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2417",
    kommunsNamn: "Norsjö",
    skolenhetsKod: "73666149",
    skolenhetensNamn: "Norsjöskolan Tjädern sär",
    postnr: "93581",
    besökspostnr: "93532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2418",
    kommunsNamn: "Malå",
    skolenhetsKod: "69141080",
    skolenhetensNamn: "Nilaskolan F-3",
    postnr: "93921",
    besökspostnr: "93931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2421",
    kommunsNamn: "Storuman",
    skolenhetsKod: "71588344",
    skolenhetensNamn: "Gunnarns skola",
    postnr: "92051",
    besökspostnr: "92051",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2421",
    kommunsNamn: "Storuman",
    skolenhetsKod: "46194530",
    skolenhetensNamn: "Parkskolan",
    postnr: "92381",
    besökspostnr: "92381",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2421",
    kommunsNamn: "Storuman",
    skolenhetsKod: "23282133",
    skolenhetensNamn: "Sameskolan Tärnaby",
    postnr: "92064",
    besökspostnr: "92064",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2421",
    kommunsNamn: "Storuman",
    skolenhetsKod: "52561988",
    skolenhetensNamn: "Skytteanska skolan",
    postnr: "92532",
    besökspostnr: "92532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2421",
    kommunsNamn: "Storuman",
    skolenhetsKod: "96944864",
    skolenhetensNamn: "Slussfors skola",
    postnr: "92397",
    besökspostnr: "92397",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2421",
    kommunsNamn: "Storuman",
    skolenhetsKod: "57644285",
    skolenhetensNamn: "Stensele skola",
    postnr: "92341",
    besökspostnr: "92341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2422",
    kommunsNamn: "Sorsele",
    skolenhetsKod: "85060217",
    skolenhetensNamn: "Ammarnäs skola",
    postnr: "92481",
    besökspostnr: "92495",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2422",
    kommunsNamn: "Sorsele",
    skolenhetsKod: "23923676",
    skolenhetensNamn: "Blattnicksele skola",
    postnr: "92481",
    besökspostnr: "92492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2422",
    kommunsNamn: "Sorsele",
    skolenhetsKod: "69943442",
    skolenhetensNamn: "Vindelälvsskolan F-3",
    postnr: "92481",
    besökspostnr: "92432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2425",
    kommunsNamn: "Dorotea",
    skolenhetsKod: "66468123",
    skolenhetensNamn: "Risbäck",
    postnr: "91703",
    besökspostnr: "91703",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2425",
    kommunsNamn: "Dorotea",
    skolenhetsKod: "17181067",
    skolenhetensNamn: "Strandenskolan F-9",
    postnr: "91732",
    besökspostnr: "91732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2460",
    kommunsNamn: "Vännäs",
    skolenhetsKod: "53454010",
    skolenhetensNamn: "Hällfors friskola",
    postnr: "91191",
    besökspostnr: "91191",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2460",
    kommunsNamn: "Vännäs",
    skolenhetsKod: "92854681",
    skolenhetensNamn: "Vegaskolan Förskoleklass",
    postnr: "91181",
    besökspostnr: "91131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2460",
    kommunsNamn: "Vännäs",
    skolenhetsKod: "71858612",
    skolenhetensNamn: "Vännäsby skola F-3",
    postnr: "91181",
    besökspostnr: "91135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2462",
    kommunsNamn: "Vilhelmina",
    skolenhetsKod: "16889382",
    skolenhetensNamn: "Dikanäs skola",
    postnr: "92394",
    besökspostnr: "92394",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2462",
    kommunsNamn: "Vilhelmina",
    skolenhetsKod: "79002691",
    skolenhetensNamn: "Saxnäs skola",
    postnr: "91088",
    besökspostnr: "91088",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2462",
    kommunsNamn: "Vilhelmina",
    skolenhetsKod: "45737019",
    skolenhetensNamn: "Strandskolan",
    postnr: "91234",
    besökspostnr: "91234",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2462",
    kommunsNamn: "Vilhelmina",
    skolenhetsKod: "55174274",
    skolenhetensNamn: "Volgsjö skola F-3",
    postnr: "91281",
    besökspostnr: "91233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2463",
    kommunsNamn: "Åsele",
    skolenhetsKod: "56562631",
    skolenhetensNamn: "Fredrika skola",
    postnr: "91993",
    besökspostnr: "91993",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2463",
    kommunsNamn: "Åsele",
    skolenhetsKod: "43833784",
    skolenhetensNamn: "Åsele Centralskola åk 1 - 9",
    postnr: "91931",
    besökspostnr: "91931",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "25521617",
    skolenhetensNamn: "Backens skola",
    postnr: "90362",
    besökspostnr: "90362",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "25207162",
    skolenhetensNamn: "Berghemsskolan",
    postnr: "90338",
    besökspostnr: "90338",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "79923530",
    skolenhetensNamn: "Botsmarks skola",
    postnr: "92276",
    besökspostnr: "92276",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "37553014",
    skolenhetensNamn: "Brännlands skola",
    postnr: "90592",
    besökspostnr: "90592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "28764965",
    skolenhetensNamn: "Bullmarks skola",
    postnr: "91892",
    besökspostnr: "91892",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "45419793",
    skolenhetensNamn: "Böleängsskolan 1",
    postnr: "90435",
    besökspostnr: "90435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "16095592",
    skolenhetensNamn: "Carlshöjdsskolan ",
    postnr: "90741",
    besökspostnr: "90741",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "78472427",
    skolenhetensNamn: "Ersdungens skola",
    postnr: "90625",
    besökspostnr: "90625",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "47009887",
    skolenhetensNamn: "Ersmarks skola",
    postnr: "90637",
    besökspostnr: "90637",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "69702464",
    skolenhetensNamn: "Ersängsskolan F-6",
    postnr: "90626",
    besökspostnr: "90626",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "37063267",
    skolenhetensNamn: "Flurkmarks skola",
    postnr: "90594",
    besökspostnr: "90594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "38044067",
    skolenhetensNamn: "Grisbacka skola F-6",
    postnr: "90354",
    besökspostnr: "90354",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "84622889",
    skolenhetensNamn: "Hagaskolan F-9",
    postnr: "90345",
    besökspostnr: "90345",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "68950088",
    skolenhetensNamn: "Hannaskolan",
    postnr: "90364",
    besökspostnr: "90364",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "69788677",
    skolenhetensNamn: "Hedlundaskolan",
    postnr: "90322",
    besökspostnr: "90322",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "60423731",
    skolenhetensNamn: "Hissjö skola",
    postnr: "90591",
    besökspostnr: "90591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "38219300",
    skolenhetensNamn: "Hällsboskolan Umeå",
    postnr: "90364",
    besökspostnr: "90364",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "60878225",
    skolenhetensNamn: "Hörnefors Centralskola F-6",
    postnr: "90531",
    besökspostnr: "90531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "96264838",
    skolenhetensNamn: "Innertavle skola",
    postnr: "90596",
    besökspostnr: "90586",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "87810158",
    skolenhetensNamn: "Kasamarks skola",
    postnr: "90586",
    besökspostnr: "90440",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "45157993",
    skolenhetensNamn: "Linblommans skola",
    postnr: "90626",
    besökspostnr: "90626",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "23470743",
    skolenhetensNamn: "Mariaskolan",
    postnr: "90362",
    besökspostnr: "90362",
    inriktning: "Konfessionell",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "38619250",
    skolenhetensNamn: "Mariebergsskolan 1",
    postnr: "90643",
    besökspostnr: "90643",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "50574364",
    skolenhetensNamn: "Mariehemsskolan",
    postnr: "90651",
    besökspostnr: "91342",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "75713343",
    skolenhetensNamn: "Minervaskolan",
    postnr: "90330",
    besökspostnr: "90330",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "96298476",
    skolenhetensNamn: "Obbola skola",
    postnr: "90581",
    besökspostnr: "90581",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "77368623",
    skolenhetensNamn: "Röbäcks skola",
    postnr: "90440",
    besökspostnr: "90581",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "32965875",
    skolenhetensNamn: "Rödåsels skola",
    postnr: "92267",
    besökspostnr: "91832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "88958524",
    skolenhetensNamn: "Rödängsskolan",
    postnr: "90353",
    besökspostnr: "91832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "73411818",
    skolenhetensNamn: "Sandalidens skola",
    postnr: "90346",
    besökspostnr: "91832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "76474572",
    skolenhetensNamn: "Sjöfruskolan 2",
    postnr: "90751",
    besökspostnr: "90751",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "24030101",
    skolenhetensNamn: "Skärgårdsskolan",
    postnr: "91332",
    besökspostnr: "91332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "67694999",
    skolenhetensNamn: "Sofiehemsskolan",
    postnr: "90738",
    besökspostnr: "90431",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "55815397",
    skolenhetensNamn: "Språkskolan",
    postnr: "90656",
    besökspostnr: "90656",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "62950059",
    skolenhetensNamn: "Stöcke skola",
    postnr: "90581",
    besökspostnr: "90581",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "45314528",
    skolenhetensNamn: "Stöcksjö skola",
    postnr: "90581",
    besökspostnr: "90337",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "90639590",
    skolenhetensNamn: "Sävar skola",
    postnr: "91832",
    besökspostnr: "91832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "99722916",
    skolenhetensNamn: "Södra Ålidhemsskolan F-5",
    postnr: "90732",
    besökspostnr: "90732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "19305941",
    skolenhetensNamn: "Sörfors skola",
    postnr: "90588",
    besökspostnr: "90732",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "67083722",
    skolenhetensNamn: "Sörmjöle skola",
    postnr: "90583",
    besökspostnr: "90332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "18541909",
    skolenhetensNamn: "Tavelsjö skola",
    postnr: "92262",
    besökspostnr: "90626",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "77145359",
    skolenhetensNamn: "Tomtebogårds skola",
    postnr: "90750",
    besökspostnr: "90750",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "36053080",
    skolenhetensNamn: "Täfteå skola",
    postnr: "90788",
    besökspostnr: "90788",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "10310119",
    skolenhetensNamn: "Umeå Montessoriskola",
    postnr: "90364",
    besökspostnr: "90364",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "16183719",
    skolenhetensNamn: "Umeå Waldorfskola, gr",
    postnr: "90362",
    besökspostnr: "90362",
    inriktning: "Waldorf",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "61648125",
    skolenhetensNamn: "Västangårds skola F-6",
    postnr: "90364",
    besökspostnr: "90364",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "48538034",
    skolenhetensNamn: "Östermalmsskolan",
    postnr: "90332",
    besökspostnr: "90332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "72356204",
    skolenhetensNamn: "Östra Ersbodaskolan",
    postnr: "90626",
    besökspostnr: "90626",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "26177892",
    skolenhetensNamn: "Östtegs skola",
    postnr: "90421",
    besökspostnr: "90421",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2480",
    kommunsNamn: "Umeå",
    skolenhetsKod: "64493166",
    skolenhetensNamn: "Överboda skola",
    postnr: "90588",
    besökspostnr: "90588",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "15825322",
    skolenhetensNamn: "Forsdalaskolan F-6",
    postnr: "92181",
    besökspostnr: "92136",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "76316166",
    skolenhetensNamn: "Furuviks skola",
    postnr: "92181",
    besökspostnr: "92146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "36036642",
    skolenhetensNamn: "Hedlunda skola",
    postnr: "92181",
    besökspostnr: "92192",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "19481421",
    skolenhetensNamn: "Knaftens skola",
    postnr: "92181",
    besökspostnr: "92192",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "65602740",
    skolenhetensNamn: "Kristinebergs skola",
    postnr: "92181",
    besökspostnr: "92040",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "73265454",
    skolenhetensNamn: "Norrängsskolan",
    postnr: "92181",
    besökspostnr: "92141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "70212191",
    skolenhetensNamn: "Rusksele skola",
    postnr: "92181",
    besökspostnr: "92194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "21774215",
    skolenhetensNamn: "Södermalmsskolan",
    postnr: "92181",
    besökspostnr: "92134",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2481",
    kommunsNamn: "Lycksele",
    skolenhetsKod: "17245575",
    skolenhetensNamn: "Umgransele skola",
    postnr: "92181",
    besökspostnr: "92191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "32952944",
    skolenhetensNamn: "Auraskolan",
    postnr: "93232",
    besökspostnr: "93232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "66002503",
    skolenhetensNamn: "Bergsbyskolan",
    postnr: "93153",
    besökspostnr: "93153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "15720147",
    skolenhetensNamn: "Björnåkersskolan",
    postnr: "93731",
    besökspostnr: "93731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "58219202",
    skolenhetensNamn: "Bodbysunds miljöskola",
    postnr: "93793",
    besökspostnr: "93793",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "39160736",
    skolenhetensNamn: "Bolidenskolan",
    postnr: "93632",
    besökspostnr: "93632",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "90056405",
    skolenhetensNamn: "Bruksskolan",
    postnr: "93237",
    besökspostnr: "93237",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "97784361",
    skolenhetensNamn: "Bureskolan",
    postnr: "93252",
    besökspostnr: "93252",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "97072671",
    skolenhetensNamn: "Bygdsiljumsskolan",
    postnr: "93733",
    besökspostnr: "93733",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "23992844",
    skolenhetensNamn: "Byskeskolan",
    postnr: "93451",
    besökspostnr: "93451",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "33502848",
    skolenhetensNamn: "Drängsmarksskolan",
    postnr: "93496",
    besökspostnr: "93496",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "75972486",
    skolenhetensNamn: "Ersmarksskolan",
    postnr: "93441",
    besökspostnr: "93441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "81992480",
    skolenhetensNamn: "Floraskolan",
    postnr: "93139",
    besökspostnr: "93139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "62717625",
    skolenhetensNamn: "Furuskolan",
    postnr: "93651",
    besökspostnr: "93651",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "29124242",
    skolenhetensNamn: "Gummarksskolan",
    postnr: "93195",
    besökspostnr: "93195",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "81715530",
    skolenhetensNamn: "Hjoggböleskolan",
    postnr: "93194",
    besökspostnr: "93194",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "92621428",
    skolenhetensNamn: "Internationella Engelska Skolan Skellefteå",
    postnr: "93144",
    besökspostnr: "93144",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "58425006",
    skolenhetensNamn: "Kusmarksskolan",
    postnr: "93493",
    besökspostnr: "93493",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "67809657",
    skolenhetensNamn: "Kågeskolan",
    postnr: "93432",
    besökspostnr: "93432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "53655678",
    skolenhetensNamn: "Lejonströmsskolan",
    postnr: "93144",
    besökspostnr: "93144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "87194036",
    skolenhetensNamn: "Lövångersskolan",
    postnr: "93010",
    besökspostnr: "93261",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "13331693",
    skolenhetensNamn: "Medleskolan",
    postnr: "93198",
    besökspostnr: "93198",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "48983548",
    skolenhetensNamn: "Morö backe skola",
    postnr: "93151",
    besökspostnr: "93151",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "20566987",
    skolenhetensNamn: "Norrhammarskolan",
    postnr: "93141",
    besökspostnr: "93141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "30772723",
    skolenhetensNamn: "Ostviksskolan",
    postnr: "93491",
    besökspostnr: "93491",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "54562453",
    skolenhetensNamn: "Sjungande dalens skola",
    postnr: "93146",
    besökspostnr: "93146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "27115850",
    skolenhetensNamn: "Skråmträskskolan",
    postnr: "93196",
    besökspostnr: "93196",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "30012770",
    skolenhetensNamn: "Stackgrönnanskolan",
    postnr: "93191",
    besökspostnr: "93191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "12558459",
    skolenhetensNamn: "Sunnanåskolan",
    postnr: "93164",
    besökspostnr: "93164",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "89661489",
    skolenhetensNamn: "Sörböleskolan",
    postnr: "93162",
    besökspostnr: "93162",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "45749845",
    skolenhetensNamn: "Tuböleskolan",
    postnr: "93155",
    besökspostnr: "93155",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "15716434",
    skolenhetensNamn: "Ursviksskolan",
    postnr: "93236",
    besökspostnr: "93236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "81215860",
    skolenhetensNamn: "Vebomarks Byaskola",
    postnr: "93294",
    besökspostnr: "93294",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "24",
    länsNamn: "Västerbottens län",
    kommun: "2482",
    kommunsNamn: "Skellefteå",
    skolenhetsKod: "71675943",
    skolenhetensNamn: "Yttre Ursviksskolan",
    postnr: "93235",
    besökspostnr: "93235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2505",
    kommunsNamn: "Arvidsjaur",
    skolenhetsKod: "16305064",
    skolenhetensNamn: "Parkskolan",
    postnr: "93381",
    besökspostnr: "93081",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2505",
    kommunsNamn: "Arvidsjaur",
    skolenhetsKod: "13473423",
    skolenhetensNamn: "Tallbackaskolan",
    postnr: "93381",
    besökspostnr: "93333",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2506",
    kommunsNamn: "Arjeplog",
    skolenhetsKod: "14254350",
    skolenhetensNamn: "Silverskolan",
    postnr: "93832",
    besökspostnr: "93832",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2510",
    kommunsNamn: "Jokkmokk",
    skolenhetsKod: "66600682",
    skolenhetensNamn: "Porjus skola",
    postnr: "98260",
    besökspostnr: "98260",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2510",
    kommunsNamn: "Jokkmokk",
    skolenhetsKod: "91024314",
    skolenhetensNamn: "Sameskolan Jokkmokk",
    postnr: "96232",
    besökspostnr: "96232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2510",
    kommunsNamn: "Jokkmokk",
    skolenhetsKod: "89451025",
    skolenhetensNamn: "Vuollerims Centralskola",
    postnr: "96270",
    besökspostnr: "96270",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2510",
    kommunsNamn: "Jokkmokk",
    skolenhetsKod: "19143733",
    skolenhetensNamn: "Västra skolan",
    postnr: "96232",
    besökspostnr: "96232",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2513",
    kommunsNamn: "Överkalix",
    skolenhetsKod: "57062315",
    skolenhetensNamn: "Åkerskolan",
    postnr: "95681",
    besökspostnr: "95631",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2513",
    kommunsNamn: "Överkalix",
    skolenhetsKod: "13342436",
    skolenhetensNamn: "Överkalix Friskola",
    postnr: "95631",
    besökspostnr: "95631",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "33183794",
    skolenhetensNamn: "Centrumskolan",
    postnr: "95221",
    besökspostnr: "95231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "45591937",
    skolenhetensNamn: "Djuptjärnsskolan",
    postnr: "95242",
    besökspostnr: "95242",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "20849857",
    skolenhetensNamn: "Grundsärskolan F-6",
    postnr: "95281",
    besökspostnr: "95231",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "35503602",
    skolenhetensNamn: "Innanbäckens skola",
    postnr: "95251",
    besökspostnr: "95251",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "20525218",
    skolenhetensNamn: "Innovitaskolan Kalix",
    postnr: "95262",
    besökspostnr: "95262",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "41544515",
    skolenhetensNamn: "Näsbyskolan",
    postnr: "95262",
    besökspostnr: "95262",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "43043051",
    skolenhetensNamn: "Sangis skola",
    postnr: "95272",
    besökspostnr: "95272",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "77268380",
    skolenhetensNamn: "Töre skola",
    postnr: "95243",
    besökspostnr: "95243",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2514",
    kommunsNamn: "Kalix",
    skolenhetsKod: "94022488",
    skolenhetensNamn: "Ytterbyns skola",
    postnr: "95250",
    besökspostnr: "95250",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2518",
    kommunsNamn: "Övertorneå",
    skolenhetsKod: "46252858",
    skolenhetensNamn: "Hietaniemi Friskoleförening",
    postnr: "95795",
    besökspostnr: "95795",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2518",
    kommunsNamn: "Övertorneå",
    skolenhetsKod: "90707437",
    skolenhetensNamn: "Svanstein skola Diamanten",
    postnr: "95794",
    besökspostnr: "95794",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2518",
    kommunsNamn: "Övertorneå",
    skolenhetsKod: "53794508",
    skolenhetensNamn: "Svarträvens skola F-3",
    postnr: "95785",
    besökspostnr: "95731",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2521",
    kommunsNamn: "Pajala",
    skolenhetsKod: "55662841",
    skolenhetensNamn: "Gårdbyskolan",
    postnr: "98433",
    besökspostnr: "98433",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2521",
    kommunsNamn: "Pajala",
    skolenhetsKod: "35837794",
    skolenhetensNamn: "Kangos Kultur- o ekologiskola",
    postnr: "98063",
    besökspostnr: "98063",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2521",
    kommunsNamn: "Pajala",
    skolenhetsKod: "36111143",
    skolenhetensNamn: "Sattajärvi skola",
    postnr: "98433",
    besökspostnr: "98492",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2521",
    kommunsNamn: "Pajala",
    skolenhetsKod: "94203685",
    skolenhetensNamn: "Smedskolan",
    postnr: "98422",
    besökspostnr: "98061",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2521",
    kommunsNamn: "Pajala",
    skolenhetsKod: "37347252",
    skolenhetensNamn: "Tärendö Centralskola",
    postnr: "98493",
    besökspostnr: "98493",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "45945515",
    skolenhetensNamn: "Emmaskolan F-6",
    postnr: "98292",
    besökspostnr: "98292",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "73575145",
    skolenhetensNamn: "Hakkas Centralskola",
    postnr: "98041",
    besökspostnr: "98041",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "45062453",
    skolenhetensNamn: "Hedskolan L",
    postnr: "98235",
    besökspostnr: "98235",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "58713286",
    skolenhetensNamn: "Mariaskolan F-6",
    postnr: "98236",
    besökspostnr: "98236",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "82657877",
    skolenhetensNamn: "Myranskolan LM",
    postnr: "98341",
    besökspostnr: "98341",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "95103750",
    skolenhetensNamn: "Puoltikasvaara skola F-6",
    postnr: "98205",
    besökspostnr: "98205",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "81098221",
    skolenhetensNamn: "Sameskolan Gällivare",
    postnr: "98237",
    besökspostnr: "98237",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "70483173",
    skolenhetensNamn: "Tallbackaskolan ",
    postnr: "98334",
    besökspostnr: "98334",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2523",
    kommunsNamn: "Gällivare",
    skolenhetsKod: "70754502",
    skolenhetensNamn: "Tjautjasjaure skola",
    postnr: "98303",
    besökspostnr: "98303",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2560",
    kommunsNamn: "Älvsbyn",
    skolenhetsKod: "92553260",
    skolenhetensNamn: "Knut Lundmarkskolan",
    postnr: "94285",
    besökspostnr: "94233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2560",
    kommunsNamn: "Älvsbyn",
    skolenhetsKod: "87068079",
    skolenhetensNamn: "Parkskolan",
    postnr: "94285",
    besökspostnr: "94233",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2560",
    kommunsNamn: "Älvsbyn",
    skolenhetsKod: "86476920",
    skolenhetensNamn: "Vidselskolan",
    postnr: "94295",
    besökspostnr: "94295",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2560",
    kommunsNamn: "Älvsbyn",
    skolenhetsKod: "39729986",
    skolenhetensNamn: "Vistträskskolan",
    postnr: "94285",
    besökspostnr: "94291",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "41443442",
    skolenhetensNamn: "Alviksskolan",
    postnr: "97593",
    besökspostnr: "97593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "66927569",
    skolenhetensNamn: "Antnässkolan",
    postnr: "97593",
    besökspostnr: "97593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "90467643",
    skolenhetensNamn: "Avanskolan",
    postnr: "97594",
    besökspostnr: "97594",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "87101377",
    skolenhetensNamn: "Benzeliusskolan",
    postnr: "97561",
    besökspostnr: "97561",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "71219716",
    skolenhetensNamn: "Borgmästarskolan",
    postnr: "97342",
    besökspostnr: "97342",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "89780532",
    skolenhetensNamn: "Boskataskolan",
    postnr: "97634",
    besökspostnr: "97634",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "77545704",
    skolenhetensNamn: "Ersnässkolan",
    postnr: "97592",
    besökspostnr: "97592",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "11064551",
    skolenhetensNamn: "Furuparksskolan 0-3",
    postnr: "97434",
    besökspostnr: "97434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "87224520",
    skolenhetensNamn: "Hedskolan",
    postnr: "97253",
    besökspostnr: "97253",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "55520065",
    skolenhetensNamn: "Innovitaskolan Luleå",
    postnr: "97633",
    besökspostnr: "97633",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "77641387",
    skolenhetensNamn: "Klöverträskskolan",
    postnr: "97591",
    besökspostnr: "97591",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "27240899",
    skolenhetensNamn: "Mandaskolan",
    postnr: "97252",
    besökspostnr: "97252",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "99392998",
    skolenhetensNamn: "Mariebergsskolan",
    postnr: "95434",
    besökspostnr: "95434",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "35085794",
    skolenhetensNamn: "Montessoriskolan",
    postnr: "97332",
    besökspostnr: "97332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "27071357",
    skolenhetensNamn: "Måttsundsskolan",
    postnr: "97595",
    besökspostnr: "97595",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "82183259",
    skolenhetensNamn: "Nya Läroverket",
    postnr: "97322",
    besökspostnr: "97331",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "52734580",
    skolenhetensNamn: "Nya Läroverket Bergnäset",
    postnr: "97322",
    besökspostnr: "97254",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "49867789",
    skolenhetensNamn: "Nya Läroverket Svartöstaden",
    postnr: "97322",
    besökspostnr: "97437",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "91656996",
    skolenhetensNamn: "Ormbergsskolan",
    postnr: "97631",
    besökspostnr: "95531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "11872888",
    skolenhetensNamn: "Persöskolan",
    postnr: "97597",
    besökspostnr: "97597",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "44278936",
    skolenhetensNamn: "Porsöskolan",
    postnr: "97752",
    besökspostnr: "97752",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "89136155",
    skolenhetensNamn: "Rutviksskolan 0-6",
    postnr: "97596",
    besökspostnr: "97596",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "21937355",
    skolenhetensNamn: "Råneskolan 0-6",
    postnr: "95531",
    besökspostnr: "95531",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "13938935",
    skolenhetensNamn: "Sunderbyskolan 0-3",
    postnr: "95441",
    besökspostnr: "95441",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "35535722",
    skolenhetensNamn: "Svedjeskolan",
    postnr: "97452",
    besökspostnr: "97452",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "54468318",
    skolenhetensNamn: "Tallkronanskolan 0-6",
    postnr: "97442",
    besökspostnr: "97442",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "28004366",
    skolenhetensNamn: "Tunaskolan 0-3",
    postnr: "97343",
    besökspostnr: "97343",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "40752367",
    skolenhetensNamn: "Vitådalens skola",
    postnr: "95596",
    besökspostnr: "95596",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "97585644",
    skolenhetensNamn: "Ängskolan",
    postnr: "97455",
    besökspostnr: "97455",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "67615094",
    skolenhetensNamn: "Öhemsskolan",
    postnr: "95432",
    besökspostnr: "95432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "25018983",
    skolenhetensNamn: "Örnässkolan 0-6",
    postnr: "97435",
    besökspostnr: "97435",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2580",
    kommunsNamn: "Luleå",
    skolenhetsKod: "58093790",
    skolenhetensNamn: "Östra Skolan 0-3",
    postnr: "97241",
    besökspostnr: "97241",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "54570457",
    skolenhetensNamn: "Backeskolan",
    postnr: "94148",
    besökspostnr: "94148",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "16816091",
    skolenhetensNamn: "Backgårdsskolan",
    postnr: "94532",
    besökspostnr: "94532",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "55036987",
    skolenhetensNamn: "Bergsviksskolan",
    postnr: "94472",
    besökspostnr: "94472",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "10654481",
    skolenhetensNamn: "Björklundaskolan",
    postnr: "94331",
    besökspostnr: "94331",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "50775398",
    skolenhetensNamn: "Böle skola",
    postnr: "94191",
    besökspostnr: "94191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "12534565",
    skolenhetensNamn: "Dalbackens Friskola",
    postnr: "94493",
    besökspostnr: "94493",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "48863212",
    skolenhetensNamn: "I ur och skur - Forsen",
    postnr: "94294",
    besökspostnr: "94294",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "71768337",
    skolenhetensNamn: "Jävre skola",
    postnr: "94472",
    besökspostnr: "94494",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "52474877",
    skolenhetensNamn: "Klubbgärdsskolan",
    postnr: "94139",
    besökspostnr: "94139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "15031493",
    skolenhetensNamn: "Kullenskolan",
    postnr: "94332",
    besökspostnr: "94332",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "27957195",
    skolenhetensNamn: "Långskataskolan",
    postnr: "94135",
    besökspostnr: "94135",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "11749297",
    skolenhetensNamn: "Munksunds skola",
    postnr: "94153",
    besökspostnr: "94153",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "12947382",
    skolenhetensNamn: "Norrbyskolan",
    postnr: "94432",
    besökspostnr: "94432",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "52510171",
    skolenhetensNamn: "Norrmalmskolan",
    postnr: "94133",
    besökspostnr: "94133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "78813157",
    skolenhetensNamn: "Rosviks skola",
    postnr: "94533",
    besökspostnr: "94533",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "60057447",
    skolenhetensNamn: "Rönnskolan",
    postnr: "94133",
    besökspostnr: "94133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "48156668",
    skolenhetensNamn: "Sjulsmarks skola",
    postnr: "94593",
    besökspostnr: "94593",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "50133724",
    skolenhetensNamn: "Strömnäs skola",
    postnr: "94140",
    besökspostnr: "94128",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "67036296",
    skolenhetensNamn: "Svensby skola",
    postnr: "94640",
    besökspostnr: "94640",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2581",
    kommunsNamn: "Piteå",
    skolenhetsKod: "44290268",
    skolenhetensNamn: "Tolvmans Friskola",
    postnr: "94473",
    besökspostnr: "94473",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "55055777",
    skolenhetensNamn: "Fagernäs skola",
    postnr: "96186",
    besökspostnr: "96137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "98998541",
    skolenhetensNamn: "Fria Emilia",
    postnr: "96136",
    besökspostnr: "96136",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "82094905",
    skolenhetensNamn: "Harads skola",
    postnr: "96186",
    besökspostnr: "96024",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "53832535",
    skolenhetensNamn: "Hedensskolan",
    postnr: "96186",
    besökspostnr: "96146",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "65431245",
    skolenhetensNamn: "Mimers Brunn Vittjärv",
    postnr: "96196",
    besökspostnr: "96196",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "75860725",
    skolenhetensNamn: "Mårängskolan",
    postnr: "96150",
    besökspostnr: "96150",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "40708452",
    skolenhetensNamn: "Noblaskolan Boden",
    postnr: "96175",
    besökspostnr: "96175",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "31341183",
    skolenhetensNamn: "Prästholmsskolan",
    postnr: "96131",
    besökspostnr: "96131",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "98704682",
    skolenhetensNamn: "Svartbjörnsbyns skola",
    postnr: "96142",
    besökspostnr: "96142",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "97359904",
    skolenhetensNamn: "Tankeborgen Svartlå Fria",
    postnr: "96198",
    besökspostnr: "96198",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "67642516",
    skolenhetensNamn: "Torpgärdsskolan",
    postnr: "96186",
    besökspostnr: "96139",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "10411502",
    skolenhetensNamn: "Unbyns skola",
    postnr: "96186",
    besökspostnr: "96193",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2582",
    kommunsNamn: "Boden",
    skolenhetsKod: "29649408",
    skolenhetensNamn: "Älvskolan",
    postnr: "96186",
    besökspostnr: "96197",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2583",
    kommunsNamn: "Haparanda",
    skolenhetsKod: "91185118",
    skolenhetensNamn: "Aspstrandens skola",
    postnr: "95385",
    besökspostnr: "95385",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2583",
    kommunsNamn: "Haparanda",
    skolenhetsKod: "26619765",
    skolenhetensNamn: "Karungi skola",
    postnr: "95393",
    besökspostnr: "95393",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2583",
    kommunsNamn: "Haparanda",
    skolenhetsKod: "74486554",
    skolenhetensNamn: "Marielund- Språkskolan",
    postnr: "95336",
    besökspostnr: "95336",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2583",
    kommunsNamn: "Haparanda",
    skolenhetsKod: "80142495",
    skolenhetensNamn: "Montessoriskolan Droppen",
    postnr: "95334",
    besökspostnr: "95334",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2583",
    kommunsNamn: "Haparanda",
    skolenhetsKod: "31409065",
    skolenhetensNamn: "Nikkala skola",
    postnr: "95395",
    besökspostnr: "95395",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2583",
    kommunsNamn: "Haparanda",
    skolenhetsKod: "68499692",
    skolenhetensNamn: "Seskarö skola",
    postnr: "95394",
    besökspostnr: "95394",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "76026322",
    skolenhetensNamn: "Abisko skola",
    postnr: "98107",
    besökspostnr: "98107",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "89218995",
    skolenhetensNamn: "Bergaskolan",
    postnr: "98185",
    besökspostnr: "98133",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "60427298",
    skolenhetensNamn: "Jukkasjärvi skola",
    postnr: "98191",
    besökspostnr: "98191",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "79467784",
    skolenhetensNamn: "Karesuando skola",
    postnr: "98016",
    besökspostnr: "98016",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "21524917",
    skolenhetensNamn: "Luossavaaraskolan",
    postnr: "98185",
    besökspostnr: "98140",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "88461066",
    skolenhetensNamn: "Nya Raketskolan f-3",
    postnr: "98185",
    besökspostnr: "98144",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "75113975",
    skolenhetensNamn: "Sameskolan Karesuando",
    postnr: "98016",
    besökspostnr: "98016",
    inriktning: "",
  },
  {
    huvudmanatyp: "Statlig",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "28247720",
    skolenhetensNamn: "Sameskolan Kiruna",
    postnr: "98137",
    besökspostnr: "98137",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "21462543",
    skolenhetensNamn: "Svappavaara skola",
    postnr: "98020",
    besökspostnr: "98020",
    inriktning: "",
  },
  {
    huvudmanatyp: "Enskild",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "58205262",
    skolenhetensNamn: "Thoren Framtid Vita vidderna",
    postnr: "98147",
    besökspostnr: "98147",
    inriktning: "Allmän",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "84886020",
    skolenhetensNamn: "Tuolluvaara skola",
    postnr: "98185",
    besökspostnr: "98141",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "76314428",
    skolenhetensNamn: "Vittangi skola",
    postnr: "98010",
    besökspostnr: "98010",
    inriktning: "",
  },
  {
    huvudmanatyp: "Kommunal",
    län: "25",
    länsNamn: "Norrbottens län",
    kommun: "2584",
    kommunsNamn: "Kiruna",
    skolenhetsKod: "30318937",
    skolenhetensNamn: "Övre Soppero skola",
    postnr: "98014",
    besökspostnr: "98014",
    inriktning: "",
  },
];

export default skolor;
