import { ReactNode, createContext, useState } from "react";

interface Props {
  children: ReactNode;
}

interface AccountContextType {
  accountLoading: boolean;
  setAccountLoading: (accountLoading: boolean) => void;
}

const defaultContext: AccountContextType = {
  accountLoading: false,
  setAccountLoading: (accountLoading: boolean) => {},
};

export const AccountLoadingContext =
  createContext<AccountContextType>(defaultContext);

const AccountLoadingProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const [accountLoading, setAccountLoading] = useState<boolean>(false);

  return (
    <AccountLoadingContext.Provider
      value={{
        accountLoading: accountLoading,
        setAccountLoading: setAccountLoading,
      }}
    >
      {children}
    </AccountLoadingContext.Provider>
  );
};

export default AccountLoadingProvider;
