import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../config/FirebaseConfig";
import { RequestHeadersContext } from "../context/requestHeadersContext";
import { getAppCheckToken } from "../utils/appCheck";
import useConsent from "./useConsent";

export const useHeaders = () => {
  const { consent } = useConsent();
  const [user] = useAuthState(firebaseAuth);
  const [baseHeadersWithAuth, setBaseHeadersWithAuth] = useState<any>(null);
  const [baseHeadersLoading, setBaseHeadersLoading] = useState(false);

  const { requestHeaders, setRequestHeaders, validUntil } = useContext(
    RequestHeadersContext
  );

  useEffect(() => {
    if (requestHeaders !== undefined) {
      setBaseHeadersWithAuth(requestHeaders);
    }
  }, [requestHeaders]);

  useEffect(() => {
    if (user) {
      setBaseHeadersLoading(true);
      const nowPreFetch = new Date(Date.now());
      if (validUntil) {
        if (validUntil.current.getTime() < nowPreFetch.getTime())
          (async () => {
            const idToken = await user.getIdToken();
            const headers = {
              "X-Firebase-AppCheck": await getAppCheckToken(
                process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
                consent !== false && consent !== undefined
              ),
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            };
            const nowPostFetch = new Date(Date.now());
            const expirationTime = new Date(
              nowPostFetch.getTime() + 5 * 60 * 1000
            );
            validUntil.current = expirationTime;
            setRequestHeaders(headers);
          })();
        setBaseHeadersLoading(false);
      }
    }
  }, [user, validUntil]);

  return { baseHeaders: baseHeadersWithAuth, baseHeadersLoading };
};
