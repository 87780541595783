import { Autocomplete, TextField } from "@mui/material";
import { Option } from "../../types/common";
import React, { ChangeEvent, useEffect, useState } from "react";
import kommuner from "../../data/kommuner";

interface Props {
  selectedKommun: Option<string> | null;
  setSelectedKommun: (kommun: Option<string> | null) => void;
  label: string;
  name: string;
  runValidation: boolean | "true";
  kommunError: string | null;
  setKommunError: (value: string | null) => void;
}

const KommunAutoCompleteField = ({
  selectedKommun,
  setSelectedKommun,
  label,
  name,
  runValidation,
  kommunError,
  setKommunError,
}: Props) => {
  const kommunerOptions = kommuner.map((kommun) => ({
    label: kommun.namn,
    id: kommun.kommunkod,
  }));

  const handleKommunChange = (
    event: ChangeEvent<{}>,
    value: { label: string; id: string } | null
  ) => {
    if (kommunError !== null) {
      setKommunError(null);
    }
    setSelectedKommun(value);
  };

  const validateKommunSelected = () => {
    const isSelected = selectedKommun !== null;
    if (isSelected === false) {
      setKommunError("Du måste välja en kommun.");
    } else {
      setKommunError(null);
    }
    return isSelected;
  };

  useEffect(() => {
    if (runValidation) {
      validateKommunSelected();
    }
  }, [runValidation]);

  return (
    <>
      <Autocomplete
        fullWidth
        value={selectedKommun ?? null}
        onChange={handleKommunChange}
        options={kommunerOptions}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          option.label === value.label && option.id === value.id
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <span>{option.label}</span>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            key={name}
            {...params}
            label={label}
            name={name}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
            error={!!kommunError}
            helperText={kommunError}
          />
        )}
      />
    </>
  );
};

export default KommunAutoCompleteField;
