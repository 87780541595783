import { Backdrop, Box, Typography } from "@mui/material";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

interface Args {
  percentage: number | null;
  label: string;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export const UploadingOverlay = ({ percentage, label }: Args) => {
  const open = percentage ? true : false;
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={open}>
      <Typography>{label}</Typography>
      {percentage && <LinearProgressWithLabel value={percentage} />}
    </Backdrop>
  );
};
