import React, { useEffect } from "react";
import { auth } from "../../../services/firebase";
import { applyActionCode } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { asString } from "../../../utils/validations";
import axios from "axios";
import { getAppCheckToken } from "../../../utils/appCheck";
import { LoadingOverlay } from "../../commons/LoadingOverlay";
import useConsent from "../../../hooks/useConsent";

interface Props {
  actionCode: string;
  lang: string;
  continueUrl: string | null;
}

const HandleVerifyEmail = ({ actionCode, lang, continueUrl }: Props) => {
  const { consent } = useConsent();
  const navigate = useNavigate();

  useEffect(() => {
    const handleVerifyEmail = async (
      actionCode: string,
      lang: string | null,
      continueUrl: string | null
    ) => {
      await applyActionCode(auth, actionCode)
        .then(async (resp) => {
          const parsedUrl = new URL(asString(continueUrl));

          const headers = {
            "X-Firebase-AppCheck": await getAppCheckToken(
              process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
              consent !== false && consent !== undefined
            ),
            "Content-Type": "application/json",
          };

          const referred = parsedUrl.searchParams.get("referred");
          const referrer = parsedUrl.searchParams.get("referrer");

          if (referred !== null && referrer !== null) {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/users/count-referral`,
              {
                referred,
                referrer,
              },
              { headers }
            );
          }

          navigate({ pathname: parsedUrl.pathname, search: parsedUrl.search });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    handleVerifyEmail(actionCode, lang, continueUrl);
  }, []);
  return <LoadingOverlay loading={true} />;
};

export default HandleVerifyEmail;
