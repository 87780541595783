import React, { useState } from "react";
import { useHeaders } from "./useHeaders";
import axios, { AxiosError } from "axios";
import { getAppCheckToken } from "../utils/appCheck";
import useConsent from "./useConsent";

const useSchool = () => {
  const { consent } = useConsent();
  const { baseHeaders } = useHeaders();
  const [loadingSchool, setLoading] = useState(false);
  const [errorSchool, setError] = useState<Error | null>(null);

  const getSchoolExists = async (
    schoolId: string
  ): Promise<boolean | undefined> => {
    let headers = baseHeaders;

    if (headers === null) {
      headers = await (async () => {
        const headers = {
          "X-Firebase-AppCheck": await getAppCheckToken(
            process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
            consent !== false && consent !== undefined
          ),
          "Content-Type": "application/json",
        };
        return headers;
      })();
    }

    try {
      setLoading(true);
      const schoolRes = await axios.get(
        `${process.env.REACT_APP_API_URL}/schools/${schoolId}/exists`,
        {
          headers: headers,
        }
      );
      setLoading(false);
      return schoolRes.data.exists;
    } catch (err) {
      const error = err as AxiosError;
      setLoading(false);
      if (error.response?.status === 404) {
        return false;
      }
      setError(err as Error);
    }
  };

  return {
    getSchoolExists,
    loadingSchool,
    errorSchool,
  };
};

export default useSchool;
